<app-user-bar class="d-none d-lg-block" [page]="'mis-datos'"></app-user-bar>
<div class="cont">


  <div>
    <div class="formstep">
      <div class="section">
        <div class="row">
          <div class="col-lg-6 col-sm-6">

            <h1 class="title">

              <img src="assets/images/user-1.png" srcset="assets/images/user-1@2x.png 2x,
                                    assets/images/user-1@3x.png 3x" class="icon">
              {{'user-page-text1'|translate}} </h1>
          </div>
          <div class="col-lg-6 col-sm-6">
            <p class="help">{{'user-page-text2'|translate}} <span [routerLink]="['/contacto']"
                class="pink">{{'user-page-text3'|translate}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="row">
          <div class="col-lg-6 col-sm-6 m-45" *ngIf="user.name">
            <label for="">{{'user-page-text4'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45-2 font-weight-bold" *ngIf="user.name">{{user.name}} {{user.surname}}</div>
          <div class="col-lg-6 col-sm-6 m-45" *ngIf="user.document_number">
            <label for="">{{'user-page-text5'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45-2 font-weight-bold" *ngIf="user.document_number">
            <img src="assets/images/document-grey.png" srcset="assets/images/document-grey@2x.png 2x,
                                    assets/images/document-grey@3x.png 3x">
            NIF: {{user.document_number}}
            <div class="incompleteAccount" *ngIf="incompleteDoc">
              <p>{{'user-page-text6'|translate}}
                {{'user-page-text7'|translate}}
                <br>
                <br>
                <span class="pink" (click)="modalDocumentation()">{{'user-page-text8'|translate}}</span>
              </p>
            </div>
            <div class="incompleteAccount" *ngIf="!incompleteDoc && user.validated == 0">
              <p>{{'user-page-text9'|translate}}
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-sm-6 m-45 " *ngIf="user.email">
            <label for="">{{'user-page-text10'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45 font-weight-bold" *ngIf="user.email">{{user.email}}</div>
          <div class="col-lg-6 col-sm-6 m-45">
            <label for="">{{'user-page-text11'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45">**********
            <!--<span class="pink">{{'user-page-text12'|translate}}</span>-->
          </div>
          <div class="col-lg-6 col-sm-6 m-45" *ngIf="user.phone">
            <label for="">{{'user-page-text13'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45 font-weight-bold" *ngIf="user.phone">{{user.phone}}</div>
          <div class="col-lg-6 col-sm-6 m-45">
            <label for="">{{'user-page-text58'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45">
            <select name="" id="" (change)="changeLang($event.target.value)" [(ngModel)]="user.language">
              <option value="ES">ES</option>
              <option value="EN">EN</option>
              <option value="FR">FR</option>
            </select>
          </div>
          <div class="col-lg-6 col-sm-6 m-45" *ngIf="user.address">
            <label for="">{{'user-page-text14'|translate}}</label>
          </div>
          <div class="col-lg-6 col-sm-6 m-45 font-weight-bold" *ngIf="user.address">{{user.address}}<br>{{user.city}}
            {{user.cp}}
            ({{user.province}})<br><a [routerLink]="['/completar-datos']" class="pink">Cambiar</a></div>
          <div class="col-lg-12 col-sm-12 m-45"><br><a (click)="removeAccount()"
              class="pink">{{'user-page-text15'|translate}}</a></div>
          <div class="col-lg-8 col-sm 8">
            <div class="incompleteAccount" *ngIf="incomplete">
              <p>{{'user-page-text16'|translate}}</p>
              <a [routerLink]="['/completar-datos'] " class="pink">{{'user-page-text17'|translate}}</a>
            </div>
          </div>

        </div>
      </div>
      <div class="section">
        <div class="">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <h1 class="title">
                {{'user-page-text18'|translate}} </h1>
            </div>
            <div class="col-lg-6 col-sm-6">
              <p class="help"><span (click)="addRepresentation()" class="pink">{{'user-page-text19'|translate}}</span>
              </p>
            </div>
          </div>
          <div class="row"
            style="height: 56px; border-radius:4px;  background-color: var(--primary-light); font-family: Lato;
            font-size: 16px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 1.31; letter-spacing: -0.01px; color: var(--primary);">
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text20'|translate}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text21'|translate}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text22'|translate}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text23'|translate}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text24'|translate}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{'user-page-text61'|translate}} / {{'user-page-text25'|translate}}</div>
          </div>
          <div class="row  tableData" *ngFor="let representation of representations let i = index">
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{representation.name}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{representation.cif}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{representation.address}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{representation.nationality}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData">{{getCompany(representation.type)}}</div>
            <div class="col-2 col-lg-2 col-sm-2 rowData d-flex flex-row justify-content-start align-items-center">
              <a class="mr-4" style="color:var(--dark);cursor:pointer"
                (click)="editRepresentation(representation.id)">
                <i class="fa fa-edit"></i>
              </a>
              <a style="cursor:pointer"
                (click)="removeRepresentation(representation.id)">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h1 class="title" style="margin-bottom:40px;">{{'user-page-text26'|translate}}</h1>
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="user.notification_auction_end"
                (change)="changeNotificationAuctionEnd($event.target.checked)" id="check_auction_end">
              <label class="form-check-label" for="check_auction_end">
                {{'user-page-text27'|translate}}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="user.notification_outbid"
                (change)="changeNotificationOutBid($event.target.checked)" id="check_out_bid">
              <label class="form-check-label" for="check_out_bid">
                {{'user-page-text28'|translate}}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="user.notification_auction_won"
                (change)="changeNotificationAuctionWon($event.target.checked)" id="check_auction_won">
              <label class="form-check-label" for="check_auction_won">
                {{'user-page-text29'|translate}}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="user.notification_favorite_end"
                (change)="changeNotificationFavoriteEnd($event.target.checked)" id="check_favorite_end">
              <label class="form-check-label" for="check_favorite_end">
                {{'user-page-text30'|translate}}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="user.notification_favorite_start"
                (change)="changeNotificationFavoriteStart($event.target.checked)" id="check_favorite_start">
              <label class="form-check-label" for="check_favorite_start">
                {{'user-page-text31'|translate}}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MODALS-->

<ng-template #documentationModal>
  <div class="modal-header">
    <h6 class="modal-title">{{'user-page-text32'|translate}}</h6>
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png,image/gif">
      <ngx-dropzone-label>{{'user-page-text33'|translate}}</ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
        [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button" class="btn altBtn" (click)="modalRef.hide()"
      data-dismiss="modal">{{'user-page-text34'|translate}}</button>
    <button type="button" class="btn primaryBtn" data-dismiss="modal" [disabled]="submittingDoc"
      (click)="uploadDoc()">{{'user-page-text35'|translate}}</button>
  </div>
</ng-template>
<ng-template #representationModal>
  <div class="modal-header">
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="section">
      <div class="row">
        <form [formGroup]="registrationForm">
          <h4>{{'user-page-text36'|translate}}</h4>
          <div class="row">
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">{{'user-page-text37'|translate}}</label><br>
              <input type="text" formControlName="name">
            </div>
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">{{'user-page-text38'|translate}}</label><br>
              <input type="text" formControlName="cif">
            </div>
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">{{'user-page-text39'|translate}}</label><br>
              <input type="text" formControlName="address">
            </div>
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">
                {{'user-page-text40'|translate}}
              </label><br>
              <input type="text" formControlName="nationality">
            </div>
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">
                {{'user-page-text41'|translate}}
              </label><br>
              <select name="" id="" formControlName="type">
                <option value="company">{{'user-page-text42'|translate}}</option>
                <option value="individual">{{'user-page-text43'|translate}}</option>
                <option value="community">{{'user-page-text44'|translate}}</option>
                <option value="others">{{'user-page-text45'|translate}}</option>
              </select>
            </div>
            <div class="field-group field col-sm-6 col-lg-6">
              <label for="" class="field-label minilabel">
                Documentación <span class="text-danger" role="alert">
                  (CIF, escrituras, poderes, NIF)*
                </span>
              </label>

              <br>
              <span class="files">
                <input type="file" multiple class="files" id="files" (change)="handleFileInput($event.target.files)">
              </span>
              <label for="files">
                <span><i class="fa-solid fa-arrow-up-from-bracket"></i>  Subir documentos</span>
              </label>
              <div id="ficheros" class="ficheros" *ngIf="representation_files">
                <p><b>Documentos</b></p>
                <ul>
                  <li *ngFor="let representation_file of representation_files">
                    {{representation_file.name}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button" class="btn altBtn" (click)="modalRef.hide()"
      data-dismiss="modal">{{'user-page-text46'|translate}}</button>
    <button *ngIf="representation_id == null" type="button" class="btn primaryBtn" data-dismiss="modal"
      [disabled]="!this.registrationForm.valid || submittingRegistration"
      (click)="addRegistration()">{{'user-page-text48'|translate}}</button>
    <button *ngIf="representation_id != null" type="button" class="btn primaryBtn" data-dismiss="modal"
      [disabled]="!this.registrationForm.valid || submittingRegistration"
      (click)="updateRepresentation()">{{'user-page-text61'|translate}}</button>
  </div>
</ng-template>


<ng-template #confirmModal>
  <div class="modal-header">
    <h6 class="modal-title">{{'user-page-text49'|translate}}</h6>
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'user-page-text50'|translate}}</p>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button" class="btn altBtn" (click)="modalRef.hide()"
      data-dismiss="modal">{{'user-page-text51'|translate}}</button>
    <button type="button" class="btn primaryBtn" data-dismiss="modal"
      (click)="removeRepresentationConfirm()">{{'user-page-text52'|translate}}</button>
  </div>
</ng-template>
<ng-template #confirmModalRemoveAccount>
  <div class="modal-header">
    <h6 class="modal-title">{{'user-page-text53'|translate}}</h6>
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{'user-page-text54'|translate}}
    </p>
    <p>
      {{'user-page-text55'|translate}}
    </p>
    <p class="text-danger" *ngIf="userAuctionsValid > 0">
      *{{'user-page-text59'|translate}}{{userAuctionsValid}}{{'user-page-text60'|translate}}
    </p>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button" class="btn altBtn" (click)="modalRef.hide()"
      data-dismiss="modal">{{'user-page-text56'|translate}}</button>
    <button type="button" class="btn primaryBtn" data-dismiss="modal"
      (click)="removeAccountPermanent()">{{'user-page-text57'|translate}}</button>
  </div>
</ng-template>