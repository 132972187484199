<div class="cont">
  <div>
    <div class="formstep">
      <div class="row flex-column">
        <div style="border-bottom: solid 1px var(--grey-2); margin-bottom: 25px;">
          <div class="row d-none d-lg-flex">
            <div class="col-2">
              <img src="assets/images/sin-texto.png"
                   srcset="assets/images/sin-texto@2x.png 2x,
                                assets/images/sin-texto@3x.png 3x">
            </div>
            <div class="col-10">
              <h1 class="stepTitle">
               {{'invalid-token-text1'|translate}}
              </h1>
              <p>
                {{'invalid-token-text2'|translate}}
              </p>
            </div>
          </div>
          <div class="row d-lg-none">
            <div class="col-sm-2 col-lg-2">
              <img src="assets/images/sin-texto.png"
                   srcset="assets/images/sin-texto@2x.png 2x,
                                assets/images/sin-texto@3x.png 3x"
                   class="SinTexto">
            </div>
            <div class="col-sm-10 col-lg-10">
              <!-- <h1 class="stepTitle">     {{'invalid-token-text2'|translate}}</h1> -->
            </div>
          </div>
        </div>
        <form>
          <div class="form-register">
            <!-- <h3 class="sublabel">{{'invalid-token-text1'|translate}}</h3> -->
            <div class="fields-container row">
              <p class="w-100">
                {{'invalid-token-text3'|translate}}
              </p>
              <p class="w-100">
                {{'invalid-token-text4'|translate}}
              </p>
            </div>
          </div>
        </form>

        <div class="w-100">
          <button type="button"
            class="next btn secondaryBG white"
            (click) = urlLogin()
          >
            {{'invalid-token-text5'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
