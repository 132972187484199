<div class="cont">
  <div>
    <div class="formstep">
      <div class="row flex-column">
        <div style="border-bottom: solid 1px var(--grey-2); margin-bottom: 25px;">
          <div class="row d-none d-lg-flex">
            <div class="col-2">
              <img src="assets/images/sin-texto.png"
                   srcset="assets/images/sin-texto@2x.png 2x,
                                assets/images/sin-texto@3x.png 3x">
            </div>
            <div class="col-10">
              <h1 class="stepTitle">
               {{'recover-password-text1'|translate}}
              </h1>
              <p>
                {{'recover-password-text2'|translate}}
              </p>
            </div>
          </div>
          <div class="row d-lg-none">
            <div class="col-sm-2 col-lg-2">
              <img src="assets/images/sin-texto.png"
                   srcset="assets/images/sin-texto@2x.png 2x,
                                assets/images/sin-texto@3x.png 3x"
                   class="SinTexto">
            </div>
            <div class="col-sm-10 col-lg-10">
              <h1 class="stepTitle">     {{'recover-password-text1'|translate}}</h1>
            </div>
            <p class="w-100 title-form">
              {{'recover-password-text2'|translate}}
            </p>
          </div>
        </div>
        <form [formGroup]="recoverform">
          <div class="form-register">
            <h3 class="sublabel">{{'recover-password-text3'|translate}}</h3>
            <div class="fields-container row">
              <div class="field-group field col-sm-12 col-lg-12">
                  <!--<input [type]="inputType1 ? 'text' : 'password'" formControlName="password" placeholder="Contraseña" class="input-password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-\.]).{8,}$">-->
                    <input [type]="inputType1 ? 'text' : 'password'" formControlName="password" placeholder="Contraseña" class="input-password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$">
                  <span (click)="changeInputType(1)" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                <div *ngIf="submitted && rf1.password.errors">
                  <p class="validation-error" *ngIf="rf1.password.errors.required">
                    {{'register-text6'|translate}}
                  </p>
                  <p class="validation-error" *ngIf="rf1.password.errors.minlength">
                    {{'register-text7'|translate}}
                  </p>
									<p class="validation-error" *ngIf="rf1.password.errors.pattern">
										{{'register-text13'|translate}}
									</p>
                </div>
                <div *ngIf="submitted && error">
                  <p class="validation-error">
                    {{'register-text14'|translate}}
                  </p>
                </div>
              </div>
              <div class="field-group field col-sm-12 col-lg-12">
                  <input [type]="inputType2 ? 'text' : 'password'" formControlName="password_confirmation" placeholder="Repetir Contraseña" class="input-password" 
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$">
                  <span (click)="changeInputType(2)" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                <div *ngIf="submitted && rf1.password_confirmation.errors">
                  <p class="validation-error" *ngIf="rf1.password_confirmation.errors.required">
                    {{'register-text8'|translate}}
                  </p>
                  <p class="validation-error" *ngIf="rf1.password_confirmation.errors.notmatched">
                    {{'register-text9'|translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="w-100">
          <button type="button"
            class="next btn secondaryBG white"
            [disabled]="submitting"
            (click)="onFormSubmit()">
            {{'recover-password-text4'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
