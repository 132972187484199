<div class="localization">
	<div class="row">
		<div class="col-sm-4 col-lg-4">
			<h3>{{'location-text1'|translate}}</h3>

			<hr class="mb-3">

			<div class="row address justify-content-center">
				<div class="col-3 col-sm-2 col-lg-2 image">
					<img src="assets/images/location_pink.png"
						srcset="assets/images/location_pink@2x.png 2x,
						assets/images/location_pink@3x.png 3x"
						class="">
				</div>

				<div class="col-9 col-sm-10 col-lg-10 mb-3">
					{{'location-text2'|translate}}
				</div>

				<div class="col-3 col-sm-2 col-lg-2 image">
					<img src="assets/images/time.png"
						srcset="assets/images/time@2x.png 2x,
						assets/images/time@3x.png 3x"
						class="">
				</div>

				<div class="col-9 col-sm-10 col-lg-10 mb-3">
					{{'location-text3'|translate}}
				</div>

				<div class="col-3 col-sm-2 col-lg-2 image">
					<img src="assets/images/mail_pink.png"
						srcset="assets/images/mail_pink@2x.png 2x,
						assets/images/mail_pink@3x.png 3x"
						class="">
				</div>

				<div class="col-9 col-sm-10 col-lg-10 mb-3">
					<strong>{{'location-text4'|translate}}</strong>
				</div>
				<div class="col-3 col-sm-2 col-lg-2 image">
					<img src="assets/images/phone_pink.png"
						srcset="assets/images/phone_pink@2x.png 2x,
						assets/images/phone_pink@3x.png 3x"
						class="">
				</div>
				<div class="col-9 col-sm-10 col-lg-10 mb-3">
					{{'location-text5'|translate}}
				</div>
			</div>
		</div>

		<div class="col-sm-8 col-lg-8">
			<iframe [src]="mapsurl" width="100%" height="325" frameborder="0" allowfullscreen class="google-maps-frame">
			</iframe>
		</div>
	</div>
</div>
