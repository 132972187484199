import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  openPdf() {
    var myPdfUrl = '/assets/SI-0441_21-02 sign.pdf'

    window.open(myPdfUrl);
  }

  openPdf2() {
    var myPdfUrl = '/assets/ENS-0245_22_signed.pdf'

    window.open(myPdfUrl);
  }
}
