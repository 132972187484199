<div class="cont">
      <div class="formstep">
          <div style="margin-bottom: 25px; width: 100%; position: relative;">
                <img src="assets/images/email.png"
                srcset="assets/images/email@2x.png 2x,
                        assets/images/email@3x.png 3x"
                class="email">
                <h2 class="title">{{'review-page-text1'|translate}}</h2>
                <hr>
                <p>
                  {{'review-page-text2'|translate}}  {{mail}}. <br>{{'review-page-text3'|translate}}
                </p>
                <button class="btn secondaryBG white" style="margin: 0 auto; display:block" [routerLink]="['/login']">{{'review-page-text4'|translate}}</button>
          </div>
      </div>
</div>
