import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService } from '../services/utils.service';
import { OthersService } from '../services/others.service';

@Component({
	selector: 'app-faq-page',
	templateUrl: './faq-page.component.html',
	styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
	activeTab = 'top';
	faqs: any[] = []
	faqs_master: any[] = []
	selected: number = 1;

	constructor(
		private translateService: TranslateService,
		private utilsService: UtilsService,
		public others: OthersService
	) {
	}

	ngOnInit(): void {

		this.getData();

		this.utilsService.lang.subscribe(lang => {
			this.getData();
		});
	}

	getData() {

		let lang = this.utilsService.lang.getValue();

		this.others.getFAQ(lang).subscribe(
			val => {
				let faqs = val['response'].filter(f => f.Faq_Question != '' && f.Faq_Answer != '');
				this.faqs = faqs;
				this.faqs_master = faqs;
				this.faqs = this.faqs.filter(f => f.category_id === 1);
			}
		)
	}

	filterCat(id) {
		this.selected = id;
		this.faqs = Object.assign([], this.faqs_master);
		this.faqs = this.faqs.filter(f => f.category_id === id);
		return this.faqs;
	}

	onSearchChange(search) {
		if (search != "") {
			this.faqs = this.faqs_master.filter(f => f.category_id === this.selected)
				.filter(q => q.Faq_Question.includes(''+search));
		} else {
			this.faqs = this.faqs_master.filter(f => f.category_id === this.selected);
		}
	}
}
