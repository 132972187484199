import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuctionsService {
  private url = environment.url
  private headers;

  constructor(public http: HttpClient) {
  }

  private setHeaders() {
    this.headers = new HttpHeaders().set("Accept", "application/json").set("Authorization", localStorage.getItem('TOKEN'))
  }

  getAuction(id) {
    return this.http.get(this.url + "/api/auctions/public/" + id , {headers: this.headers})

  }

  getBidsFromAuction(id) {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/" + id + "/bids", {headers: this.headers})
  }

  getBidsFromUser(id) {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/user/bids", {headers: this.headers})
  }


  setAuctionToUser(auction_id) {
    this.setHeaders();
    return this.http.post(this.url + "/api/auctions/user/create", {"auction_id": auction_id}, {headers: this.headers});
  }

  sendIncidence(incidence: {}, id) {
    this.setHeaders();
    return this.http.post(this.url + "/api/auctions/" + id + "/incidence", incidence, {headers: this.headers});
  }
}
