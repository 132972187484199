<div style="background-color:var(--grey-1);">
  <div class="row">
    <div class="col-12 col-lg-6 login">
      <div class="card">
        <div class="row">
          <div class="col-sm-2 col-lg-2">
            <img src="assets/images/sin-texto.png"
                 srcset="assets/images/sin-texto@2x.png 2x,
                                assets/images/sin-texto@3x.png 3x"
                 class="SinTexto">
          </div>
          <div class="col-sm-10 col-lg-10">
            <h1 class="log-in">{{ 'login-text1' | translate }}</h1>
          </div>
        </div>
        <div class="row" style="height: 32px;">
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-12 ">
            <form>
              <div class="fields-container field ">
                <label class="field-label minilabel  d-none d-lg-block">{{ 'login-text2' | translate }}</label>
                <br>
                <input placeholder="Email" type="text" [(ngModel)]="user.mail" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="fields-container field">
                <input [type]="inputType ? 'text' : 'password'" [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" placeholder="Contraseña" class="input-password">
                <span (click)="changeInputType()" class="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>
              <div class="fields-container">
                <span  style="color: red">
                  {{error}}
                </span>
              </div>

            </form>
          </div>
        </div>
        <div class="row" style="height: 32px;"></div>
        <div class="row">
          <div class="col-sm-12 col-lg-12 h-100">
            <button class="btn secondaryBG seeAllButton" (click)="loginUser()">{{ 'login-text3' | translate }}</button>
            <br><br>
            <a class="rememberPass" (click)="openModal()">{{ 'login-text4' | translate }}</a>
          </div>
        </div>

      </div>

    </div>
    <div class="col-12 col-lg-6">
      <div class="bgForm">
        <h3 class="cardTitle d-none d-lg-block">{{ 'login-text6' | translate }}</h3>
        <p class="blue">{{ 'login-text7' | translate }}</p>
        <p class="blue2">
          <img src="assets/images/check-azul.png"
               srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
               class="check-azul">
          {{ 'login-text8' | translate }}
        </p>
        <p class="blue2">
          <img src="assets/images/check-azul.png"
               srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
               class="check-azul">
          {{ 'login-text9' | translate }}
        </p>
        <p class="blue2">
          <img src="assets/images/check-azul.png"
               srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
               class="check-azul">
          {{ 'login-text10' | translate }}
        </p>
        <a class="btn primaryBG whiteTextButton" [routerLink]="['/registrar-sin-certificado']">{{ 'login-text13' | translate }}</a>
      </div>
    </div>
  </div>
</div>

<!--MODALS-->

<ng-template #rememberModal>
  <div class="modal-header">
    <div class="row">
      <div class="col-lg-3 col-sm-3"></div>
      <div class="col-lg-6 col-sm-6">
        <h5 class="modal-title modalTitle">Recordar contraseña</h5>
      </div>
      <div class="col-lg-3 col-sm-3">
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <p class="rememberPassParagraph">Introduce el correo asociado a tu cuenta Trademat y te enviaremos las instrucciones
      para recuperar tu contraseña.</p>
    <form>
      <div class="fields-container field">
        <label class="field-label minilabel">Email</label>
        <br>
        <input type="text" [(ngModel)]="mail_recovery" [ngModelOptions]="{standalone: true}" name="" id="">
      </div>
    </form>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button"
      class="btn secondaryBG white"
      [disabled]="submitting"
      (click)="recoverPassword()"
      data-dismiss="modal">
      Continuar
    </button>
  </div>
</ng-template>
