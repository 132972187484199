import { Component, OnInit } from '@angular/core';
import { Auction, AuctionListResponse } from 'src/app/models/auction';
import { User } from 'src/app/models/users';
import { AssetsService } from "../../services/assets.service";
import { BaseResponse } from "../../models/base-response.model";
import { environment } from "../../../environments/environment";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {
  collapsed = true;
  public user: User
  public auctions: Auction[] = []
  url = environment.url

  constructor(
    private assetsService: AssetsService,
    private userService: UserService,
    private router: Router
  ) {

    if (localStorage.USER) {
      this.user = JSON.parse(localStorage.USER)
    }
  }

  ngOnInit(): void {

    if (this.user) {
      this.reloadValues();
    }

    this.userService.loginSubscriber
    .subscribe(it => {
      if (it && localStorage.USER) {
        this.user = JSON.parse(localStorage.USER)
        this.reloadValues();
      }
    });
  }

  reloadValues() {
    this.assetsService.getMyAuctions()
    .subscribe((response: BaseResponse<AuctionListResponse>) => {
      //this.auctions = response.response.auctions_list.slice(0, 3);
      this.auctions = response.response.auctions_list;
    })
  }

  getLastBid(a: Auction) {
    return a.last_bid ? a.last_bid.import : a.price;
  }

  logoutUser() {
    // localStorage.clear();
    const items = { ...localStorage };
    for(let item in items) {
      if (item != 'LANG') localStorage.removeItem(item);
    }
    
    location.href = "";
  }

  navigateProduct(auction: Auction) {

    const auctionId = auction ? auction.id : null;

    this.router.navigate(['/producto', auctionId])
    .then(nav => {
      window.location.reload();
    }, err => {
      console.log(err)
    });
  }
}