<div class="recent-actives">

	<div class="recent-actives__contents">
		<div class="center" style="padding-bottom: 64px">

			<h2 class="sectionTitle center">{{ 'active-recents-text1' | translate }}</h2>

			<hr class="sectionTitleBar">

			<p class="primary">
			  {{ 'active-recents-text2' | translate }}
			</p>

			<div class="btn-group btn-group-toggle">
				<div class="buttonGroup" [ngClass]="{'primaryBG': auction == true, 'white' : auction == true, 'active': auction == true, 'btn-primary': auction == false, 'whiteBG': auction == false, 'primary':auction == false}" ngbButtonLabel (click)="toogleSlide(0)">
					<div>
						<i class="fa-solid fa-gavel mr-1"></i>
						{{ 'active-recents-text3' | translate }}
					</div>
				</div>
				<div class="buttonGroup" [ngClass]="{'primaryBG': directSale == true, 'white' : directSale == true, 'active': directSale == true, 'btn-primary': directSale == false, 'whiteBG': directSale == false, 'primary':directSale == false}"ngbButtonLabel (click)="toogleSlide(1)">
					<div>
						<i class="fa-solid fa-money-bill mr-1"></i>
						{{ 'active-recents-text4' | translate }}
					</div>
				</div>
			</div>
		</div>

		<ngb-carousel class="carousel-active p-1" [showNavigationArrows]="false">
			<ng-template ngbSlide *ngFor="let slide of slidesAuction">
				<div class="row">
					<div class="col-12 col-md-4 col-lg-4" *ngFor="let asset of slide">
						<app-product-item-row [auction]="asset"></app-product-item-row>
					</div>
				</div>
			</ng-template>
		</ngb-carousel>

		<div class="recent-actives__button d-flex justify-content-center">
			<button class="btn secondaryBG seeAllButton" [routerLink]="['listado-activos']">{{ 'active-recents-text6' | translate }}</button>
		</div>

	</div>

</div>
