<div class="note-container">
  <p class="note">
    {{note.description}}
  </p>
  <div class="row">
    <p class="col date">{{note.created_at | shortDate}}</p>
    <img src="assets/images/download.png"
         srcset="assets/images/download@2x.png 2x, assets/images/download@3x.png 3x"
         class="download-image col-auto">
    <div class="download col-auto">
      <a href="javascript:void(0)" (click)="openFile(note.document_path)">
        <span *ngIf="!isImage(note.document_path)">{{'dossier-page-text1'|translate}}</span>
        <span *ngIf="isImage(note.document_path)">{{'dossier-page-text3'|translate}}</span>
      </a>
    </div>
  </div>
</div>

<!--MODALS-->

<ng-template #imageModal>
  <div class="modal-header">
    <button type="button" (click)="image_url='';modalRef.hide()" class="close" data-dismiss="modal"
      aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="image-container">
      <img class="note-press-image" [src]="image_url">
    </div>
  </div>
</ng-template>


