<nav aria-label="page navigation example">
  <ul class="pagination">
    <li class="page-item" (click)="onClickPage(activePage - 1)"><a class="page-link" href="javascript:void(0);"> <span
      class="previous"><i class="fa fa-angle-left"></i></span></a></li>

    <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"
        (click)="onClickPage(item)"><a class="page-link items" href="javascript:void(0);">{{item}}</a></li>

    <li class="page-item" (click)="onClickPage(activePage + 1)"><a class="page-link" href="javascript:void(0);"> <span
      class="next"><i class="fa fa-angle-right"></i></span></a></li>
  </ul>
</nav>
