import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Province } from '../models/provinces';
import { User } from '../models/users';
import { OthersService } from '../services/others.service';
import { UserService } from '../services/user.service';
import {Country} from "../models/country";
import {BaseResponse} from "../models/base-response.model";
import {Router} from '@angular/router';
import {UtilsService} from 'src/app/services/utils.service';

@Component({
	selector: 'app-complete-data',
	templateUrl: './complete-data.component.html',
	styleUrls: ['./complete-data.component.scss']
})
export class CompleteDataComponent implements OnInit {
	modalRef: BsModalRef;
	provinces:Province[]
	countries:Country[]
	completeUserForm:FormGroup
	user:User
	submitting: boolean = false;
	statusmsg:string="";
	errorsServer: string[] = [];

	@ViewChild('confirmModal') confirmModal:TemplateRef<any>

	constructor(
		public util: OthersService,
		public fb:FormBuilder,
		private modalService: BsModalService,
		private userService:UserService,
		public router: Router,
		public utils: UtilsService
	) {
	}

	get form() {
		return this.completeUserForm.controls;
	}

	setUserFormFields(){
		this.completeUserForm = this.fb.group({
			id:'',
			name: new FormControl('', [Validators.minLength(2)]),
			surname:'',
			email:'',
			password:null,
			password_confirmation:null,
			document_number:'',
			prefix:'',
			phone:'',
			address:'',
			city:'',
			cp:'',
			province_id:'',
			country_id:'',
		})
	}
	ngOnInit(): void {
		this.setUserFormFields();
		this.util.getCountries().subscribe(
			val=>{
				this.countries = val['response']["country_list"];
			}
		);
		this.userService.getUser().subscribe((value:BaseResponse<User[]>) => {
			this.user = value.response[0]
			this.completeUserForm.patchValue(this.user);
			this.util.getProvinces(this.user.country_id).subscribe(
				val=>{
					this.provinces = val['response']["provinces_list"];
					this.provinces.unshift({ id: null, name: null, Provinces_Image: null });
					this.completeUserForm.patchValue(this.user);
				}
			)
		});
	}
	confirmUserData(){
		this.modalRef = this.modalService.show(this.confirmModal,Object.assign({}, { class: 'gray modal-sm' }));
	}

	updateInfoUser() {
		if (!this.completeUserForm.valid) { return; }

		if (this.submitting) { return; }
		this.submitting = true;

		this.util.formToObject(this.completeUserForm,this.user)
		this.userService.updateUser(this.user)
		.subscribe((val:BaseResponse<any>) => {

			if (val.status == 200) {
				this.utils.showToast('Actualizado con éxito', "success")
				this.userService.getUser().subscribe((value:BaseResponse<User[]>) => {
					this.user = value.response[0]
					localStorage.setItem('USER',JSON.stringify(value.response[0]));
					this.modalRef.hide();
					this.statusmsg = "Actualizado con éxito";
					this.completeUserForm.patchValue(this.user);
					this.router.navigateByUrl('/mis-datos');
				});
			} else {
				
				this.utils.showToast('Ha ocurrido un error al actualizar los datos', "error")
				this.modalRef.hide();
				
				// Show notification for invalid form for error from server
				for(let errors of val.response) {
					for(let field in errors) {
						let error = errors[field];
						this.errorsServer[field] = this.errorsServer.concat(error);
					}
				}
				
			}
			this.submitting = false;

		}, error => {
			this.submitting = false;
		});
	}

	selectedCountry(id: any){
		this.util.getProvinces(id).subscribe(
			val=>{
				this.provinces = val['response']["provinces_list"];
				this.provinces.unshift({ id: null, name: null, Provinces_Image: null });
			}
		)
	}

}
