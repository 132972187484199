<div style="padding: 48px 11%;">
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
    <strong><u><span style="font-size:19px;line-height:107%;">{{'cookie-1' | translate}}
        </span></u></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <strong><u><span style="font-size:9px;line-height:107%;"><span
            style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-2' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-3' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-4' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-5' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-6' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie7' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
    <strong><span style="font-size:21px;line-height:107%;">{{'cookie-8' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:19px;line-height:107%;">{{'cookie-9' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;"> {{'cookie-10' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-11' | translate}}</span><strong><span
        style="font-size:16px;line-height:107%;">&ldquo;</span>www.subastastrademat.com&rdquo;</strong><span
      style="font-size:16px;line-height:107%;">&nbsp;</span><span
      style="font-size:16px;line-height:107%;">{{'cookie-12' | translate}}</span>
  </p>
  <ul style="list-style-type: undefined;">
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-13' | translate}}</span>
    </li>
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-14' | translate}}</span>
    </li>
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-15' | translate}}</span>
    </li>
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-16' | translate}}</span>
    </li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-17' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-18' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:12px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:19px;line-height:107%;">{{'cookie-19' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-20' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;"> <strong>{{'cookie-21' | translate}}</strong></span>
  </p>
  <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-22' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-23' | translate}}</span>
    </li>
  </ul>
  <ul style="list-style-type: undefined;margin-left:68px;">
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-24' | translate}}</span>
    </li>
    <li><span style="line-height:107%;font-size:16px;">{{'cookie-25' | translate}}</span>
    </li>
  </ul>
  <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-26' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-27' | translate}}</span>
    </li>
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-28' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-29' | translate}}</span>
    </li>
  </ul>
  <ul style="list-style-type: undefined;margin-left:68px;">
    <li><span style="line-height:107%;">{{'cookie-30' | translate}}</span><span
        style="line-height:107%;font-size:16px;">&nbsp;{{'cookie-31' | translate}}</span>
    </li>
    <li><span style="line-height:107%;">{{'cookie-32' | translate}}</span><span
        style="line-height:107%;font-size:16px;">&nbsp;{{'cookie-33' | translate}}</span>
    </li>
  </ul>
  <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-34' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-35' | translate}}</span>
    </li>
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-36' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-37' | translate}}</span>
    </li>
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-38' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-39' | translate}}</span>
    </li>
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-40' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-41' | translate}}</span>
    </li>
    <li
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <strong><span style="font-size:16px;line-height:107%;">{{'cookie-42' | translate}}</span></strong><span
        style="font-size:16px;line-height:107%;">{{'cookie-43' | translate}}</span></li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:19px;line-height:107%;">{{'cookie-44' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-45' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">&nbsp;</span></strong></p>
  <table style="float: left;width:100%;border-collapse:collapse;border:none;margin-left:4.8pt;margin-right:4.8pt;">
    <tbody>
      <tr>
        <td colspan="4"
          style="width: 453.35pt;border: 1pt solid windowtext;padding: 0cm 3.5pt;height: 42.2pt;vertical-align: top;">
          <p
            style='margin-top:0cm;margin-right:-8.65pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
            <strong>{{'cookie-46' | translate}}</strong></p>
          <p
            style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:  justify;'>
            {{'cookie-47' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td
          style="width:180.5pt;border:solid windowtext 1.0pt;border-top:  none;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-8.65pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            <strong>{{'cookie-48' | translate}}</strong></p>
        </td>
        <td
          style="width:68.8pt;border-top:none;border-left:none;border-bottom:  solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-6.55pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            <strong>{{'cookie-49' | translate}}</strong></p>
        </td>
        <td
          style="width:48.75pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-5.85pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            <strong>{{'cookie-50' | translate}}</strong></p>
        </td>
        <td
          style="width:155.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-7.45pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            <strong>{{'cookie-51' | translate}}</strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width:180.5pt;border:solid windowtext 1.0pt;border-top:  none;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-8.65pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            cookieconsent_status</p>
        </td>
        <td
          style="width:68.8pt;border-top:none;border-left:none;border-bottom:  solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-6.55pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            1 a&ntilde;o</p>
        </td>
        <td
          style="width:48.75pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-5.85pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
            {{'cookie-52' | translate}}</p>
        </td>
        <td
          style="width:155.3pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:white;padding:4.5pt 7.5pt 4.5pt 0cm;height:15.55pt;">
          <p
            style='margin-top:0cm;margin-right:-7.45pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
            <strong><em>&nbsp;<u>Subastastrademat.com:</u></em></strong></p>
          <p
            style='margin-top:0cm;margin-right:-7.45pt;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
            {{'cookie-53' | translate}} </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">&nbsp;</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:1px;line-height:107%;">&nbsp;</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:19px;line-height:107%;">{{'cookie-54' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-55' | translate}}</span><strong><span
        style="font-size:16px;line-height:107%;">&ldquo;</span>www.subastastrademat.com&rdquo;</strong><span
      style="font-size:16px;line-height:107%;">&nbsp;</span><span
      style="font-size:16px;line-height:107%;">{{'cookie-56' | translate}}<strong>{{'cookie-57' | translate}}</strong>{{'cookie-58' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'cookie-59' | translate}}
      <strong>{{'cookie-60' | translate}}</strong>{{'cookie-61' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:11px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
</div>
