<div class="carousel" *ngIf="auction">

	<!-- Carousel (desktop version) -->

	<ngb-carousel class="d-none d-lg-block" *ngIf="images_gallery">
		<ng-template ngbSlide *ngFor="let img of images_gallery">
			<div class="carousel__image-row">
				<div class="carousel__image" *ngFor="let asset of img">
					<a href="javascript:void(0)" (click)="openImage(asset)">
						<img class="picsum-img-wrapper" src="{{asset}}">
					</a>
				</div>
			</div>
		</ng-template>
	</ngb-carousel>

	<!-- Carousel (mobile version) -->

	<ngb-carousel class="d-lg-none" *ngIf="images_gallery_mobile">
		<ng-template ngbSlide *ngFor="let img of images_gallery_mobile">
			<div class="carousel-mobile__image" *ngFor="let asset of img">
				<a href="javascript:void(0)" (click)="openImage(asset)">
					<img class="picsum-img-wrapper" src="{{asset}}">
				</a>
			</div>
		</ng-template>
	</ngb-carousel>
</div>

<div class="container" *ngIf="auction">
	<div class="product-page__actions">
		<div class="product-page__actions__go-back" style="cursor: pointer;">
			<a [routerLink]="['/listado-activos']">
				<img src="assets/images/arrow-left.png"
					srcset="assets/images/arrow-left@2x.png 2x,
					assets/images/arrow-left@3x.png 3x"
					class="arrow-left">
				<label>{{'product-page-text1'|translate}} </label>
			</a>
		</div>

		<div class="product-page__actions__menu menu">
			<button class="btn whiteBG" (click)="openShareModal()">
				<img src="assets/images/share-1.png"
					srcset="assets/images/share-1@2x.png 2x,
					assets/images/share-1@3x.png 3x"
					class="Share-1">
				{{'product-page-text2'|translate}}
			</button>

			<button class=" btn whiteBG" (click)="toogleFavorite()" *ngIf="this.userExist">
				<i class="fa-solid fa-heart" style="color:var(--primary)!important;opacity:0.5" *ngIf="!auction.fav"></i>
				<i class="fa-solid fa-heart" style="color:#E7288B!important;" *ngIf="auction.fav"></i>
				{{'product-page-text3'|translate}}
			</button>
		</div>

		<div class="d-none d-lg-block"></div>
	</div>

	<div class="product-page__product row" *ngIf="!isPack; else isPackZone">

		<div class="col-12 col-lg-6">
			<div class="mb-2">
				<span *ngIf="auction.id&&auction.auction_type_id==1"
					class="badge badgeToolbar greenBG white"
					style="display: inline-block">
					<img src="assets/images/auction.png"
						srcset="assets/images/auction@2x.png 2x,
						assets/images/auction@3x.png 3x"
						class="auction">
						{{'product-item-text2'|translate}}
				</span>

				<span *ngIf="auction.auctions"
					class="badge badgeToolbar greenBG white"
					style="display: inline-block">
					<img src="assets/images/pack.png"
						srcset="assets/images/pack@2x.png 2x,
						assets/images/pack@3x.png 3x"
						class="auction">
					{{'product-item-text3'|translate}}
				</span>

				<span *ngIf="auction.id&&auction.auction_type_id==2"
					class="badge badgeToolbar purpleBG white"
					style="display: inline-block">
					<img src="assets/images/bag-of-money.png"
						srcset="assets/images/bag-of-money@2x.png 2x,
						assets/images/bag-of-money@3x.png 3x"
						class="auction">
						{{'product-item-text4'|translate}}
				</span>
			</div>

			<h1 class="tituloProducto">{{auction.name}}</h1>

			<div>
				<img src="assets/images/time-dark.png"
					srcset="assets/images/time-dark@2x.png 2x,
					assets/images/time-dark@3x.png 3x"
					class="time-dark">
						{{auction.start_date | customDate}}- {{auction.end_date | customDate}} (ID: {{auction.id}})
			</div>

			<img src="assets/images/location_pink.png"
				srcset="assets/images/location_pink@2x.png 2x,
				assets/images/location_pink@3x.png 3x"
				class="location">

			<label class="direccionActivo">{{auction.address}}, {{auction.city}} ({{auction.province}})</label>

			<div class="blurbs-activo" style="margin-top: 40px; margin-bottom: 40px;">
				<div class="menu">
					<img src="{{auction.type_image}}"
						srcset="{{auction.type_image}} 2x,
						{{auction.type_image}} 3x"
						class="icon_features">
					<label for="" class="label_feature">{{auction.type}}</label>
				</div>
				<div class="menu">
					<img src="assets/images/superficie.png"
							 srcset="assets/images/superficie@2x.png 2x,
												assets/images/superficie@3x.png 3x"
							 class="icon_features">
					<label for="" class="label_feature">{{auction.area}} m<sup>2</sup></label>
				</div>
				<div class="menu">
					<img src="assets/images/bed.png"
							 srcset="assets/images/bed@2x.png 2x,
												assets/images/bed@3x.png 3x"
							 class="icon_features">
					<label for="" class="label_feature">{{auction.bedrooms}} {{'product-page-text5'|translate}}</label>
				</div>
			</div>
			<p class="description">
				{{auction.description}}
			</p>
			<div class="d-lg-none col-12 px-0 ">
				<app-auction-widget [auction]="auction" *ngIf="auction.auction_type_id == 1 "></app-auction-widget>
			</div>
			<div class="">
				<label (click)="selected=0" class="section-s">{{'product-page-text6'|translate}}</label>
				<label (click)="selected=1" *ngIf="auction.deposit" class="section-s">{{'product-page-text7'|translate}}</label>
				<label (click)="selected=4" *ngIf="auction.auction_type_id==2 || auction.auction_type_id==3" class="section-s">{{'product-page-text7-2'|translate}}</label>
				<label (click)="selected=2" *ngIf="assetDocuments.length>0"
							 class="section-s">{{'product-page-text8'|translate}}</label>
				<label (click)="selected=3" class="section-s">{{'product-page-text9'|translate}}</label>
			</div>

			<div *ngIf="selected==0" id="containerDetalles" class="sectionContainer">
				<h2 class="titleContainer">{{'product-page-text10'|translate}}</h2>
				<img src="assets/images/check.png"
						 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
						 class="check">
				<label class="checkLabelTitle">{{'product-page-text11'|translate}}</label>
				<br>
				<label class="detail">{{auction.type}}</label>
				<br>
				<img src="assets/images/check.png"
						 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
						 class="check">
				<label class="checkLabelTitle">{{'product-page-text12'|translate}}</label>
				<br>
				<label class="detail">{{auction.area}}m<sup>2</sup></label>
				<br>

				<img src="assets/images/check.png"
						 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
						 class="check">
				<label class="checkLabelTitle">{{'product-page-text13'|translate}}</label>
				<br>
				<label class="detail">{{auction.address}}, {{auction.city}} ({{auction.province}})</label>
				<br>

				<img src="assets/images/check.png"
						 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
						 class="check">
				<label class="checkLabelTitle">{{'product-page-text14'|translate}}</label>
				<br>
				<label class="detail">{{auction.cadaster_number}}</label>
				<br>
			</div>
			<div id="containerDepositoCondiciones" class="sectionContainer" *ngIf="auction.deposit && selected==1">
				<h2 class="titleContainer">{{'product-page-text15'|translate}}</h2>
				<img src="assets/images/check.png"
						 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
						 class="check">
				<label class="checkLabelTitle">{{'product-page-text16'|translate}}</label>
				<br>
				<label class="detail">{{auction.deposit}} €<br>{{'product-page-text17'|translate}}
				</label>
				<br>
				<a class="auctionConditionsLink" href="{{getUrl(auction.conditions)}}" target="_blank">
					{{'product-page-text18'|translate}}
				</a>
			</div>

			<div id="containerCondiciones" class="sectionContainer" *ngIf="selected == 4 && (auction.auction_type_id == 2 || auction.auction_type_id == 3)">
				<h2 class="titleContainer">{{'product-page-text15-2'|translate}}</h2>

				<label class="detail">{{auction.conditions}}
				</label>
				<br>
				<!-- <a class="auctionConditionsLink" href="{{getUrl(auction.conditions)}}" target="_blank">
					{{'product-page-text18'|translate}}
				</a> -->
			</div>

			<div id="containerDocumentacion" class="sectionContainer"
					 *ngIf="(assetDocuments.length>0 || assetCharges.length>0) &&selected==2">
				<div *ngIf="userExist else hideCharges">
					<div *ngIf="assetDocuments.length>0">
						<h2 class="titleContainer">{{'product-page-text19'|translate}}</h2>
						<ul class="doclist">
							<li *ngFor="let doc of assetDocuments">
								<img src="assets/images/document.png"
										 srcset="assets/images/document@2x.png 2x,
																assets/images/document@3x.png 3x"
										 class="doc-icon"><span class="doctitle">{{doc.name}}</span>
								<br>
								<a class="doclink" target="_blank" href="{{getUrl(doc.path)}}">{{'product-page-text20'|translate}}</a>
							</li>
						</ul>
					</div>
					<div *ngIf="assetCharges.length>0">
						<h2 class="titleContainer">{{'product-page-text21'|translate}}</h2>

						<ul class="doclist">
							<li *ngFor="let chr of assetCharges">
								<img src="assets/images/document.png"
										 srcset="assets/images/document@2x.png 2x,
																				assets/images/document@3x.png 3x"
										 class="doc-icon"><span class="doctitle">{{chr.name}}</span>
								<br>
								<a class="doclink" target="_blank" href="{{getUrl(chr.path)}}">{{'product-page-text20'|translate}}</a>
							</li>
						</ul>

					</div>
				</div>
				<ng-template #hideCharges>

					<div class="blur">
						<h2 class="titleContainer">{{'product-page-text23'|translate}}</h2>
						<ul class="doclist">
							<li>
								<img src="assets/images/document.png"
										 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
										 class="doc-icon"><span class="doctitle"></span>
								<br>
								<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
							</li>
							<li>
								<img src="assets/images/document.png"
										 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
										 class="doc-icon"><span class="doctitle"></span>
								<br>
								<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
							</li>
							<li>
								<img src="assets/images/document.png"
										 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
										 class="doc-icon"><span class="doctitle"></span>
								<br>
								<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
							</li>
						</ul>
					</div>
					<div class="hideModal">
						<h3>{{'product-page-text22'|translate}}</h3>
						<div style="padding:3%; text-align: center;">
							<p>{{'product-page-text24'|translate}} <span>{{'product-page-text25'|translate}}</span></p>
							<button class="btn secondaryBG white"
											[routerLink]="['/login']">{{'product-page-text26'|translate}}</button>
						</div>
					</div>
				</ng-template>
			</div>
			<div id="containerUbicacion" class="sectionContainer" *ngIf="selected==3">
				<h2 class="titleContainer">{{'product-page-text27'|translate}}</h2>
				<google-map [center]="AssetPosition" [zoom]="13" width="100%" height="400px">
					<map-marker
						[position]="AssetPosition"
						[options]="MarkerOptions"
					></map-marker>
				</google-map>
			</div>
		</div>

		<div class="d-none d-lg-block col-sm-1 col-lg-1"></div>

		<div class="d-none d-lg-block col-sm-5 col-lg-5">
			<app-auction-widget [auction]="auction" *ngIf="auction.auction_type_id == 1"></app-auction-widget>
		</div>
	</div>

	<ng-template #isPackZone>
		<div class="row">
			<div class="col-sm-12 col-lg-12">
				<span class="badge badgeToolbar greenBG white">
						<img src="assets/images/pack.png"
								 srcset="assets/images/pack@2x.png 2x,
										assets/images/pack@3x.png 3x"
								 class="auction">
					{{'product-page-text28'|translate}}
				</span>
			</div>
			<div class="col-sm-6 col-lg-6">
				<h1 class="tituloProducto">{{auction.name}}</h1>
				<pre class="description">
					{{auction.description}}
				</pre>

				<div class="sectionTitlesContainer">
					<label (click)="selected=0" class="section-s">{{'product-page-text29'|translate}}</label>
					<label (click)="selected=2" *ngIf="assetDocuments.length>0"
								 class="section-s">{{'product-page-text30'|translate}}</label>
				</div>
				<div id="containerDocumentacion" class="sectionContainer"
						 *ngIf="(assetDocuments.length>0 || assetCharges.length>0) &&selected==2">
					<div *ngIf="userExist else hideCharges">
						<div *ngIf="assetDocuments.length>0">
							<h2 class="titleContainer">{{'product-page-text31'|translate}}</h2>
							<ul class="doclist">
								<li *ngFor="let doc of assetDocuments">
									<img src="assets/images/document.png"
											 srcset="assets/images/document@2x.png 2x,
																assets/images/document@3x.png 3x"
											 class="doc-icon"><span class="doctitle">{{doc.name}}</span>
									<br>
									<a class="doclink" target="_blank" href="{{getUrl(doc.path)}}">{{'product-page-text20'|translate}}</a>
								</li>
							</ul>
						</div>
						<div *ngIf="assetCharges.length>0">
							<h2 class="titleContainer">{{'product-page-text32'|translate}}</h2>

							<ul class="doclist">
								<li *ngFor="let chr of assetCharges">
									<img src="assets/images/document.png"
											 srcset="assets/images/document@2x.png 2x,
																				assets/images/document@3x.png 3x"
											 class="doc-icon"><span class="doctitle">{{chr.name}}</span>
									<br>
									<a class="doclink" target="_blank" href="{{getUrl(chr.path)}}">{{'product-page-text20'|translate}}</a>
								</li>
							</ul>

						</div>
					</div>
					<ng-template #hideCharges>

						<div class="blur">
							<h2 class="titleContainer">{{'product-page-text33'|translate}}</h2>
							<ul class="doclist">
								<li>
									<img src="assets/images/document.png"
											 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
											 class="doc-icon"><span class="doctitle">Lorem Ipsum Dolor Sit Amet</span>
									<br>
									<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
								</li>
								<li>
									<img src="assets/images/document.png"
											 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
											 class="doc-icon"><span class="doctitle">Lorem Ipsum Dolor Sit Amet</span>
									<br>
									<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
								</li>
								<li>
									<img src="assets/images/document.png"
											 srcset="assets/images/document@2x.png 2x,
																		assets/images/document@3x.png 3x"
											 class="doc-icon"><span class="doctitle">Lorem Ipsum Dolor Sit Amet</span>
									<br>
									<a class="doclink" href="#">{{'product-page-text20'|translate}}</a>
								</li>
							</ul>
						</div>
						<div class="hideModal">
							<h3>{{'product-page-text34'|translate}}</h3>
							<div style="padding:3%; text-align: center;">
								<p>{{'product-page-text35'|translate}} <span>{{'product-page-text36'|translate}}</span></p>
								<button class="btn secondaryBG white"
												[routerLink]="['/login']">{{'product-page-text37'|translate}}</button>
							</div>
						</div>
					</ng-template>
				</div>
				<div id="containerDetalles" class="sectionContainer">
					<h2 class="titleContainer">{{'product-page-text38'|translate}}</h2>
					<img src="assets/images/check.png"
							 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
							 class="check">
					<label class="checkLabelTitle">{{'product-page-text39'|translate}}</label>
					<br>
					<label class="detail">{{auction.type}}</label>
					<br>
					<img src="assets/images/check.png"
							 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
							 class="check">
					<label class="checkLabelTitle">{{'product-page-text40'|translate}}</label>
					<br>
					<label class="detail">{{assets.length}}</label>
					<br>

					<img src="assets/images/check.png"
							 srcset="assets/images/check@2x.png 2x,
												assets/images/check@3x.png 3x"
							 class="check">
					<label class="checkLabelTitle">{{'product-page-text41'|translate}}</label>
					<br>
					<label class="detail">{{auction.province}}</label>
					<br>
				</div>

				<div id="containerDocumentacion" class="sectionContainer" *ngIf="assetDocuments.length>0">
					<h2 class="titleContainer">{{'product-page-text42'|translate}}</h2>
					<ul class="doclist">
						<li *ngFor="let doc of assetDocuments">
							<img src="assets/images/document.png"
									 srcset="assets/images/document@2x.png 2x,
														assets/images/document@3x.png 3x"
									 class="doc-icon"><span class="doctitle">{{doc.name}}</span>
							<br>
							<a class="doclink"
								 href="http://trademat.lemurstaging.com{{doc.path}}">{{'product-page-text20'|translate}}</a>
						</li>
					</ul>
				</div>

			</div>
			<div class="col-sm-1 col-lg-1"></div>
			<div class="d-none d-lg-block col-sm-5 col-lg-5" *ngIf="!isPack">
				<app-auction-widget [auction]="auction" [pack]="isPack"
														*ngIf="auction.auction_type_id == 1"></app-auction-widget>
			</div>
			<div class="d-none d-lg-block col-sm-5 col-lg-5" *ngIf="isPack">
				<app-auction-widget [auction]="auction" [pack]="isPack"
														*ngIf="auction.auction_type_id == 1"></app-auction-widget>
			</div>
		</div>
	</ng-template>

	<app-similar-products [id]="auction.asset_id"></app-similar-products>
</div>

<ng-template #auctionConditionsModal>
	<div class="modal-header">
		<div class="row">

			<div class="col-lg-9 col-sm-9">
				<h5 class="modal-title modalTitle">{{'product-page-text43'|translate}}</h5>
			</div>
			<div class="col-lg-3 col-sm-3">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
								aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-body">
		{{AuctionCondition}}
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #shareModal>
	<div class="modal-header">
		<div>Enviar esta subasta</div>
	</div>
	<div class="modal-body">
		<share-buttons theme="circles-light"
									 [include]="['copy', 'facebook', 'email', 'telegram',	'twitter', 'whatsapp']"
									 [showIcon]="true"
									 [showText]="false"
									 url="{{getUrlShare()}}"
									 description="{{auction.description}}"
									 class="pt-5">
		</share-buttons>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #imageModal>
	<div class="modal-header">
		<button type="button" (click)="image_url='';modalRef.hide()" class="close" data-dismiss="modal"
			aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div class="image-container">
			<img class="note-press-image" [src]="image_url">
		</div>
	</div>
</ng-template>