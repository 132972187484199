<div class="row general-style d-lg-flex">
    <div class="col-12 pink-background padding-top14">
      <div class="row">
        <div class="col-12 p-tittle">
          {{'second-live-text1'|translate}}
        </div>
      </div>
    </div>
    <div class="col-12 pink-background">
        <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
                <p>{{'second-live-text2'|translate}}</p>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
    <div class="col-12 margin-bottom44 pink-background">
        <button class="button" [routerLink]="['/#']">{{'second-live-text3'|translate}}</button><button class="button" [routerLink]="['/#']">{{'second-live-text4'|translate}}</button>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12 general-style margin-bottom44">
                <h4>{{'second-live-text5'|translate}}</h4>
            </div>
        </div>
    </div>
    <div class="col-12"><hr class="hr"/></div>
    <div class="col-12">
      <div class="row general-style stadistics d-lg-flex">
          <div class="col-lg-1"></div>
          <div class="col-lg-2 col-sm-12">
              <h4>300M €</h4>
              <span class="smaller">{{'second-live-text6'|translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-12">
              <h4>50M €</h4>
              <span class="smaller">{{'second-live-text7'|translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-12">
              <h4>80 000</h4>
              <span class="smaller">{{'second-live-text8'|translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-12">
              <h4>500 +</h4>
              <span class="smaller">{{'second-live-text9'|translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-12">
              <h4>15</h4>
              <span class="smaller">{{'second-live-text10'|translate}}</span>
          </div>
          <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
