import {Component, Input, OnInit} from '@angular/core';
import {AssetsService} from "../services/assets.service";
import {Asset} from "../models/assets";
import {Router} from "@angular/router";
import {OthersService} from "../services/others.service";
import {Auction} from "../models/auction";

@Component({
  selector: 'app-similar-products',
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.scss']
})
export class SimilarProductsComponent implements OnInit {
  slidesAuction: Auction[][];
  public auctions:Auction[]=[]
  public asset=""
  public auction = false
  @Input() id;
  constructor(public assetServide:AssetsService, public router:Router, public utils:OthersService) {



  }
  slideGenerator(assets:Auction[]){
    var slidesIndex = 0
    var slidesElement = 0
    var slides =[]
    slides[0]=[]
    for(var i = 0;i<assets.length; i++){

      if(slidesElement<=2){
        slides[slidesIndex][slidesElement]=assets[i];
        slidesElement ++

      }else{

        slidesIndex ++
        slidesElement = 0
        slides[slidesIndex]=[]
        slides[slidesIndex][slidesElement]=assets[i];

      }

    }
    return slides
  }

  ngOnInit(): void {
    this.assetServide.getAssetsSimilar(this.id).subscribe(
      val=>{
        this.auctions = val['response']["auctions_list"];
        this.auction = true
        this.slidesAuction = this.slideGenerator(this.auctions)

      }
    )
  }

}

