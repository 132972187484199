import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/users';

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.scss']
})
export class UserBarComponent implements OnInit {
  @Input() page:string
  public user:User
  public last_login:Date
  public formatedDate:string
  constructor(public router:Router) {
    this.user = JSON.parse(localStorage.USER)
    if(localStorage.USER === undefined && localStorage.TOKEN === undefined){
      this.router.navigateByUrl('/login')
    }else if(this.user.confirmed != 1){
      this.router.navigateByUrl('/usuario-no-confirmado')
    }else{
      this.user = JSON.parse(localStorage.USER)
      this.last_login = new Date(localStorage.LAST_LOGIN)

      this.formatedDate = this.last_login.getDay()+"/"+(this.last_login.getMonth()+1)+"/"+this.last_login.getFullYear()+" a las "+this.last_login.getHours()+":"+this.last_login.getMinutes()
    }
  }

  ngOnInit(): void {

  }

}
