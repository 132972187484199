<mat-nav-list>
  <mat-list-item><a class="nav-link" [routerLink]="['/listado-activos']" >
    {{'side-text1'|translate}}
  </a></mat-list-item>
    <mat-list >
      <div>
        <mat-list-item><a ngbDropdownItem href="#" [routerLink]="['/about-us']" (click)="onSidenavClose()">{{'side-text2'|translate}}<br> <span class="secondary">{{'side-text3'|translate}}</span></a></mat-list-item>
        <mat-list-item><a ngbDropdownItem href="#" [routerLink]="['/team']" (click)="onSidenavClose()">
          {{'side-text4'|translate}} <br> <span class="secondary">{{'side-text5'|translate}}</span></a></mat-list-item>
        <mat-list-item><a ngbDropdownItem href="#" [routerLink]="['/comunication']" (click)="onSidenavClose()">{{'side-text6'|translate}}<br>
          <span class="secondary">{{'side-text7'|translate}}</span></a></mat-list-item>
      </div>
    </mat-list>
</mat-nav-list>
