import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public lang : BehaviorSubject<string> = new BehaviorSubject('es');

  constructor(public http:HttpClient, private toastr: ToastrService) { }

  //TOASTR
  showToast(msg,type?) {
    const toastConf = {
      positionClass: 'toast-top-center',
      //positionClass: 'toast-top-right',
      closeButton: true,
      timeOut: 5000,
      enableHtml: true
    }

    switch(type){
      case 'success':
        this.toastr.success(msg, null, toastConf);
        break;
      case 'warning':
        this.toastr.warning(msg, null, toastConf);
        break
      case 'error':
        this.toastr.error(msg, null, toastConf);
        break;
      case 'info':
        this.toastr.info(msg, null, toastConf);
        break;
      default:
        this.toastr.success(msg, null, toastConf);
        break;
    }
  }

  /**
   * Creates a filename.
   */
   public filename(objectName: string) {

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let hh = String(today.getHours()).padStart(2, '0');
    let ii = String(today.getMinutes()).padStart(2, '0');
    let ss = String(today.getSeconds()).padStart(2, '0');

    return `Trademat-${objectName}--${yyyy}${mm}${dd}-${hh}${ii}${ss}`
      .replace(' ','-')
      .toLowerCase();
  }

  /**
   * Helps download a binary file.
   */
  public downloadFile(data: any, type: string, objectName: string) {

    let fileType = '', filename = this.filename(objectName);

    switch (type) {
      case 'xlsx': fileType = 'application/ms-excel'; filename += '.xlsx'; break;
      case 'csv': fileType = 'application/csv'; filename += '.csv'; break;
      case 'pdf': fileType = 'application/pdf'; filename += '.pdf'; break;
    }

    let blob = new Blob([data], {type: fileType});
    let url = window.URL.createObjectURL(blob);
    saveAs(data, filename);
  }

  public showErrors(errors: any) {
    let message: string = '';
    
    let response = errors['error'];
    let data = response['data'];
    let errores = [];
    for (let campo in data){
      let erroresCampo: [];
      erroresCampo = data[campo];
      errores = errores.concat(erroresCampo);
    };
    message = response['message'] + '<br>';
    message += errores.join('<br>');

    return message;
  }
}
