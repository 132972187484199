<div class="rectangle" [style.background-color]="color">
	<div class="row no-gutters">
		<img src="assets/images/{{icon}}.png"
			srcset="assets/images/{{icon}}@2x.png 2x,
			assets/images/{{icon}}@3x.png 3x"
			class="layer">
		<p class="numberResponsive d-lg-none">{{number}}</p>
	</div>
	<div class="row no-gutters">
		<p class="text1">{{text1}}</p>
	</div>
	<div class="row no-gutters"><p class="{{oneline?'text2':'text3'}}">{{text2}}</p>
	</div>
	<p class="number">{{number}}</p>
</div>
