import { Component, OnInit } from '@angular/core';
import { NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { GalleryService } from "../services/gallery.service";
import { GalleryResponse } from "../models/gallery_response";
import { Gallery, GalleryType } from "../models/gallery";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {
  item: any = [{ "name": "Equipo Trademat", "id": 0 }, {
    "name": "Empresa",
    "id": 1
  }, { "name": "Visitas a bienes inmuebles", "id": 2 }, { "name": "Subastas", "id": 3 }];
  images: Gallery[];
  isCollapsed: any = true;
  isCollapsed2: any = true;
  isCollapsed3: any = true;
  isCollapsed1: any = true;
  private galleryResponse: GalleryResponse;

  url = environment.url
  Gallery: [];
  GalleryType: [];
  select: any;
  show: any = false;
  gallery_select: any;
  download: "";

  constructor(private galleryService: GalleryService) {
  }

  ngOnInit(): void {
    /* this.galleryService.getGallery().subscribe(
      val => {
        this.galleryResponse = val['response'];

      }
    ) */
    this.getGallery();
    this.getGalleryType();
  }

  checkOther(collapse1: NgbCollapse, collapse2: NgbCollapse, collapse: NgbCollapse) {
    if (!collapse1.collapsed) {
      collapse1.toggle();
    }
    if (!collapse2.collapsed) {

      collapse2.toggle();
    }
    if (!collapse.collapsed) {

      collapse.toggle();
    }

  }

  selectImages(id: number) {
    this.show = true;
    this.gallery_select = id;
    return this.images = this.Gallery.filter(
      it => it['gallery_type_id'] === id
    );
  }

  /* selectImages(id: number) {
    this.images = this.galleryResponse.gallery_list.filter(it => it.gallery_type_id === id)
  } */

  /* getTypes() {
    return this.galleryResponse.gallery_list.map(it => ({
      id: it.gallery_type_id,
        name: it.type
    }) ).filter(
      (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
    );
  } */

  private getGallery() {
    this.galleryService.getGallery().subscribe(data => {
      this.Gallery = data['response'].gallery_list;
    })
  }

  getGalleryType() {
    this.galleryService.getGalleryTypes().subscribe(data => {
      this.GalleryType = data['response'].gallery_type_list;
    })
  }

  downloadAll(id) {
    this.galleryService.downloadAllImages(id).subscribe(data => {
      console.log(data);
      this.download = data['response'].url
      if(data['status'] == 200){
        window.open(this.download);
      }
      //this.downLoadFile(data, "application/zip")/* "application/pdf" */
    })
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }
}
