<div class="searchSection">
	<div class="imageBack">
		<img src="assets/images/group-3.png"
			srcset="assets/images/group-3@2x.png 2x,
			assets/images/group-3@3x.png 3x">
	</div>
	<div class="row no-gutters">
		<div class="col-12 col-lg-5">
			<h1 class="title">{{'search-form-text1'|translate}}</h1>
			<div class="separator"></div>
			<h2 class="subtextSearch"> {{'search-form-text2'|translate}}</h2>
		</div>

		<div class="col-12 col-lg-7">
			<div class="row no-gutters">
				<span class="tab" (click)="selectTab(0)" [ngClass]="selected==0?'selected-item':''">
					{{'product-list-text5'|translate}}
				</span>
				<span class="tab" (click)="selectTab(1)" [ngClass]="selected==1?'selected-item':''">
					{{'product-list-text6'|translate}}
				</span>
				<span class="tab" (click)="selectTab(2)" [ngClass]="selected==2?'selected-item':''">
					{{'product-list-text7'|translate}}
				</span>
				<span class="tab" (click)="selectTab(3)" [ngClass]="selected==3?'selected-item':''">
					{{'product-list-text8'|translate}}
				</span>
			</div>

			<div class="searchWidget">
				<div class="row no-gutters">
					<div class="inputstyle" style="padding: 9px 41.8px 7px 24px; margin-bottom: 0">
						<img src="assets/images/search_azul.png"
							srcset="assets/images/search_azul@2x.png 2x,
							assets/images/search_azul@3x.png 3x"
							class="icon">
						<input type="text" #searchInput id="" placeholder="{{'search-form-text3'|translate}}">
					</div>
				</div>

				<div class="row no-gutters">
					<div class="multiselect-dropdown-wrapper inputstyle col-12 col-lg">
						<ng-multiselect-dropdown
							name="activeType"
							[placeholder]="'Tipo de Activo'"
							[settings]="dropdownActiveTypeSettings"
							[data]="activeType"
							[(ngModel)]="active">
						</ng-multiselect-dropdown>
					</div>
					<div class="multiselect-dropdown-wrapper inputstyle col-12 col-lg">
						<ng-multiselect-dropdown
							name="Provinces"
							[placeholder]="'Provincia'"
							[settings]="dropdownProvinceSettings"
							[data]="provinces"
							[(ngModel)]="province">
						</ng-multiselect-dropdown>
					</div>
					<div class="multiselect-dropdown-wrapper inputstyle col-12 col-lg">
						<ng-multiselect-dropdown
							name="Status"
							[placeholder]="'Estado'"
							[settings]="dropdownStatusSettings"
							[data]="status"
							[(ngModel)]="statusInput">
						</ng-multiselect-dropdown>
					</div>
				</div>

				<div class="row no-gutters">
					<button class="btn secondaryBG buttonSearch" (click)="search()">
						{{'search-form-text4'|translate}}
					</button>
				</div>
			</div>
		</div>
	</div>

</div>
