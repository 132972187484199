<div class="item-container">
  <img class="item-image "
                               src="{{url+communication.image}}" alt="">
  <div class="item-title">{{communication.description}}</div>
  <div class="row justify-content-center">
    <div class="item-type justify-content-center"><p class="item-type-text">{{communication.type}}</p></div>
    <div class="item-date">{{communication.created_at | customDate}}</div>
  </div>

</div>
