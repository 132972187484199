<div class="searchWidget d-lg-block">
	<div class="row">
		<div class="search inputstyle col-sm-3 col-lg-4 " style="padding: 15px 7px 7px 24px;">
			<input type="text" placeholder="{{'product-list-text4'|translate}}" class="w-90"
				[(ngModel)]="customSelected"
				[typeahead]="auctionList"
				typeaheadOptionField="name"
				(keyup)="filterbyText($event)">
      		<img class="icon w-10 mr-3"
				src="assets/images/search_azul.png"
				srcset="assets/images/search_azul@2x.png 2x,
					assets/images/search_azul@3x.png 3x"
				(click)="filterbyText(this.customSelected)"
				style="cursor: pointer;">
		</div>

		<div class="inputstyle col-sm-2 col-lg-2">
			<ng-multiselect-dropdown
				#activeTypes
				placeholder="{{'product-list-text21'|translate}}"
				[data]="activeType"
				[(ngModel)]="assetstypeArray"
				[settings]="dropdownActiveTypeSettings"
				(onSelect)="onAssetTypes('select', $event)"
				(onDeSelect)="onAssetTypes('deselect', $event)"
				(onSelectAll)="onAssetTypes('select-all', $event)"
				(onDeSelectAll)="onAssetTypes('deselect-all', $event)">
			</ng-multiselect-dropdown>
		</div>

		<div class="inputstyle col-sm-2 col-lg-2 ">
			<ng-multiselect-dropdown
				name="Provinces"
				placeholder="{{'product-list-text22'|translate}}"
				[data]="provinces"
				[(ngModel)]="provincesArray"
				[settings]="dropdownProvinceSettings"
				(onSelect)="onProvinces('select', $event)"
				(onDeSelect)="onProvinces('deselect', $event)"
				(onSelectAll)="onProvinces('select-all', $event)"
				(onDeSelectAll)="onProvinces('deselect-all', $event)">
			</ng-multiselect-dropdown>
		</div>

		<div class="inputstyle col-sm-2 col-lg-2 ">
			<ng-multiselect-dropdown
				name="Status"
				placeholder="{{'product-list-text23'|translate}}"
				[data]="status"
				[(ngModel)]="statusArray"
				[settings]="dropdownStatusSettings"
				(onSelect)="onStatus('select', $event)"
				(onDeSelect)="onStatus('deselect', $event)"
				(onSelectAll)="onStatus('select-all', $event)"
				(onDeSelectAll)="onStatus('deselect-all', $event)">
			</ng-multiselect-dropdown>
		</div>
	</div>
</div>

<div class="productlistpage">
	<div class="row">
		<div [ngClass]="{'col-lg-7': mapVar == true, 'col-sm-7' : mapVar == true, 'col-lg-12': mapVar == false, 'col-sm-12': mapVar == false}">
			<div class="row" style="padding: 5%;">

				<div class="product-list__counter col-sm-12 col-lg-12">
					<div class="counterContainer">
						<h1 class="activeCounter">{{auctionList.length }} {{'product-list-text3'|translate}}</h1>

						<div class="d-none d-lg-block">
							<button class="btn secondaryBG white"
								[disabled]="submittingAlert"
								(click)="openAlertModal()">
								<img src="assets/images/alerta.png"
									srcset="assets/images/alerta@2x.png 2x,
									assets/images/alerta@3x.png 3x"
									class="List">
								<span class="d-lg-inline">
									{{'product-list-text18'|translate}}
								</span>
							</button>
						</div>
					</div>
				</div>

				<div class="product-list__filters col-lg-12 col-sm-12">
					<div class="sectionTitlesContainer row">
						<label class="section-l col-2 col-lg-auto"
							[ngClass]="{'section-l-selected ': this.filter_type == 0}"
							(click)="setFilter(0)">
								{{'product-list-text5'|translate}}
						</label>
						<label class="section-l col-3 col-lg-auto"
							[ngClass]="{'section-l-selected ': this.filter_type == 1}"
							(click)="setFilter(1)">
								{{'product-list-text6'|translate}}
						</label>
						<label class="section-l col-4 col-lg-auto"
							[ngClass]="{'section-l-selected ': this.filter_type == 2}"
							(click)="setFilter(2)">
								{{'product-list-text7'|translate}}
						</label>
						<label class="section-l col-4 col-lg-auto"
							[ngClass]="{'section-l-selected ': this.filter_type == 3}"
							(click)="setFilter(3)">
								{{'product-list-text39'|translate}}
						</label>
						<label class="section-l col-4 col-lg-auto"
							[ngClass]="{'section-l-selected ': this.filter_type == 4}"
							(click)="setFilter(4)">
								{{'product-list-text8'|translate}}
						</label>
					</div>
				</div>

				<div class="product-list__order inputstyle col-lg-7 col-sm-7 d-none d-lg-block">
					<img src="assets/images/arrows.png"
						srcset="assets/images/arrows@2x.png 2x,
						assets/images/arrows@3x.png 3x"
						class="icon">

					{{'product-list-text9'|translate}}

					<select name="" id="" (change)="orderBy($event.target.value)">
						<option value="4">{{'product-list-text38'|translate}}</option>
						<option value="0">{{'product-list-text10'|translate}}</option>
						<option value="1">{{'product-list-text11'|translate}}</option>
						<option value="2">{{'product-list-text12'|translate}}</option>
						<option value="3">{{'product-list-text13'|translate}}</option>
					</select>
				</div>

				<div class="product-list__map-button col-lg-4 col-sm-4 d-none d-lg-block">
					<div class="custom-control custom-switch custom-control-right">
						<input type="checkbox" id="botnMapa" class="custom-control-input"
							[checked]="mapVar"
							(change)="mapVar = !mapVar">
						<label class="custom-control-label" for="botnMapa">
							{{'product-list-text14'|translate}}
						</label>
					</div>
				</div>

				<div class="product-list__filters-mobile row m-auto d-lg-none">
					<div class="col-3 my-auto filter"
						(click)="openFilterModal()">
						<img src="assets/images/filter.png"
							srcset="assets/images/filter@2x.png 2x,
							assets/images/filter@3x.png 3x"
							class="icon">
						{{'product-list-text15'|translate}}
					</div>

					<div class="col-3 my-auto filter"
						[ngClass]="{'d-block': mapVar == false,'d-none': mapVar == true}"
						(click)="mapVar = !mapVar">
						<img src="assets/images/map.png"
							srcset="assets/images/map@2x.png 2x,
							assets/images/map@3x.png 3x"
							class="icon">
						{{'product-list-text16'|translate}}
					</div>

					<div class="col-3 my-auto filter"
						[ngClass]="{'d-block': mapVar == true,'d-none': mapVar == false}"
						(click)="mapVar = !mapVar">
						<img src="assets/images/list-b.png"
							srcset="assets/images/list-b@2x.png 2x,
							assets/images/list-b@3x.png 3x"
							class="icon">
						{{'product-list-text17'|translate}}
					</div>

					<div class="col-6 col-sm-3 col-lg-3 mt-1">
						<button class="btn secondaryBG whiteTextButton"
							[disabled]="submittingAlert"
							(click)="openAlertModal()">
							<img src="assets/images/alerta.png"
								srcset="assets/images/alerta@2x.png 2x,
								assets/images/alerta@3x.png 3x"
								class="icon">
							{{'product-list-text18'|translate}}
						</button>
					</div>
				</div>

				<div class="col-lg-12 col-sm-12 mt-30"></div>

				<div class="col-lg-12 col-sm-12">
					<div class="row">
						<div *ngFor="let pr of auctionList"
							class="mt-30"
							[ngClass]="{'col-lg-6 col-sm-6': mapVar == true, 'col-sm-6 col-lg-3': mapVar == false,'d-none d-lg-block': mapVar == true}">

							<app-product-item-row [auction]="pr"></app-product-item-row>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div id="map"
			[ngClass]="{'col-12': mapVar == true,'col-lg-5': mapVar == true, 'col-sm-5' : mapVar == true, 'hide': mapVar == false}">
			<google-map [options]="options" width="100%" height="80vh">
				<map-marker
					*ngFor="let pr of auctionList let i = index"
					#marker="mapMarker"
					[position]="getLatLang(pr)"
					[options]="getMapMarkerOptions(pr)"
					(mapClick)="openInfoWindow(marker,i)">
				</map-marker>
				<map-info-window>
					<app-product-item-row [auction]="prd"></app-product-item-row>
				</map-info-window>
			</google-map>
		</div>
	</div>
</div>

<!--MODAL-->

<ng-template #filterModal>
	<div class="modal-header">
		<div class="row">
			<div class="col-lg-3 col-sm-3"
				(click)="cleanFilters()">
				<label class="label-clean">{{'product-list-text19'|translate}}</label>
			</div>

			<div class="col-lg-6 col-sm-6">
				<h5 class="modal-title modalTitle">{{'product-list-text20'|translate}}</h5>
			</div>

			<div class="col-lg-3 col-sm-3">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>

	<div class="modal-body">
		<div class="filterSection">
			<h6 class="filterTitle">{{'product-list-text21'|translate}}</h6>
			<div class="row">
				<div class="col-lg-6 col-sm-6">
					<label class="label-filter">
						<input type="checkbox"
							[checked]="assetstypeArray.length == activeType.length"
							(change)="onAssetTypes(assetstypeArray.length < activeType.length ? 'select-all' : 'deselect-all', $event)">
						<span *ngIf="assetstypeArray.length < activeType.length">
							{{'select-all'|translate}}
						</span>
						<span *ngIf="assetstypeArray.length == activeType.length">
							{{'unselect-all'|translate}}
						</span>
					</label>
				</div>
				<div class="col-lg-6 col-sm-6" *ngFor="let active of activeType">
					<label class="label-filter">
						<input type="checkbox" id="assettype-{{active.id}}"
							[checked]="assetstypeArray.includes(active)"
							(change)="checkActive($event, active)">
						{{active.name}}
					</label>
				</div>
			</div>
		</div>

		<div class="filterSection">
			<h6 class="filterTitle">{{'product-list-text22'|translate}}</h6>
			<div class="row">
				<div class="col-lg-6 col-sm-6">
					<label class="label-filter">
						<input type="checkbox"
							[checked]="provincesArray.length == provinces.length"
							(change)="onProvinces(provincesArray.length < provinces.length ? 'select-all' : 'deselect-all', $event)">
						<span *ngIf="provincesArray.length < provinces.length">
							{{'select-all'|translate}}
						</span>
						<span *ngIf="provincesArray.length == provinces.length">
							{{'unselect-all'|translate}}
						</span>
					</label>
				</div>
				<div class="col-lg-6 col-sm-6" *ngFor="let province of provinces">
					<label class="label-filter">
						<input type="checkbox" id="province-{{province.id}}"
							[checked]="provincesArray.includes(province)"
							(change)="checkProvince($event, province)">
						{{province.name}}
					</label>
				</div>
			</div>
		</div>

		<div class="filterSection">
			<h6 class="filterTitle">{{'product-list-text23'|translate}}</h6>
			<div class="row">
				<div class="col-lg-6 col-sm-6">
					<label class="label-filter">
						<input type="checkbox"
							[checked]="statusArray.length == status.length"
							(change)="onStatus(statusArray.length < status.length ? 'select-all' : 'deselect-all', $event)">
						<span *ngIf="statusArray.length < status.length">
							{{'select-all'|translate}}
						</span>
						<span *ngIf="statusArray.length == status.length">
							{{'unselect-all'|translate}}
						</span>
					</label>
				</div>
				<div class="col-lg-6 col-sm-6" *ngFor="let stat of status">
					<label class="label-filter">
						<input type="checkbox" id="status-{{stat.id}}"
							[checked]="statusArray.includes(stat)"
							(change)="checkStatus($event, stat)">
						{{stat.name}}
					</label>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" data-dismiss="modal"
			class="btn secondaryBG seeAllButton"
			(click)="applyFilters()">
			{{'product-list-text24'|translate}}
		</button>
	</div>
</ng-template>

<ng-template #alertModal>
	<div class="modal-header">
		<div class="row">
			<div class="col-lg-3 col-sm-3"></div>
			<div class="col-lg-6 col-sm-9"></div>
			<div class="col-lg-3 col-sm-3">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<div class="row">
			<img src="assets/images/confirmacion-puja.png"
				srcset="assets/images/confirmacion-puja@2x.png 2x,
				assets/images/confirmacion-puja@3x.png 3x"
				class="confirmacion-puja text-center">
		</div>
		<div class="row">
			<h3 class="modal-title modalTitle w-100 text-center">
				{{'product-list-text31'|translate}}
				<br>
				{{'product-list-text32'|translate}}
			</h3>
		</div>
	</div>

	<div class="modal-body" style="padding: 54px">
		<div class="row pt-1">
			<label class="col-3 subtype-label">{{'product-list-text33'|translate}}</label>
			<label class="col-1"></label>
			<label class="col subtype-value">{{ search }}</label>
		</div>
		<div class="row pt-1">
			<label class="col-3 subtype-label">{{'product-list-text34'|translate}}</label>
			<label class="col-1"></label>
			<label class="col subtype-value">{{ getActiveSelected() }}</label>
		</div>
		<div class="row  pt-1">
			<label class="col-3 subtype-label">{{'product-list-text35'|translate}}</label>
			<label class="col-1"></label>
			<label class="col subtype-value">{{ getProvinceSelected() }}</label>
		</div>
		<div class="row  pt-1">
			<label class="col-3 subtype-label">{{'product-list-text36'|translate}}</label>
			<label class="col-1"></label>
			<label class="col subtype-value">{{ getStatusSelected() }}</label>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn menuButton pinkButton"
			(click)="modalRef.hide()">
			{{'product-list-text37'|translate}}
		</button>
	</div>
</ng-template>
