import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AssetType } from 'src/app/models/assets';
import { AssetsService } from 'src/app/services/assets.service';
import {environment} from "../../../environments/environment";

@Component({
	selector: 'app-active-by-categories',
	templateUrl: './active-by-categories.component.html',
	styleUrls: ['./active-by-categories.component.scss']
})
export class ActiveByCategoriesComponent implements OnInit {
	public slides=[]
	public slidesIndex = 0
	public slidesElement = 0
	public assetTypes:AssetType[]
	constructor(assetService:AssetsService) {

		assetService.getAssetTypes().subscribe(
			val=>{
				this.assetTypes = val['response']['asset_type_list']
				this.slides[0]=[]

				for(var i = 0;i<this.assetTypes.length; i++){
					if(this.slidesElement < (window.innerWidth<=768?2:4)){

						this.slides[this.slidesIndex][this.slidesElement]=this.assetTypes[i];
						this.slidesElement ++

					}else{

						this.slidesIndex ++
						this.slidesElement = 0
						this.slides[ this.slidesIndex]=[]
						this.slides[this.slidesIndex][this.slidesElement]=this.assetTypes[i];
						this.slidesElement ++
					}
				}
			}
		)
	}

	ngOnInit(): void {
	}

	getImage(assetType: AssetType) {
				return environment.url + assetType.path

	}

	goCategory(id: any){
		location.href="/listado-activos?active="+id;
	}
}
