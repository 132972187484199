<div class="searchWidget">
    <div class="row">
        <div class="btn-white col-sm-3 col-lg-3" style="padding: 9px 41.8px 7px 24px;">
            <img src="assets/images/search_azul.png"
                srcset="assets/images/search_azul@2x.png 2x,
                        assets/images/search_azul@3x.png 3x"
                class="icon">
            <input type="text" name="" id="" placeholder="{{'product-list-text1'|translate}}">
        </div>
        <div class="btn-white col-sm-2 col-lg-2">
            <ng-multiselect-dropdown
                name="activeType"
                [placeholder]="'Tipo de Activo'"
                [data]="activeType"
                [settings]="dropdownActiveTypeSettings"
            >
            </ng-multiselect-dropdown>
        </div>
        <div class="btn-white col-sm-2 col-lg-2">
            <ng-multiselect-dropdown
                name="Provinces"
                [placeholder]="'Provincia'"
                [data]="provinces"
                [settings]="dropdownProvinceSettings"
            >
            </ng-multiselect-dropdown>
        </div>
        <div class="btn-white col-sm-2 col-lg-2">
            <ng-multiselect-dropdown
                name="Status"
                [placeholder]="'Estado'"
                [data]="status"
                [settings]="dropdownStatusSettings"
            >
            </ng-multiselect-dropdown>
        </div>
        <div class="btn-white col-sm-2 col-lg-2">
            <button class="mostrarmas btn ">
                <img src="assets/images/filter.png"
                    srcset="assets/images/filter@2x.png 2x,
                            assets/images/filter@3x.png 3x"
                    class="icon">
              {{'product-list-text2'|translate}}
            </button>
        </div>
    </div>
</div>
