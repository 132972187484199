import {Bid} from "./bid";
import {User} from "./users";
import {Archive} from "./Archive";

export class Auction {
  minimun: any;
  countdown: any;
  linkProduct: any;

  constructor(id: number, name: string, start_date: string, archives: Archive[], auctions: Auction[], description: string) {
    this.id = id;
    this.name = name;
    this.start_date = start_date;
    this.auction_type_id = 4;
    this.archives = archives
    this.auctions = auctions
    this.description_auction = description
  }

  description_auction: string;

  creditor: Boolean=false;
  address: string
  archives: Archive[]
  auctions: Auction[]
  area: number
  asset_id: number
  province_id: number
  asset_type_id: number
  bedrooms: 1
  cadaster_number: string
  city: string
  latitude: number
  longitude: number
  price_bid: any;
  paid: any;
  id: number
  auction_id: number
  description: string
  name: string
  position: number
  province: string
  type: string
  auction_type_id: number
  start_date: string
  end_date: string
  price: number
  deposit: number
  bid_price_interval: number
  fee: number
  conditions: string
  views: number
  created_at: string
  reference_code: string
  last_bid: Bid
  my_last_bid: Bid
  best_bid: boolean
  count: number
  nick: string
  nick_sent: boolean
  status: string
  sort_status: number
  user_auction_status: string
  dni_validated: boolean
  fav: boolean
  user: User
  user_auctions_id: number
}

export interface AuctionResponse {
  auction: Auction
}

export interface AuctionListResponse {
  auctions_list: Auction[]
}
