<div class="banner-home d-none d-lg-flex flex-column">
	<div class="banner-home__contents row flex-grow-1 d-flex align-items-stretch">
		<div class="col-lg-6 text-center d-flex align-items-center justify-content-center">
			<img src="assets/images/logo-banner.svg" class="logo img-fluid"/>
		</div>		  
		<div class="col-lg-6 banner-home__video d-flex align-items-center">
			<video class="video" controls poster="/assets/videos/trademat_general_poster.jpg">
				<source src="/assets/videos/trademat_general.mp4" type="video/mp4">
			</video>
		</div>
	</div>
	<div class="banner-home__contents row">
		<div class="col-lg-6 banner-home__texts text-center pr-0">
			<h1 class="title home-title">
				{{'banner-text2'|translate}}
			</h1>
		</div>
		<div class="col-lg-6 text-center">
			<button class="btn pinkButton largeButton" [routerLink]="['/registrar-sin-certificado']">
				<span class="encontrar-activos">{{'banner-text3'|translate}}</span>
			</button>
		</div>
	</div>
</div>

<div class="bannerHomeResponsive d-lg-none">
	<div class="m-auto">
		<video class="homeVideoResponsive" controls>
			<source src="/assets/videos/trademat_general.mp4" type="video/mp4">
		</video>
	</div>
	<div class="row">
		<div class="col-12">
			<h1 class="titleResponsive text-center">
				{{'banner-text2'|translate}}
			</h1>

			<button class="btn pinkButton largeButtonResponsive row no-gutters" [routerLink]="['/registrar-sin-certificado']">
				<span class="encontrar-activos">{{'banner-text3'|translate}}</span>
			</button>
		</div>
	</div>
</div>
