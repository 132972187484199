
<div *ngIf="!user">
    <button class="btn menuButton pinkButton altBtn" [routerLink]="['/login']" >{{'access-text2'|translate}}</button>
    &nbsp;
    <a class="btn menuButton pinkButton altBtn vender" href="https://trademateurope.com/subastas/" target="_blank">{{'menu-text21'|translate}}</a>
</div>

<nav class="navbar navbar-expand-md d-none d-md-block" *ngIf="user">
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation" (click)="collapsed = !collapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">

      <ul class="navbar-nav ml-auto">
        <li class="nav-item" ngbDropdown>
          <a class="nav-link"  ngbDropdownToggle id="navbarDropdown" tabindex="0" role="button">
            <img src="assets/images/auction-pink.png"
                srcset="assets/images/auction-pink@2x.png 2x,
                assets/images/auction-pink@3x.png 3x">
            {{'access-text3'|translate}}
            <span *ngIf="auctions.length" style="vertical-align: text-bottom;" class="badge badge-pill badge-primary">{{auctions.length}}</span>
          </a>

          <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu rounded" style="left: -50%; width: 357px;">
            <a ngbDropdownItem *ngFor="let a of auctions" (click)="navigateProduct(a)">
              <div class="row">
                <div class="col-2">
                  <img class="auctionImg" src="{{this.url}}{{a.archives[0]?.path}}">
                </div>
                <div class="col">
                  <label class="auctionLabel">ID: {{a.id}} - {{a.name}}</label>
                </div>
              </div>
              <div class="pt-1">
                <label class="auctionLabel">{{'access-text4'|translate}} {{getLastBid(a)}} €</label>
              </div>
            </a>

            <a *ngIf="auctions.length == 0">
              <div class="alert alert-info text-center" role="alert">
                No tienes subastas añadidas
              </div>
            </a>

            <a ngbDropdownItem style="border: none !important;"  [routerLink]="['/my-auctions']"  *ngIf="auctions.length > 0" >
              <button class="btn primaryBtn btn100 txtBtn">{{'access-text5'|translate}}</button>
            </a>
          </div>
        </li>

        <li class="nav-item" ngbDropdown>
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
            <img src="assets/images/user-1.png"
                srcset="assets/images/user-1@2x.png 2x,
                assets/images/user-1@3x.png 3x">
            {{'access-text6'|translate}}
          </a>

          <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
            <a ngbDropdownItem href="#" [routerLink]="['/mis-datos']">
                <img src="assets/images/user-1.png"
                    srcset="assets/images/user-1@2x.png 2x,
                    assets/images/user-1@3x.png 3x"
                    class="miniIcon">
                {{'access-text7'|translate}}
            </a>
            <a ngbDropdownItem href="#" [routerLink]="['/historial-subastas']">
                <img src="assets/images/auction-pink.png"
                    srcset="assets/images/auction-pink@2x.png 2x,
                    assets/images/auction-pink@3x.png 3x"
                    class="miniIcon">
                {{'access-text8'|translate}}
            </a>
            <a ngbDropdownItem href="#" [routerLink]="['/mis-favoritos']">
                <img src="assets/images/like-pink.png"
                    srcset="assets/images/like-pink@2x.png 2x,
                    assets/images/like-pink@3x.png 3x"
                    class="miniIcon">
                {{'access-text9'|translate}}
            </a>
            <a ngbDropdownItem href="#" [routerLink]="['/mis-alertas']">
                <img src="assets/images/alerta-pink.png"
                    srcset="assets/images/alerta-pink@2x.png 2x,
                    assets/images/alerta-pink@3x.png 3x"
                    class="miniIcon">
                {{'access-text10'|translate}}
            </a>
            <a ngbDropdownItem href="#" (click)="logoutUser()">
                <img src="assets/images/logout.png"
                    srcset="assets/images/logout@2x.png 2x,
                    assets/images/logout@3x.png 3x"
                    class="miniIcon">
                {{'access-text11'|translate}}
            </a>
          </div>
        </li>

        <li class="nav-item">
            <a class="nav-link hello-text">
                {{'access-text12'|translate}}, {{user.name}}
            </a>
        </li>
      </ul>
    </div>
</nav>
