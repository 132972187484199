<app-user-bar class="d-none d-lg-block" [page]="'mis-favoritos'"></app-user-bar>
<div class="cont">
    <div>
        <div class="formstep">
            <div class="section">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">

                        <h1 class="title">

                            <img src="assets/images/like-pink.png"
                            srcset="assets/images/like-pink@2x.png 2x,
                                    assets/images/like-pink@3x.png 3x"
                            class="icon">
                          {{'favorites-text1'|translate}} </h1>
                    </div>
                    <div class="col-lg-6 col-sm-6">

                    </div>
                </div>
            </div>
            <div class="section">
                <div class="row">
                    <div *ngIf="auctions.length == 0">
                        <h2 class="noProducts">{{msg}}</h2>
                    </div>
                    <div *ngFor="let pr of auctions" class="mt-30 col-md-6 col-lg-4">

                      <app-product-item-row [auction]="pr"></app-product-item-row>

                    </div>
                </div>
            </div>
            <div class="section">
                <div class="image-btn-container">
                    <div class="text-center">
                        <img src="/assets/images/my-favs-image.svg" alt="">
                    </div>
                    <p>Recibe en tu email las últinas novedades en Trademat</p>
                    <div>
                        <button [routerLink]="['/mis-alertas']" class="pinkButton"><i class="fa-solid fa-bell"></i> Crear alerta</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
