import {Component, OnInit} from '@angular/core';
import {Auction, AuctionListResponse} from "../models/auction";
import {AssetsService} from "../services/assets.service";
import {BaseResponse} from "../models/base-response.model";
import {Router} from "@angular/router";
import {StateService} from "../services/state-service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-my-auctions',
  templateUrl: './my-auctions.component.html',
  styleUrls: ['./my-auctions.component.scss']
})
export class MyAuctionsComponent implements OnInit {
  auctions: Auction[];
  url: any=environment.url ;
  filter_type = 0;
  private filter_order = "0";

  constructor(private assetsService: AssetsService, private router: Router, private stateService: StateService) {
  }

  ngOnInit(): void {
    this.assetsService.getAllMyAuctions(this.filter_type, this.filter_order).subscribe((response: BaseResponse<AuctionListResponse>) => {
      // console.log(response.response.auctions_list)
      this.auctions = response.response.auctions_list;

      this.auctions.forEach(it => {

        if (it.user_auction_status == "Valid") {
          it.linkProduct = "/producto/" + it.id;
        } else {
          this.stateService.selectedAuction = it;
          it.linkProduct = '/auction-access?reference=' + it.reference_code;
          //this.router.navigate(['auction-access'], {queryParams: {reference: it.reference_code}});
        }
      });

    })
  }

  getLastBid(a: Auction) {
    return a.last_bid ? a.last_bid.import : a.price;
  }

  routeTo(a: Auction) {
    if (a.user_auction_status == "Valid") {
      this.router.navigate(['producto', a.id]);

    } else {
      this.stateService.selectedAuction = a;
      this.router.navigate(['auction-access'], {queryParams: {reference: a.reference_code}});
    }
  }

  apiCallGetMyAuctions(): void {
    this.assetsService.getAllMyAuctions(this.filter_type, this.filter_order).subscribe((response: BaseResponse<AuctionListResponse>) => {
      // console.log(response.response.auctions_list)
      this.auctions = response.response.auctions_list;

      this.auctions.forEach(it => {

        if (it.user_auction_status == "Valid") {
          it.linkProduct = "/producto/" + it.id;
        } else {
          this.stateService.selectedAuction = it;
          it.linkProduct = '/auction-access?reference=' + it.reference_code;
          //this.router.navigate(['auction-access'], {queryParams: {reference: it.reference_code}});
        }
      });

    })
  }

	setFilter(number: number) {
		this.filter_type = number;
		this.apiCallGetMyAuctions()
	}
}
