<div class="client-profile">
	<div class="banner">
		<div class="contentbanner">
			<h5>{{ 'contact-page-text1' | translate }}</h5>
			<h1>{{ 'contact-page-text2' | translate }}</h1>
			<h6>{{ 'contact-page-text2-1' | translate }} </h6>
			<h6>{{ 'contact-page-text2-2' | translate }} </h6>
		</div>
	</div>

	<form class="content" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
		<div class="card">
			<div class="contentCard">
				<h3 style="text-align: center;">{{ 'contact-page-text3' | translate }}</h3>

				<hr>

				<div class="row cont">
					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormName" placeholder="{{ 'name' | translate }}">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text"  formControlName="contactFormCompany" placeholder="{{ 'company' | translate }}">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormEmail" placeholder="{{ 'email' | translate }}">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormPhone"  placeholder="{{ 'phone' | translate }}">
						</div>
					</div>

					<div class="col-lg-12 col-md-12"></div>

					<div class="col-lg-12 col-md-12">
						<div class="fields-container field-textarea">
							<textarea formControlName="contactFormMessage" placeholder="{{ 'message_describe' | translate }}" width="100%"></textarea>
						</div>
					</div>

					<div class="help-form__submit">
						<div class="help-form__submit__wrapper">
							<button class="btn secondaryBG white pieBtn" type="submit" [disabled]="disabledSubmitButton || submitting">
								{{'contact-page-text4'|translate}}
							</button>
							<label class="pie mt-2">
								{{'contact-page-text5'|translate}} <a href="/privacy-policy">{{'contact-page-text6'|translate}}</a>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>

	<app-localization></app-localization>
</div>
