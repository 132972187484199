import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {User} from '../models/users';
import {UserService} from '../services/user.service';
import {BaseResponse} from "../models/base-response.model";
import {UserRepresentation, UserRepresentationResponse} from "../models/UserRepresentation";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {OthersService} from "../services/others.service";
import { UtilsService } from "../services/utils.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {
  files: File[] = [];
  representation_files: FileList;
  modalRef: BsModalRef;
  public user: User
  public incomplete: boolean = false
  public incompleteDoc: boolean = false
  formData = new FormData()
  @ViewChild('documentationModal') documentationModal: TemplateRef<any>
  @ViewChild('representationModal') representationModal: TemplateRef<any>
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>
  @ViewChild('confirmModalRemoveAccount') confirmModalRemoveAccount: TemplateRef<any>
  representations: UserRepresentation[];
  registrationForm: FormGroup;
  representation_id: number = null;
  submittingDoc: boolean = false;
  submittingRegistration: boolean = false;
  submittingNotifications: boolean = false;
  representation: {} = {};
  private removeId: number;
  userAuctionsValid: any = "";

  constructor(
    public router: Router,
    private modalService: BsModalService,
    public fb: FormBuilder,
    public userservice: UserService,
    public utilService: OthersService,
    public utilsService: UtilsService,
    public translateService: TranslateService
  ) {

    if (localStorage.USER === undefined) {
      this.router.navigateByUrl('/login')
    } else {
      this.user = JSON.parse(localStorage.USER)
      this.userservice.getUser().subscribe((value: BaseResponse<User[]>) => {
        this.user = value.response[0]
        localStorage.setItem('USER', JSON.stringify(value.response[0]));
      })
      this.getRepresentations();
    }
  }

  getRepresentations() {
    this.userservice.getRepresentation().subscribe((value: BaseResponse<UserRepresentationResponse>) => {
      
      this.representations = value.response.user_representation_list
      //let response = value['data'];
      //this.representations = response.user_representation_list
    })
  }

  setRepresentationForm() {
    this.registrationForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      cif: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      nationality: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.user.address === "" ||
      this.user.address === undefined ||
      this.user.cp === null ||
      this.user.cp === undefined ||
      this.user.city === "" ||
      this.user.city === undefined ||
      this.user.province_id === "" ||
      this.user.province_id === undefined
    ) {
      this.incomplete = true
    }

    if (this.user.document_path === "" ||
      this.user.document_path === undefined ||
      this.user.document_path === null) {
      this.incompleteDoc = true
    }
    this.getUserAuctionsValid();
  }

  modalRepresentation() {
    this.setRepresentationForm()
    this.modalRef = this.modalService.show(this.representationModal, Object.assign({}, {class: 'gray modal-lg'}));
  }

  modalDocumentation() {
    this.modalRef = this.modalService.show(this.documentationModal, Object.assign({}, {class: 'gray modal-lg'}));
  }

  onSelect(event) {
    this.formData.delete("images[]");
    this.files.push(...event.addedFiles);
    for (var i = 0; i < this.files.length; i++) {
      this.formData.append("images[]", this.files[i]);
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadDoc() {
    if (this.submittingDoc) { return; }
    this.submittingDoc = true;

    this.formData.append('UserID', this.user.id)
    this.userservice.uploadDocuments(this.formData).subscribe(
      val => {
        if(val['status'] === 201){
          this.utilsService.showToast(val['response'].message, 'success')
        }
        if(val['status'] === 404){
          this.utilsService.showToast(val['message'], 'error')
        }
        this.submittingDoc = false;
        this.formData.delete('images[]');
      }, error => {
        this.submittingDoc = false;
      }
    )
    this.modalRef.hide()
  }

  changeNotificationAuctionEnd(value) {
    this.user.notification_auction_end = value
    this.updateNotifications()

  }

  changeNotificationAuctionWon(value) {
    this.user.notification_auction_won = value
    this.updateNotifications()

  }

  changeNotificationFavoriteStart(value) {
    this.user.notification_favorite_start = value
    this.updateNotifications()

  }

  changeNotificationFavoriteEnd(value) {
    this.user.notification_favorite_end = value
    this.updateNotifications()

  }

  changeNotificationOutBid(value) {
    this.user.notification_outbid = value
    this.updateNotifications()
  }

  updateNotifications() {
  
    if (this.submittingNotifications) { return; }
    this.submittingNotifications = true;

    this.userservice.updateNotifications(this.user)
    .subscribe(it => {
      this.submittingNotifications = false;
    }, error => {
      this.submittingNotifications = false;
    });
  }

  addRegistration() {

    if (this.submittingRegistration) { return; }
    this.submittingRegistration = true;

    if (this.registrationForm.valid) {

      let rep = {} as any;
      this.utilService.formToObject(this.registrationForm, rep);

      let formData = new FormData();
      formData.append("name", rep.name);
      formData.append("cif", rep.cif);
      formData.append("address", rep.address);
      formData.append("nationality", rep.nationality);
      formData.append("type", rep.type);

      if (this.representation_files && this.representation_files.length) {
        formData.delete("file[]");
        for (var i = 0; i < this.representation_files.length; i++) {
          formData.append("file[]", this.representation_files[i]);
        }
        this.representation_files = null;
      }

      this.userservice.addRepresentation(formData)
        .subscribe(it => {
          //if (it['status'] === 201) {
            this.utilsService.showToast(it['message'], 'success');
            this.modalRef.hide()
            this.getRepresentations();
            this.submittingRegistration = false;
          //}
          /*
          else {
            let message = it['message'] + '\n';
            if (it['response'].length > 0) {
              for (var res in it['response']){
                  let erroresCampo: [];
                  erroresCampo = it['response'][res];
                  for (var errorCampo in erroresCampo){
                    let errores: [];
                    errores = erroresCampo[errorCampo];
                    message += errores.join('\n');
                  }
              };
            }
            this.utilsService.showToast(message, 'error');
            this.submittingRegistration = false;          
          }
          */
        }, error => {
          let messageErrors = this.utilsService.showErrors(error);
          this.utilsService.showToast(messageErrors, "error")

          this.submittingRegistration = false;
        });
    }
  }

  getCompany(type: string) {
    switch (type) {
      case "company":
        return "Empresa"
      case "individual":
        return "Persona fisica"
      case "community":
        return "Comunidad"
      case "others":
        return "Otros"
    }
  }

  addRepresentation() {
    this.files = null;
    this.representation_files = null;
    this.representation_id = null;
    this.modalRepresentation();
  }

  editRepresentation(id: number) {
    this.files = null;
    this.representation_files = null;
    this.representation_id = id;

    this.userservice.editRepresentation(this.representation_id)
      .subscribe(it => {
        if (it['status'] === 200) {
          let rep = it['response']['representation'];

          this.registrationForm = new FormGroup({
            name: new FormControl(rep.name, [Validators.required]),
            file: new FormControl(null, []),
            cif: new FormControl(rep.cif, [Validators.required]),
            address: new FormControl(rep.address, [Validators.required]),
            nationality: new FormControl(rep.nationality, [Validators.required]),
            type: new FormControl(rep.type, [Validators.required]),
          });

          this.modalRef = this.modalService.show(this.representationModal, Object.assign({}, {class: 'gray modal-lg'}));
        }
      }, error => {
        this.utilsService.showToast(error.message, 'error');
        this.modalRef.hide();
      });
  }

  updateRepresentation() {
    if (this.submittingRegistration) { return; }
    this.submittingRegistration = true;

    let rep = {} as any;
    this.utilService.formToObject(this.registrationForm, rep);

    let formData = new FormData();
    formData.append("name", rep.name);
    formData.append("cif", rep.cif);
    formData.append("address", rep.address);
    formData.append("nationality", rep.nationality);
    formData.append("type", rep.type);

    if (this.representation_files && this.representation_files.length) {
      formData.delete("file[]");
      for (var i = 0; i < this.representation_files.length; i++) {
        formData.append("file[]", this.representation_files[i]);
      }
    }

    this.userservice.updateRepresentation(formData, this.representation_id)
      .subscribe(it => {
        //if (it['status'] === 201){
          this.utilsService.showToast(it['message'], 'success');
          this.submittingRegistration = false;
          this.getRepresentations();
          this.modalRef.hide();
        //}
      }, error => {
        let response = error['error'];
        let message = response['message'] + '\n';
        let data = response['data'];
        let errores = [];
        for (let campo in data){
          let erroresCampo: [];
          erroresCampo = data[campo];
          errores = errores.concat(erroresCampo);
        };
        message += errores.join('\n');

        this.utilsService.showToast(message, 'error');
        this.submittingRegistration = false;
        //this.utilsService.showToast(error.message, 'error');
      }, () => {
        //debugger
        //this.submittingRegistration = false;
        //this.getRepresentations();
        //this.modalRef.hide();
      });
  }

  removeRepresentation(id: number) {
    this.removeId=id;
    this.modalRef = this.modalService.show(this.confirmModal, Object.assign({}, {class: 'gray modal-lg'}));
  }

  removeRepresentationConfirm() {
   this.modalRef.hide()
    this.userservice.removeRepresentation(this.removeId).subscribe(it => {
      if(it['status'] === 200){
        this.utilsService.showToast(it['message'], 'success')
      }
      this.getRepresentations();
    })
  }

  removeAccount() {
    this.modalRef = this.modalService.show(this.confirmModalRemoveAccount, Object.assign({}, {class: 'gray modal-lg'}));

  }

  getUserAuctionsValid() {
    this.userservice.getUserAuctions().subscribe(data => {
      this.userAuctionsValid = data['response'].data
    })
  }

  removeAccountPermanent() {
    this.userservice.removeAccount().subscribe(it=>{
      this.utilsService.showToast(it['message'], 'success');
      setTimeout(() => {
        localStorage.clear()
        window.location.reload();
        this.router.navigateByUrl('/login')
      }, 1000);

    })
  }

  changeLang(value: any) {
    this.userservice.changeLanguage(value).subscribe(it=>{
      this.userservice.getUser().subscribe((value: BaseResponse<User[]>) => {
        // Cambio de idioa en base de datos y actualizacion de user en localstorage
        this.user = value.response[0]
        localStorage.setItem('USER', JSON.stringify(value.response[0]));
        this.utilsService.showToast(it['message'], 'success');

        // Cambio de idioma en la web en el storage
        let current_lang = localStorage.LANG = value.response[0].language.toLowerCase();
        this.utilsService.lang.next(current_lang);
        this.translateService.use(current_lang);
        location.reload(); // Es necsario para cambiar el selector en el header
      })
    }, error =>
    {
      this.utilsService.showToast(error.error.message, 'error');
    })
  }

  handleFileInput(files: FileList): void {
    this.representation_files = files.length ? files : null;
  }
}
