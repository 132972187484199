import { Component, OnInit, HostListener } from '@angular/core';


@Component({
	selector: 'app-scrolltop',
	templateUrl: './scrolltop.component.html',
	styleUrls: ['./scrolltop.component.scss']
})
export class ScrollTopComponent implements OnInit {

	public show_scrolltop_button: boolean = false;

	@HostListener('window:scroll', ['$event']) onScrollEvent($event) {
		const verticalOffset = window.pageYOffset
			|| document.documentElement.scrollTop
			|| document.body.scrollTop || 0;

		this.show_scrolltop_button = verticalOffset > 100;
	}

	constructor() {
	}

	ngOnInit(): void {
	}

	scrollToTop() {

		window.scroll({
			top: 0,
			behavior: 'smooth'
		});
	}
}
