import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AssetsService} from "../services/assets.service";
import {AssetType} from "../models/assets";
import {OthersService} from "../services/others.service";
import {Province} from "../models/provinces";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../services/user.service";
import {Alert, AlertResponse} from "../models/Alert";
import {BaseResponse} from "../models/base-response.model";
import {Router} from "@angular/router";

@Component({
	selector: 'app-user-alerts',
	templateUrl: './user-alerts.component.html',
	styleUrls: ['./user-alerts.component.scss']
})
export class UserAlertsComponent implements OnInit {

	modalRef: BsModalRef;
	@ViewChild('successModal') successModal: TemplateRef<any>
	@ViewChild('deleteModal') deleteModal: TemplateRef<any>
	types: AssetType[];
	provinces: Province[];
	alertForm: FormGroup;
	private alert = {};
	submittingAlert: boolean = false;
	alertList: Alert[];
	private removeId: number;

	constructor(
		private modalService: BsModalService,
		private assetsService:AssetsService,
		private othersService:OthersService,
		private userService:UserService,
		private router:Router,
		public fb: FormBuilder
	) {
	}

	ngOnInit(): void {

		this.assetsService.getAssetTypes().subscribe((val)=>{
			this.types=val['response']['asset_type_list']
		})
		this.othersService.getProvinces(null).subscribe((val)=>{
			this.provinces = val['response']["provinces_list"]
		})
		this.getAlerts();
		this.alertForm = new FormGroup({
			key: new FormControl(null),
			auction_type_id: new FormControl(null),
			asset_type_id: new FormControl(null),
			province_id: new FormControl(null),
			status: new FormControl(null),
		});
	}

	private getAlerts() {
		this.userService.getAlerts().subscribe((val: BaseResponse<AlertResponse>) => {
			this.alertList = val.response.alert_list;
		});
	}

	openAlert() {
		this.modalRef = this.modalService.show(this.successModal, Object.assign({}, {class: 'gray modal-lg modal-success'}));
	}

	clickCreateAlert() {

		if (this.submittingAlert) { return; }
		this.submittingAlert = true;

		this.othersService.formToObject(this.alertForm, this.alert);

		this.userService.createAlert(this.alert).subscribe(it => {
			this.getAlerts();
			this.modalRef.hide();
			this.submittingAlert = false;
		}, error => {
			this.submittingAlert = false;
		});
	}

	getAuctionType(auction_type_id: number) {
		switch (auction_type_id) {
			case 0:
			case null:
				return "Todos los activos"
			case 1:
				return "Solo subastas"
			case 2:
				return "Solo venta directa"
		}
	}

	getAssetType(asset_types: any) {

		if (asset_types.length == 0) { return '---'; }
		return asset_types.map(it => it.name).join(', ');
	}

	getProvince(provinces: any) {

		if (provinces.length == 0) { return '---'; }
		return provinces.map(it => it.name).join(', ');
	}

	getStatus(status: any) {

		if (!status || status.length == 0) { return '---'; }

		return status.map(it => {
			switch (it) {
				case '1': return 'Finalizadas';
				case '2': return 'En Curso';
				case '3': return 'Disponible Proximamente';
			}
		}).join(', ');
	}

	removeAlert(id: number) {
		this.removeId = id;
		this.modalRef = this.modalService.show(this.deleteModal, Object.assign({}, {class: 'gray modal-lg modal-success'}));
	}

	deleteAlert() {
		this.userService.removeAlert(this.removeId).subscribe(it => {
			this.modalRef.hide();
			this.getAlerts();
		});
	}

	findActives(alert: Alert) {

		let queryParams: any = {};

		if (alert.key) { queryParams.search = alert.key; }
		if (alert.asset_types && alert.asset_types.length) { queryParams.active = alert.asset_types.map(it => it.asset_type_id).join(','); }
		if (alert.provinces && alert.provinces.length) { queryParams.province = alert.provinces.map(it => it.province_id).join(','); }
		if (alert.status && alert.status.length) { queryParams.status = alert.status.join(','); }

		this.router.navigate(['/listado-activos'], { queryParams });
	}
}
