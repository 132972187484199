import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { environment } from "../../../environments/environment";

import { News } from "../../models/news.model";

@Component({
  selector: 'app-note-press-item',
  templateUrl: './note-press-item.component.html',
  styleUrls: ['./note-press-item.component.scss']
})
export class NotePressItemComponent implements OnInit {

  @Input() note: News;

  private image_exts = ['jpg', 'jpeg', 'png', 'gif'];

  modalRef: BsModalRef;
  @ViewChild('imageModal') imageModal: TemplateRef<any>;
  public image_url = '';

  constructor(
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {

  }

  isImage(path: string) {
    return this.image_exts.includes(path.split('.').pop());
  }

  openFile(path: string) {
    let ext = path.split('.').pop();

    if (this.isImage(path)) {

      this.image_url = environment.url + path;
      this.modalRef = this.modalService.show(this.imageModal, Object.assign({}, { class: 'gray modal-xl' }));

    } else {

      window.open(environment.url + path, '_blank');
    }
  }
}
