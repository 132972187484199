import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { UtilsService } from "../services/utils.service";
import { OthersService } from "../services/others.service";
import { UserService } from "../services/user.service";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  submitting: boolean = false;

  @HostListener('input') oninput() {
    this.disabledSubmitButton = !this.contactForm.valid;
  }

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private othersService: OthersService,
    private userService: UserService
  ) {

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormCompany': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactFormPhone': ['', Validators.required],
    });
  }

  onSubmit() {

    if (this.submitting) { return; }
    this.submitting = true;

    this.userService.sendMessage(this.contactForm.value).subscribe(() => {
      this.utilsService.showToast('Mensaje enviado con éxito (recibirá un email de confirmación)');
      this.contactForm.reset();
      this.disabledSubmitButton = true;
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

}
