<div class="innerFooter">
	<div class="col-12 paddingBottom48">
		<div class="row">
			<div class="col-12">
				<img class="width100" src="assets/images/logo-horizontal.png"
					srcset="assets/images/horizontal-claim-copy@2x.png 2x,
					assets/images/horizontal-claim-copy@3x.png 3x">
			</div>
		</div>
	</div>
	<div class="col-12">
		<div class="row">
			<div class="col-lg-1 col-md-1"></div>
			<div class="col-lg-5 col-md-5 col-sm-12 d-lg-flex first-block">
				<div class="col-lg-6 col-sm-12">
					<table class="contactTable">
						<tr>
							<td valign="top">
								<img src="assets/images/location.png"
									srcset="assets/images/location@2x.png 2x,
									assets/images/location@3x.png 3x"
									class="footerIcon">
							</td>
							<td>
								C/ Merindad de Montija 18, Nave 11C<br>
								Polígono Industrial Villalonquéjar<br>
								09001 Burgos - España
							</td>
						</tr>
						<tr>
							<td>
								<img src="assets/images/phone.png"
									srcset="assets/images/phone@2x.png 2x,
									assets/images/phone@3x.png 3x"
									class="footerIcon">
							</td>
							<td><a href="tel:+34947040204">+34 947 04 02 04</a></td>
						</tr>
						<tr>
							<td>
								<img src="assets/images/mail.png"
									srcset="assets/images/mail@2x.png 2x,
									assets/images/mail@3x.png 3x"
									class="footerIcon">
							</td>
							<td><a class="email" href="mailto:hola@trademat.io">hola@trademat.io</a></td>
						</tr>
					</table>
				</div>
				<div class="col-lg-6 col-sm-12">

					<div class="row">
						<span class="footerBottom" style="cursor: pointer;" (click)="openPdf()">
							<img src="assets/images/ISO_IEC_27001_RGB.png" class="miniLogo2">
						</span>
						<span class="footerBottom" style="cursor: pointer;" (click)="openPdf2()">
							<img src="assets/images/Distintivo_ens_certificacion_MEDIA.png" class="miniLogo2">
						</span>
					</div>

				</div>
			</div>
			<div class="col-12 separator"></div>
			<div class="col-lg-5 col-md-5 col-sm-12 d-lg-flex">
				<div class="col-lg-6 col-sm-12">
					<span class="footerColumnTitle">{{'footer-text2'|translate}}</span>
					<br/><br/>
					<span class="company-links">
						<a [routerLink]="['/']">{{'footer-text15'|translate}}</a><br/>
						<a [routerLink]="['/listado-activos']">{{'footer-text16'|translate}}</a><br/>
						<a [routerLink]="['/faq']">{{'footer-text6'|translate}}</a><br/>
						<a [routerLink]="['/login']">{{'footer-text17'|translate}}</a><br/>
						<a href="https://trademateurope.com/subastas/" target="_blank">{{'footer-text18'|translate}}</a><br/>
						<a [routerLink]="['/about-us']">{{'footer-text5'|translate}}</a><br/>
						<a [routerLink]="['/perfil-cliente']">{{'footer-text19'|translate}}</a><br/>
					</span>
				</div>
				<div class="col-lg-6 col-sm-12 support-links">
					<span class="support-links">
						<a href="https://trademateurope.com/casos-de-exito/" target="_blank">{{'footer-text20'|translate}}</a><br/>
						<a [routerLink]="['/comunication']">{{'footer-text21'|translate}}</a><br/>
						<a [routerLink]="['/contact']">{{'footer-text1'|translate}}</a><br/>
						<a class="bold" href="https://www.trademateurope.com" target="_blank">TRADEMATEUROPE.COM</a>
					</span>
				</div>
			</div>
			<div class="col-lg-1 col-md-1"></div>
		</div>
	</div>
	<div class="col-lg-12">
		<div class="row">
			<div class="col-lg-12 socials">
				{{'footer-text13'|translate}}
				<a href="https://www.facebook.com/Subastas-Trademat-100423858947544" target="_blank">
					<img src="assets/images/socials-facebook.png" class="socials-icon">
				</a>
				<a href="#" target="_blank">
					<img src="assets/images/socials-linkedin.png" class="socials-icon">
				</a>
				<a href="#" target="_blank">
					<img src="assets/images/socials-twitter.png" class="socials-icon">
				</a>
				<a href="https://www.instagram.com/subastas_trademat" target="_blank">
					<img src="assets/images/socials-instagram.png" class="socials-icon">
				</a>
			</div>
		</div>
	</div>
</div>

<div class="secondaryBG footer d-lg-flex">
  <div class="col-sm-12 col-lg-10">
    <span class="footerInfo">©2023 Trademat Subastas. {{'footer-text12'|translate}}</span>
  </div>
  <div class="col-sm-12 col-lg-2">
    <span class="footerInfo">
      <a class="dirtyWhite" [routerLink]="['/legal']">{{'footer-text10'|translate}}</a>
    </span>
  </div>
</div>
