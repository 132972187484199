import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-verify-page',
  templateUrl: './verify-page.component.html',
  styleUrls: ['./verify-page.component.scss']
})
export class VerifyPageComponent implements OnInit {

  constructor( public rutaActiva: ActivatedRoute , private userService:UserService ) { }

  ngOnInit(): void {

    let email = this.rutaActiva.snapshot.params.email;
    let token = this.rutaActiva.snapshot.params.token;

    this.userService.verifyAccount(email,token).subscribe(() => {

    });

  }

}
