<div class="client-profile">
  <div class="banner">
    <div class="contentbanner">
      <h5> {{ 'faq-text1' | translate }}</h5>
      <h1>{{ 'faq-text2' | translate }}</h1>
      <div class="row">
        <div class="rectangle col-6 m-auto">
          <div class="rectangle-container2">
            <div class="rectangle-container">
              <img src="assets/images/search_azul.png"
                   srcset="assets/images/search_azul@2x.png 2x,
                                assets/images/search_azul@3x.png 3x"
                   class="Search">
              <input type="text" (input)="onSearchChange($event.target.value)"
                     placeholder="{{ 'faq-text3' | translate }}">
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none d-lg-flex" style="height: 90px;"></div>
      <div class="row justify-content-start d-none d-lg-flex">
        <div class="col-lg-2 col-sm-2 catfaq"></div>
        <div class="col-lg-2 col-sm-2 catfaq " [ngClass]="{'disable-cat':selected!=1}" (click)="filterCat(1);selected=1">
          <img src="assets/images/message.png"
               srcset="assets/images/message@2x.png 2x,
                            assets/images/message@3x.png 3x"
               class="Security-announcement ">
          <span class=" col-auto">{{ 'faq-text4' | translate }}</span>
        </div>
        <div class="col-lg-2 col-sm-2 catfaq" [ngClass]="{'disable-cat':selected!=2}" (click)="filterCat(2);selected=2">
          <img src="assets/images/user_alt.png"
               srcset="assets/images/user_alt@2x.png 2x,
                            assets/images/user_alt@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text5' | translate }}</span></div>
        <div class="  col-lg-2 col-sm-2 catfaq" [ngClass]="{'disable-cat':selected!=3}" (click)="filterCat(3);selected=3">
          <img src="assets/images/auction-alt.png"
               srcset="assets/images/auction-alt@2x.png 2x,
                            assets/images/auction-alt@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text6' | translate }}</span></div>
        <div class=" col-lg-2 col-sm-2 catfaq" [ngClass]="{'disable-cat':selected!=4}" (click)="filterCat(4);selected=4">
          <img src="assets/images/security-announcement.png"
               srcset="assets/images/security-announcement@2x.png 2x,
                            assets/images/security-announcement@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text7' | translate }}</span>
        </div>
        <div class="col-lg-2 col-sm-2 catfaq"></div>
      </div>
      <div class="items justify-content-start d-lg-none">
        <div class="col-12 row " (click)="filterCat(1)">
          <img src="assets/images/message.png"
               srcset="assets/images/message@2x.png 2x,
                            assets/images/message@3x.png 3x"
               class="Security-announcement ">
          <span>{{ 'faq-text4' | translate }}</span>
        </div>
        <div class="col-12 row " (click)="filterCat(2)">
          <img src="assets/images/user_alt.png"
               srcset="assets/images/user_alt@2x.png 2x,
                            assets/images/user_alt@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text5' | translate }}</span></div>
        <div class=" col-12 row " (click)="filterCat(3)">
          <img src="assets/images/auction-alt.png"
               srcset="assets/images/auction-alt@2x.png 2x,
                            assets/images/auction-alt@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text6'| translate }}</span></div>
        <div class=" col-12 row " (click)="filterCat(4)">
          <img src="assets/images/security-announcement.png"
               srcset="assets/images/security-announcement@2x.png 2x,
                            assets/images/security-announcement@3x.png 3x"
               class="Security-announcement">
          <span>{{ 'faq-text7' | translate }}</span>
        </div>
      </div>

    </div>
  </div>
  <div class="content">
    <div class="card" style="border-radius: 12px!important;">
      <div class="contentCard">

        <div class="d-flex">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills pill" orientation="vertical">
            <li ngbNavItem="{{faq.FaqID}}" *ngFor="let faq of faqs">
              <a ngbNavLink>
                <div class="row">
                  <a style="width:90%">{{faq.Faq_Question}} </a>
                  <img src="assets/images/arrow-drop-right.png"
                    srcset="assets/images/arrow-drop-right@2x.png 2x,
                    assets/images/arrow-drop-right@3x.png 3x"
                    style="width:20px; height:20px">
                </div>
              </a>
              <ng-template ngbNavContent>
                <h3>{{faq.Faq_Question}}</h3>
                <hr>
                <span [outerHTML]="faq.Faq_Answer"></span>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="ml-lg-4"></div>
        </div>

      </div>
    </div>
  </div>
  <app-help-form></app-help-form>
</div>
