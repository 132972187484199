<div class="advice">
	<h3 class="sectionSubtitle center">{{ 'advice-text1' | translate }}</h3>

	<h2 class="sectionTitle center">{{ 'advice-text2' | translate }}</h2>

	<hr class="sectionTitleBar">

	<div class="advice__blurbs">
		<div class="advice__blurbs__row1">
			<div class="blurb">
				<img src="assets/images/icon-circulo-perfiles-particular.png"
					srcset="assets/images/icon-circulo-perfiles-particular@2x.png 2x,
					assets/images/icon-circulo-perfiles-particular@3x.png 3x"
					class="img-fluid mx-auto d-block center icon col col-lg-12">
				<h3 class="directAccess my-auto m-auto col col-lg-12">
					{{ 'advice-text3' | translate }}
				</h3>
			</div>

			<div class="blurb">
				<img src="assets/images/icon-circulo-perfiles-empresa.png"
					srcset="assets/images/icon-circulo-perfiles-empresa@2x.png 2x,
					assets/images/icon-circulo-perfiles-empresa@3x.png 3x"
					class="img-fluid mx-auto d-block center icon col col-lg-12">
				<h3 class="directAccess my-auto m-auto col col-lg-12">
					{{ 'advice-text4' | translate }}
				</h3>
			</div>

			<div class="blurb">
				<img src="assets/images/icon-circulo-perfiles-acreedor-privilegiado.png"
					srcset="assets/images/icon-circulo-perfiles-acreedor-privilegiado@2x.png 2x,
					assets/images/icon-circulo-perfiles-acreedor-privilegiado@3x.png 3x"
					class="img-fluid mx-auto d-block center icon col col-lg-12">
				<h3 class="directAccess my-auto m-auto col col-lg-12">
					{{ 'advice-text5' | translate }}
				</h3>
			</div>
		</div>

		<div class="advice__blurbs__row2">
			<div class="blurb">
				<img src="assets/images/icon-circulo-perfiles-fondo.png"
					srcset="assets/images/icon-circulo-perfiles-fondo@2x.png 2x,
					assets/images/icon-circulo-perfiles-fondo@3x.png 3x"
					class="img-fluid mx-auto d-block center icon col col-lg-12">
				<h3 class="directAccess my-auto m-auto col col-lg-12">
					{{ 'advice-text6' | translate }}
				</h3>
			</div>

			<div class="blurb">
				<img src="assets/images/icon-circulo-perfiles-administrador.png"
					srcset="assets/images/icon-circulo-perfiles-administrador@2x.png 2x,
					assets/images/icon-circulo-perfiles-administrador@3x.png 3x"
					class="img-fluid mx-auto d-block center icon col col-lg-12">
				<h3 class="directAccess my-auto m-auto col col-lg-12">
					{{ 'advice-text7' | translate }}
				</h3>
			</div>
		</div>

		<div class="advice__buttons row justify-content-md-center">
			<button class="btn primaryBG white btntxt" [routerLink]="['/perfil-cliente']">{{ 'advice-text8' | translate }}</button>
			<button class="btn primaryBG white btntxt" [routerLink]="['/faq']">{{ 'advice-text9' | translate }}</button>
		</div>
	</div>

</div>
