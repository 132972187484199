
<div class="cont" *ngIf="this.stateService.selectedAuction">
	<div>
		<div class="formstep">

			<!-- DESKTOP VERSION -->

			<div class="section d-none d-lg-block">
				<div class="row">
					<div class="col-9">
						<h1 class="title">
							{{ 'auction_access_title' | translate }}
						</h1>
					</div>

					<div class="col-3 my-auto row">
						<h1 class="subtitleForm2" [ngClass]="{'green-steep': this.getSteps()>0}">
							{{ getSteps() }}/3
						</h1>
						<h1 class="subtitleForm3 m-auto">
							{{ 'auction_access_steps' | translate }}
						</h1>
					</div>
				</div>

				<div class="row no-gutters">
					<div class="col-auto subtitleForm">
						<div>
							ID:{{ this.stateService.selectedAuction.id }}
							- {{ this.stateService.selectedAuction.name }}
						</div>
					</div>

					<div class="col-2 badge badgeToolbar purpleBG white ml-2"
						*ngIf="this.stateService.selectedAuction.auction_type_id==2">
						<img src="assets/images/bag-of-money.png"
							srcset="assets/images/bag-of-money@2x.png 2x,
							assets/images/bag-of-money@3x.png 3x"
							class="auction">
						{{ 'auction_access_direct_payment' | translate }}
					</div>
				</div>
			</div>

			<div class="section-info d-none d-lg-block">

				<!-- SECTION 1 -->

				<div class="sub-section">
					<div class="row no-gutters section2">
						<div class="number-circle col-auto text-center">
							<span class="number">1</span>
						</div>
						<div class="title-subsection col">
							{{ 'client_values_title' | translate }}
						</div>
						<div *ngIf="!this.stateService.selectedAuction.nick" class="col-auto pending">
							{{ 'auction_access_pending' | translate }}
						</div>
					</div>

					<div class=" row no-gutters alias">

						<div *ngIf="!this.stateService.selectedAuction.nick" class="col-6">
							<label *ngIf="representations.length == 0">
								{{ 'auction_access_representation' | translate }}
							</label>

							<select *ngIf="representations.length > 0" [(ngModel)]="nick" class="section3 col-12">
								<option *ngFor="let representation of representations" value="{{representation.name}}">
									{{representation.name}}
								</option>
							</select>
						</div>

						<div *ngIf="this.stateService.selectedAuction.nick" class="title-subsection col-12 t-16">
							<span *ngIf="this.stateService.selectedAuction.is_representation" class="t-16">
								<strong>{{ this.stateService.selectedAuction.user.name + " " + this.stateService.selectedAuction.user.surname + " "}}</strong>{{ 'auction_access_connected_as_representation_of' | translate }}<strong>{{" " + this.stateService.selectedAuction.nick}}</strong>
							</span>
							<ng-container *ngIf="!this.stateService.selectedAuction.is_representation; else elseBlock">
								<span class="t-16">
									{{ 'auction_access_connected_as_own-representation' | translate }}<strong>{{ " " + this.stateService.selectedAuction.user.name + " " + this.stateService.selectedAuction.user.surname + " "}}</strong>
								</span>
							</ng-container>
						</div>

						<div *ngIf="!this.stateService.selectedAuction.nick" class="info-rectangle row">
							<img src="assets/images/info.png"
								srcset="assets/images/info@2x.png 2x,
						 		assets/images/info@3x.png 3x"
						 		class="info my-auto">
							<h1 class="text-info my-auto">
								{{ 'auction_access_alias' | translate }}
							</h1>
						</div>

						<!-- User data -->

						<div *ngIf="this.stateService.selectedAuction.user" class="row alias no-gutters">
							<div class="user-value col-4">
								<div class="row no-gutters">
									<div class="col-1">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check">
									</div>
									<div class="col-11 pl-1">
										{{ 'auction_access_bame_surname' | translate }}
									</div>
									<div class="col-1"></div>
									<div class="col-11 pl-1">
										{{ this.stateService.selectedAuction.user.name }} {{ this.stateService.selectedAuction.user.surname }}
									</div>
								</div>
							</div>

							<div class="user-value col-6">
								<div class="row no-gutters">
									<div class="col-1">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check">
									</div>
									<div class="col-11 ">
										{{ 'auction_access_email' | translate }}
									</div>
									<div class="col-1"></div>
									<div class="col-11">
										{{ this.stateService.selectedAuction.user.email }}
									</div>
								</div>
							</div>

							<div class="user-value col-2">
								<div class="row no-gutters">
									<div class="col-1">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check">
									</div>
									<div class="col-11 pl-3">
										{{ 'auction_access_phone' | translate }}
									</div>
									<div class="col-1"></div>
									<div class="col-11 pl-2">
										{{ this.stateService.selectedAuction.user.phone }}
									</div>
								</div>
							</div>
						</div>

						<div class="row alias no-gutters">
							<div class="user-value col-12">
								<div class="row no-gutters">
									<div class="col-1">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check">
									</div>
									<div class="col-11 pl-1">
										{{ 'auction_access_address' | translate }}
									</div>
									<div class="col-1"></div>
									<div class="col-11 pl-1">
										{{ this.stateService.selectedAuction.user.address }}, {{ this.stateService.selectedAuction.user.city }},
										{{ this.stateService.selectedAuction.user.cp }} {{ this.stateService.selectedAuction.user.country_id }}
									</div>
								</div>
							</div>
						</div>

						<!-- Submit button -->

						<div *ngIf="!this.stateService.selectedAuction.nick" class="row alias no-gutters" style="margin-top:41px">
							<div class="h-100 row no-gutters">
								<button class="btn secondaryBG seeAllButton" [disabled]="submittingNick"
									(click)="sendNick()">
									{{ 'auction_access_confirmation' | translate }}
								</button>

								<span class="pl70 m-auto">
									{{ 'auction_access_not_me' | translate }}
									<a class="link" (click)="collapsedProfile = true; logoutUser()">
										{{ 'close_session' | translate }}
									</a>
								</span>
							</div>
						</div>

					</div>
				</div>

				<!-- SECTION 2 -->

				<div class="sub-section mt-4">
					<div class="row no-gutters section2">
						<div class="number-circle col-auto">
							<span class="number">2</span>
						</div>
						<div class="title-subsection col">
							{{ 'documentation' | translate }}
						</div>
						<!--<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !this.stateService.selectedAuction.dni_validated" class="col-auto pending">
							{{ 'auction_access_pending' | translate }}
						</div>-->
					</div>

					<div class=" row no-gutters alias subtitle-info">
						{{ 'auction_access_dni_document' | translate }}<b>{{this.stateService.selectedAuction.user.document_number}}</b>.
					</div>

					<!-- Upload area -->
					<div *ngIf="stateService.selectedAuction.is_users_auctions_dni_validated && stateService.selectedAuction.dni_validated;else uploadDocumentation" class="sub-section w-75 documentation">
						<p><b>Documentación ya enviada</b></p>
					</div>

					<ng-template #uploadDocumentation>
						<div class="sub-section w-75 documentation"
							[ngClass]="{'border-0':stateService.selectedAuction.dni_validated}">
							<div class="row no-gutters pl70">
								<!--<img src="assets/images/document.png"
									srcset="assets/images/document@2x.png 2x,
									assets/images/document@3x.png 3x"
									class="Document col-auto">

								<div class="col">
									NIF: {{ this.stateService.selectedAuction.user.document_number }}
								</div>-->

								<div *ngIf="stateService.selectedAuction.dni_validated" class="row no-gutters col info-rectangle3 mt-1">
									<img src="assets/images/info.png"
										srcset="assets/images/info@2x.png 2x,
										assets/images/info@3x.png 3x"
										class="info col-1">
									<h1 class="text-info my-auto col">
										{{ 'auction_access_dni_valid' | translate }}
									</h1>
								</div>
							</div>

							<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !stateService.selectedAuction.dni_validated" class="row no-gutters mt-2 pl70">
								<input type="file" class="files" id="files" (change)="handleFileInput($event.target.files)" multiple>
							</div>

							<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !stateService.selectedAuction.dni_validated" class="row no-gutters mt-2 pl70">
								{{ 'auction_access_dni_document_info' | translate }}
							</div>
						</div>

						<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !stateService.selectedAuction.dni_validated" class="row alias no-gutters documentation">
							<div class="field-group col-sm-12 col-lg-12">
								<input class="form-check-input" type="checkbox" [(ngModel)]="save_dni">
								<label class="form-check-label">
									{{ 'auction_access_dni_document_save' | translate }}
								</label>
							</div>
						</div>

						<!-- Submit button -->

						<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !stateService.selectedAuction.dni_validated" class="row alias no-gutters">
							<div class="h-100 row no-gutters">
								<button class="btn secondaryBG seeAllButton"
									[ngClass]="{'clicked': submittingDni}"
									[disabled]="submittingDni"
									(click)="sendDNI()">
									{{ 'auction_access_dni_document_send' | translate }}
								</button>
							</div>
						</div>
					</ng-template>
				</div>

				<!-- SECTION 3 -->

				<div class="sub-section mt-4">
					<div class="row no-gutters section2">
						<div class="number-circle col-auto">
							<span class="number">3</span>
						</div>
						<div class="title-subsection col">
							{{ 'auction_access_payment_conditions' | translate }}
						</div>
						<div *ngIf="!this.stateService.selectedAuction.dni_validated" class="col-auto pending">
							{{ 'auction_access_payment_pending' | translate }}
						</div>
					</div>

					<div *ngIf="this.stateService.selectedAuction.deposit == 0" class="row no-gutters alias section2">
						{{ 'auction_access_no_deposit' | translate }}
						<br>
						{{ 'auction_access_no_deposit2' | translate }}
					</div>

					<div *ngIf="this.stateService.selectedAuction.deposit > 0" class="row no-gutters alias section2">
						{{ 'auction_access_accept_conditions' | translate:{ deposit: this.stateService.selectedAuction.deposit } }}
						<br>
						{{ 'auction_access_payment_conditions2' | translate }}
					</div>

					<div class="row no-gutters alias section2">
						<div class="col-1">
							<img class="image" src="{{this.url}}{{this.stateService.selectedAuction.archives[0]?.path}}" alt="">
						</div>

						<div class="col">
							<div>
								ID:{{ this.stateService.selectedAuction.id }}
								- {{ this.stateService.selectedAuction.name }}
							</div>

							<div *ngIf="this.stateService.selectedAuction.auction_type_id == 1"
								class="badge badgeToolbar greenBG white">
								<img src="assets/images/auction.png"
									srcset="assets/images/auction@2x.png 2x,
									assets/images/auction@3x.png 3x"
									class="auction">
								{{ 'auction' | translate }}
							</div>

							<div *ngIf="this.stateService.selectedAuction.auction_type_id == 2"
								class="badge badgeToolbar purpleBG white">
								<img src="assets/images/bag-of-money.png"
									srcset="assets/images/bag-of-money@2x.png 2x,
									assets/images/bag-of-money@3x.png 3x"
									class="auction">
								{{ 'direct_payment' | translate }}
							</div>
						</div>

						<span class="col-auto">
							{{ 'deposit' | translate }}
						</span>

						<span *ngIf="this.stateService.selectedAuction.deposit == 0"
							class="col-auto text-right font-weight-bold">
							{{ 'auction_access_payment_conditions_not_required' | translate }}
						</span>

						<span *ngIf="this.stateService.selectedAuction.deposit > 0"
							class="col-auto text-right">
							{{ this.stateService.selectedAuction.deposit }} €
						</span>
					</div>

					<div *ngIf="this.stateService.selectedAuction.deposit > 0" class="row alias no-gutters col-12">
						<div class="col-6 text-left">
							{{ 'auction_access_total_deposit' | translate }}
						</div>
						<div class="col-6 text-right payment-method">
							<span>{{this.stateService.selectedAuction.deposit}} €</span>
						</div>
					</div>

					<!-- Payment method selection -->

					<div *ngIf="this.stateService.selectedAuction.deposit > 0 && !this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor"
						class="row alias no-gutters payment-method">
						{{ 'auction_access_select_payment_method' | translate }}
					</div>

					<div *ngIf="this.stateService.selectedAuction.deposit > 0 && !this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor"
						class="row alias no-gutters">

						<div class="col m-2" style="cursor:pointer"
							[ngClass]="{ 'Elevation': this.selectedMethod == 1, 'Elevation-disable': selectedMethod != 1 }"
							(click)="selectedMethod = 1">
							<div class="row no-gutters">
								<img src="assets/images/transfer.png"
									srcset="assets/images/transfer@2x.png 2x,
									assets/images/transfer@3x.png 3x"
									class="Equipo col-2">
								<div class="Combined-Shape col-auto my-auto"></div>
								<div class="col my-auto title-method">
									{{ 'auction_access_bank_payment' | translate }}
								</div>
							</div>

							<div class="row no-gutters">
								<div class="item-bank col-2"></div>
								<div class=" col my-auto text-method">
									{{ 'auction_access_concept' | translate }}: "{{ this.stateService.selectedAuction.name }}"
									<!--{{ 'auction_access_concept' | translate }}: {{ this.stateService.selectedAuction.reference_code }}-->
								</div>
							</div>

							<div class="row no-gutters">
								<div class="item-bank col-2"></div>
								<div class=" col my-auto text-method">
									{{ 'auction_access_bank_account' | translate }}:<br/>ES98 0128 0231 5801 0004 7822
								</div>
							</div>
						</div>

						<!-- <div class="col m-2" style="cursor:pointer"
							[ngClass]="{ 'Elevation': this.selectedMethod == 2, 'Elevation-disable': selectedMethod != 2 }"
							(click)="selectedMethod = 2">
							<div class="row no-gutters">
								<img src="assets/images/payment.png"
									srcset="assets/images/payment@2x.png 2x,
									assets/images/payment@3x.png 3x"
									class="Equipo col-2">
								<div class="Combined-Shape col-auto my-auto"></div>
								<div class=" col my-auto title-method">
									{{ 'auction_access_card_payment' | translate }}
								</div>
							</div>

							<div class="row no-gutters">
								<div class="item-bank col-2"></div>
								<div class=" col my-auto text-method">
									{{ 'auction_access_card_redirect' | translate }}
								</div>
							</div>
						</div> -->

						<div class="col m-2" style="cursor:pointer"
							[ngClass]="{ 'Elevation': this.selectedMethod == 3, 'Elevation-disable': selectedMethod != 3 }"
							(click)="selectedMethod = 3">
							<div class="row no-gutters">
								<img src="assets/images/acreedor.png"
									srcset="assets/images/acreedor@2x.png 2x,
									assets/images/acreedor@3x.png 3x"
									class="Equipo col-2">
								<div class="Combined-Shape col-auto my-auto"></div>
								<div class="col my-auto title-method">
									{{ 'auction_access_creditor' | translate }}
								</div>
							</div>
						</div>

						<!-- Payment method 1: bank transfer -->

						<div class=" row w-100 pt-3" *ngIf="this.selectedMethod == 1">
							<span class="Prueba-de-transferen col-12">
								{{ 'auction_access_pay_proof' | translate }}
							</span>

							<span class="col-12">
								{{ 'auction_access_pay_proof_document' | translate }}
							</span>

							<div class="col-12 pt-3">
								<input type="file" id="files" multiple (change)="handleFilePayment($event.target.files)">
							</div>

							<span class="col-12 pt-2">
								{{ 'auction_access_select_multiple_limit' | translate }}
							</span>

							<span class="Prueba-de-transferen col-12 pt-2">
								{{ 'auction_access_account_values' | translate }}
							</span>

							<div class="info-rectangle2 row no-gutters col-12 mt-3">
								<img src="assets/images/info.png"
									srcset="assets/images/info@2x.png 2x,
									assets/images/info@3x.png 3x"
									class="info my-auto">
								<h1 class="text-info my-auto">
									{{ 'auction_access_info_payment' | translate }}
								</h1>
							</div>

							<div class=" row no-gutters alias col-12 mt-2">
								<input class="section3 col-6"
									placeholder="{{ 'auction_access_titular' | translate }}"
									[(ngModel)]="titular">
							</div>

							<div class="row no-gutters alias col-12">
								<input class="section3 col-6"
									placeholder="{{ 'auction_access_iban' | translate }}"
									[(ngModel)]="iban">
							</div>
						</div>
					</div>

					<div *ngIf="!this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor"
						class="row alias no-gutters documentation">
						<div class="field-group col-sm-12 col-lg-12 margin-bottom-30">
							<input type="checkbox" class="form-check-input" [(ngModel)]="check">
							<a href="{{ getUrl(this.stateService.selectedAuction.conditions) }}" class="form-check-label" target="_blank">
								{{ 'auction_access_read' | translate }}
							</a>
						</div>
					</div>

					<div *ngIf="!this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor"
						class="row alias no-gutters my-3">
						<div class="h-100 row no-gutters w-100">
							<button class="btn secondaryBG seeAllButton"
								[disabled]="!this.check || (!stateService.selectedAuction.is_users_auctions_dni_validated && !this.stateService.selectedAuction.dni_validated) || !this.stateService.selectedAuction.nick || this.checkPayment() || submittingEnd"
								(click)="payAndEnd()">
								{{ 'auction_access_end' | translate }}
							</button>
						</div>
					</div>
				</div>
			</div>

			<!-- MOBILE VERSION -->

			<div class="d-lg-none">

				<div class="">
					<div class="row">
						<div class="col">
							<h1 class="title">
								{{ 'auction_access_title' | translate }}
							</h1>
						</div>

						<div class="col-auto my-auto">
							<h1 class="subtitleForm2" [ngClass]="{'green-steep': this.getSteps() > 0 }">
								{{ getSteps() }}/3 <span>{{ 'auction_access_steps' | translate }}</span>
							</h1>
						</div>
					</div>

					<div class=" row no-gutters">
						<div class="col-10 subtitleForm">
							<div>
								ID:{{ this.stateService.selectedAuction.id }}
								- {{ this.stateService.selectedAuction.name }}
							</div>
						</div>
					</div>
				</div>

				<!-- SECTION 1 -->

				<div class="section-info my-2">
					<div class="sub-section p-2">
						<div class="row no-gutters section2 p-2">
							<div class="number-circle col-auto">
								<span class="number">1</span>
							</div>
							<div class="title-subsection col t-16">
								{{ 'client_values_title' | translate }}
							</div>
							<div class="col-auto"> -</div>
						</div>

						<div *ngIf="!this.stateService.selectedAuction.nick" class="col-6">
							<label *ngIf="representations.length == 0">
								{{ 'auction_access_representation' | translate }}
							</label>

							<select *ngIf="representations.length > 0"
								class="section3 col-12"
								[(ngModel)]="nick">
								<option *ngFor="let representation of representations" value="{{ representation.name }}">
									{{ representation.name }}
								</option>
							</select>
						</div>

						<div class="row alias no-gutters m-2">
							<div class="user-value col-12">
								<div class="row no-gutters ">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check col-1">
									<div class="col-auto t-14">
										{{ 'auction_access_bame_surname' | translate }}
									</div>
									<div class="col t-14 ta-l text-right">
										{{ this.stateService.selectedAuction.user.name }} {{ this.stateService.selectedAuction.user.surname }}
									</div>
								</div>
							</div>

							<div class="user-value col-12">
								<div class="row no-gutters">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check col-1">
									<div class="col-auto t-14">
										{{ 'auction_access_email' | translate }}
									</div>
									<div class="col t-14 ta-l text-right">
										{{ this.stateService.selectedAuction.user.email }}
									</div>
								</div>
							</div>

							<div class="user-value col-12">
								<div class="row no-gutters ">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check col-1">
									<div class="col-auto t-14">
										{{ 'auction_access_phone' | translate }}
									</div>
									<div class="col  t-14 ta-l text-right">
										{{this.stateService.selectedAuction.user.phone}}
									</div>
								</div>
							</div>
						</div>

						<div class="row no-gutters px-2">
							<div class="user-value col-12">
								<div class="row no-gutters ">
									<img src="assets/images/check.png"
										srcset="assets/images/check@2x.png 2x,
										assets/images/check@3x.png 3x"
										class="Check col-1">
									<div class="col-auto t-14">
										{{ 'auction_access_address' | translate }}
									</div>
									<div class="col t-14 ta-l text-right">
										{{ this.stateService.selectedAuction.user.address }}, {{ this.stateService.selectedAuction.user.city }},
										{{ this.stateService.selectedAuction.user.cp }} {{ this.stateService.selectedAuction.user.country_id }}
									</div>
								</div>
							</div>
						</div>

						<!-- Submit button -->

						<div *ngIf="!this.stateService.selectedAuction.nick" class="row alias no-gutters m-2" style="margin-top:41px">
							<div class="h-100 row no-gutters">
								<button class="btn secondaryBG seeAllButton"
									(click)="sendNick()">
									{{ 'auction_access_confirmation' | translate }}
								</button>

								<span class="pl70 m-auto">
									{{ 'auction_access_not_me' | translate }}
									<a class="link" (click)="collapsedProfile = true; logoutUser()">
										{{ 'close_session' | translate }}
									</a>
								</span>
							</div>
						</div>

					</div>
				</div>

				<!-- SECTION 2 -->

				<div class="section-info my-2">
					<div class="sub-section p-2">
						<div class="row no-gutters section2">
							<div class="number-circle col-auto">
								<span class="number">2</span>
							</div>
							<div class="title-subsection col">
								{{ 'documentation' | translate }}
							</div>
							<div class="col-auto"> --</div>
						</div>

						<div class="row no-gutters alias doc-title">
							{{ 'auction_access_dni_document' | translate }}<b>{{this.stateService.selectedAuction.user.document_number}}</b>.
							{{ 'auction_access_dni_save' | translate }}
						</div>

						<!-- Upload area -->

						<div class="sub-section w-100">
							<!--<div class="row no-gutters pl70 p-10">
								<img src="assets/images/document.png"
									srcset="assets/images/document@2x.png 2x,
									assets/images/document@3x.png 3x"
									class="Document col-auto">

								<div class="col">NIF: {{this.stateService.selectedAuction.user.document_number}}</div>
							</div>-->

							<div *ngIf="!stateService.selectedAuction.dni_validated"
								class="row no-gutters w-100 info-rectangle3 mt-1">
								<img src="assets/images/info.png"
									srcset="assets/images/info@2x.png 2x,
									assets/images/info@3x.png 3x"
									class="info col-1">
								<h1 class="text-info my-auto col">
									{{ 'auction_access_dni_valid' | translate }}
								</h1>
							</div>

							<div *ngIf="!this.stateService.selectedAuction.dni_validated" class="row no-gutters mt-2 w-100">
								<input type="file" class="w-100" id="files" (change)="handleFileInput($event.target.files)" multiple>
							</div>

							<!--<div *ngIf="!this.stateService.selectedAuction.dni_validated" class="row no-gutters mt-2 pl70">
								{{ 'auction_access_dni_document_info' | translate }}
							</div>-->
						</div>

						<div *ngIf="!this.stateService.selectedAuction.dni_validated" class="row alias no-gutters documentation">
							<div class="field-group col-sm-12 col-lg-12">
								<input class="form-check-input" type="checkbox">
								<label class="form-check-label">
									{{ 'auction_access_dni_document_save' | translate }}
								</label>
							</div>
						</div>

						<div *ngIf="!stateService.selectedAuction.is_users_auctions_dni_validated || !stateService.selectedAuction.dni_validated" class="row alias no-gutters my-3">
							<div class="h-100 row no-gutters w-100">
								<button class="btn secondaryBG seeAllButton"
									[ngClass]="{'clicked': submittingDni}"
									[disabled]="submittingDni"
									(click)="sendDNI()">
									{{ 'auction_access_dni_document_send' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>

				<!-- SECTION 3 -->

				<div class="section-info mt-2">
					<div class="sub-section p-2">
						<div class="row no-gutters section2">
							<div class="number-circle col-auto"><span class="number">3</span></div>
							<div class="title-subsection col">Condiciones y documentación</div>
							<div class="col-auto"> -</div>
						</div>

						<div *ngIf="this.stateService.selectedAuction.deposit == 0" class="row no-gutters alias doc-title section2">
							{{ 'auction_access_no_deposit' | translate }}
							<br>
							{{ 'auction_access_no_deposit2' | translate }}
						</div>

						<div *ngIf="this.stateService.selectedAuction.deposit > 0" class="row no-gutters alias doc-title section2">
							{{ 'auction_access_accept_conditions' | translate:{ deposit: this.stateService.selectedAuction.deposit } }}
							<br>
							{{ 'auction_access_payment_conditions2' | translate }}
						</div>

						<div class="row no-gutters alias section2 mt-3">
							<div class="col-3">
								<img class="image" src="{{this.url}}{{this.stateService.selectedAuction.archives[0]?.path}}">

								<img *ngIf="this.stateService.selectedAuction.auction_type_id == 1"
									src="assets/images/auction.png" alt=""
									srcset="assets/images/auction@2x.png 2x,
									assets/images/auction@3x.png 3x"
									class="auction greenBG">

								<img *ngIf="this.stateService.selectedAuction.auction_type_id == 2"
									src="assets/images/bag-of-money.png"
									srcset="assets/images/bag-of-money@2x.png 2x,
									assets/images/bag-of-money@3x.png 3x"
									class="auction purpleBG">
							</div>

							<div class="col">
								<div>
									ID:{{ this.stateService.selectedAuction.id }}
									- {{ this.stateService.selectedAuction.title }}
								</div>
							</div>

							<div class="col-12  row no-gutters">
								<span class="col-6 text-left">
									{{ 'deposit' | translate }}:
								</span>

								<span *ngIf="this.stateService.selectedAuction.deposit == 0" class="col-6 text-right font-weight-bold">
									{{ 'auction_access_payment_conditions_not_required' | translate }}
								</span>

								<span *ngIf="this.stateService.selectedAuction.deposit > 0" class="col-6 text-right">
									{{ this.stateService.selectedAuction.deposit }} €
								</span>
							</div>

							<div *ngIf="this.stateService.selectedAuction.deposit > 0" class="row alias no-gutterscol-12">
								<div class="col-6 text-left">
									{{ 'auction_access_total_deposit' | translate }}
								</div>
								<div class="col-6 text-right payment-method">
									<span>{{ this.stateService.selectedAuction.deposit }} €</span>
								</div>
							</div>
						</div>

						<!-- Payment method selection -->

						<div *ngIf="this.stateService.selectedAuction.deposit > 0 && !this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor">
							<div class="row alias no-gutters payment-method mt-30">
								{{ 'auction_access_select_payment_method' | translate }}
							</div>
							<div *ngIf="!this.stateService.selectedAuction.paid && !this.stateService.selectedAuction.creditor"
								class="row alias no-gutters mt-2">
								<div class=" col-12 py-2"
									[ngClass]="{ 'Elevation': selectedMethod == 1, 'Elevation-disable': selectedMethod != 1 }"
									(click)="selectedMethod = 1">
									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class=" col my-auto title-method">
											{{ 'auction_access_bank_payment' | translate }}
										</div>
									</div>

									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class=" col my-auto t-12">
											{{ 'auction_access_concept' | translate }}:
											<span class="m-auto blue-method">{{ this.stateService.selectedAuction.name }}</span>
											<!--<span class="m-auto blue-method">{{ this.stateService.selectedAuction.reference_code }}</span>-->
										</div>
									</div>

									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class=" col my-auto t-12">
											{{ 'auction_access_bank_account' | translate }}:<br/>ES98 0128 0231 5801 0004 7822
										</div>
									</div>
								</div>

								<!-- <div class="col-12 mt-3 py-2"
									[ngClass]="{ 'Elevation': selectedMethod == 2, 'Elevation-disable': selectedMethod != 2 }"
									(click)="selectedMethod = 2">
									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class="col my-auto title-method">
											{{ 'auction_access_card_payment' | translate }}
										</div>
									</div>

									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class="col my-auto t-12">
											{{ 'auction_access_card_redirect' | translate }}
										</div>
									</div>
								</div> -->

								<div class=" col-12 mt-3 py-2"
									[ngClass]="{ 'Elevation': selectedMethod == 3, 'Elevation-disable': selectedMethod != 3 }"
									(click)="selectedMethod = 3">
									<div class="row no-gutters">
										<div class="item-bank col-1"></div>
										<div class=" col my-auto">
											{{ 'auction_access_creditor' | translate }}
										</div>
									</div>
								</div>

								<!-- Payment method 1: bank transfer -->

								<div *ngIf="selectedMethod == 1" class="row w-100 mt-3">
									<span class=" col-12">
										{{ 'auction_access_pay_proof' | translate }}
									</span>

									<span class=" col-12 mt-3">
										{{ 'auction_access_pay_proof_document' | translate }}
									</span>

									<div class="col-12">
										<input type="file" id="filesPayment" multiple class="w-100" (change)="handleFilePayment($event.target.files)">
									</div>

									<span class="col-12 mt-3">
										{{ 'auction_access_select_multiple_limit' | translate }}
									</span>

									<span class="col-12 mt-3">
										{{ 'auction_access_account_values' | translate }}
									</span>

									<div class="row no-gutters col-12 info-rectangle2">
										<img src="assets/images/info.png"
											srcset="assets/images/info@2x.png 2x,
											assets/images/info@3x.png 3x"
											class="info col-1">

										<h1 class="text-info my-auto col">
											{{ 'auction_access_info_payment' | translate }}
										</h1>
									</div>

									<div class="row no-gutters col-12 w-100 mt-3">
										<input class="section3 col-12"
											placeholder="Nombre del titular de la cuenta"
											[(ngModel)]="titular">
									</div>

									<div class="row no-gutters col-12 w-100 mt-3">
										<input class="section3 col-12"
											placeholder="Número de la cuenta bancaria con IBAN"
											[(ngModel)]="iban">
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!this.stateService.selectedAuction.paid&&!this.stateService.selectedAuction.creditor"
							class="row alias no-gutters documentation">
							<div class="field-group col-sm-12 col-lg-12">
								<input class="form-check-input" [(ngModel)]="check" type="checkbox">
								<a href="{{getUrl(this.stateService.selectedAuction.conditions)}}" class="form-check-label" target="_blank">
									{{ 'auction_access_read' | translate }}
								</a>
							</div>
						</div>

						<div *ngIf="!this.stateService.selectedAuction.paid&&!this.stateService.selectedAuction.creditor"
							class="row alias no-gutters my-3">
							<div class="h-100 row no-gutters w-100">
								<button class="btn secondaryBG seeAllButton"
									[disabled]="!this.check || !this.stateService.selectedAuction.dni_validated || !this.stateService.selectedAuction.nick || this.checkPayment() || submittingEnd"
									(click)="payAndEnd()">
									{{ 'auction_access_end' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
