<div class="register-form">
	<img src="assets/images/azulclaro.png"
		srcset="assets/images/azulclaro@2x.png 2x,
		assets/images/azulclaro@3x.png 3x"
		class="register-form__half-moon-blue">

	<img src="assets/images/rosaclaro.png"
		srcset="assets/images/rosaclaro@2x.png 2x,
		assets/images/rosaclaro@3x.png 3x"
		class="register-form__half-moon-pink">

	<div class="register-form__contents">
		<div class="formstep">
			<div class="row">
				<div style="border-bottom: solid 1px var(--grey-2); margin-bottom: 25px;">
					<div class="row d-none d-lg-flex">
						<div class="col-2">
							<img src="assets/images/sin-texto.png"
								srcset="assets/images/sin-texto@2x.png 2x,
								assets/images/sin-texto@3x.png 3x">
						</div>
						<div class="col-10">
							<h1 class="stepTitle">
								{{step}}{{'register-text1'|translate}}
							</h1>
							<!--<p>
								{{'register-text2'|translate}}
							</p>-->
						</div>
					</div>
					<div class="row d-lg-none">
						<div class="col-sm-2 col-lg-2">
							<img src="assets/images/sin-texto.png"
								srcset="assets/images/sin-texto@2x.png 2x,
								assets/images/sin-texto@3x.png 3x"
								class="SinTexto">
						</div>
						<div class="col-sm-10 col-lg-10">
							<h1 class="stepTitle">     {{step}}{{'register-text1'|translate}}</h1>
						</div>
						<p class="w-100 title-form">
							{{'register-text2'|translate}}
						</p>
					</div>
				</div>
				<form [formGroup]="registerForm1">
					<div *ngIf="step === 1" class="form-register">
						<h3 class="sublabel">{{'register-text3'|translate}}</h3>
						<div class="fields-container row">
							<div class="field-group field col-sm-12 col-lg-12">
								<input type="text" placeholder="Email" formControlName="email" >
								<div *ngIf="submitted && rf1.email.errors">
									<p class="validation-error" *ngIf="rf1.email.errors.required">
										{{'register-text4'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf1.email.errors.email">
										{{'register-text5'|translate}}
									</p>
								</div>
							</div>
							<div class="field-group field col-sm-12 col-lg-12">
									<!--<input [type]="inputType1 ? 'text' : 'password'" formControlName="password" placeholder="Contraseña" class="input-password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-\.]).{8,}$">-->
									<input [type]="inputType1 ? 'text' : 'password'" formControlName="password" placeholder="Contraseña" class="input-password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$">
									<span (click)="changeInputType(1)" class="fa fa-fw fa-eye field-icon toggle-password"></span>
								<div *ngIf="submitted && rf1.password.errors">
									<p class="validation-error" *ngIf="rf1.password.errors.required">
										{{'register-text6'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf1.password.errors.minlength">
										{{'register-text7'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf1.password.errors.pattern">
										{{'register-text13'|translate}}
									</p>
								</div>

							</div>
							<div class="field-group field col-sm-12 col-lg-12">
									<!--<input [type]="inputType2 ? 'text' : 'password'" formControlName="password_confirmation" placeholder="Repetir Contraseña" class="input-password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-\.]).{8,}$">-->
									<input [type]="inputType2 ? 'text' : 'password'" formControlName="password_confirmation" placeholder="Repetir Contraseña" class="input-password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$">
									<span (click)="changeInputType(2)" class="fa fa-fw fa-eye field-icon toggle-password"></span>
								<div *ngIf="submitted && rf1.password_confirmation.errors">
									<p class="validation-error" *ngIf="rf1.password_confirmation.errors.required">
										{{'register-text8'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf1.password_confirmation.errors.notmatched">
										{{'register-text9'|translate}}
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- <pre>
						{{ registerForm1.valid }}
						<br>
						{{ registerForm1.status }}
						<br>
						{{ registerForm1.value | json }}
					</pre> -->
				</form>
				<form [formGroup]="registerForm2">
					<div *ngIf="step === 2" class="form-register">
						<h3 class="sublabel">{{'register-text10'|translate}}</h3>
						<div class="fields-container row">
							<div class="field-group  col-sm-12 col-lg-12">
								<input type="text" placeholder="{{'register-text41'|translate}}" class="field" formControlName="name" pattern="^[A-za-zÀ-ú ]+$">

								<div *ngIf="submitted && rf2.name.errors">
									<p class="validation-error" *ngIf="rf2.name.errors.minlength">
										{{'register-text40'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf2.name.errors.required">
										{{'register-text11'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf2.name.errors.pattern">
										{{'register-text44'|translate}}
									</p>
								</div>
								<div class="info-msg text-center" style="font-size:20px;">
									{{'register-text43'|translate}}
								</div>
							</div>

							<div class="field-group  col-sm-12 col-lg-12">
								<input type="text" placeholder="{{'register-text42'|translate}}" class="field" formControlName="surname" pattern="^[A-za-zÀ-ú ]+$">

								<div *ngIf="submitted && rf2.surname.errors">
									<p class="validation-error" *ngIf="rf2.surname.errors.required">
										{{'register-text12'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf2.surname.errors.pattern">
										{{'register-text44'|translate}}
									</p>
								</div>
							</div>
							<div class="field-group col-sm-6 col-lg-6 form-inline">
									<!-- <label for="" class="my-1 mr-2">{{'register-text27'|translate}}</label> -->
									<select formControlName="prefix">
										<option value="null" disabled selected>-- {{'register-text27'|translate}} --</option>
										<option *ngFor="let country of countries" value="{{country.id}}">{{country.name}} - {{country.prefix}}</option>
									</select>
							</div>
								<div class="field-group col-sm-6 col-lg-6">
									<input type="text" class="field" placeholder="{{'register-text19'|translate}}" formControlName="phone">
								</div>
						</div>
					</div>
				</form>
				<form [formGroup]="registerForm3">
					<div *ngIf="step === 3" class="form-register">
						<h3 class="sublabel">{{'register-text21'|translate}}</h3>
						<div class="fields-container row">
							<div class="field-group field col-sm-12 col-lg-12">
								<input type="text" placeholder="{{'register-text22'|translate}}" formControlName="address" pattern="[A-zÀ-ú0-9 \-/',#.()º]+">
								<div *ngIf="submitted && rf3.address.errors">
									<p class="validation-error" *ngIf="rf3.address.errors.minlength">
										{{'register-text36'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf3.address.errors.required">
										{{'register-text36'|translate}}
									</p>
								</div>
							</div>
							<div class="field-group field col-sm-6 col-lg-6">
								<input type="text" placeholder="{{'register-text23'|translate}}" formControlName="city" pattern="^[A-za-zÀ-ú ]+$">

								<div *ngIf="submitted && rf3.city.errors">
									<p class="validation-error" *ngIf="rf3.city.errors.minlength">
										{{'register-text37'|translate}}
									</p>
									<p class="validation-error" *ngIf="rf3.city.errors.pattern">
										{{'register-text38'|translate}}
									</p>
								</div>
							</div>
							<div class="field-group field col-sm-6 col-lg-6">
								<input type="text" placeholder="{{'register-text24'|translate}}" formControlName="cp" pattern="[0-9]+">

								<div *ngIf="submitted && rf3.cp.errors">
									<p class="validation-error" *ngIf="rf3.cp.errors.pattern">
										{{'register-text39'|translate}}
									</p>
								</div>
							</div>
							<div class="field-group field col-sm-6 col-lg-6">
								<label for="" class="field-label minilabel">{{'register-text26'|translate}}</label><br>
								<select formControlName="country_id" (change)="selectedCountry($event.target.value)">
									<option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
								</select>
							</div>
							<div class="field-group field col-sm-6 col-lg-6">
								<label for="" class="field-label minilabel">{{'register-text25'|translate}}</label><br>
								<select formControlName="province_id">
									<option *ngFor="let province of provinces"
													value="{{province.id}}">{{province.name}}</option>
								</select>
							</div>
							<div class="field-group col-sm-12 col-lg-12">
								<input class="form-check-input" formControlName="terms" type="checkbox" (change)="termsChange($event)">
								<label class="form-check-label">
									Acepto el <a href="/legal">Aviso legal</a> y la <a href="/privacy-policy">Política de privacidad</a>
								</label>

								<div *ngIf="submitted && rf3.terms.errors">
									<p class="validation-error" *ngIf="rf3.terms.errors.required">
										{{'register-text28'|translate}}
									</p>
								</div>

							</div>
							<div class="field-group col-sm-12 col-lg-12">
								<input class="form-check-input" formControlName="newsletter" type="checkbox">
								<label class="form-check-label">
									{{'register-text29'|translate}}
								</label>
							</div>
						</div>
					</div>
				</form>
				<div *ngIf="error" class="w-100 text-center">
					<p class="validation-error" *ngIf="error_format">
						{{'register-text35'|translate}}
					</p>
					<p class="validation-error" *ngIf="error_exist">
						{{'register-text33'|translate}}
					</p>
				</div>
				<div class="w-100 d-flex justify-content-between text-center">
					<button
						*ngIf="step > 1"
						type="button"
						class="prev btn"
						(click)="minusStep()"
					>{{'register-text30'|translate}}
					</button>

					<button
						*ngIf="step === 1"
						type="button"
						class="next btn w-100 secondaryBG white"
						(click)="plusStep()"
					>{{'register-text31'|translate}}
					</button>

					<button
						*ngIf="step > 1 && step < 3 "
						type="button"
						class="next btn secondaryBG white"
						(click)="plusStep()"
					>{{'register-text31'|translate}}
					</button>

					<button [disabled]="canRegister!=true"
						*ngIf="step === 3"
						type="button"
						class="next btn secondaryBG white"
						(click)="onFormSubmit()"
						[disabled]="submitting"
					>{{'register-text32'|translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
