<div class="searchHomeWidget d-lg-block">
	<div class="row">
		<div class="col-sm-1 col-lg-3"></div>
		<div class="categories inputstyle col-sm-3 col-lg-2">
			<ng-multiselect-dropdown class="categories"
				#activeTypes
        placeholder="{{'product-list-text40'|translate}}"
				[data]="activeType"
				[(ngModel)]="assetstypeArray"
				[settings]="dropdownActiveTypeSettings"
				(onSelect)="onAssetTypes('select', $event)"
				(onDeSelect)="onAssetTypes('deselect', $event)"
				(onSelectAll)="onAssetTypes('select-all', $event)"
				(onDeSelectAll)="onAssetTypes('deselect-all', $event)">
			</ng-multiselect-dropdown>
		</div>
		<div class="search inputstyle col-sm-7 col-lg-4 " style="padding: 5px 7px 7px 24px;">
			<input #searchField type="text" placeholder="{{'product-list-text4'|translate}}" class="w-90"
				[(ngModel)]="customSelected"
				[typeahead]="auctionList"
				typeaheadOptionField="name"
				(keyup.enter)="headerSearch($event)">
			<img class="icon w-10 mr-3"
				src="assets/images/search_azul.png"
				srcset="assets/images/search_azul@2x.png 2x,
				assets/images/search_azul@3x.png 3x"
				(click)="glassSearch(this.customSelected)"
				style="cursor: pointer;">
		</div>
		<div class="col-sm-1 col-lg-3"></div>
	</div>
</div>
