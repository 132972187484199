import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackService {
  private headers: HttpHeaders;
  private url = environment.url

  constructor(public http: HttpClient) {

  }

  private setHeaders() {
    this.headers = new HttpHeaders().set("Accept", "application/json");

    if (localStorage.getItem('TOKEN')) {
      this.headers= this.headers.append("Authorization", localStorage.getItem('TOKEN'))
    }
  }
  getAllPacks(filterType: number, order: string) {
    let param: any = {'filter_type': filterType, 'filter_order': order};
    this.setHeaders();

    return this.http.get(this.url + "/api/pack/public/list", {params: param, headers: this.headers})
  }
  getPack(id:number) {
    this.setHeaders();
    return this.http.get(this.url + "/api/pack/"+id+"/public", {headers: this.headers})
  }
}
