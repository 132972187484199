import {Component, OnInit} from '@angular/core';
import {NotePressService} from "../services/note-press.service";
import {News, NewsResponse} from "../models/news.model";
import {BaseResponse} from "../models/base-response.model";
import {CommunicationResponse} from "../models/comunication.model";

@Component({
  selector: 'app-note-press-page',
  templateUrl: './note-press-page.component.html',
  styleUrls: ['./note-press-page.component.scss']
})
export class NotePressPageComponent implements OnInit {
  activePage: number = 1;
  limit = 10;
  total = 0;
   notePres_list: News[];

  constructor(private service: NotePressService) {
  }

  ngOnInit(): void {

  }

  getCols() {
    return window.innerWidth > 768 ? 2 : 1;
  }

  getHeight() {
    return window.innerWidth > 768 ? "176px" : window.innerWidth > 375 ? "200px" : "250px";

  }
  displayActivePage(activePageNumber:number){
    this.activePage = activePageNumber
    this.activePage = activePageNumber
    this.service.getNotePress(this.limit, this.activePage).subscribe(
      (val:BaseResponse<NewsResponse>)=>{
        this.notePres_list = val.response.news_list;
        this.total = val.response.total

      }
    )

  }

}
