<div class="banner-bottom row no-gutters">
  <div class="col-12 col-lg">
    <h1 class="title-banner-bottom">{{'comunication-banner-text1'|translate}}</h1>
    <div class="banner-bottom-separator">
    </div>
    <h2 class="banner-bottom-subtext">{{'comunication-banner-text2'|translate}} </h2>
  </div>
  <div class="col-12 col-lg comunication-items">
    <div class="row no-gutters">
      <img src="../../../assets/images/phone-comunication.png"
           srcset="../../../assets/images/phone-comunication@2x.png 2x,
             assets/images/phone-comunication@3x.png 3x"
           class="Telefono">
      <h2 class="banner-bottom-phone">{{'comunication-banner-text3'|translate}}</h2></div>
    <div class="row no-gutters email-comunication">
      <img src="../../../assets/images/mail-comunication.png"
           srcset="../../../assets/images/mail-comunication@2x.png 2x,
             assets/images/mail-comunication@3x.png 3x"
           class="Telefono">
      <h2 class="banner-bottom-phone">{{'comunication-banner-text4'|translate}}</h2></div>
  </div>
  <img src="../../../assets/images/comunication-banner-paint.png"
       srcset="../../../assets/images/comunication-banner-paint@2x.png 2x,
             assets/images/comunication-banner-paint@3x.png 3x"
       class="comunication-banner-paint">

</div>
