<div class="nearOffers ">
    <div class=" center " >
        <h2 class="sectionTitle center">{{'near-offers-text1'|translate}}</h2>
        <hr class="sectionTitleBar">
        <div class="row no-gutter" *ngIf="topSixCities">
            <div class="col-6 col-md-4 col-lg-4" *ngFor="let city of topSixCities">
                <div (click)="goProvince(city.id)" class="topCity" style="background-image: linear-gradient(to bottom, var(--primary50) 2%, var(--secondary50)),url('{{getUrl(city)}}');">
                    <span class="cityName">{{city.name}}</span>
                    <br>
                    <span class="cityQuantity">{{city.quantity}} {{'near-offers-text2'|translate}}</span>
                </div>
            </div>
        </div>
        <br>

        <div class="row  d-none d-lg-flex">
            <div class="col-2 col-md-4 col-lg-4"  *ngIf="citiesColum1">
                <ul class="cityList">
                    <li *ngFor="let city of citiesColum1" (click)="goProvince(city.id)">
                        {{city.name}}
                        <div class="circlelist">{{city.quantity}}</div>
                    </li>
                </ul>
            </div>
            <div class="col-3  col-md-4 col-lg-4"  *ngIf="citiesColum2">
                <ul class="cityList">
                    <li *ngFor="let city of citiesColum2" (click)="goProvince(city.id)">
                        {{city.name}}
                        <div class="circlelist">{{city.quantity}}</div>
                    </li>
                </ul>
            </div>
            <div class="col-3  col-md-4 col-lg-4"  *ngIf="citiesColum3">
                <ul class="cityList">
                    <li *ngFor="let city of citiesColum3" (click)="goProvince(city.id)">
                        {{city.name}}
                        <div class="circlelist">{{city.quantity}}</div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-60">
            <button class="btn secondaryBG seeAllButton" [routerLink]="['/listado-activos']">{{'near-offers-text3'|translate}}</button>
        </div>

    </div>
</div>
