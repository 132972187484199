import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {User} from '../models/users';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.url
  private headers: HttpHeaders;
  private loginSource = new BehaviorSubject(false);
  public loginSubscriber = this.loginSource.asObservable();

  constructor(public http: HttpClient) {
  }

  async loginUser(User_Mail: string, User_Password: string) {
    let body = {'email': User_Mail, 'password': User_Password};
    const response = await this.http.post(this.url + "/api/auth/login", body).toPromise()
    return response
  }

  notify(login: any) {
    this.loginSource.next(login)
  }

  private setHeaders() {
    this.headers = new HttpHeaders().set("Accept", "application/json").set("Authorization", localStorage.getItem('TOKEN')?localStorage.getItem('TOKEN'):'')
  }

  resendMailActiveUser(email) {
    return this.http.post(this.url + "/api/user/resend/activate", {"email": email})
  }

  registerUser(user) {
    return this.http.post(this.url + "/api/user/register/", user)
  }

  updateUser(user: User) {
    this.setHeaders()
    return this.http.put(this.url + "/api/user/update/", user, {headers: this.headers})
  }

  uploadDocuments(docs) {
    return this.http.post(this.url + "/api/user/upload/documentation/", docs, {headers: this.headers})
  }

  getMyAuctions() {
    return this.http.get(this.url + "/api/auctions/user")
  }

  getUser() {
    this.setHeaders()
    return this.http.get(this.url + "/api/auth/user-profile", {headers: this.headers})
  }

  updateNotifications(user: User) {
    this.setHeaders()
    return this.http.put(this.url + "/api/user/notifications", user, {headers: this.headers})
  }

  getRepresentation() {
    this.setHeaders()
    return this.http.get(this.url + "/api/user/representation", {headers: this.headers})
  }

  editRepresentation(id: number) {
    this.setHeaders()
    return this.http.get(this.url + "/api/user/representation/" + id, {headers: this.headers})
  }

  updateRepresentation(formData: FormData, id: number) {
    this.setHeaders()
    formData.append("_method", "PUT");
    return this.http.post(this.url + "/api/user/representation/" + id, formData, {headers: this.headers})
  }

  addRepresentation(registrationForm: any) {
    this.setHeaders()
    return this.http.post(this.url + "/api/user/representation", registrationForm, {headers: this.headers})
  }

  removeRepresentation(id) {
    this.setHeaders()
    return this.http.delete(this.url + "/api/user/representation/" + id, {headers: this.headers})
  }

  removeAccount() {
    this.setHeaders()
    return this.http.delete(this.url + "/api/user/delete_permanent/", {headers: this.headers})
  }

  getUserAuctions() {
    this.setHeaders()

    return this.http.get(this.url + "/api/user/userAuctions/all", {headers: this.headers})
  }

  createAlert(alertForm) {
    this.setHeaders()
    return this.http.post(this.url + "/api/user/alert/create", alertForm, {headers: this.headers})
  }

  getAlerts() {
    this.setHeaders()

    return this.http.get(this.url + "/api/user/alert", {headers: this.headers})
  }

  removeAlert(id: number) {
    this.setHeaders()
    return this.http.delete(this.url + "/api/user/alert/" + id, {headers: this.headers})
  }

  sendMessage(value: any) {
    this.setHeaders()
    return this.http.post(this.url + "/api/auth/contact", value, {headers: this.headers})
  }

  checkToken(token) {
    let body = { };

    let headers = new HttpHeaders().set("Accept", "application/json").set("Authorization", token)

    return this.http.post(this.url + "/api/user/checkToken", body, {headers: headers})
  }

  recoverPassword(user: any, token) {
    this.headers = new HttpHeaders().set("Accept", "application/json").set("Authorization", token)

    return this.http.post(this.url + "/api/user/recover_password", user, {headers: this.headers})

  }

  recoverPasswordPetition(email) {
    return this.http.post(this.url + "/api/user/petition_recover", {email: email})

  }

  changeLanguage(value) {
    this.setHeaders()
    return this.http.put(this.url + "/api/user/change_language?language"+value, { language: value }, {headers: this.headers})
  }

  verifyAccount(email: any , token: any) {
    return this.http.get(this.url + "/api/auth/complete/"+email+"/"+token)
  }
}