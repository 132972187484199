<div class="general-style d-lg-flex blue-background">

  <div class="col-lg-6 col-sm-12">
    <div class="row p-tittle text-center d-block">
      {{'buy-easily-text1'|translate}}
    </div>
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 col-sm-12">
        <p class="align-left">
          {{'buy-easily-text2'|translate}}<br/>
          {{'buy-easily-text3'|translate}}<br/>
          {{'buy-easily-text4'|translate}}<br/>
        </p>
        <br/>
        {{'buy-easily-text5'|translate}}
      </div>
      <div class="col-lg-3"></div>
    </div>
    <div class="row text-center">
      <button class="Rectangle-butto d-block mx-auto" [routerLink]="['/registrar-sin-certificado']">{{'buy-easily-text6'|translate}}</button>
    </div>
  </div>

  <div class="col-lg-6 d-none d-lg-block"></div>

</div>

<div class="row general-style">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-sm-12">
        <img src="assets/images/experiencia.png" alt="Trademat"/>
        <br/>
        {{'buy-easily-text7'|translate}}
    </div>
    <div class="col-lg-2 col-sm-12">
        <img src="assets/images/icon-circulo-perfiles-empresa.png" alt="Trademat"/>
        <br/>
        {{'buy-easily-text8'|translate}}
    </div>
    <div class="col-lg-2 col-sm-12">
        <img src="assets/images/icon-circulo-valores-transparencia.png" alt="Trademat"/>
        <br/>
        {{'buy-easily-text9'|translate}}
    </div>
    <div class="col-lg-2 col-sm-12">
        <img src="assets/images/icon-circulo-valores-tech.png" alt="Trademat"/>
        <br/>
        {{'buy-easily-text10'|translate}}
    </div>
    <div class="col-lg-2"></div>
</div>
