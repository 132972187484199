import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Asset} from 'src/app/models/assets';
import {Auction} from 'src/app/models/auction';
import {User} from 'src/app/models/users';
import {AssetsService} from 'src/app/services/assets.service';
import {AuctionsService} from 'src/app/services/auctions.service';
import {BaseResponse} from "../../models/base-response.model";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {environment} from "../../../environments/environment";
import {Pack} from "../../models/pack";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pack-widget',
  templateUrl: './pack-widget.component.html',
  styleUrls: ['./pack-widget.component.scss']
})
export class PackWidgetComponent implements OnInit {
  @ViewChild('auctionConditionsModal') auctionConditionsModal: TemplateRef<any>
  modalRef: BsModalRef;
  AuctionCondition: any;
  @Input() public pack: Pack = {} as any
  public user: User
  constructor(public auctionService: AuctionsService, public assetsService: AssetsService, public router: Router) {

  }

  ngOnInit(): void {
  }


  getImageUrl(path: String) {
    return environment.url + path
  }

  goToAuction(auction: Auction) {
    this.router.navigate(['producto', auction.id]);

  }
}
