// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as moment from 'moment-timezone';

export const environment = {
  //timezone: 60, //60 para horario de invierno; 120 para horario de verano
  timezone: moment.tz('Europe/Madrid').isDST() ? 2 : 1,
  production: true,
  //url: "http://trademat.test"
  url: 'https://dev.subastaconcursal.com'
  //url: 'http://trademartws-local.com' //Para trabajar en local

  //url: "https://youthful-hawking.82-223-246-50.plesk.page",
  //url: "http://localhost:4200"
  //url: "http://trademat.local"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
