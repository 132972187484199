<div>
	<div class="communication-banner">
		<div class="communication-banner__contents">
			<div class="col-12 d-lg-none col-md-2 col-lg-2">
				<img src="assets/images/comunication-banner.png"
					srcset="assets/images/comunication-banner@2x.png 2x,
					assets/images/comunication-banner@3x.png 3x"
					class="communication-banner__image">
			</div>

			<div class="row no-gutters information">
				<div class="col-md-7 col-lg-7" style="padding-right:3rem">
					<h2 class="sub-title">
						{{ 'communication-page-text1' | translate }}
					</h2>

					<h1 class="title">
						{{ 'communication-page-text2' | translate }}
					</h1>

					<p class="subtext white">
						{{ 'communication-page-text3' | translate }}
						<br><br>
						{{ 'communication-page-text4' | translate }}
					</p>
				</div>

				<div class="d-none d-lg-block col-md-5 col-lg-5">
					<img src="assets/images/comunication-banner.png"
						srcset="assets/images/comunication-banner@2x.png 2x,
						assets/images/comunication-banner@3x.png 3x"
						class="communication-banner__image">
				</div>

				<div class="section-comunication-parent row no-gutters">
					<div class="col-12 col-lg section-comunication" [routerLink]="['/gallery']" (click)="$event.preventDefault()">
						<div class="row no-gutters">
							<div class="col-4">
								<img src="assets/images/equipo.png"
									srcset="assets/images/equipo@2x.png 2x,
									assets/images/equipo@3x.png 3x"
									class="Equipo">
							</div>
							<div class="col-4">
								<h1 class="section-text">
									{{ 'communication-page-text5' | translate }}
								</h1>
							</div>
						</div>
					</div>

					<div class="col-12 col-lg  section-comunication" [routerLink]="['/note-press']">
						<div class="row no-gutters">
							<div class="col-4">
								<img src="assets/images/prensa.png"
									srcset="assets/images/prensa@2x.png 2x,
									assets/images/prensa@3x.png 3x"
									class="Equipo">
							</div>
							<div class="col-4">
								<h1 class="section-text">
									{{ 'communication-page-text6' | translate }}
								</h1>
							</div>
						</div>
					</div>

					<div class="col-12 col-lg  section-comunication" [routerLink]="['/dossier']">
						<div class="row no-gutters">
							<div class="col-4">
								<img src="assets/images/dossier.png"
									srcset="assets/images/dossier@2x.png 2x,
									assets/images/dossier@3x.png 3x"
									class="Equipo">
							</div>
							<div class="col-4">
								<h1 class="section-text">
									{{ 'communication-page-text7' | translate }}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-news">
		<div class="row justify-content-center">
			<h1 class="section-news-title">
				{{ 'communication-page-text8' | translate }}
			</h1>
		</div>

		<div class="row justify-content-center">
			<div class="section-news-bar"></div>
		</div>

		<div class="section-news-container-news">
			<mat-grid-list cols="{{window.innerWidth>=768?'3':'1'}}" rowHeight="{{window.innerWidth>=768?'1:1':'268pt'}}">
				<mat-grid-tile *ngFor="let communication of communication_list">
					<app-section-news-item [communication]="communication">
					</app-section-news-item>
				</mat-grid-tile>
			</mat-grid-list>
		</div>

		<div class="separator"></div>
	</div>

	<div class="row justify-content-center">
		<app-pagination [totalRecords]="total" recordsPerPage="{{limit}}" (onPageChange)="displayActivePage($event)">
		</app-pagination>
	</div>
</div>
