import {Component, OnInit} from '@angular/core';
import {CommunicationService} from "../services/communication.service";
import {BaseResponse} from "../models/base-response.model";
import {Communication, CommunicationResponse} from "../models/comunication.model";
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-comunication-page',
  templateUrl: './comunication-page.component.html',
  styleUrls: ['./comunication-page.component.scss']
})
export class ComunicationPageComponent implements OnInit {
  window: any = window;
  communication_list: Communication[];
  activePage: number = 1;
  limit = 10;
  total = 0;

  constructor(private communicationService: CommunicationService,private scroll: ViewportScroller) {
  }

  ngOnInit(): void {

  }


  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber
    this.communicationService.getCommunications(this.limit, this.activePage).subscribe(
      (val: BaseResponse<CommunicationResponse>) => {
        this.communication_list = Object.values(val.response.communication_list)
        this.total = val.response.total
      });

  }
}
