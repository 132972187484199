<div class="container" *ngIf="pack">
  <div class="carousel">
    <ngb-carousel *ngIf="imagesGal">
      <ng-template ngbSlide *ngFor="let img of imagesGal">
        <img class="picsum-img-wrapper" src="{{img}}" >
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="row m-auto" style="padding:40px 0">
    <div class="  col-4 col-sm-4 col-lg-4 my-auto ">
      <a [routerLink]="['/']">
        <img src="assets/images/arrow-left.png"
             srcset="assets/images/arrow-left@2x.png 2x,
                    assets/images/arrow-left@3x.png 3x"
             class="arrow-left">
        <label>{{'product-page-text1'|translate}} </label>
      </a>
    </div>

  </div>
    <span class="badge badgeToolbar greenBG white">
        <img src="assets/images/pack.png"
             srcset="assets/images/pack@2x.png 2x,
                assets/images/pack@3x.png 3x"
             class="auction">
      {{'product-page-text28'|translate}}
    </span>
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <h1 class="tituloProducto">{{pack.title}}</h1>
        <p class="description">
          {{pack.description}}
        </p>



        <div id="containerDetalles" class="sectionContainer">
          <h2 class="titleContainer">{{'product-page-text38'|translate}}</h2>

          <img src="assets/images/check.png"
               srcset="assets/images/check@2x.png 2x,
                        assets/images/check@3x.png 3x"
               class="check">
          <label class="checkLabelTitle">{{'product-page-text40'|translate}}</label>
          <br>
          <label class="detail">{{pack.auctions.length}}</label>
          <br>

          <img src="assets/images/check.png"
               srcset="assets/images/check@2x.png 2x,
                        assets/images/check@3x.png 3x"
               class="check">
          <label class="checkLabelTitle">{{'product-page-text41'|translate}}</label>
          <br>
          <label class="detail">{{pack.auctions[0].province}}</label>
          <br>
        </div>

        <div id="containerDocumentacion" class="sectionContainer" *ngIf="assetDocuments.length>0">
          <h2 class="titleContainer">{{'product-page-text42'|translate}}</h2>
          <ul class="doclist">
            <li *ngFor="let doc of assetDocuments">
              <img src="assets/images/document.png"
                   srcset="assets/images/document@2x.png 2x,
                            assets/images/document@3x.png 3x"
                   class="doc-icon"><span class="doctitle">{{doc.name}}</span>
              <br>
              <a class="doclink"
                 href="http://trademat.lemurstaging.com{{doc.path}}">{{'product-page-text20'|translate}}</a>
            </li>
          </ul>
        </div>

      </div>
      <div class="col-sm-1 col-lg-1"></div>
      <div class="d-none d-lg-block col-sm-5 col-lg-5" >
        <app-pack-widget [pack]="pack" ></app-pack-widget>
      </div>
    </div>
</div>

