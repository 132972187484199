import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private url = environment.url

  constructor(public http:HttpClient) { }

  getGallery(){
    return this.http.get(this.url+"/api/gallery")
  }

  getGalleryTypes() {
    return this.http.get(this.url + "/api/gallery_type")

  }

  downloadAllImages(id){
    return this.http.get(this.url + `/api/gallery/downloadAllImages/${id}`)
  }
  
}
