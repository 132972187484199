import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbCarousel, NgbSlideEvent, NgbSlideEventSource} from '@ng-bootstrap/ng-bootstrap';
import {Archive} from "../models/Archive";
import {Auction} from "../models/auction";
import {BaseResponse} from "../models/base-response.model";
import {environment} from "../../environments/environment";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Pack, PackResponse} from "../models/pack";
import {PackService} from "../services/pack.service";

@Component({
  selector: 'app-pack-page',
  templateUrl: './pack-page.component.html',
  styleUrls: ['./pack-page.component.scss']
})
export class PackPageComponent implements OnInit {
  public packID: any
  public pack: Pack
  public gallery: Archive[]
  public imagesGal: any[] = []
  public assetDocuments: Archive[] = []
  public assetCharges: Archive[] = []
  public AssetPosition: any = ""
  public MapIcon: google.maps.Icon = {url: "assets/images/ficha.png"}
  public MarkerOptions: google.maps.MarkerOptions = {icon: this.MapIcon}
  public isPack: boolean = false
  public userExist: boolean = false
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnFocus = true;
  @ViewChild('auctionConditionsModal') auctionConditionsModal: TemplateRef<any>
  modalRef: BsModalRef;
  AuctionCondition: any;
  @ViewChild('carousel', {static: true}) carousel: NgbCarousel;
  selected: number = 0;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, public packService: PackService, private modalService: BsModalService) {
    this.packID = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.checkRoute()
    if (localStorage.USER != null || localStorage.USER != undefined) {
      this.userExist = true
    }

    this.packService.getPack(this.packID).subscribe(
      (val: BaseResponse<PackResponse>) => {
        this.pack = val.response.pack
        this.assetDocuments = this.pack.auctions[0].archives.filter(it => it.archive_type_id == 3);
        this.assetCharges = this.pack.auctions[0].archives.filter(it => it.archive_type_id == 4);
        console.log(this.assetDocuments)

        this.AssetPosition = {lat: this.pack.auctions[0].latitude, lng: this.pack.auctions[0].longitude}
        this.gallery = this.pack.auctions[0].archives.filter(it2 => it2.archive_type_id == 2);
        this.imagesGal = this.gallery.map((n) => environment.url + n.path)

      }
    )


  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  openAuctionConditionModal(a: Auction) {
    this.AuctionCondition = a.conditions
    this.modalRef = this.modalService.show(this.auctionConditionsModal, Object.assign({}, {class: 'gray modal-lg'}));
  }

  checkRoute() {
    this.route.pathFromRoot[1].url.subscribe(
      val => {
        if (val[0].path == 'pack') {
          this.isPack = true
          return this.isPack
        }
      }
    );
  }

  getUrl(path: string) {
    return environment.url + path;
  }
}
