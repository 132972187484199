<div class="">
	<div class="banner">
		<!--<div>
				<img src="assets/images/group.png"
						 srcset="assets/images/group@2x.png 2x,
						 assets/images/group@3x.png 3x"
						class="medialuna">
		</div>-->
		<div class="row">
			<div class="col-4 col-md-6 col-lg-10">
				<h2 class="sub-title">
					{{ 'about_us_title' | translate }}

				</h2>
				<h1 class="title">
					{{ 'trademat_title' | translate }}


				</h1>
				<p class="subtext white">
					{{ 'about_us_subtext_info' | translate }}

				</p>

			</div>
			<img src="assets/images/about-us-banner.svg"
					 class="about-us-banner">
		</div>
	</div>
	<div class="info-container">
		<div class="info-container__contents">
			<div class="grid1">
				<div class="item">
					<div class="row justify-content-center m-auto">
						<div><img src="assets/images/sin-texto-background.png"
											srcset="assets/images/sin-texto-background@2x.png 2x,
													assets/images/sin-texto-background@3x.png 3x"
											class="SinTexto"></div>
						<div class=""><p class=" homologado">
							{{ 'about_us_approved' | translate }}
						</p>
							<p class=" homologado2">
								{{ 'about_us_approved2' | translate }}
							</p>
							<div class="rectangle"></div>

						</div>

					</div>

				</div>

				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item1_text1' | translate }}"
														 text2="{{ 'about_us_item1_text2' | translate }}" color="#ef84c9" number="01"
														 icon="charts"></app-about-us-item>
				</div>
			</div>
			<div class="grid2">
				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item2_text1' | translate }}"
														 text2="{{ 'about_us_item2_text2' | translate }}" color="#ef56b1" number="02" icon="2"
														 [oneline]="false"></app-about-us-item>
				</div>
				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item3_text1' | translate }}"
														 text2="{{ 'about_us_item3_text2' | translate }}" color="#e82a8c" number="03" icon="3"
														 [oneline]="false"></app-about-us-item>
				</div>
				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item4_text1' | translate }}"
														 text2="{{ 'about_us_item4_text2' | translate }}" color="#d62b99" number="04" icon="4"
														 [oneline]="false"></app-about-us-item>
				</div>

				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item5_text1' | translate }}"
														 text2="{{ 'about_us_item5_text2' | translate }}" color="#c6207b" number="05"
														 icon="5"></app-about-us-item>
				</div>
				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item6_text1' | translate }}"
														 text2="{{ 'about_us_item6_text2' | translate }}" color="#a31f6e" number="06" icon="6"
														 [oneline]="false"></app-about-us-item>
				</div>
				<div class="item m-auto">
					<app-about-us-item text1="{{ 'about_us_item7_text1' | translate }}"
														 text2="{{ 'about_us_item7_text2' | translate }}" color="#8e035c" number="07" icon="7"
														 [oneline]="false"></app-about-us-item>
				</div>
			</div>
		</div>
	</div>

	<app-search-form></app-search-form>
</div>
