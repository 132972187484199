<div class="cont">
	<div class="formstep">
		<div class="section">
			<h3>{{ 'complete-data-text1' | translate }}</h3>
			<p>{{ 'complete-data-text2' | translate }}</p>
		</div>
		<div class="section">
			<form [formGroup]="completeUserForm" autocomplete="none">
			<input autocomplete="false" name="hidden" type="text" style="display:none;">
			<h4>{{ 'complete-data-text3' | translate }}</h4>
			<div class="row">
				<input type="hidden" formControlName="id">
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text4' | translate }}</label><br>
					<input type="text" formControlName="name" class="form-control">

					<div *ngIf="form.name.errors">
						<p class="validation-error" *ngIf="form.name.errors.minlength">
							{{'complete-data-text17'|translate}}
						</p>
					</div>					
					<div *ngIf="errorsServer['name']">
						<p class="validation-error" *ngFor="let error of errorsServer['name']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-tex5' | translate }}</label><br>
					<input type="text" formControlName="surname" class="form-control">

					<div *ngIf="errorsServer['surname']">
						<p class="validation-error" *ngFor="let error of errorsServer['surname']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-12 col-lg-12">
					<label for="" class="field-label minilabel">{{ 'complete-data-text6' | translate }}</label>
					<input disabled type="text" formControlName="email" class="form-control">

					<div *ngIf="errorsServer['email']">
						<p class="validation-error" *ngFor="let error of errorsServer['email']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text7' | translate }} (<i>{{ 'complete-data-text16' | translate }}</i>)</label>
					<input type="password" placeholder="*******" formControlName="password" class="form-control" autocomplete="nope" onfocus="this.removeAttribute('readonly');" readonly>

					<div *ngIf="errorsServer['password']">
						<p class="validation-error" *ngFor="let error of errorsServer['password']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">Repetir {{ 'complete-data-text7' | translate }}</label>
					<input type="password" placeholder="*******" formControlName="password_confirmation" class="form-control" autocomplete="nope" onfocus="this.removeAttribute('readonly');" readonly>

					<div *ngIf="errorsServer['password_confirmation']">
						<p class="validation-error" *ngFor="let error of errorsServer['password_confirmation']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-12 col-lg-12">
					<label for="" class="field-label minilabel">{{ 'complete-data-text8' | translate }}</label><br>
					<input type="text" formControlName="document_number" class="form-control">

					<div *ngIf="errorsServer['document_number']">
						<p class="validation-error" *ngFor="let error of errorsServer['document_number']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-12 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'register-text27' | translate }}</label><br>
					<select formControlName="prefix" class="custom-select form-control">
						<option *ngFor="let country of countries" value="{{country.id}}">{{country.name}} - {{country.prefix}}</option>
					</select>

					<div *ngIf="errorsServer['prefix']">
						<p class="validation-error" *ngFor="let error of errorsServer['prefix']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-12 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text9' | translate }}</label>
					<input type="text" formControlName="phone" class="form-control">

					<div *ngIf="errorsServer['phone']">
						<p class="validation-error" *ngFor="let error of errorsServer['phone']">
							{{ error }}
						</p>
					</div>
				</div>

				<h4>{{ 'complete-data-text10' | translate }}</h4>

				<div class="field-group field col-sm-12 col-lg-12">
					<label for="" class="field-label minilabel">{{ 'complete-data-text11' | translate }}</label><br>
					<input type="text" formControlName="address" class="form-control">

					<div *ngIf="errorsServer['address']">
						<p class="validation-error" *ngFor="let error of errorsServer['address']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text12' | translate }}</label><br>
					<input type="text" formControlName="city" class="form-control">

					<div *ngIf="errorsServer['city']">
						<p class="validation-error" *ngFor="let error of errorsServer['city']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text13' | translate }}</label><br>
					<input type="text" formControlName="cp" class="form-control">

					<div *ngIf="errorsServer['cp']">
						<p class="validation-error" *ngFor="let error of errorsServer['cp']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text15' | translate }}</label><br>
					<select formControlName="country_id" (change)="selectedCountry($event.target.value)" class="custom-select form-control">
					  <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
					</select>

					<div *ngIf="errorsServer['country_id']">
						<p class="validation-error" *ngFor="let error of errorsServer['country_id']">
							{{ error }}
						</p>
					</div>
				</div>
				<div class="field-group field col-sm-6 col-lg-6">
					<label for="" class="field-label minilabel">{{ 'complete-data-text14' | translate }}</label><br>
					<select formControlName="province_id" class="custom-select form-control">
						<option *ngFor="let province of provinces" value="{{province.id}}">{{province.name}}</option>
					</select>

					<div *ngIf="errorsServer['province_id']">
						<p class="validation-error" *ngFor="let error of errorsServer['province_id']">
							{{ error }}
						</p>
					</div>
				</div>
			</div>
		</form>
		<button class="btn primaryBtn btn-block"
			[disabled]="!completeUserForm.valid"
			(click)="confirmUserData()">
			{{ 'user-page-text35' | translate }}
		</button>
		<p class="pink" style="text-align: center;">{{statusmsg}}</p>
		</div>
	</div>
</div>
<!--MODALS-->
<ng-template #confirmModal>
	<div class="modal-header">
		<h6 class="modal-title" >Guardar cambios</h6>
		<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>¿Seguro que quieres guardar los datos introducidos?.</p>
	</div>
	<div class="modal-footer" style="margin: 0 auto;">
		<button type="button" class="btn altBtn" (click)="modalRef.hide()" data-dismiss="modal">Cancelar</button>
		<button type="button"
			class="btn primaryBtn"
			data-dismiss="modal"
			[disabled]="submitting"
			(click)="updateInfoUser()">Guardar
		</button>
	</div>
</ng-template>
