<div class="active-cats container-fluid d-none d-lg-flex">

	<div class="active-cats__contents">
		<div class="active-cats__carousel">
			<ngb-carousel class="carousel">
				<ng-template ngbSlide *ngFor="let slide of slides">
					<div class="row">
						<div class="col-sm-3 col-lg-3" *ngFor="let assetType of slide">
							<div class="activeCard" *ngIf="assetType" (click)="goCategory(assetType.id)" style="background-image: url('{{getImage(assetType)}}');">
								<div class="activeFooter">
									<span>{{assetType.name}}</span>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</ngb-carousel>
		</div>

	</div>

</div>

<div class="active-cats-mob container-fluid d-lg-none m-auto">
	 <ngb-carousel class="m-auto"  [showNavigationArrows]="false">
		<ng-template ngbSlide *ngFor="let slide of slides" >
				<div class="row justify-content-center">
						<div class="" *ngFor="let assetType of slide">
							<div class="activeCardResponsive" *ngIf="assetType" style="background-image: url('{{getImage(assetType)}}');">
								<div class="activeFooter">
									<span>{{assetType.name}}</span>
								</div>
							</div>
						</div>
				</div>
		</ng-template>
	</ngb-carousel>
</div>
