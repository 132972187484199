<div>
    <div style="border-bottom: solid 1px var(--grey-2); margin-bottom: 25px;">
    <div class="row">
        <div class="col-2">
          <img src="assets/images/sin-texto.png"
                  srcset="assets/images/sin-texto@2x.png 2x,
                          assets/images/sin-texto@3x.png 3x">
        </div>
        <div class="col-10">
          <h1 class="stepTitle">
            {{ stepItems[activeStepIndex].label }}
          </h1>
          <p>
            {{'step-form-text1'|translate}}

          </p>
        </div>
    </div>
    </div>
    <h3 class="sublabel">{{ stepItems[activeStepIndex].sublabel }}</h3>
    <ng-container
      *ngFor="let form of masterForm; let i = index; trackBy: trackByFn"
    >
      <form
        action=""
        [formGroup]="masterForm[i]"
        *ngIf="i === activeStepIndex || masterForm.length === 1"
      >
        <div class="">
          <div class="fields-container row">
            <div
              *ngFor="let field of formFields[i]; trackBy: trackByFn"
              [ngSwitch]="currentFormContent[i][field].type"
              class="{{currentFormContent[i][field].columns}} field-group"
            >
              <div class="full-width" [ngClass]="[currentFormContent[i][field].title !== '' ? 'field' : 'noFieldName' , currentFormContent[i][field].type == 'checkbox' ? 'checkboxField' : 'field']">
                <label *ngIf="currentFormContent[i][field].type !== 'checkbox'" class="field-label text-bold {{ currentFormContent[i][field].class }}" [for]="field">{{ currentFormContent[i][field].title }}</label>
                <br *ngIf="currentFormContent[i][field].class == 'minilabel'">

                <ng-container *ngSwitchCase="'textarea'">
                  <textarea
                    [id]="field"
                    [formControlName]="field"
                    [placeholder]="currentFormContent[i][field].placeholder"></textarea>
                </ng-container>

                <ng-container *ngSwitchCase="'file'">
                  <input type="file" (change)="onFileChange($event)" />
                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                  <select [formControlName]="field" [id]="field">
                    <option
                      *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                      [value]="option.id"
                      >{{ option.name }}</option>
                  </select>
                </ng-container>

                <ng-container *ngSwitchCase="'radio'">
                    <span  *ngFor="let option of currentFormContent[i][field].options" >
                        <input [formControlName]="field" [id]="field" type="radio" [value]="option.id"> {{ option.name }}
                    </span>
                </ng-container>

                <!--<ng-container *ngSwitchCase="'checkbox'">
                        <div class="form-check">
                            <input class="form-check-input" [formControlName]="field" type="checkbox" [id]="field">
                            <label class="form-check-label">
                                {{ currentFormContent[i][field].title }}
                            </label>
                        </div>
                </ng-container>-->

                <ng-container *ngSwitchDefault>
                  <input
                    [type]="currentFormContent[i][field].type"
                    [formControlName]="field"
                    [id]="field"
                    [placeholder]="currentFormContent[i][field].placeholder"
                  />
                </ng-container>
              </div>

              <p
                class="validation-error"
                *ngIf="
                  (masterForm[i].get(field).dirty ||
                    masterForm[i].get(field).touched) &&
                  masterForm[i].get(field).invalid &&
                  masterForm[i].get(field).errors
                "
              >
                {{ getValidationMessage(i, field) }}
              </p>
            </div>
          </div>

          <div class="button-container">
            <button
              type="button"
              [disabled]="!activeStepIndex"
              (click)="goToStep('prev')"
              class="back"
            >{{'step-form-text2'|translate}}</button>

            <button
              *ngIf="activeStepIndex !== stepItems.length - 1"
              type="button"
              [disabled]="activeStepIndex === stepItems.length - 1 || masterForm[i].invalid"
              (click)="goToStep('next')"
              class="next"
            >{{'step-form-text3'|translate}}</button>

            <button
              *ngIf="activeStepIndex === stepItems.length - 1"
              type="button"
              (click)="onFormSubmit()"
              class="next"
            >{{'step-form-text4'|translate}}</button>

          </div>
        </div>
      </form>
    </ng-container>
  </div>
