<div class="help-form">
	<div class="help-form__contents row">
		<div class="col-12 col-sm-6 col-lg-6">
			<h2 class="sectionTitle">
				{{'help-form-text1'|translate}}
			</h2>

			<hr class="sectionTitleBar">

			<p>{{'help-form-text2'|translate}}</p>

			<button [routerLink]="['/faq']" class="btn primaryBG white">
				{{'help-form-text3'|translate}}
			</button>
		</div>

		<form class="help-form__form col-12 col-sm-6 col-lg-6"
			[formGroup]="contactForm" (ngSubmit)="onSubmit()">

			<div class="card">
				<div class="section">
					<h3>
						{{'help-form-text4'|translate}}
					</h3>
				</div>

				<div class="row cont">
					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormName" placeholder="{{'name'|translate}}" class="w-100">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormCompany" placeholder="{{'company'|translate}}" class="w-100">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormEmail" placeholder="{{'email'|translate}}" class="w-100">
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormPhone" placeholder="{{'phone'|translate}}" class="w-100">
						</div>
					</div>

					<div class="col-lg-12 col-md-12">
					</div>

					<div class="col-lg-12 col-md-12">
						<div class="fields-container field">
							<input type="text" formControlName="contactFormMessage" placeholder="{{'message_describe'|translate}}" class="w-100">
						</div>
					</div>

					<div class="help-form__submit">
						<div class="help-form__submit__wrapper">
							<button class="btn secondaryBG white pieBtn" type="submit" [disabled]="disabledSubmitButton">
								{{'help-form-text9'|translate}}
							</button>
							<label class="pie">
								{{'contact-page-text5'|translate}} <a href="/privacy-policy">{{'contact-page-text6'|translate}}</a>
							</label>
						</div>
					</div>
				</div>
			</div>

		</form>
	</div>
</div>
