<app-user-bar class="d-none d-lg-block" [page]="'historial-subastas'"></app-user-bar>
<div class="cont">
  <div>
    <div class="formstep">
      <div class="section">
        <div class="row">
          <div class="col-10 col-lg-6 col-sm-6 ">

            <h1 class="title">

              <img src="assets/images/auction-pink.png"
                   srcset="assets/images/auction-pink@2x.png 2x,
                                    assets/images/auction-pink@3x.png 3x"
                   class="icon">
              {{'user-auction-text1'|translate}}  </h1>
          </div>

          <div class="col-2 col-lg-6 col-sm-3 text-right">
            <button class="btn secondaryBG white" (click)="openUserBidListModal(user.id)">
              <img src="assets/images/list.png"
                   srcset="assets/images/list@2x.png 2x,
                                        assets/images/list@3x.png 3x"
                   class="List">
              <span class="d-none d-lg-inline">{{'user-auction-text2'|translate}} </span>
            </button>
          </div>
          <div class="col-10 col-sm-3 col-lg-3">
            <span class="results">{{auctions.length}} {{'user-auction-text3'|translate}}</span>
          </div>
          <div class="col-2 col-sm-3 col-lg-3">
            <img src="assets/images/filter.png"
                 srcset="assets/images/filter@2x.png 2x,
                                    assets/images/filter@3x.png 3x"
                 class="icon d-lg-none" (click)="collapsed=!collapsed">
          </div>
          <div [ngbCollapse]="collapsed" class="col-sm-9 col-lg-9">
            <div class="row">
              <div class="col-sm-4 col-lg-4">
                <div style="border: 1px solid #adadad; height: 38px; border-radius: 4px;">
                  <img src="assets/images/search_azul.png" srcset="assets/images/search_azul@2x.png 2x,
                                            assets/images/search_azul@3x.png 3x" class="icon">
                  <input type="text" name="" id="" style="height: 100%; border: none;"
                         [(ngModel)]="customSelected"
                         (keyup)="filterbyText($event)"
                  >
                </div>


              </div>
              <div class="col-sm-2 col-lg-2">
                <ng-multiselect-dropdown
                  name="Status"
                  [placeholder]="'Estado'"
                  [settings]="dropdownprocessTypeSettings"
                  [data]="statusAuction"
                  (onSelect)="onStatusSelect($event)"
                  (onDeSelect)="onStatusDeselect($event)"
                >
                </ng-multiselect-dropdown>
              </div>

              <div class="col-sm-3 col-lg-3">
                <ng-multiselect-dropdown
                  name="Types"
                  [placeholder]="'Tiempo Restante'"
                  [settings]="dropdownprocessTypeSettings"
                  [data]="restTime"
                  (onSelect)="onRestTimeSelect($event)"
                  (onDeSelect)="onRestTimeDeselect($event)"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div id="{{a.id}}" class=" row  mb-5" style="border-bottom: 1px solid var(--grey-2);" *ngFor="let a of auctions">
          <!--<div class="col-lg-4 col-sm-4" style="cursor: pointer;" (click)="goAuction(a.id)">-->
          <!--<a class="col-lg-4 col-sm-4" style="cursor: pointer;" href="" (click)="goAuction($event, a.id)">-->
          <a class="col-lg-4 col-sm-4" style="cursor: pointer;" [href]="a.linkProduct">
            <img class="auctionImg" src="{{this.url}}{{a.archives[0]?.path}}">
            <div class="col-auto badge badgeToolbar greenBG white ml-2" *ngIf="a.auction_type_id==1"
            >
              <img src="assets/images/auction.png"
                   srcset="assets/images/auction@2x.png 2x,
                                                    assets/images/auction@3x.png 3x"
                   class="auction">
              {{'user-auction-text4'|translate}}
            </div>
            <!-- <div *ngIf="a.auction_type_id == 1 && a.status!='Initiated'" class="endframe">
              <div *ngIf="soonDate(a.start_date) else auctionInCourse">
                <label for="">{{'user-auction-text6'|translate}}</label>
                <p>{{a.end_date | date:'dd/MM/YYYY'}}</p>
              </div>
              <ng-template #auctionInCourse>
                <label for="">{{'user-auction-text7'|translate}}</label>
                <p>
                  {{countDownAuction(a.end_date)}}
                </p>
              </ng-template>
            </div> -->
          </a>
          <div class="col-lg-8 col-sm-8">
            <h6>ID: {{a.id}} - {{a.name}}</h6>
            <label>
              <img src="assets/images/location_pink.png"
                   srcset="assets/images/location_pink@2x.png 2x,
                                        assets/images/location_pink@3x.png 3x"
                   class="location_icon">
              <span class="pink">{{a.address}}, {{a.city}} ({{a.province}}
                )</span>   {{a.type}} · {{a.area}}m<sup>2</sup> · {{a.bedrooms}}
              {{'user-auction-text8'|translate}}
            </label>
            <br>
            <!-- <label class="rest-time">
              <img src="assets/images/time-dark.png"
                   srcset="assets/images/time-dark@2x.png 2x,
                                                assets/images/time-dark@3x.png 3x"
                   class="Time-1">
              {{'user-auction-text9'|translate}}
              <span class="dateString"> {{ a.countdown }}</span>
            </label>
            <br> -->
            <label>{{'user-auction-text47'|translate}} <span
              class="dateString">{{a.start_date | date:'dd MMM y H:mm:ss' }}</span> - {{'user-auction-text48'|translate}}
              <span
                class="dateString">{{a.end_date | date:'dd MMM y H:mm:ss' }}</span></label>
            <br>
            <div *ngIf="a.status!='Win'">
              <div class="Status row no-gutters" *ngIf="a.user_auction_status=='Valid'">
                <div class="col-auto">{{'user-auction-text10'|translate}}</div>
                <div class="col-auto">
                  <img src="assets/images/check-v.png"
                       srcset="assets/images/check-v@2x.png 2x,
                        assets/images/check-v@3x.png 3x"
                       class="check">
                </div>
                <div class="col">{{'user-auction-text11'|translate}}</div>
              </div>
            </div>
            <div class="Status-incomplete row no-gutters" *ngIf="a.user_auction_status=='Incomplete' && a.countdown!='Subasta finalizada'">
              <div class="col-auto">{{'user-auction-text12'|translate}}</div>
              <a [routerLink]="['/my-auctions']" class="col">{{'user-auction-text13'|translate}}</a>
            </div>
            <div class="row" >
              <div class="col-12 col-sm-4 col-lg-4" *ngIf="a.status=='Initiated'">
                <label class="rest-time">
                  <img src="assets/images/winner.png"
                       srcset="assets/images/winner@2x.png 2x,
                                                        assets/images/winner@3x.png 3x"
                       class="Time-1">
                  {{'user-auction-text14'|translate}}
                </label>
                <div *ngIf="!a.best_bid && a.last_bid">
                  <label class="my-best"
                    for="" [ngStyle]="{'color':'#9d0000'}">{{'user-auction-text16'|translate}}
                  </label>
                  <br class="d-none d-lg-block">
                  <label class="money-label" [ngStyle]="{'color':'#9d0000'}"
                         for="">{{a.last_bid.import | currency:'EUR'}}</label>
                </div>
                <br class="d-none d-lg-block">
                <label *ngIf="a.best_bid" class="my-best"
                    for="" [ngStyle]="{'color':getTextColor(a)}">{{'user-auction-text15'|translate}}
                </label>
                <label *ngIf="a.user_auction_status=='Loose' && a.my_last_bid" class="my-best"
                  for="">{{'user-auction-text16'|translate}}</label>
                <br>
                <div *ngIf="a.user_auction_status=='Loose' && a.my_last_bid" class="row">
                  <img src="assets/images/chart-4.png"
                       srcset="assets/images/chart-4@2x.png 2x,
                                                        assets/images/chart-4@3x.png 3x"
                       style="width: 20px;height: 20px">
                  <label class="money-label" [ngStyle]="{'color':getTextColor(a)}"
                         for="">{{getLastBid(a) | currency:'EUR'}}</label>
                  <label *ngIf="a.my_last_bid" class="money-label-lose" [ngStyle]="{'color':getTextColor(a)}"
                         for="">{{ (a.my_last_bid ? a.my_last_bid.import : 0) | currency:'EUR'}}</label>
                </div>
                <label *ngIf="a.user_auction_status=='Loose' && a.my_last_bid" class="my-date" for="">{{getTextLose(a)}}</label>

                <label *ngIf="a.best_bid" class="money-label" [ngStyle]="{'color':getTextColor(a)}"
                       for="">{{getLastBid(a) | currency:'EUR'}}</label>
                       <br class="d-none d-lg-block">
                <label *ngIf="a.best_bid" class="my-date" for="">{{getTextBid(a)}}</label>
                <label *ngIf="!a.my_last_bid" class="my-date" for="">{{'user-auction-text17'|translate}}</label>
              </div>
              <div class="col-12 col-sm-8 col-lg-8">
                <div class="Status-incomplete row no-gutters" *ngIf="a.user_auction_status=='Loose'">
                  <div class="col">{{'user-auction-text18'|translate}}</div>
                </div>
                <div class="col-12 col-sm-8 col-lg-8" *ngIf="a.status=='Initiated'">
                  <label for="" class="minitext">{{'user-auction-text19'|translate}} {{a.bid_price_interval}}€)</label>
                  <div class="row">
                    <div class="col-10 col-sm-6 col-lg-6">
                        <!--
                          <div class="priceField row no-gutters my-2">
                            <label class="col" [(ngModel)]="a.price_bid" name="" id="">{{a.price_bid|currency:'EUR':true}}</label>
                            <button class="changeval col-auto" (click)="decreasePrice(a)">-</button>
                            <button class="changeval col-auto" (click)="increasePrice(a)">+</button>
                          </div>
                        -->
                        <div class="price-input-container">
                          <input type="number"
                            id="lastBid{{a.id}}"
                            [(ngModel)]="a.price_bid"
                            min="{{a.minimun}}"
                            step="{{a.bid_price_interval}}">
                        </div>
                    </div>
                    <div class="col-2 col-sm-6 col-lg-6 p-0">
                      <button class="btn primaryBtn btn100 txtBtn" [disabled]="a.user_auction_status!='Valid'" (click)="openTemplate(a)">
                      <!--<button class="btn primaryBtn btn100 txtBtn" [disabled]="a.user_auction_status!='Valid'" (click)="makeBid(a)">-->
                        <img src="assets/images/auction-solid.png"
                             srcset="assets/images/auction-solid@2x.png 2x,
                                                                assets/images/auction-solid@3x.png 3x"
                             class="Auction-solid">
                        <span class="d-none d-lg-inline">{{'user-auction-text20'|translate}}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="a.status=='Win'">
              <div class="row">
                <div class="col-12 col-lg-4 ">

                  <label class="rest-time">
                    <img src="assets/images/winner.png"
                         srcset="assets/images/winner@2x.png 2x,
                                                        assets/images/winner@3x.png 3x"
                         class="Time-1">
                    {{'user-auction-text21'|translate}}
                  </label>
                  <br class="d-none d-lg-block">
                  <label *ngIf="a.best_bid" class="my-best"
                    for="">{{'user-auction-text22'|translate}}<br></label>
                  <label class="money-label col-12 " [ngStyle]="{'color':getTextColor(a)}"
                    for="">{{getLastBid(a) | currency:'EUR'}}</label>
                  <label class="my-date col-12"
                    for="">{{'user-auction-text23'|translate}} {{getMyLastBid(a) | currency:'EUR'}}</label>
                  <label *ngIf="a.best_bid" class="my-date"
                    for="">{{getTextBid(a)}}</label>
                </div>
                <div class="col-12 col-lg-8" *ngIf="a.user_auction_status=='Win'">
                  <div class="Status row no-gutters">
                    <div class="col-auto">
                      <img src="assets/images/mail.png"
                           srcset="assets/images/mail@2x.png 2x,
                        assets/images/mail@3x.png 3x"
                           class="check">
                    </div>
                    <div class="col">{{'user-auction-text24'|translate}}<br>
                      {{'user-auction-text25'|translate}}
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="row text-center">

              <div class="col-sm-12 col-lg-12">
                <ul class="submenu">
                  <li style="cursor: pointer;" (click)="openbidListModal(a.id)">{{'user-auction-text26'|translate}}</li>
                  <li ><a href="{{getUrl(a.conditions)}}" target="_blank">{{'user-auction-text27'|translate}}</a></li>
                  <li style="cursor: pointer;" (click)="sendIncidenceModal(a)">{{'user-auction-text28'|translate}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="restTime">
              <label class="generic">
                <span>
                <!-- <span *ngIf="completed==''">  -->
                  {{ 'auction-text7' | translate }}
                  <span class="restTimeText">
                    {{ a.countdown }}
                  </span>
                </span>
                <!-- <span class="" *ngIf="completed!=''">
                  {{ completed }}
                </span> -->
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="section">

      </div>
    </div>
  </div>
</div>

<!--MODAL-->
<ng-template #incidenceModal>
  <div class="modal-header">
    <h6 class="modal-title">{{'user-auction-text29'|translate}}</h6>
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
            aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="incidenceForm" class="modal-body" style="padding: 54px">
    <div class="row no-gutters">
      <label class="col-4 label-alert">{{'user-auction-text30'|translate}}</label>
      <textarea class="col-12 p-2" formControlName="text"></textarea>
    </div>
  </div>
  <div class="modal-footer" style="margin: 0 auto;">
    <button type="button" class="btn altBtn" (click)="modalRef.hide()"
            data-dismiss="modal">{{'user-auction-text31'|translate}}</button>
    <button type="button"
      class="btn primaryBtn"
      data-dismiss="modal"
      [disabled]="!incidenceForm.valid || submitting"
      (click)="sendIncidence()">
      {{'user-auction-text32'|translate}}
    </button>
  </div>
</ng-template>

<ng-template #bidListModal>
  <div class="modal-header">
    <div class="row">
      <div class="col-lg-5 col-sm-5">
        <h5>{{'user-auction-text33'|translate}}</h5>
      </div>
      <div class="col-lg-5 col-sm-5">
        <label class="pink" (click)="downloadPDF()">
          <img src="assets/images/pdf.png"
               srcset="assets/images/pdf@2x.png 2x,
                            assets/images/pdf@3x.png 3x"
               class="icon">
          {{'user-auction-text34'|translate}}
        </label>
        |
        <label class="pink" (click)="printDiv('printarea')">
          <img src="assets/images/print.png"
               srcset="assets/images/print@2x.png 2x,
                            assets/images/print@3x.png 3x"
               class="icon">
          {{'user-auction-text35'|translate}}
        </label>
      </div>
      <div class="col-lg-2 col-sm-2">
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="container" id="printarea" #printarea>

      <div class="row" style="height: 56px; border-radius:4px;  background-color: var(--primary-light); font-family: Lato;
            font-size: 16px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 1.31; letter-spacing: -0.01px; color: var(--primary);">
        <div class="col-3 col-lg-4 col-sm-2 rowData">{{'user-auction-text46'|translate}}</div>
        <div class="col-3 col-lg-2 col-sm-2 rowData">{{'user-auction-text37'|translate}}</div>
        <div class="col-3 col-lg-4 col-sm-2 rowData">{{'user-auction-text38'|translate}}</div>
        <div class="col-3 col-lg-2 col-sm-2 rowData">{{'user-auction-text39'|translate}}</div>
      </div>
      <div class="row  tableData" *ngFor="let bid of bids let i = index">
        <div class="col-3 col-lg-4 col-sm-4 rowData" style="white-space:pre-wrap;overflow-wrap:break-word">{{bid.name}}</div>
        <div class="col-3 col-lg-2 col-sm-2 rowData">{{bid.import | currency:'EUR'}}</div>
        <div class="col-3 col-lg-4 col-sm-4 rowData">{{bid.created_at | date:'dd MMM, YYYY H:mm:ss'}}</div>
        <div class="col-3 col-lg-2 col-sm-2 rowData">
          <span *ngIf="bid.is_outbid" class="badge bg-light">
              <img src="assets/images/chart-4.png"
                    srcset="assets/images/chart-4@2x.png 2x,
                          assets/images/chart-4@3x.png 3x"
                    class="chartIcon">
            {{'user-auction-text40'|translate}}
          </span>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
  </div>
</ng-template>

<ng-template #auctionConditionsModal>
  <div class="modal-header">
    <div class="row">

      <div class="col-lg-9 col-sm-9">
        <h5 class="modal-title modalTitle">{{'user-auction-text41'|translate}}</h5>
      </div>
      <div class="col-lg-3 col-sm-3">
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    {{AuctionCondition}}
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
<ng-template #successModal>
  <div class="modal-header">
    <div class="row">
      <div class="col-lg-3 col-sm-3"></div>
      <div class="col-lg-6 col-sm-9">
        <h5 class="modal-title modalTitle w-100 text-center">{{'user-auction-text42'|translate}}</h5>
      </div>
      <div class="col-lg-3 col-sm-3">
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="row">
      <label class="modal-subtitle w-100 text-center">{{'user-auction-text43'|translate}}</label>
    </div>
    <div class="row">
      <img src="assets/images/confirmacion-puja.png"
           srcset="assets/images/confirmacion-puja@2x.png 2x,
             assets/images/confirmacion-puja@3x.png 3x"
           class="confirmacion-puja text-center">
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn menuButton pinkButton" (click)="modalRef.hide()">{{'user-auction-text45'|translate}}</button>&nbsp;

  </div>
</ng-template>

<ng-template #template>
	<div class="modal-header">
		<h4 class="modal-title pull-left"></h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<div class="modal-body">
		<h4 class="text-center">
      {{ 'auction-text25' | translate }} {{(this.auctionSelected.price_bid != null ? this.auctionSelected.price_bid : ((this.auctionSelected.last_bid ? this.auctionSelected.last_bid.import : 0) + this.auctionSelected.bid_price_interval) ) | currency:'EUR':true}}?
			<!-- {{ 'auction-text25' | translate }} {{(price != null ? price : ((this.auction.last_bid ? this.auction.last_bid.import : 0) + this.auction.bid_price_interval) ) | currency:'EUR':true}}?-->
		</h4>
	</div>

	<div class="modal-footer">
		<div>
			<button class="btn primaryBtn btn100 txtBtn" (click)="makeBid(auctionSelected)" [disabled]="loading || submittingBid">
				<img src="assets/images/auction-solid.png"
					srcset="assets/images/auction-solid@2x.png 2x,
					assets/images/auction-solid@3x.png 3x"
					class="auction-solid">
				{{ 'auction-text16' | translate }}
			</button>
		</div>
		<div>
			<button type="button" class="btn btn100 txtBtn btn-outline-secondary" (click)="modalRef.hide()">Cancelar</button>
		</div>
	</div>
</ng-template>
