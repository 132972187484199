import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AssetType } from 'src/app/models/assets';
import { Province } from 'src/app/models/provinces';
import { AssetsService } from 'src/app/services/assets.service';
import { OthersService } from 'src/app/services/others.service';

@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.component.html',
  styleUrls: ['./search-products.component.scss'],
  encapsulation: ViewEncapsulation.None // Add this line
})
export class SearchProductsComponent implements OnInit {

  public activeType:AssetType[]=[]
  public provinces:Province[]=[]
  public dropdownActiveTypeSettings:IDropdownSettings={}
  public dropdownProvinceSettings:IDropdownSettings={}
  public dropdownStatusSettings:IDropdownSettings={}
  public status:any[]=[]
  constructor(public asctiveService:AssetsService, public otherServices:OthersService) { 

  }

  ngOnInit(): void {

    this.status=[
      {statusId:"1",statusName:"Finalizadas"},
      {statusId:"2",statusName:"En Curso"},
      {statusId:"3",statusName:"Disponible Proximamente"}
    ]
    
    this.dropdownActiveTypeSettings={
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "AssetsTypesID",
      "textField": "AssetsTypes_Name",
    }

    this.dropdownProvinceSettings={
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "ProvincesID",
      "textField": "Provinces_Name",
    }

    this.dropdownStatusSettings={
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "statusId",
      "textField": "statusName",
    }

    this.asctiveService.getAssetTypes().subscribe(
      val=>{
        this.activeType = val['response']
      }
    )
    this.otherServices.getProvinces(null).subscribe(
      val=>{
        this.provinces = val['response']
      }
    )
  }

}
