import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {OthersService} from '../services/others.service';
import {UserService} from '../services/user.service';
import {BaseResponse} from "../models/base-response.model";

@Component({
  selector: 'app-invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['./invalid-token.component.scss']
})
export class InvalidTokenComponent implements OnInit {

  constructor(public utilService: OthersService, public fb: FormBuilder, public userService: UserService, public router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  urlLogin() {
    this.router.navigateByUrl('/login');
  }
}