<div class="banner row">
  <div class="section-gallery">
    <div class="row align-items-start">
      <div class="col-md-auto">
        <div class="row">
          <div class="breadcrumb-gallery">
            <span class="link-bread" [routerLink]="['/']">{{ 'dossier-page-title' | translate }}</span> / <span
              class="link-bread" [routerLink]="['/comunication']">{{ 'dossier-page-title3' | translate }}</span> / <span
              class="select-bread">{{ 'communication-page-text7' | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            {{ 'dossier-page-title2' | translate }}
          </div>
        </div>
        <div class="row">
          <div class="section-gallery-separator">
          </div>
        </div>
        <div class="row" *ngIf="dossier">
          <div class="download-dossier"><a href="{{dossier}}" class="text-style-1"> {{ 'dossier-page-text1' | translate }}</a>
            {{ 'dossier-page-text2' | translate }}.</div>
        </div>
        <div class="row" *ngIf="dossier">
          <div class="dossier">
            <a href="{{dossier}}" target="_blank">
              <img src="./assets/images/dossier/trademat.png" class="img-fluid">
            </a>
          </div>
        </div>
        <div class="row" *ngIf="msg">
          <p><strong>{{ 'dossier-page-text4' | translate }}</strong></p>
        </div>
      </div>
    </div>
  </div>

</div>

<app-comunication-banner></app-comunication-banner>
