import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DossierService {
    private headers;
    private url = environment.url

    constructor(public http: HttpClient) {
        this.headers = new HttpHeaders().set("Accept", "application/json").set('Content-Type', 'multipart/form-data')

    }

    storeDossierFile(formData: any) {
        return this.http.post(this.url + "/api/dossier", formData)
    }

    downloadFile(){
        return this.http.get(this.url + "/api/dossier/downloadDossier")
    }

    getFile(){
        return this.http.get(this.url + "/api/dossier/getFile")
    }
}