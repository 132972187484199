import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OthersService {

  private url = environment.url
  constructor(public http:HttpClient) { }

  getProvinces(id: any) {

    if (this.isInt(id) == true) {
      return this.http.get(this.url+"/api/province/list/"+id)
    }

    return this.http.get(this.url+"/api/province/list")
  }

  getCountries() {
    return this.http.get(this.url+"/api/country/list")
  }

  getFAQ(lang = 'es') {
    return this.http.get(this.url+"/api/faq/get/all?lang=" + lang);
  }

  formToObject(formGroup:FormGroup,object:Object){
    let k = formGroup.controls;

    for (let key of Object.keys(k)) {
      let valor = k[key].value;
      object[key] = valor
    }
    return object;
  }

  toogleFavorite(userid, activeID, type?)
  {
    if(!type){
      type = 0
    }
    const data={UserID:userid,activeID:activeID,Type:type}
    return this.http.post(this.url+"/api/assets/toogle/favorite/", data);
  }

  setNovelty(date:string){
    var today = new Date().getTime()
    var datePublish = new Date(date).getTime()
    var diference = today - datePublish
    var diference_days = diference/(1000*3600*24);

    if(diference_days<=7){
      return true
    }else{
      return false
    }
  }

  isInt(value) {
    if (isNaN(value)) {
      return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
  }


  /**
   * Calculates the difference between two datetimes
   * and returns it in the form of a readable string.
   */
  public timeDiffString(before: any, after: any = null): string {

    if (!before) {
      return '';
    } else if (!(before instanceof Date)) {
      before = new Date(before);
    }

    // If there's no 'after' date then calculate against today's date
    if (!after) {
      after = new Date();
    } else if (!(after instanceof Date)) {
      after = new Date(after);
    }

    let diff = before.getTime() - after.getTime();

    if (diff < 0) { return ''; }

    let delta = diff / 1000;

    let dd: any = Math.floor(delta / 86400);
    delta -= dd * 86400;

    let hh: any = Math.floor(delta / 3600) % 24;
    delta -= hh * 3600;

    let mm: any = Math.floor(delta / 60) % 60;
    delta -= mm * 60;

    let ss: any = Math.floor(delta % 60);

    hh = hh.toString().padStart(2, '0');
    mm = mm.toString().padStart(2, '0');
    ss = ss.toString().padStart(2, '0');

    if (dd > 0) {
      return dd + " dias";
    } else {
      return `${hh}:${mm}:${ss}`;
    }
  }
}
