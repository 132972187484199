import { Component, OnInit } from '@angular/core';
import { User } from '../models/users';
import { AssetsService } from '../services/assets.service';
import { OthersService } from '../services/others.service';
import {BaseResponse} from "../models/base-response.model";
import {AuctionListResponse} from "../models/auction";

@Component({
  selector: 'app-user-favorites',
  templateUrl: './user-favorites.component.html',
  styleUrls: ['./user-favorites.component.scss']
})
export class UserFavoritesComponent implements OnInit {
  favorites: any[];
  favs: any[]=[];
  auctions: any[]=[]
  user: User;
  msg:string = "Aún no tienes activos favoritos";
  constructor(public assetsService:AssetsService, public otherServices:OthersService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.USER)
    this.getFavorites()
  }

  getFavorites(){
    this.assetsService.getFavorites().subscribe(
      (val:BaseResponse<AuctionListResponse>)=>{
        this.auctions = val.response.auctions_list
      }
    )
  }
}
