import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../models/users';
import { UserService } from '../services/user.service';
import {BaseResponse} from "../models/base-response.model";

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})
export class ConfirmUserComponent implements OnInit {
  msg:any
  sendedMail:string=""
  validated: boolean;
  user:User;
  submitting: boolean = false;

  constructor(private route: ActivatedRoute, public userService:UserService) {
    if(localStorage.USER){
      this.user = JSON.parse(localStorage.USER)
    }}

  ngOnInit(): void {
    this.msg = this.route
      .data
      .subscribe(v => {
        this.validated = v['confirmed']
      });
  }

  resendMail() {

    if (this.submitting) { return; }
    this.submitting = true;

    this.userService.resendMailActiveUser(localStorage.EMAIL)
    .subscribe((val: BaseResponse<any>) => {
      if (val.status == 201) {
        this.sendedMail = "Enviado con éxito";
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }
}
