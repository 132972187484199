import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Asset} from 'src/app/models/assets';
import {AssetsService} from 'src/app/services/assets.service';
import {OthersService} from 'src/app/services/others.service';
import {Auction, AuctionListResponse} from "../../models/auction";
import {BaseResponse} from "../../models/base-response.model";

@Component({
  selector: 'app-active-recents',
  templateUrl: './active-recents.component.html',
  styleUrls: ['./active-recents.component.scss']
})
export class ActiveRecentsComponent implements OnInit {
  public assets: Auction[] = []
  public asset = ""
  public auction = false
  public directSale = false
  slidesAuction: Asset[][]=[];

  constructor(public assetServide: AssetsService, public router: Router, public utils: OthersService) {

    assetServide.getLastAuctions().subscribe(
      (val: BaseResponse<AuctionListResponse>) => {
        console.log(val.response.auctions_list)
        this.assets = val.response.auctions_list;
        this.toogleSlide(0)
      }
    )

  }

  slideGenerator(auctions: Auction[]) {
    var slidesIndex = 0
    var slidesElement = 0
    var slides = []
    slides[0] = []
    for (var i = 0; i < auctions.length; i++) {

      if (slidesElement <= 2) {
        slides[slidesIndex][slidesElement] = auctions[i];
        slidesElement++

      } else {

        slidesIndex++
        slidesElement = 0
        slides[slidesIndex] = []
        slides[slidesIndex][slidesElement] = auctions[i];

      }
      /*  if(auctions[i].assets[0]){
          assets[i].images = assets[i].images[0].AssetsImages_Path+assets[i].images[0].AssetsImages_Name
        }*/
    }
    return slides
  }

  toogleSlide(id) {
    switch (id) {
      case 0:
        this.auction = true
        this.directSale = false
        this.slidesAuction = this.slideGenerator(this.assets.filter(a => a.auction_type_id === 1));
        break;
      case 1:
        this.auction = false
        this.directSale = true
        this.slidesAuction = this.slideGenerator(this.assets.filter(a => a.auction_type_id === 2));
        break;
    }
  }

  ngOnInit(): void {
  }

}
