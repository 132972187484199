<div class="client-profile">

	<div class="client-profile__banner">
		<div class="contentbanner">
			<h5 style="text-transform:uppercase">{{ 'client-profile-component-title' | translate }}</h5>

			<h1>{{ 'client-profile-component-subtitle' | translate }}</h1>

			<div class="row d-none d-lg-flex" style="row-gap:2rem">
				<div class="col-md col-lg">
					<div class="pastilla" (click)="select(1)" [class.pastilla_selected]="selected == 1">
						<img src="assets/images/icon-circulo-perfiles-particular.png"
							srcset="assets/images/icon-circulo-perfiles-particular@2x.png 2x,
							assets/images/icon-circulo-perfiles-particular@3x.png 3x"
							class="icon">
						<label for="">{{ 'client-profile-component-text1' | translate }}</label>
					</div>
				</div>
				<div class="col-md col-lg">
					<div class="pastilla" (click)="select(2)" [class.pastilla_selected]="selected == 2">
						<img src="assets/images/icon-circulo-perfiles-empresa.png"
							srcset="assets/images/icon-circulo-perfiles-empresa@2x.png 2x,
							assets/images/icon-circulo-perfiles-empresa@3x.png 3x"
							class="icon">
						<label for="">{{ 'client-profile-component-text2' | translate }}</label>
					</div>
				</div>
				<div class="col-md col-lg">
					<div class="pastilla" (click)="select(3)" [class.pastilla_selected]="selected == 3">
						<img src="assets/images/icon-circulo-perfiles-acreedor-privilegiado.png"
							srcset="assets/images/icon-circulo-perfiles-acreedor-privilegiado@2x.png 2x,
							assets/images/icon-circulo-perfiles-acreedor-privilegiado@3x.png 3x"
							class="icon">
						<label for="">{{ 'client-profile-component-text3' | translate }}</label>
					</div>
				</div>
				<div class="col-md col-lg">
					<div class="pastilla" (click)="select(4)" [class.pastilla_selected]="selected == 4">
						<img src="assets/images/icon-circulo-perfiles-fondo.png"
							srcset="assets/images/icon-circulo-perfiles-fondo@2x.png 2x,
							assets/images/icon-circulo-perfiles-fondo@3x.png 3x"
							class="icon">
						<label for="">{{ 'client-profile-component-text4' | translate }}</label>
					</div>
				</div>
				<div class="col-md col-lg">
					<div class="pastilla" (click)="select(5)" [class.pastilla_selected]="selected == 5">
						<img src="assets/images/icon-circulo-perfiles-administrador.png"
							srcset="assets/images/icon-circulo-perfiles-administrador@2x.png 2x,
							assets/images/icon-circulo-perfiles-administrador@3x.png 3x"
							class="icon">
						<label for="">{{ 'client-profile-component-text5' | translate }}</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="client-profile__contents">
		<div class="content">
			<div class="card">
				<div class="contentCard" *ngIf="selected==1">
					<h3 style="text-align: center;">{{ 'client-profile-component-text1' | translate }}</h3>
					<hr>
					<p>{{ 'client-profile-component-info8' | translate }}</p>
					<p>{{ 'client-profile-component-info9' | translate }}</p>
					<p>{{ 'client-profile-component-info10' | translate }}</p>
				</div>
				<div class="contentCard" *ngIf="selected==2">
					<h3 style="text-align: center;">{{ 'client-profile-component-text2' | translate }}</h3>
					<hr>
					<p>{{ 'client-profile-component-info11' | translate }}</p>
					<p>{{ 'client-profile-component-info12' | translate }}</p>
					<p>{{ 'client-profile-component-info13' | translate }}</p>
				</div>
				<div class="contentCard" *ngIf="selected==3">
					<h3 style="text-align: center;">{{ 'client-profile-component-text3' | translate }}</h3>
					<hr>
					<p>{{ 'client-profile-component-info14' | translate }}</p>

				</div>
				<div class="contentCard" *ngIf="selected==4">
					<h3 style="text-align: center;">{{ 'client-profile-component-text4' | translate }}</h3>
					<hr>
					<p>{{ 'client-profile-component-info15' | translate }}</p>

				</div>
				<div class="contentCard" *ngIf="selected==5">
					<h3 style="text-align: center;">{{ 'client-profile-component-text5' | translate }}</h3>
					<hr>
					<p>{{ 'client-profile-component-info1' | translate }}</p>
					<ul>
						<li>{{ 'client-profile-component-info2' | translate }}</li>
						<li>{{ 'client-profile-component-info3' | translate }}</li>
						<li>{{ 'client-profile-component-info4' | translate }}</li>
					</ul>
					<p>{{ 'client-profile-component-info5' | translate }}</p>
					<p>{{ 'client-profile-component-info6' | translate }}</p>
					<p>{{ 'client-profile-component-info7' | translate }}</p>
				</div>
			</div>
		</div>
	</div>

	<app-help-form></app-help-form>
</div>
