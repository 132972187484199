import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToolbarComponent} from './structure/toolbar/toolbar.component';
import {MenuComponent} from './structure/menu/menu.component';
import {ScrollTopComponent} from './structure/scrolltop/scrolltop.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UserAccessComponent} from './structure/user-access/user-access.component';
import {BannerHomeComponent} from './modules/banner-home/banner-home.component';
import {FooterComponent} from './structure/footer/footer.component';
import {ActiveByCategoriesComponent} from './modules/active-by-categories/active-by-categories.component';
import {ActiveRecentsComponent} from './modules/active-recents/active-recents.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NearOffersComponent} from './modules/near-offers/near-offers.component';
import {ChooseTradematComponent} from './modules/choose-trademat/choose-trademat.component';
import {AdviceComponent} from './modules/advice/advice.component';
import {ProductPageComponent} from './product-page/product-page.component';
import {HomeComponent} from './home/home.component';
import {AuctionWidgetComponent} from './modules/auction-widget/auction-widget.component';
import {RegisterPageComponent} from './register-page/register-page.component';
import {RegisterFormComponent} from './register-form/register-form.component';
import {NgWizardConfig, NgWizardModule, THEME} from 'ng-wizard';
import {ProductListPageComponent} from './modules/product-list-page/product-list-page.component';
import {SearchProductsComponent} from './modules/search-products/search-products.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StepFormComponent} from './modules/step-form/step-form.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {UserBarComponent} from './modules/user-bar/user-bar.component';
import {UserPageComponent} from './user-page/user-page.component';
import {UserAuctionsComponent} from './user-auctions/user-auctions.component';
import {UserFavoritesComponent} from './user-favorites/user-favorites.component';
import {UserAlertsComponent} from './user-alerts/user-alerts.component';
import {ClientProfileComponent} from './client-profile/client-profile.component';
import {HelpFormComponent} from './modules/help-form/help-form.component';
import {ConfirmUserComponent} from './confirm-user/confirm-user.component';
import {ReviewMailComponent} from './review-mail/review-mail.component';
import {FaqPageComponent} from './faq-page/faq-page.component';
import {ContactPageComponent} from './contact-page/contact-page.component';
import {LocalizationComponent} from './modules/localization/localization.component';
import {CompleteDataComponent} from './complete-data/complete-data.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {GoogleMapsModule} from '@angular/google-maps';
import {AboutUsPageComponent} from './about-us-page/about-us-page.component';
import {AboutUsItemComponent} from './modules/about-us-item/about-us-item.component';
import {SearchFormComponent} from './modules/search-form/search-form.component';
import {TeamPageComponent} from './team-page/team-page.component';
import {ComunicationPageComponent} from './comunication-page/comunication-page.component';
import {SectionNewsItemComponent} from './modules/section-news-item/section-news-item.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatGridListModule} from "@angular/material/grid-list";
import {GalleryPageComponent} from './gallery-page/gallery-page.component';
import {NotePressPageComponent} from './note-press-page/note-press-page.component';
import {NotePressItemComponent} from './modules/note-press-item/note-press-item.component';
import {ComunicationBannerComponent} from './modules/comunication-banner/comunication-banner.component';
import {DossierPageComponent} from './dossier-page/dossier-page.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule, MatNavList} from "@angular/material/list";
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { SidenavListComponent } from './structure/sidenav-list/sidenav-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { CustomDatePipe } from './custom-date.pipe';
const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
import localeES from '@angular/common/locales/es';
import {LocationStrategy, PathLocationStrategy, registerLocaleData} from "@angular/common";
import {MatPaginatorModule} from "@angular/material/paginator";
import { PaginationComponent } from './pagination/pagination.component';
import {ShortDatePipe} from "./short-date.pipe";
import { SimilarProductsComponent } from './similar-products/similar-products.component';
import { ProductItemRowComponent } from './product-item-row/product-item-row.component';
import { MyAuctionsComponent } from './my-auctions/my-auctions.component';
import { AuctionAccessComponent } from './auction-access/auction-access.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {RecoverPasswordComponent} from "./recover-password/recover-password.component";
import {InvalidTokenComponent} from "./invalid-token/invalid-token.component";
import {PackPageComponent} from "./pack-page/pack-page.component";
import {PackWidgetComponent} from "./modules/pack-widget/pack-widget.component";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BannerBottomHomeComponent } from './modules/banner-bottom-home/banner-bottom-home.component';
import {NgxImageZoomModule} from "ngx-image-zoom";
import { LegalComponent } from './legal/legal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { ToastrModule } from 'ngx-toastr';
import { SecondLiveHomeComponent } from './modules/second-live-home/second-live-home.component';
import { BuyEasilyComponent } from './modules/buy-easily/buy-easily.component';
import { HeaderProductListComponent } from './modules/header-product-list/header-product-list.component';
import { LastChanceComponent } from './modules/last-chance/last-chance.component';
import { HeaderTopComponent } from './header-top/header-top.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localeES, 'es');
@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    MenuComponent,
    ScrollTopComponent,
    UserAccessComponent,
    BannerHomeComponent,
    FooterComponent,
    RecoverPasswordComponent,
    InvalidTokenComponent,
    ActiveByCategoriesComponent,
    ActiveRecentsComponent,
    NearOffersComponent,
    ChooseTradematComponent,
    AdviceComponent,
    ProductPageComponent,
    HomeComponent,
    PackPageComponent,
    PackWidgetComponent,
    AuctionWidgetComponent,
    RegisterPageComponent,
    RegisterFormComponent,
    ProductListPageComponent,
    SearchProductsComponent,
    StepFormComponent,
    LoginPageComponent,
    UserBarComponent,
    UserPageComponent,
    UserAuctionsComponent,
    UserFavoritesComponent,
    UserAlertsComponent,
    ClientProfileComponent,
    HelpFormComponent,
    ConfirmUserComponent,
    ReviewMailComponent,
    FaqPageComponent,
    ContactPageComponent,
    LocalizationComponent,
    CompleteDataComponent,
    AboutUsPageComponent,
    AboutUsItemComponent,
    SearchFormComponent,
    TeamPageComponent,
    ComunicationPageComponent,
    SectionNewsItemComponent,
    GalleryPageComponent,
    NotePressPageComponent,
    NotePressItemComponent,
    ComunicationBannerComponent,
    DossierPageComponent,
    SidenavListComponent,
    CustomDatePipe,
    ShortDatePipe,
    PaginationComponent,
    SimilarProductsComponent,
    ProductItemRowComponent,
    ProductItemRowComponent,
    MyAuctionsComponent,
    AuctionAccessComponent,
    BannerBottomHomeComponent,
    LegalComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    VerifyPageComponent,
    SecondLiveHomeComponent,
    BuyEasilyComponent,
    HeaderProductListComponent,
    LastChanceComponent,
    HeaderTopComponent,
  ],
  imports: [
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxDropzoneModule,
    FormsModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatGridListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatPaginatorModule,
    ShareButtonsModule,
    ShareIconsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxImageZoomModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    //{ provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
