import {Component, HostListener, OnInit} from '@angular/core';
import {Route, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss']
})
export class HelpFormComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }
  constructor(public router: Router,private fb: FormBuilder,private userService:UserService,) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormCompany': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactFormPhone': ['', Validators.required],
    });
  }
  onSubmit() {
    this.userService.sendMessage(this.contactForm.value).subscribe(() => {
      this.contactForm.reset();
      this.disabledSubmitButton = true;
    }, error => {
      console.log(error)

    });
  }
  ngOnInit(): void {
  }

  navigate() {
    this.router.navigateByUrl('/faq');
  }
}
