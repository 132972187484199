<div class="cont">
    <div>
      <div class="formstep">
        <div class="row">
            <div *ngIf="validated==true then yesvalidated else novalidated"></div>
            <ng-template #yesvalidated>
                <p>
                  {{ 'confirm-user-text1' | translate }}
                </p>
            </ng-template>
            <ng-template #novalidated>
                <p>
                  {{ 'confirm-user-text2' | translate }}
                    <br>
                  {{ 'confirm-user-text3' | translate }}<br><br>
                    <span style="color: var(--secondary)">{{sendedMail}}</span>
                </p>
                <button class="btn secondaryBG white" (click)="resendMail()" [disabled]="submitting">
                  {{ 'confirm-user-text4' | translate }}
                </button>
            </ng-template>
        </div>
      </div>
    </div>
</div>
