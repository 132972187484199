import { Component, OnInit } from '@angular/core';
import {User} from "../../models/users";
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  collapsed = true;
  collapsedProfile = true;
  selectedMenu:any;
  public user: User;
  public url: any;

  constructor( private router: Router ) {
    router.events.subscribe( (event) => ( event instanceof NavigationEnd ) && this.handleRouteChange() )
  }

  ngOnInit(): void {
    if (localStorage.USER) {
      this.user = JSON.parse(localStorage.USER)
    }
  }

  logoutUser(){
    // localStorage.clear()
    const items = { ...localStorage };
    for(let item in items) {
      if (item != 'LANG') localStorage.removeItem(item);
    }
  }

  handleRouteChange(){
    this.selectedMenu=0;
    if(this.router.url=='/faq'){
      this.selectedMenu=1;
    }
    if(this.router.url=='/contact'){
      this.selectedMenu=2;
    }

    this.url = this.router.url;
  };

}
