<div style="padding: 48px 11%;">
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:center;'>
    <strong><u><span style="font-size:19px;line-height:107%;">{{'legal-1' | translate}}</span></u></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:center;'>
    <strong><u><span style="font-size:16px;line-height:107%;"><span
      style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-2' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-3' | translate}}</span>
  </p>
  <ul style="list-style-type: undefined;">
    <li><strong><span style="line-height:107%;font-size:16px;">{{'legal-4' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">:</span> <span style="line-height:107%;font-size:16px;">{{'legal-5' | translate}}</span>
    </li>
    <li><strong><span style="line-height:;font-size:12.0pt;">{{'legal-6' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: B09484346</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;">{{'legal-7' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: C/ Merindad de Montija 18, Nave 11C, Polígono Industrial Villalonquéjar, 09001, Burgos.&nbsp;</span>
    </li>
    <li><strong><span style="line-height:;font-size:12.0pt;">{{'legal-8' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">&nbsp;Registro Mercantil de Burgos en el Tomo 723, Hoja BU-16756, Libro 514, Folio 142, Inscripción 3º, Sección 8.</span>
    </li>
    <li><strong><span style="line-height:;font-size:12.0pt;">{{'legal-9' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: (+34) 947 04 02 04</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;">{{'legal-10' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: hola@trademat.io</span></li>
  </ul>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-11' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-12' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-13' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-14' | translate}} <strong>{{'legal-15' | translate}}</strong>.</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-16' | translate}}<strong>{{'legal-63' | translate}}</strong>.</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-17' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-18' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-19' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-20' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-21' | translate}}</span>
  </p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="1" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-22' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="2" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span
          style="line-height:107%;font-size:16px;">{{'legal-23' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="3" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-24' | translate}}&nbsp;</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="4" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-25' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="5" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-26' | translate}}</span>
        <span style="line-height:107%;font-size:16px;">{{'legal-27' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="6" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-28' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="7" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-29' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ol start="8" style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-30' | translate}}</span>
      </li>
    </ol>
  </div>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-31' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-32' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-33' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-34' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-35' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-36' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-37' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-38' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-39' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-40' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-41' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-42' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:18.75pt;text-align:justify;'>
    <span style='font-family:"Calibri",sans-serif;'>{{'legal-43' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:18.75pt;text-align:justify;'>
    <span style='font-family:"Calibri",sans-serif;'>{{'legal-44' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:18.75pt;text-align:justify;'>
    <span style='font-family:"Calibri",sans-serif;'>{{'legal-45' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:18.75pt;text-align:justify;'>
    <span style='font-family:"Calibri",sans-serif;'>{{'legal-46' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-47' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-48' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span
      style="font-size:16px;line-height:107%;">{{'legal-49' | translate}}</span>
  </p>
  <ul style="list-style-type: disc;">
    <li><span style="line-height:107%;font-size:16px;">{{'legal-50' | translate}}</span>
    </li>
    <li><span style="line-height:107%;font-size:16px;">{{'legal-51' | translate}}</span>
    </li>
  </ul>
  <p
    style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:107%;text-align:justify;'>
    <span style="font-size:5px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ul style="margin-bottom:0cm;list-style-type: disc;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <span style="line-height:107%;font-size:16px;">{{'legal-52' | translate}}</span>
      </li>
    </ul>
  </div>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">&nbsp;</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-53' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-54' | translate}}</span><span
    style="font-size:16px;line-height:107%;">{{'legal-55' | translate}}&nbsp;</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-56' | translate}}</span><span
    style="font-size:16px;line-height:107%;">{{'legal-57' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span
      style="font-size:16px;line-height:107%;">{{'legal-58' | translate}}</span><span
    style="font-size:16px;line-height:107%;">{{'legal-59' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">&nbsp;</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'legal-60' | translate}}</span></strong></p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-61' | translate}}</span>
  </p>
  <p
    style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'legal-62' | translate}}</span>
  </p>
  <p><span style='font-size:16px;line-height:107%;font-family:"Calibri",sans-serif;'>{{'legal-63' | translate}}</span>
  </p>
</div>
