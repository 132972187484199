import {Component, Input, OnInit} from '@angular/core';
import {Communication} from "../../models/comunication.model";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-section-news-item',
  templateUrl: './section-news-item.component.html',
  styleUrls: ['./section-news-item.component.scss']
})
export class SectionNewsItemComponent implements OnInit {
  @Input() communication: Communication
    url = environment.url

  constructor() { }

  ngOnInit(): void {
  }

  getDate() {

  }
}
