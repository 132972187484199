<div class="user-bar">
    <h1 class="welcome">
    	{{'user-bar-text1'|translate}} {{user.name}}!
    	<label class="tiempo">{{'user-bar-text2'|translate}} {{formatedDate}}</label>
    </h1>

    <div class="btn-group">
		<label class="btn btn-white" [class.btn-pink]="page == 'mis-datos'"
			btnCheckbox [routerLink]="['/mis-datos']"
			tabindex="0" role="button">
				{{'user-bar-text3'|translate}}
		</label>
		<label class="btn btn-white" [class.btn-pink]="page == 'historial-subastas'"
			btnCheckbox [routerLink]="['/historial-subastas']"
			tabindex="0" role="button">
				{{'user-bar-text4'|translate}}
		</label>
		<label class="btn btn-white" [class.btn-pink]="page == 'mis-favoritos'"
			btnCheckbox [routerLink]="['/mis-favoritos']"
			tabindex="0" role="button">
				{{'user-bar-text5'|translate}}
		</label>
		<label class="btn btn-white" [class.btn-pink]="page == 'mis-alertas'"
			btnCheckbox [routerLink]="['/mis-alertas']"
			tabindex="0" role="button">
				{{'user-bar-text6'|translate}}
		</label>
    </div>
</div>
