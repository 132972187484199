<div class="top-menu">
	<div class="top-menu__contents">
		<div class="mail-phone-container">
			<a class="mr-3" href="mailto:hola@trademat.io">
				<i class="fa-solid fa-envelope mr-1"></i>
				hola@trademat.io
			</a>
			<a href="tel:+34947040204">
				<i class="fa-solid fa-phone mr-1"></i>
				+34 947 04 02 04
			</a>
		</div>

		<div class="lang-clock-container">
			<div class="lang-menu">
				<div class="selected-lang">
					<img [src]="'assets/images/flag-'+current_lang+'.png'"
						class="">
							{{ current_lang }}
					<img src="assets/images/arrowDropDown.png"
						srcset="assets/images/arrowDropDown@2x.png 2x,
						assets/images/arrowDropDown@3x.png 3x"
						class="">
				</div>

				<ul>
					<li>
						<a href="javascript:void(0)" class="es"
							(click)="changeLang('es')">
							Español
						</a>
					</li>
					<li>
						<a href="javascript:void(0)" class="en"
							(click)="changeLang('en')">
							English
						</a>
					</li>
					<li>
						<a href="javascript:void(0)" class="fr"
							(click)="changeLang('fr')">
							Français
						</a>
					</li>
				</ul>
			</div>

			<div class="clock">
				<div class="miniSecondaryText">
					<i class="fa-solid fa-clock mr-1"></i>{{hour}}:{{minutes}}:{{seconds}}
				</div>
			</div>
		</div>
	</div>
</div>
