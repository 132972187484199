import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Asset, AssetType} from 'src/app/models/assets';
import {AssetsService} from 'src/app/services/assets.service';
import {OthersService} from 'src/app/services/others.service';
import {Auction, AuctionListResponse} from "../../models/auction";
import {BaseResponse} from "../../models/base-response.model";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-last-chance',
  templateUrl: './last-chance.component.html',
  styleUrls: ['./last-chance.component.scss']
})
export class LastChanceComponent implements OnInit {
  public assetsEnd: Auction[] = [];
  public assetsNew: Auction[] = [];
  public assetsViews: Auction[] = [];
  public assetTypes: AssetType[] = [];
  public assetTypesArray = [];
  public slides = [];
  public slidesIndex = 0;
  public slidesElement = 0;
  public asset = '';
  public auctionLast = false;
  public auctionNew = false;

  public auctionViews = false;
  public directSaleLast = false;
  public directSaleNew = false;
  public directSaleViews = false;
  public slidesAuctionEnd: Asset[][] = [];
  public slidesAuctionNew: Asset[][] = [];
  public slidesAuctionViews: Asset[][] = [];
  public pathUrlList = '';

  constructor(public assetService: AssetsService, public router: Router, public utils: OthersService) {

    assetService.getLastAuctions().subscribe(
      (val: BaseResponse<AuctionListResponse>) => {
        // console.log(val.response.auctions_list);
        this.assetsEnd = val.response.auctions_list;
        this.toogleSlide(0, 'last');
      }
    );

    assetService.getNextAuctions().subscribe(
      (val: BaseResponse<AuctionListResponse>) => {
        // console.log(val.response.auctions_list);
        this.assetsNew = val.response.auctions_list;
        this.toogleSlide(0, 'next');
      }
    );

    assetService.getAllAuctions(0, '3').subscribe(
      (val: BaseResponse<AuctionListResponse>) => {
        // console.log(val.response.auctions_list);
        this.assetsViews = val.response.auctions_list;
        this.toogleSlide(0, 'views');
      }
    );

    assetService.getAssetTypes().subscribe(
      val => {
        this.assetTypes = val['response']['asset_type_list'];
        this.assetTypesArray[0] = [];

        for (var i = 0; i < this.assetTypes.length; i++){
          if(this.slidesElement < (window.innerWidth <= 768 ? 2 : 4)){

            this.assetTypesArray[this.slidesIndex][this.slidesElement] = this.assetTypes[i];
            this.slidesElement ++;

          }else{

            this.slidesIndex ++;
            this.slidesElement = 0;
            this.assetTypesArray[this.slidesIndex] = [];
            this.assetTypesArray[this.slidesIndex][this.slidesElement] = this.assetTypes[i];
            this.slidesElement ++;
          }
        }

        this.goCategories();
      }
    );
  }

  slideGenerator(auctions: Auction[]) {
    let slidesIndex = 0;
    let slidesElement = 0;
    let slides = [];
    slides[0] = [];
    for (let i = 0; i < auctions.length; i++) {

      if (slidesElement <= 2) {
        slides[slidesIndex][slidesElement] = auctions[i];
        slidesElement++;

      } else {

        slidesIndex++;
        slidesElement = 0;
        slides[slidesIndex] = [];
        slides[slidesIndex][slidesElement] = auctions[i];

      }
      /*  if(auctions[i].assets[0]){
          assets[i].images = assets[i].images[0].AssetsImages_Path+assets[i].images[0].AssetsImages_Name
        }*/
    }
    return slides;
  }

  getImage(assetType: AssetType) {
    return environment.url + assetType.path;
  }

  toogleSlide(id, slide) {
    switch (id) {
      case 0:
        if (slide === 'last') {
          this.auctionLast = true;
          this.directSaleLast = false;
          this.slidesAuctionEnd = this.slideGenerator(this.assetsEnd.filter(a => a.auction_type_id === 1));
        }else if (slide === 'next') {
          this.auctionNew = true;
          this.directSaleNew = false;
          this.slidesAuctionNew = this.slideGenerator(this.assetsNew.filter(a => a.auction_type_id === 1));
        }else if (slide === 'views') {
          this.auctionViews = true;
          this.directSaleViews = false;
          this.slidesAuctionViews = this.slideGenerator(this.assetsViews.filter(a => a.auction_type_id === 1));
        }
        break;
      case 1:
        if (slide === 'last') {
          this.auctionLast = false;
          this.directSaleLast = true;
          this.slidesAuctionEnd = this.slideGenerator(this.assetsEnd.filter(a => a.auction_type_id === 2));
        }else if (slide === 'next') {
          this.auctionNew = false;
          this.directSaleNew = true;
          this.slidesAuctionNew = this.slideGenerator(this.assetsNew.filter(a => a.auction_type_id === 2));
        }else if (slide === 'views') {
          this.auctionViews = false;
          this.directSaleViews = true;
          this.slidesAuctionViews = this.slideGenerator(this.assetsViews.filter(a => a.auction_type_id === 2));
        }
        break;
    }
  }

  search(statusValue) {
    this.router.navigate(['/listado-activos'], {
      queryParams: {
        status: statusValue
      },
    });
  }

  goCategories() {
    if (this.assetTypes.length === 0) { return ''; }

    const activeIds = this.assetTypes.map(it => it.id);

    this.pathUrlList =  "/listado-activos?active=" + activeIds.join(', ');
  }

  goCategory(id: any){
    location.href="/listado-activos?active="+id;
  }

  ngOnInit(): void {
  }
}
