import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";

let cc = window as any;

cc.cookieconsent.initialise({
  palette: {
    popup: {
      background: "#164969"
    },
    revokable:true,
    onStatusChange: function(status) {
      console.log(this.hasConsented() ?
        'enable cookies' : 'disable cookies');
    },
    button: {
      background: "#ffe000",
      text: "#164969"
    }
  },
  theme: "classic",
  content: {
    message: "Este sitio web almacena datos, tales como cookies, para garantizar el funcionamiento básico del sitio.",
    link: "Política de cookies",
    href: "/cookie-policy",
    dismiss: 'Aceptar',
    allow: 'Allow cookies',
    deny: 'Decline',
    close: '&#x274c;',
    policy: 'Cookie Policy',
    target: '_blank',
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'trademat-public';

  constructor(translate: TranslateService,private router: Router) {
    translate.setDefaultLang('es');
    translate.use('es');
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngOnDestroy() {

  }


}
