<app-user-bar class="d-none d-lg-block" [page]="'mis-alertas'"></app-user-bar>

<div class="cont">
	<div>
		<div class="formstep">
			<div class="section">
				<div class="row">
					<div class="col-lg col-sm-6">
						<h1 class="title">
							<img src="assets/images/alerta-pink.png"
								srcset="assets/images/alerta-pink@2x.png 2x,
								assets/images/alerta-pink@3x.png 3x"
								class="icon">
							{{'access-text10' | translate}}
						</h1>
					</div>
					<div class="col-lg-auto col-sm-6">
						<button class="btn secondaryBG white">
							<img src="assets/images/alerta.png"
								srcset="assets/images/alerta@2x.png 2x,
								assets/images/alerta@3x.png 3x"
								class="List">
							<span class="d-lg-inline"
								(click)="openAlert()">
								{{'alerts-text1'|translate}}
							</span>
						</button>
					</div>
				</div>
			</div>
			<div class="section" *ngFor="let alert of alertList">
				<div class="row">
					<label class="col-auto type-label">{{getAuctionType(alert.auction_type_id)}}</label>
					<label class="col-auto">{{'alerts-text2'|translate}} {{alert.created_at | shortDate}}</label>
				</div>
				<div class="row pt-1">
					<label class="col-3 subtype-label">{{'alerts-text3'|translate}}</label>
					<label class="col-1"></label>
					<label class="col subtype-value"><i>{{alert.key ? alert.key : '---'}}</i></label>
				</div>
				<div class="row pt-1">
					<label class="col-3 subtype-label">{{'alerts-text4'|translate}}</label>
					<label class="col-1"></label>
					<label class="col subtype-value" *ngIf="types">{{getAssetType(alert.asset_types)}}</label>
				</div>
				<div class="row pt-1">
					<label class="col-3 subtype-label">{{'alerts-text5'|translate}}</label>
					<label class="col-1"></label>
					<label class="col subtype-value"  *ngIf="provinces">{{getProvince(alert.provinces)}}</label>
				</div>
				<div class="row pt-1">
					<label class="col-3 subtype-label">{{'alerts-text6'|translate}}</label>
					<label class="col-1"></label>
					<label class="col subtype-value">{{getStatus(alert.status)}}</label>
				</div>

				<div class="row pt-2">
					<a  class="col-auto actions" role="button" (click)="removeAlert(alert.id)">{{'alerts-text7'|translate}}</a>
					<label class="col-auto separator p-0"></label>
					<a class="col-auto actions"  role="button" (click)="findActives(alert)" >{{'alerts-text8'|translate}}</a>
				</div>
			</div>

			<div class="section">
				<div class="image-btn-container">
					<div class="text-center">
						<img src="/assets/images/my-favs-image.svg" alt="">
					</div>
					<p>Recibe en tu email las últinas novedades en Trademat</p>
					<!-- <div>
						<button [routerLink]="['/mis-alertas']" class="pinkButton"><i class="fa-solid fa-bell"></i> Crear alerta</button>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #successModal>
	<div class="modal-header">
		<div class="row">
			<div class="col-lg-3 col-sm-3"></div>
			<div class="col-lg-6 col-sm-9">
			</div>
			<div class="col-lg-3 col-sm-3">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
								aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<div class="row">
			<img src="assets/images/confirmacion-puja.png"
					srcset="assets/images/confirmacion-puja@2x.png 2x,
						assets/images/confirmacion-puja@3x.png 3x"
					class="confirmacion-puja text-center">
		</div>
		<div class="row">
			<h5 class="modal-title modalTitle w-100 text-center">{{'alerts-text9'|translate}}</h5>
		</div>
		<div class="row">
			<label class="modal-subtitle w-100 text-center">{{'alerts-text10'|translate}}
				<br>{{'alerts-text11'|translate}}</label>
		</div>

	</div>
	<div [formGroup]="alertForm" class="modal-body" style="padding: 54px">
		<div class="row no-gutters">
			<label class="col-4 label-alert">{{'alerts-text33'|translate}}</label>
			<input class="col-8" formControlName="key">

		</div>
		<div class="row no-gutters pt-4">
			<label class="col-4 label-alert">{{'alerts-text12'|translate}}</label>
			<select class="col-8" formControlName="auction_type_id">
				<option value="{{null}}" disabled selected hidden>{{'alerts-text13'|translate}}</option>
				<option value="{{null}}">{{'alerts-text14'|translate}}</option>
				<option value="1">{{'alerts-text15'|translate}}</option>
				<option value="2">{{'alerts-text16'|translate}}</option>
			</select>
		</div>
		<div class="row no-gutters pt-4">
			<label class="col-4 label-alert">{{'alerts-text17'|translate}}</label>
			<select class="col-8" formControlName="asset_type_id">
				<option value="{{null}}" disabled selected hidden>{{'alerts-text18'|translate}}</option>
				<option value="{{null}}">{{'alerts-text19'|translate}}</option>
				<option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
			</select>

		</div>
		<div class="row no-gutters pt-4">
			<label class="col-4 label-alert" >{{'alerts-text20'|translate}}</label>
			<select class="col-8" formControlName="province_id">
				<option value="{{null}}" disabled selected hidden>{{'alerts-text21'|translate}}</option>
				<option value="{{null}}">{{'alerts-text22'|translate}}</option>
				<option *ngFor="let province of provinces" value="{{province.id}}">{{province.name}}</option>
			</select>

		</div>
		<div class="row no-gutters pt-4">
			<label class="col-4 label-alert" >{{'alerts-text23'|translate}}</label>
			<select class="col-8" formControlName="status">
				<option value="{{null}}" disabled selected hidden>{{'alerts-text24'|translate}}</option>
				<option value="{{null}}">{{'alerts-text25'|translate}}</option>
				<option value="1">{{'alerts-text26'|translate}}</option>
				<option value="2">{{'alerts-text27'|translate}}</option>
			</select>

		</div>
	</div>
	<div class="modal-footer">
		<button class="btn menuButton pinkButton"
			[disabled]="submittingAlert"
			(click)="clickCreateAlert()">
			{{'alerts-text28'|translate}}
		</button>&nbsp;
	</div>
</ng-template>

<ng-template #deleteModal>
	<div class="modal-header">
		<h6 class="modal-title" >{{'alerts-text29'|translate}}</h6>
		<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
						aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{'alerts-text30'|translate}}</p>
	</div>
	<div class="modal-footer" style="margin: 0 auto;">
		<button type="button" class="btn altBtn" (click)="modalRef.hide()" data-dismiss="modal">{{'alerts-text31'|translate}}</button>
		<button type="button" class="btn primaryBtn" data-dismiss="modal" (click)="deleteAlert()">{{'alerts-text32'|translate}}</button>
	</div>
</ng-template>
