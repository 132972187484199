import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetType } from '../../models/assets';
import { Province } from '../../models/provinces';
import { User } from '../../models/users';
import { UtilsService } from '../../services/utils.service';
import { AssetsService } from '../../services/assets.service';
import { OthersService } from '../../services/others.service';
import { BaseResponse } from "../../models/base-response.model";
import { Auction, AuctionListResponse } from "../../models/auction";
import { UserService } from "../../services/user.service";
import { Alert } from "../../models/Alert";
import { PackService } from "../../services/pack.service";
import { Pack, PackListResponse } from "../../models/pack";
import LatLng = google.maps.LatLng;

@Component({
	selector: 'app-product-list-page',
	templateUrl: './product-list-page.component.html',
	styleUrls: ['./product-list-page.component.scss'],
	encapsulation: ViewEncapsulation.None // Add this line

})
export class ProductListPageComponent implements OnInit {
	options: google.maps.MapOptions = {
		center: {lat: 40.4167, lng: -3.70325},
		zoom: 8
	};

	mapMarkerOptions = {
		label: {
			text: '',
			className: 'mapPoint',
			color: 'white',
			fontFamily: 'TypoGroteskRounded',
		},
		icon: {
			url: 'assets/images/map_marker1.png',
		}
	};

	public mapVar: boolean = false
	public auctionList: Auction[] = []
	public productsMaster: any[] = []
	public user: User
	public favorites: any[] = []
	public activeType: AssetType[] = [];
	public assetstypeArray: AssetType[] = [];
	public provincesArray: Province[] = [];
	public provinces: Province[] = [];
	public status = [];
	public statusArray: any[] = [];
	public dropdownActiveTypeSettings: IDropdownSettings = {}
	public dropdownProvinceSettings: IDropdownSettings = {}
	public dropdownStatusSettings: IDropdownSettings = {}
	public favs: any[] = []
	 filter_type = 0;
	modalRef: BsModalRef;
	customSelected: string;
	@ViewChild('filterModal') filterModal: TemplateRef<any>
	@ViewChild('alertModal') alertModal: TemplateRef<any>
	submittingAlert: boolean = false;
	@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
	hours: any;
	days: any;
	minutes: any;
	seconds: any;
	prd: any = {};
	private filter_order = "4";
	private search: any = "";
	private packList: Pack[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		public activeService: AssetsService,
		public packService: PackService,
		public utils: UtilsService,
		public otherServices: OthersService,
		private modalService: BsModalService,
		private userService: UserService
	) {

		if (localStorage.USER) {
			this.user = JSON.parse(localStorage.USER)
		}

		this.route.queryParams
			.subscribe(params => {
					if (params.type) {
						this.filter_type = params.type;

					}

				}
			)

		this.statusArray = [
			{ id: 2, name: "En Curso" },
			{ id: 3, name: "Disponible Proximamente "}
		]

		this.apiCallGetProducts()
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams
		.subscribe((params: Params) => {
			if (params['moreview']) {
				/*this.products = this.products.sort(function (a, b) {
					return b.CountViews - a.CountViews
				})*/
			} else if (params['soon']) {

			}

      if (params.search){
        this.customSelected = params.search;
        this.search = params.search;
      }

			this.initializeDropdowns();

			this.translateService.onLangChange.subscribe(event => {

				this.initializeDropdowns();
			});

			this.activeService.getAssetTypes()
			.subscribe( val => {

				this.activeType = val['response']["asset_type_list"];

				if (!params.active) { return; }
				let selected = params.active.split(',').map(it => parseInt(it));
				console.log(this.activeType);
				this.assetstypeArray = this.activeType.filter(it => selected.includes(it.id));
			});

			this.otherServices.getProvinces(null)
			.subscribe( val => {

				this.provinces = val['response']["provinces_list"];

				if (!params.province) { return; }
				let selected = params.province.split(',').map(it => parseInt(it));
				this.provincesArray = this.provinces.filter(it => selected.includes(it.id));
			});

			setTimeout(() => {
				this.status = [
					{ id: 1, name: "Finalizadas" },
					{ id: 2, name: "En Curso" },
					{ id: 3, name: "Disponible Proximamente"},
					{ id: 4, name: "Acaban en 24 horas"},
					{ id: 5, name: "Acaban en una semana"},
					{ id: 6, name: "Los más vistos"},
				];

				if (!params.status) {
					// Seteamos filtros por defecto
					this.statusArray = [
						{ id: 2, name: "En Curso" },
						{ id: 3, name: "Disponible Proximamente "}
					]
					return;
				}
				let selected = params.status.split(',').map(it => parseInt(it));
				this.statusArray = this.status.filter(it => selected.includes(it.id));
			}, 500);
		});
	}

	initializeDropdowns() {

		this.dropdownActiveTypeSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"idField": "id",
			"textField": "name",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		};

		this.dropdownProvinceSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"idField": "id",
			"textField": "name",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		};

		this.dropdownStatusSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"idField": "id",
			"textField": "name",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		};
	}

	// Asset type filter selection

	onAssetTypes(type, event) {

		switch (type) {
			case 'select-all':
				this.assetstypeArray = this.activeType;
				break;
			case 'deselect-all':
				this.assetstypeArray = [];
				break;
		}
		this.filteredArray();
	}

	checkActive(event, n) {
		if (event.target.checked) {
			this.assetstypeArray.push(n);
		} else {
			this.assetstypeArray = this.assetstypeArray.filter(i => i !== n);
		}
	}

	// Province filter selection

	onProvinces(type, event) {

		switch (type) {
			case 'select-all':
				this.provincesArray = this.provinces;
				break;
			case 'deselect-all':
				this.provincesArray = [];
				break;
		}
		console.log(this.provincesArray);
		this.filteredArray();
	}

	checkProvince(event, n) {
		if (event.target.checked) {
			this.provincesArray.push(n);
		} else {
			this.provincesArray = this.provincesArray.filter(i => i !== n);
		}
		console.log(this.provincesArray);
	}

	// Status filter selection

	onStatus(type, event) {

		switch (type) {
			case 'select-all':
				this.statusArray = this.status;
				break;
			case 'deselect-all':
				this.statusArray = [];
				break;
		}
		this.filteredArray();
	}

	checkStatus(event, n) {
		if (event.target.checked) {
			this.statusArray.push(n);
		} else {
			this.statusArray = this.statusArray.filter(i => i !== n);
		}
	}

	// Filter actions

	filterbyText(event) {
		this.search = event.target.value;
		this.filteredArray();
	}

	cleanFilters() {
		this.assetstypeArray = [];
		this.provincesArray = [];
		this.statusArray = [];
		this.filteredArray();
	}

	filteredArray() {

		this.auctionList = Object.assign([], this.productsMaster);

		if (this.assetstypeArray.length != 0) {
			this.auctionList = this.auctionList.filter(a =>
				this.assetstypeArray.map(it => it.id).includes(a.asset_type_id)
			);
		}

		if (this.provincesArray.length != 0) {
			this.auctionList = this.auctionList.filter(a =>
				this.provincesArray.map(it => it.id).includes(a.province_id)
			);
		}

    console.log(this.statusArray);
    if (this.statusArray.length != 0 && this.statusArray.map(it => it.id).includes(6)) {
      this.auctionList.sort((a, b) => b.views - a.views);
    }
    console.log(this.auctionList);

		// debugger
		// El filtro estado hay que tratarlo de forma especial ya que se basa en fechas de los registros de subastas
		// pero hay tipos de subastas que no tienen (Venta Directa que si deben mostrarse siempre)
		// o fechas invalidas (que si no es venta directa no deben salir)
		this.auctionList = this.auctionList.filter(a => {
			var show = false;
			// debugger
			// Si el tipo de subasta es Venta directa o Subasta Informativa, como no se especifican fechas al crearlas
			// no podemos filtrar por estado ya que se basa en las fechas de inicio y fin de subasta
			// Por tanto este filtro no funcionara para estos tipos de subastas y se mostraran siempre independientemente de los
			// valores del filtro Estado
			if (a.auction_type_id != 1) {
				show = true;
				return show;
			}

			// En otro caso comprobamos si la fecha es valida.
			// Si no es una fecha valida (o != null) lo saltamos y es visible ya que en venta directa no hay fechas
			var timestamp = Date.parse(a.start_date);
			if (isNaN(timestamp) !== false) {
				// debugger
				// Para toda subasta que no sea de tipo "Subasta" comprobamos si no tiene fecha (Venta directa) o si la tiene, si es valida
				// Con esto conseguimos que las de Venta directa e informativas que no tienen fechas aparezcan en sus filtros y en la pestaña "Todos"
				if (a.auction_type_id !== 1) {
					show = true;
					return show;
				}
				else {
					show = false;
					return show;
				}
			}

			// Si llegamos aqui, tenemos fecha valida y el tipo es "Subasta"
			// Filtramos por fecha segun los estados seleccionados
			if (this.statusArray.length != 0) {
        const today = new Date().getTime();
        const datePublish = new Date(a.start_date).getTime();
        const dateEnd = new Date(a.end_date).getTime();
        let difference;
        let differenceDays;
        this.statusArray.forEach(s => {
          switch (s.id) {
            case 1:
              if (new Date(a.end_date) < new Date()) {
                show = true;
              }
              break;
            case 2:
              if ((new Date(a.start_date) < new Date()) && (new Date(a.end_date) > new Date())) {
                show = true;
              }
              break;
            case 3:
              difference = datePublish - today;
              differenceDays = difference / (1000 * 3600 * 24);

              if (differenceDays > 0 && differenceDays <= 20) {
                show = true;
              }
              break;
            case 4:
              difference = dateEnd - today;
              differenceDays = difference / (1000 * 3600 * 24);
              if (differenceDays > 0 && differenceDays <= 1) {
                show = true;
              }
              break;
            case 5:
              difference = dateEnd - today;
              differenceDays = difference / (1000 * 3600 * 24);

              if (differenceDays > 0 && differenceDays <= 7) {
                show = true;
              }
              break;
            case 6:
              if (a.views >= 30) {
                console.log(a.views);
                show = true;
              }
              break;
          }
        });
			}
			else { // En caso de no tener valores en el filtro, se ha de mostrar siempre
				// debugger
				show = true;
			}
			return show;
		});

		if (this.search != "") {

			let searching = this.search.toLowerCase();

			this.auctionList = this.auctionList.filter(a => {

				let searchable = [
					a.name, a.type,
					a.address, a.city, a.province,
				].join('|');

				return searchable.toLowerCase().indexOf(searching) != -1;
			});
		}
	}

	openFilterModal() {
		this.modalRef = this.modalService.show(this.filterModal, Object.assign({}, {class: 'gray modal-lg'}));
	}

	openAlertModal() {

		if (this.user) {

			var alert: Alert = {
				asset_type_id: this.assetstypeArray.map(it => it.id),
				status: this.statusArray.map(it => it.id),
				province_id: this.provincesArray.map(it => it.id),
				key: this.search,
			};

			if (this.submittingAlert) { return; }
			this.submittingAlert = true;

			this.userService.createAlert(alert)
			.subscribe((it: any) => {

				this.submittingAlert = false;
				if (it.status == 401) {
					this.utils.showToast(it.message, "error");
				} else {
					this.modalRef = this.modalService.show(this.alertModal, Object.assign({}, {class: 'gray modal-lg'}));
				}
			}, error => {
				this.submittingAlert = false;
			})
		}
	}

	applyFilters() {
		this.modalRef.hide();
		this.filteredArray();
	}

	orderBy(e) {
		this.filter_order = e
		this.apiCallGetProducts()
	}

	apiCallGetProducts(): void {

		this.activeService.getAllAuctions(this.filter_type, this.filter_order)
		.subscribe((val: BaseResponse<AuctionListResponse>) => {
// debugger
			let auctions = val.response.auctions_list;

			this.packService.getAllPacks(this.filter_type, this.filter_order)
			.subscribe((val2: BaseResponse<PackListResponse>) => {
				// debugger
				if (this.filter_type == 4) { // Si es Packs mostrar solo Packs
					this.packList = val2.response.pack_list;

					let packs = this.packList.map(it => new Auction(
						it.id, it.title, it.created_at,
						it.auctions[0].archives,
						it.auctions,
						it.description
					));

					auctions = packs;
				}
				else if (this.filter_type != 2) { // Si es venta directa no mostrar Packs
					this.packList = val2.response.pack_list;

					let packs = this.packList.map(it => new Auction(
						it.id, it.title, it.created_at,
						it.auctions[0].archives,
						it.auctions,
						it.description
					));

					auctions = auctions.concat(packs).map(it => {
						switch (it.status) {
							case 'Initiated': it.sort_status = 1; break;
							case 'Win': it.sort_status = 2; break;
							case 'End': it.sort_status = 3; break;
						}
						return it;
					});
				}

				switch (this.filter_order) {
					case "0":
						this.auctionList = auctions.sort((a, b) => {
							return +new Date(a.created_at) -+new Date(b.created_at);
						});
						break;
					case "1":
						this.auctionList = auctions.sort((a, b) => {
							return a.price - b.price;
						});
						break;
					case "2":
						this.auctionList = auctions.sort((a, b) => {
							return b.price -a.price;
						});
						break;
					case "3":
						this.auctionList = auctions.sort((a, b) => {
							return a.views -b.views;
						});
						break;
					case "4":
						this.auctionList = auctions.sort((a, b) => {
							return +new Date(a.end_date) -+new Date(b.end_date);
						})
						break;
				}

				this.auctionList = this.auctionList
					.sort((a, b) => {
						return a.sort_status - b.sort_status;
					})
					.sort((a, b) => {
						return a.auction_type_id - b.auction_type_id;
					});

				this.productsMaster = this.auctionList;

				// console.log(this.packList)
				this.filteredArray();
			});
		});
	}

	openInfoWindow(marker: MapMarker, id) {
		this.prd = this.auctionList[id]
		this.infoWindow.open(marker);
	}

	keyLog(event) {
		if (event == "") {
			this.auctionList = Object.assign([], this.productsMaster);
		}
	}


	setFilter(number: number) {
		this.filter_type = number;
		this.apiCallGetProducts()

	}

	getLatLang(pr: Auction) {
		return new LatLng(pr.latitude, pr.longitude)
	}

	getActiveSelected() {

		if (this.assetstypeArray.length == 0) { return '---'; }

		let active_ids = this.assetstypeArray.map(it => it.id);
		let active_names: any = this.activeType
			.filter(it => active_ids.includes(it.id))
			.map(it => it.name);

		if (active_names.length > 6) {
			active_names = active_names.slice(0,6);
			active_names.push('...');
		}

		return active_names.join(', ');
	}

	getStatusSelected() {

		if (this.statusArray.length == 0) { return '---'; }

		let status_ids = this.statusArray.map(it => it.id);
		let status_names: any = this.status
			.filter(it => status_ids.includes(it.id))
			.map(it => it.name);

		if (status_names.length > 6) {
			status_names = status_names.slice(0,6);
			status_names.push('...');
		}

		return status_names.join(', ');
	}

	getProvinceSelected() {

		if (this.provincesArray.length == 0) { return '---'; }

		let province_ids = this.provincesArray.map(it => it.id);
		let province_names: any = this.provinces
			.filter(it => province_ids.includes(it.id))
			.map(it => it.name);

		if (province_names.length > 6) {
			province_names = province_names.slice(0,6);
			province_names.push('...');
		}

		return province_names.join(', ');
	}

	getMapMarkerOptions(pr) {
		let options = {...this.mapMarkerOptions};

		options.label.text = String(pr.type);
		return options;
	}
}
