<div class="">
  <div class="banner">
    <video class="homeVideo" controls>
      <source src="assets/videos/4%202020%20trademat%20video.mp4" type="video/mp4">
    </video>
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <h2 class="sub-title">
          {{'team-text1'|translate}}
        </h2>
        <h1 class="title">
           {{'team-text2'|translate}}
        </h1>
        <p class="subtext white">
          {{'team-text3'|translate}}
        </p>

      </div>

    </div>
  </div>
  <div class="info-container d-none d-lg-block">
    <div class="row no-gutters photo">
      <div class=" image" style='background-image: url("/assets/images/Luisma11.jpg") ;'>
        <div class="information"><h1 class="information-text">{{'team-text4'|translate}}
          <br><br>{{'team-text5'|translate}}</h1></div>
      </div>
      <div class="">
        <div class="row">
          <h1 class="name">Luis María Arnaiz Barbero</h1>
        </div>
        <div class="row">
          <h2 class="job">{{'team-text6'|translate}}</h2>
          <h2 class="job">luisma@subastastrademat.com</h2>

        </div>
      </div>

    </div>
    <div class="row no-gutters photo2 ">
      <div class=" image" style='background-image: url("/assets/images/ch.jpeg") ;'>
        <div class="information"><h1 class="information-text">{{'team-text7'|translate}}
          <br><br>{{'team-text8'|translate}}</h1></div>
      </div>
      <div class="">
        <div class="row">
          <h1 class="name">Christian Tremsal</h1>
        </div>
        <div class="row">
          <h2 class="job">CEO Trademat</h2>
          <h2 class="job">ctremsal@trademateurope.com</h2>

        </div>
      </div>

    </div>
  </div>
  <div class="info-containerResponsive  d-lg-none">
    <div class="card">
      <div class=" image-responsive" style='background-image: url("/assets/images/Luisma11.jpg") ;'></div>
      <div class="row pt-3">
        <h1 class="col-10 name">Luis María Arnaiz Barbero</h1>
        <img src="assets/images/add1.png"
             srcset="assets/images/add1@2x.png 2x,
                                assets/images/add1@3x.png 3x"
             class="icon col-auto " (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
             aria-controls="collapseExample"
        >
      </div>
      <div class="row">
        <h1 class="col-10 job">{{'team-text6'|translate}}</h1>
      </div>
      <div class="row">
        <h1 class="col-10 email-responsive" >luisma@subastastrademat.com</h1>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <h1 class="information-text">{{'team-text7'|translate}}</h1>

      </div>
    </div>
    <div class="card">
      <div class=" image-responsive"  style='background-image: url("/assets/images/ch.jpeg") ;'></div>
      <div class="row pt-3">
        <h1 class="col-10 name">Christian Tremsal</h1>
        <img src="assets/images/add1.png"
             srcset="assets/images/add1@2x.png 2x,
                                assets/images/add1@3x.png 3x"
             class="icon col-auto " (click)="collapse2.toggle()" [attr.aria-expanded]="!isCollapsed2"
             aria-controls="collapseExample"
        >
      </div>
      <div class="row">
        <h1 class="col-10 job">CEO Trademat</h1>
      </div>
      <div class="row">
        <h1 class="col-10 email-responsive">ctremsal@trademateurope.com</h1>
      </div>
      <div #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
        <h1 class="information-text">{{'team-text7'|translate}}</h1>

      </div>
    </div>
  </div>
  <ngb-carousel [showNavigationIndicators]="false">
    <ng-template ngbSlide *ngFor="let slide of slider">
      <div class="row" >
        <div class="col-md-4 col-lg-4 p-0" *ngFor="let asset of slide">
          <div class="activeCard" *ngIf="asset">
            <img src="assets/images/{{asset}}.jpg" style="   max-width: 100%;
  aspect-ratio: 1.6;"
                 class="Rectangle-Copy-2">
          </div>
        </div>
      </div>
    </ng-template>

  </ngb-carousel>
  <app-search-form></app-search-form>
</div>
