import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CommunicationResponse} from "../models/comunication.model";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private url = environment.url

  constructor(public http:HttpClient) { }
  getCommunications(limit,page){
    let param: any = {'limit': limit,'page':page};
    return this.http.get(this.url+"/api/communication", {params: param})
  }
}
