import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationBehaviorOptions, Route, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";

import { OthersService } from "../../services/others.service";
import { AssetsService } from "../../services/assets.service";

import { AssetType } from "../../models/assets";
import { Province } from "../../models/provinces";

@Component({
	selector: 'app-search-form',
	templateUrl: './search-form.component.html',
	styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
	public activeType: AssetType[] = []
	public provinces: Province[] = []
	public dropdownActiveTypeSettings: IDropdownSettings = {}
	public dropdownProvinceSettings: IDropdownSettings = {}
	public dropdownStatusSettings: IDropdownSettings = {}
	public status: any[] = []
	selected: number = 0;
	@ViewChild('searchInput' , {static: true}) searchValue: ElementRef;
	province:  Province[]=[];
	active: AssetType[]=[];
	statusInput: any=[];

	constructor(
		private translateService: TranslateService,
		public asctiveService: AssetsService,
		public otherServices: OthersService,
		private router: Router
	) {
	}

	ngOnInit(): void {

		this.initializeDropdowns();

		this.translateService.onLangChange.subscribe(event => {

			this.initializeDropdowns();
		});

		this.asctiveService.getAssetTypes().subscribe(
			val => {
				this.activeType = val['response']['asset_type_list']
			}
		)

		this.otherServices.getProvinces(null).subscribe(
			val => {
				this.provinces = val['response']["provinces_list"]
			}
		)

		this.status = [
			{statusId: 1, statusName: "Finalizadas"},
			{statusId: 2, statusName: "En Curso"},
			{statusId: 3, statusName: "Disponible Proximamente"}
		];
	}

	initializeDropdowns() {

		this.dropdownActiveTypeSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"itemsShowLimit": 3,
			"idField": "id",
			"textField": "name",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		}

		this.dropdownProvinceSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"itemsShowLimit": 3,
			"idField": "id",
			"textField": "name",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		}

		this.dropdownStatusSettings = {
			"singleSelection": false,
			"defaultOpen": false,
			"itemsShowLimit": 3,
			"idField": "statusId",
			"textField": "statusName",
			"selectAllText": this.translateService.instant("select-all"),
			"unSelectAllText": this.translateService.instant("unselect-all"),
		}
	}

	selectTab(tab: number) {
		this.selected=tab;
	}

	search() {

		this.router.navigate(['/listado-activos'], {
			queryParams: {
				search: this.searchValue.nativeElement.value,
				province: this.province.map(it=>it.id).join(','),
				type: this.selected,
				status: this.statusInput.map(it=>it.statusId).join(','),
				active: this.active.map(it=>it.id).join(','),
			},
		});
	}
}
