<div class="activeCard">
	<!-- <a (click)="goToProduct()"> -->
	<a [href]="enlaceProducto">
		<div class="activePhoto">
			<img class="imgActivePhoto"src="{{getImage()}}">
			<div class="activeToolbar">
				<span class="badge badgeToolbar orangeBG white" *ngIf="otherServices.setNovelty(auction.start_date)">
					<img src="assets/images/lightning.png"
						srcset="assets/images/lightning@2x.png 2x,
						assets/images/lightning@3x.png 3x"
						class="lightning">
					{{'product-item-text1'|translate}}
				</span>
				<span class="badge badgeToolbar orangeBG white" *ngIf="auction.auction_type_id == 3">
					<img src="assets/images/lightning.png"
						srcset="assets/images/lightning@2x.png 2x,
						assets/images/lightning@3x.png 3x"
						class="lightning">
					{{'product-item-text8'|translate}}
				</span>
				<span class="badge badgeToolbar greenBG white" *ngIf="auction.id&&auction.auction_type_id == 1">
					<img src="assets/images/auction.png"
						srcset="assets/images/auction@2x.png 2x,
						assets/images/auction@3x.png 3x"
						class="auction">
					{{'product-item-text2'|translate}}
				</span>
				<span class="badge badgeToolbar greenBG white" *ngIf="auction.auctions">
					<img src="assets/images/pack.png"
						srcset="assets/images/pack@2x.png 2x,
						assets/images/pack@3x.png 3x"
						class="auction">
					{{'product-item-text3'|translate}}
				</span>
				<span class="badge badgeToolbar purpleBG white" *ngIf="auction.id&&auction.auction_type_id == 2">
					<img src="assets/images/bag-of-money.png"
						srcset="assets/images/bag-of-money@2x.png 2x,
						assets/images/bag-of-money@3x.png 3x"
						class="auction">
					{{'product-item-text4'|translate}}
				</span>
				<span class="badge badgeToolbar2 likeBadge white" *ngIf="user != undefined && (auction.auction_type_id == 1 || auction.auction_type_id == 2)"
					(click)="toogleFavorite(auction);$event.stopPropagation()">
					<img *ngIf="!auction.fav" src="assets/images/like-2.png"
						srcset="assets/images/like-2@2x.png 2x,
						assets/images/like-2@3x.png 3x"
						class="auction">
					<img *ngIf="auction.fav" src="assets/images/like-solid.png"
						srcset="assets/images/like-solid@2x.png 2x,
						assets/images/like-solid@3x.png 3x"
						class="auction">
				</span>
			</div>
		</div>

		<div class="activeInfo">
			<h4>
				{{auction.name}}
			</h4>
			<span class="activeLocation secondary" *ngIf="auction.city">
				<img src="assets/images/location_pink.png"
					srcset="assets/images/location_pink@2x.png 2x,
					assets/images/location_pink@3x.png 3x"
					class="Location">
				{{auction.city}}, {{auction.province}}
			</span>
			<p class="activeProperties dark" *ngIf="auction.type">
				{{auction.type}} · {{auction.area}} m2 ·{{auction.bedrooms}} {{'product-item-text5'|translate}}
			</p>
		</div>

		<div class="activeFooter">
			<div *ngIf="auction.auction_type_id == 1" class="w-100">
				<div *ngIf="soonDate(auction.start_date)" class="soon-data">
					<label class="primary text-center">
						<!-- {{'product-item-text6'|translate}} {{auction.start_date | date:'dd/MM/YYYY'}} -->
						{{'product-item-text6'|translate}} {{days}}d {{hours}}:{{minutes}}:{{seconds}}
					</label>
				</div>

				<div *ngIf="!soonDate(auction.start_date)" class="data text-center primary">
					<div>{{'product-item-text7'|translate}}</div>
					<div>{{(auction.last_bid ? auction.last_bid.import : 0) | currency:'EUR'}}</div>
					<div>
						<img src="assets/images/time-blue.png"
							srcset="assets/images/time-blue@2x.png 2x,
							assets/images/time-blue@3x.png 3x"
							class="time-dark">
						<span class="" *ngIf="completed==''">
							{{days}}d {{hours}}:{{minutes}}:{{seconds}}
						</span>
						<span class="" *ngIf="completed!=''">
							{{ completed }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</a>
</div>
