<!-- Texto de cabecera indicando que es un entorno de pruebas -->
<!--<div style="background: #f8f9fa;color: #dc3545;width: 100%;position: relative;z-index: 2;">
	<p class="text-center m-0">
		{{'menu-text-test1'|translate}}<br>
		{{'menu-text-test2'|translate}} <a href="https://subastastrademat.com/es/" target="_blank">https://subastastrademat.com/es/</a>
	</p>
</div>-->
<nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-flex">

	<div class="navbar__contents">
		<div class="nabvar__left-items">
			<a routerLink="/">
				<div class="logo">
					<img src="assets/images/logo-menu.png" class="logoImg">
				</div>
			</a>

			<ul class="navbar-nav mr-auto">
				<li class="nav-item" [class.selectedMenu]="selectedMenu==1">
					<a class="nav-link"
						[routerLink]="['/faq']">
						{{'menu-text1'|translate}}
					</a>
				</li>

				<li class="separator"></li>

				<li class="nav-item" [class.selectedMenu]="selectedMenu==2">
					<a class="nav-link"
						[routerLink]="['/contact']">
						{{'menu-text2'|translate}}
					</a>
				</li>
			</ul>
		</div>

		<div class="nabvar__right-items">
			<ul class="navbar-nav">
				<app-user-access></app-user-access>
			</ul>
		</div>
	</div>

</nav>


<nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none"
	[ngStyle.xs]="'	border-bottom: 1px solid var(--grey-2);'">
	<div class="col-5 col-lg-auto">
	<a routerLink="/">
		<div class="logo">
		<img src="assets/images/horizontal.png"
			srcset="assets/images/horizontal@2x.png 2x,
			assets/images/horizontal@3x.png 3x"
			class="logoImg">
		</div>
	</a>
	</div>

	<div class="col-6 col-lg-auto navbar-toggler d-inline text-right">
	<a class="menu-icon navbar-toggler d-lg-none" *ngIf="user" [routerLink]="['/my-auctions']">
		<img src="assets/images/auction-pink.png"
		srcset="assets/images/auction-pink@2x.png 2x,
		assets/images/auction-pink@3x.png 3x">
	</a>
	<img src="assets/images/user-1.png"
		srcset="assets/images/user-1@2x.png 2x,
		assets/images/user-1@3x.png 3x"
		(click)="collapsedProfile = !collapsedProfile;collapsed=true">
	<img src="assets/images/menu.png"
		srcset="assets/images/menu@2x.png 2x,
		assets/images/menu@3x.png 3x"
		class="menu-icon navbar-toggler d-lg-none"
		(click)="collapsed = !collapsed;collapsedProfile=true">
	</div>

	<div [ngbCollapse]="collapsed" class="collapse navbar-collapse" [class.w-100]="!user">
		<ul class="navbar-nav mr-auto">
			<li class="nav-item" [class.selectedMenu]="selectedMenu==1">
				<a class="nav-link" [routerLink]="['/faq']"
					(click)="collapsed = true">{{'menu-text1'|translate}}</a>
			</li>

			<li class="separator"></li>

			<li class="nav-item" [class.selectedMenu]="selectedMenu==2">
				<a class="nav-link" [routerLink]="['/contact']"
					(click)="collapsed = true">{{'menu-text2'|translate}}</a>
			</li>
		</ul>

		<ul class="navbar-nav">
			<app-user-access></app-user-access>
		</ul>
	</div>

	<div [ngbCollapse]="collapsedProfile" class="collapse navbar-collapse">
		<ul class="navbar-nav mr-auto d-lg-none">
			<li class="nav-item ">
			<a class="nav-link" [routerLink]="['/mis-datos']" (click)="collapsedProfile = true">
				<img src="assets/images/user-1.png"
				srcset="assets/images/user-1@2x.png 2x,
				assets/images/user-1@3x.png 3x"
				class="">
				{{'menu-text16'|translate}}
			</a>
			</li>

			<li class="separator"></li>

			<li class="nav-item ">
			<a class="nav-link" [routerLink]="['/historial-subastas']" (click)="collapsedProfile = true">
				<img src="assets/images/auction-pink.png"
				srcset="assets/images/auction-pink@2x.png 2x,
				assets/images/auction-pink@3x.png 3x">
				{{'menu-text17'|translate}}
			</a>
			</li>

			<li class="separator"></li>

			<li class="nav-item">
			<a class="nav-link" [routerLink]="['/mis-favoritos']" (click)="collapsedProfile = true">
				<img src="assets/images/like-pink.png"
				srcset="assets/images/like-pink@2x.png 2x,
				assets/images/like-pink@3x.png 3x">
				{{'menu-text18'|translate}}
			</a>
			</li>

			<li class="separator"></li>

			<li class="nav-item ">
			<a class="nav-link" [routerLink]="['/mis-alertas']" (click)="collapsedProfile = true">
				<img src="assets/images/alerta-pink.png"
				srcset="assets/images/alerta-pink@2x.png 2x,
				assets/images/alerta-pink@3x.png 3x">
				{{'menu-text19'|translate}}
			</a>
			</li>

			<li class="separator"></li>

			<li class="nav-item ">
			<a class="nav-link" (click)="logoutUser();collapsedProfile = true">
				<img src="assets/images/logout.png"
				srcset="assets/images/logout@2x.png 2x,
				assets/images/logout@3x.png 3x">
				{{'menu-text20'|translate}}
			</a>
			</li>
		</ul>
	</div>
</nav>
<app-header-product-list *ngIf="url === '/'"></app-header-product-list>
