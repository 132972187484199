import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-about-us-item',
  templateUrl: './about-us-item.component.html',
  styleUrls: ['./about-us-item.component.scss']
})
export class AboutUsItemComponent implements OnInit {
  @Input() icon: string;
  @Input() text1: string;
  @Input() text2: string;
  @Input() number: string;
  @Input() oneline: boolean=true;
  @Input() color: string;
  constructor() { }

  ngOnInit(): void {
  }

}
