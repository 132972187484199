<div class="Mask d-lg-flex">

  <div class="image col-sm-12 col-lg-6">
    <img src="assets/images/trademat-dudas.jpg"
        srcset="assets/images/trademat-dudas@2x.jpg 2x,
              assets/images/trademat-dudas@3x.jpg 3x">
  </div>

  <div class="doubts-text col-sm-12 col-lg-6">
    <div class="row">
      <span class="No-te-pierdas-ningu">{{'banner-bottom-home-text1'|translate}}</span>
    </div>
    <div class="row no-gutters">
      <div class="Rectangle"></div>
    </div>
    <div class="row no-gutters">
      <span class="alguna-duda">
        {{'banner-bottom-home-text2'|translate}}
      </span>
    </div>
    <div class="row no-gutters">
      <button class="Rectangle-butto" [routerLink]="['/faq']">{{'banner-bottom-home-text3'|translate}}</button>
    </div>
  </div>

</div>
