<div class="last-chance">

	<div class="last-chance__contents">
		<div class="center margin-bottom-128">

			<div class="row light-blue">
				<div class="info col-lg-4 col-sm-12">
					<img src="assets/images/auction-blue@2x.png" alt="Subastas Trademat"/>
          {{'last-change-text1'|translate}}
				</div>
				<div class="info col-lg-4 col-sm-12">
					<img src="assets/images/check-azul@2x.png" alt="Subastas Trademat"/>
          {{'last-change-text2'|translate}}
				</div>
				<div class="info col-lg-4 col-sm-12">
					<img src="assets/images/bag-of-money-blue@2x.png" alt="Subastas Trademat"/>
          {{'last-change-text3'|translate}}
				</div>
			</div>

			<!-- Última oportunidad -->
			<div class="row margin-top-64">
				<div class="col-lg-6 col-sm-12 p-tittle text-left">
          			{{'last-change-text4'|translate}}
				</div>
				<div class="col-lg-6 d-none d-lg-block p-tittle text-right">
					<a href="/listado-activos">{{'last-change-text5'|translate}}</a>
				</div>
			</div>

			<div class="btn-group btn-group-toggle margin-top-32 margin-bottom-32">
				<div class="buttonGroup" [ngClass]="{'primaryBG': auctionLast == true, 'white' : auctionLast == true, 'active': auctionLast == true, 'btn-primary': auctionLast == false, 'whiteBG': auctionLast == false, 'primary':auctionLast == false}" ngbButtonLabel (click)="toogleSlide(0, 'last')">
					<div>
						<i class="fa-solid fa-gavel mr-1"></i>
						{{ 'active-recents-text3' | translate }}
					</div>
				</div>
				<div class="buttonGroup" [ngClass]="{'primaryBG': directSaleLast == true, 'white' : directSaleLast == true, 'active': directSaleLast == true, 'btn-primary': directSaleLast == false, 'whiteBG': directSaleLast == false, 'primary':directSaleLast == false}"ngbButtonLabel (click)="toogleSlide(1, 'last')">
					<div>
						<i class="fa-solid fa-money-bill mr-1"></i>
						{{ 'active-recents-text4' | translate }}
					</div>
				</div>
			</div>

			<ngb-carousel class="margin-bottom-64 carousel-active p-1" [showNavigationArrows]="true">
				<ng-template ngbSlide *ngFor="let slide of slidesAuctionEnd">
					<div class="row">
						<div class="col-12 col-md-4 col-lg-4" *ngFor="let asset of slide">
							<app-product-item-row [auction]="asset"></app-product-item-row>
						</div>
					</div>
				</ng-template>
			</ngb-carousel>

    </div>

    <!-- Próximas subastas -->
    <div class="center margin-top-64 margin-bottom-128">
			<div class="row margin-bottom-32">
				<div class="col-lg-6 col-sm-12 p-tittle text-left">
          			{{'last-change-text6'|translate}}
				</div>
				<div class="col-lg-6 d-none d-lg-block p-tittle text-right">
					<a href="/listado-activos">{{'last-change-text5'|translate}}</a>
				</div>
			</div>

			<div class="btn-group btn-group-toggle margin-bottom-32">
				<div class="buttonGroup" [ngClass]="{'primaryBG': auctionNew == true, 'white' : auctionNew == true, 'active': auctionNew == true, 'btn-primary': auctionNew == false, 'whiteBG': auctionNew == false, 'primary':auctionNew == false}" ngbButtonLabel (click)="toogleSlide(0, 'next')">
					<div>
						<i class="fa-solid fa-gavel mr-1"></i>
						{{ 'active-recents-text3' | translate }}
					</div>
				</div>
				<div class="buttonGroup" [ngClass]="{'primaryBG': directSaleNew == true, 'white' : directSaleNew == true, 'active': directSaleNew == true, 'btn-primary': directSaleNew == false, 'whiteBG': directSaleNew == false, 'primary':directSaleNew == false}"ngbButtonLabel (click)="toogleSlide(1, 'next')">
					<div>
						<i class="fa-solid fa-money-bill mr-1"></i>
						{{ 'active-recents-text4' | translate }}
					</div>
				</div>
			</div>

			<ngb-carousel class="carousel-active p-1" [showNavigationArrows]="true">
				<ng-template ngbSlide *ngFor="let slide of slidesAuctionNew">
					<div class="row">
						<div class="col-12 col-md-4 col-lg-4" *ngFor="let asset of slide">
							<app-product-item-row [auction]="asset"></app-product-item-row>
						</div>
					</div>
				</ng-template>
			</ngb-carousel>
    </div>

    <!-- Lo más visto -->
    <div class="center margin-top-64 margin-bottom-128">
      <div class="row margin-bottom-32">
        <div class="col-lg-6 col-sm-12 p-tittle text-left">
          {{'last-change-text7'|translate}}
        </div>
        <div class="col-lg-6 d-none d-lg-block p-tittle text-right">
          <a href="/listado-activos">{{'last-change-text8'|translate}}</a>
        </div>
      </div>

      <div class="btn-group btn-group-toggle margin-bottom-32">
        <div class="buttonGroup" [ngClass]="{'primaryBG': auctionViews == true, 'white' : auctionViews == true, 'active': auctionViews == true, 'btn-primary': auctionViews == false, 'whiteBG': auctionViews == false, 'primary':auctionViews == false}" ngbButtonLabel (click)="toogleSlide(0, 'views')">
          <div>
            <i class="fa-solid fa-gavel mr-1"></i>
            {{ 'active-recents-text3' | translate }}
          </div>
        </div>
        <div class="buttonGroup" [ngClass]="{'primaryBG': directSaleViews == true, 'white' : directSaleViews == true, 'active': directSaleViews == true, 'btn-primary': directSaleViews == false, 'whiteBG': directSaleViews == false, 'primary':directSaleViews == false}"ngbButtonLabel (click)="toogleSlide(1, 'views')">
          <div>
            <i class="fa-solid fa-money-bill mr-1"></i>
            {{ 'active-recents-text4' | translate }}
          </div>
        </div>
      </div>

      <ngb-carousel class="carousel-active p-1" [showNavigationArrows]="true">
        <ng-template ngbSlide *ngFor="let slide of slidesAuctionViews">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-4" *ngFor="let asset of slide">
              <app-product-item-row [auction]="asset"></app-product-item-row>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <!-- Bienes por categorías -->
    <div class="row margin-top-64">
      <div class="col-lg-6 col-sm-12 p-tittle text-left">
        {{'last-change-text9'|translate}}
      </div>
      <div class="col-lg-6 d-none d-lg-block p-tittle text-right">
        <a [href]="pathUrlList">
          {{'last-change-text10' |translate}}
        </a>
      </div>
    </div>

    <app-active-by-categories></app-active-by-categories>

		<div class="last-chance__button d-flex justify-content-center">
			<button class="btn secondaryBG seeAllButton" [routerLink]="['listado-activos']">{{ 'active-recents-text6' | translate }}</button>
		</div>

	</div>

</div>
