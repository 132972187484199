import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import { StateService } from '../services/state-service';
import { AssetsService } from '../services/assets.service';
import { UserService } from '../services/user.service';

import { BaseResponse } from '../models/base-response.model';
import { AuctionResponse } from '../models/auction';
import { UserRepresentation, UserRepresentationResponse } from '../models/UserRepresentation';

@Component({
	selector: 'app-auction-access',
	templateUrl: './auction-access.component.html',
	styleUrls: ['./auction-access.component.scss']
})
export class AuctionAccessComponent implements OnInit {

	public url: any = environment.url;

	public formData = new FormData();
	public formDataPayment = new FormData();
	private reference: string;

	public representations: UserRepresentation[] = [];
	public nick: string = "";
	public representation_id: any;
	public submittingNick: boolean = false;

	public files: any;
	public submittingDni: boolean = false;

	public selectedMethod = 1;
	public filesPayment: any;
	public titular: any;
	public iban: any;
	public check: any = false;
	public submittingEnd: boolean = false;

	public message = "";

	public save_dni = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		public stateService: StateService,
		private userService: UserService,
		private assetsService: AssetsService,
		private router: Router,
		public utils: UtilsService
	) {
	}

	ngOnInit(): void {

		this.activatedRoute.queryParams.subscribe(it => {

			this.reference = it.reference;

			this.getAuction();

			this.userService.getRepresentation()
			.subscribe((it: BaseResponse<UserRepresentationResponse>) => {
				this.representations = it.response.user_representation_list;
				this.representations.unshift({
					id: null,
					name: "Representación propia",
					cif: "",
					address: "",
					nationality: "",
					type: ""
				});
			});
		});
	}

	private getAuction() {
		this.assetsService.getAuctionByReference(this.reference)
		.subscribe((it: BaseResponse<AuctionResponse>) => {

			console.log("it.response.auction", it.response.auction);

			this.stateService.selectedAuction = it.response.auction;
		});
	}

	sendNick() {

		if (this.nick == "") {
			this.utils.showToast('Debe seleccionar una opción', "error");
			return;
		}

		if (this.submittingNick) { return; }
		this.submittingNick = true;

		this.formData.set("nick", this.nick);

		const data = this.representations.find(element => element.name == this.nick);
		this.representation_id = data.id;
		if (this.representation_id != null) {
			this.formData.set("representation_id", this.representation_id);
		}
		this.formData.set("user_auction_id", this.stateService.selectedAuction.user_auctions_id?.toString());

		this.assetsService.sendNick(this.formData)
		.subscribe((it: any) => {

			this.formData = new FormData();
			this.getAuction();

			if (it['status'] == 200) {
				this.message = it['message'];
				this.utils.showToast(this.message, "success");
			} else {
				this.message = it['message'];
				this.utils.showToast(this.message, "error");
			}

			if (it.response.status == 'Pending') {
				this.router.navigateByUrl('/my-auctions');
			}

			this.submittingNick = false;
		}, error => {
			const message = this.utils.showErrors(error)
			this.utils.showToast(message, "error")
			this.submittingNick = false;
		});
	}

	sendDNI() {

		//if (this.submittingDni) { return; }
		this.submittingDni = true;

		this.formData.set("hasDNI", "true");
		this.formData.set("save_dni", this.save_dni.toString());

		this.assetsService.sendDni(this.formData, this.stateService.selectedAuction.user_auctions_id)
		.subscribe((it: any) => {

			this.formData = new FormData();
			this.getAuction();

			if (it['status'] == 200) {
				this.message = it['message'];
				this.utils.showToast(this.message, "success");
			} else {
				this.message = it['message'];
				this.utils.showToast(this.message, "error");
			}

			if (it.response.status == 'Pending') {
				this.router.navigateByUrl('/my-auctions');
			}

			//this.submittingDni = false;
		}, error => {
			const message = this.utils.showErrors(error)
			this.utils.showToast(message, "error")
			this.submittingDni = false;
		});
	}

	getSteps() {

		let steps = 0;

		if (this.stateService.selectedAuction.nick_sent) { steps++; }
		if (this.stateService.selectedAuction.dni_validated) { steps++; }
		if (this.stateService.selectedAuction.paid) { steps++; }

		return steps;
	}

	handleFileInput(files: FileList) {
		this.formData.delete("dni[]");
		this.files = files;
		for (var i = 0; i < this.files.length; i++) {
			this.formData.append("dni[]", this.files[i]);
		}
	}

	handleFilePayment(files: FileList) {
		this.formData.delete("payment[]");
		this.filesPayment = files;
		for (var i = 0; i < this.filesPayment.length; i++) {
			this.formDataPayment.append("payment[]", this.filesPayment[i]);
		}
	}

	payAndEnd() {

		if (this.submittingEnd) { return; }
		this.submittingEnd = true;

		if (this.iban) {
			this.formDataPayment.set("iban", this.iban);
		}
		if (this.titular) {
			this.formDataPayment.set("titular", this.titular);
		}
		if (this.selectedMethod == 3) {
			this.formDataPayment.set("creditor", "true")
		}
		this.formDataPayment.set("haspayment", "true")

		this.assetsService.sendDoc(this.formDataPayment, this.stateService.selectedAuction.user_auctions_id)
		.subscribe((it: any) => {

			this.formData = new FormData();
			this.getAuction();

			if (it['status'] == 200) {
				this.message = it['message'];
				this.utils.showToast(this.message, "success")
			} else {
				this.message = it['message']
				this.utils.showToast(this.message, "error")
			}

			if (it.response.status == 'Pending') {
				this.router.navigateByUrl('/my-auctions');
			}

			this.submittingEnd = false;
		}, error => {
			const message = this.utils.showErrors(error)
			this.utils.showToast(message, "error")
			this.submittingEnd = false;
		});
	}

	checkPayment() {

		if (this.selectedMethod == 1 && this.stateService.selectedAuction.deposit > 0) {
			return this.filesPayment == null || this.filesPayment.length == 0;
		}
		return false;
	}

	isDisabled() {
		return !this.check || !this.stateService.selectedAuction.dni_validated || !this.stateService.selectedAuction.nick || this.checkPayment();
	}

	getUrl(path: string) {
		return environment.url + path;
	}

	logoutUser() {
		// localStorage.clear();
		const items = { ...localStorage };
		for(let item in items) {
		  if (item != 'LANG') localStorage.removeItem(item);
		}

		location.href = "/";
	}
}
