import { Component, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { environment } from "../../../environments/environment";
import { UtilsService } from 'src/app/services/utils.service';
import { StateService } from "../../services/state-service";
import { AssetsService } from 'src/app/services/assets.service';
import { AuctionsService } from 'src/app/services/auctions.service';

import { BaseResponse } from 'src/app/models/base-response.model';
import { User } from 'src/app/models/users';
import { Asset } from 'src/app/models/assets';
import { Auction } from 'src/app/models/auction';
import { Bid } from 'src/app/models/bid';

import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-auction-widget',
  templateUrl: './auction-widget.component.html',
  styleUrls: ['./auction-widget.component.scss']
})
export class AuctionWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('template') template: TemplateRef<any>
  @ViewChild('auctionConditionsModal') auctionConditionsModal: TemplateRef<any>
  @ViewChild('bidListModal') bidListModal: TemplateRef<any>
  modalRef: BsModalRef;
  AuctionCondition: any;
  @Input() pack: boolean
  @Input() public auction: Auction = {} as any
  submitting: boolean = false;
  private countdown_to_start;
  private countdown_to_end;
  public percentage: number;
  public days: any = ""
  public hours: any = ""
  public minutes: any = ""
  public seconds: any = ""
  public user: User

  public nologin: boolean = false
  public clickAuction: boolean = false
  public userAssetAuction: boolean
  public userAuctions: Auction[]
  public assets: Asset[]
  submittingBid: boolean = false;
  price: any = 0;
  minimum: any = 0;
  maximum: any = 999999999.99;
  error: string;
  valid: string;
  loading: boolean = false;
  auctionUser: Auction;
  reloadAuction: any = "";
  public completed: any = "";

  public bids: Bid[];

  constructor(
    public auctionService: AuctionsService,
    public assetsService: AssetsService,
    public AssetService: AssetsService,
    private modalService: BsModalService,
    private router: Router,
    private stateService: StateService,
    public utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.USER) {
      this.user = JSON.parse(localStorage.USER);
      if (this.user.address === "" ||
        this.user.address === undefined ||
        this.user.address === null ||
        this.user.cp === null ||
        this.user.cp === undefined ||
        this.user.city === "" ||
        this.user.city === undefined ||
        this.user.city === null ||
        this.user.province_id === "" ||
        this.user.province_id === undefined ||
        this.user.province_id === null ||
        this.user.document_path === "" ||
        this.user.document_path === undefined ||
        this.user.document_path === null ||
        this.user.confirmed !== 1 ||
        this.user.validated !== 1
      ) {
      }
    } else {
      this.nologin = true;
    }

    //console.log("this.auction", this.auction);
    this.reload();
    var dateNow = new Date();
    var dateStart = new Date(this.auction.start_date);
    var dateEnd = new Date(this.auction.end_date);
    this.countdown_to_start = dateStart.getTime();
    this.countdown_to_end = dateEnd.getTime();
    setInterval(() => {
      let dateNow = new Date();
      let timezoneOffsetLaravel = environment.timezone // 120 minutos es el offset respecto a EuropaMadrid que es lo configurado en Laravel en config/app.php
      let timezoneOffsetLocal = dateNow.getTimezoneOffset() // En la peninsula excepto canarias sera -120 minutos (UTC +2:00), en Canarias -60 (UTC +1:00)
      let diffHorariaEnMinutos = timezoneOffsetLocal + timezoneOffsetLaravel
      dateNow.setMinutes(dateNow.getMinutes() + diffHorariaEnMinutos)
      const madridTimezone = 'Europe/Madrid';
      const nowInMadrid = utcToZonedTime(new Date(), madridTimezone);
      const now = nowInMadrid.getTime();
      //var now = dateNow.getTime();

      // var now = new Date().getTime();

      var distance = this.countdown_to_start - now;

      if (distance <= 0) {
        distance = this.countdown_to_end - now;
      }

      if (distance < 0) {
        this.completed = 'Subasta finalizada';
        return;
      }

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.hours = this.hours < 10 ? "0" + this.hours : this.hours
      this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes
      this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds
    }, 1000)

    var start: any = new Date(this.auction.start_date),
      end: any = new Date(this.auction.end_date),
      today: any = new Date();

    //use Math.abs to avoid sign
    var q = Math.abs(today - start);
    var d = Math.abs(end - start);
    this.percentage = Math.round((q / d) * 100)
  }

  private reload() {
    if (localStorage.USER) {
      this.assetsService.getMyAuctions().subscribe(it => {
        this.userAuctions = it["response"]["auctions_list"];
        this.auctionUser = this.userAuctions.find(o => {
          return o.id === this.auction.id
        })
        if (this.auctionUser) {
          this.userAssetAuction = true

          let new_minimum = (this.auction.last_bid ? Number.parseFloat(this.auction.last_bid.import.toString()) : 0) + this.auction.bid_price_interval;
          new_minimum = new_minimum < this.auction.price ? this.auction.price : new_minimum;
          this.price = new_minimum;
          this.minimum = this.price;
        } else {
          this.userAssetAuction = false
        }
      })
    }

    clearInterval(this.reloadAuction);

    this.reloadAuction = setInterval(() => {
      this.reloadDataAuction()
    }, 3000);
  }

  reloadDataAuction() {
    this.assetsService.getReloadAuction(this.auction.id).subscribe(val => {
// debugger
      if (val['response']) {

        if (val['response'].auction) {
          this.auction.end_date = val['response'].auction.end_date;

          var dateNow: any = new Date();
          var dateEnd: any = new Date(this.auction.end_date);
          var dateStart: any = new Date(this.auction.start_date);
          this.countdown_to_start = dateStart.getTime();
          this.countdown_to_end = dateEnd.getTime();

          //use Math.abs to avoid sign
          var q = Math.abs(dateNow - dateStart);
          var d = Math.abs(dateEnd - dateStart);
          this.percentage = Math.round((q / d) * 100);
        }

        if (val['response'].bid) {
          this.auction.last_bid = val['response'].bid;
          //this.price = this.auction.last_bid.import + this.auction.bid_price_interval;
          let new_minimum = (this.auction.last_bid ? Number.parseFloat(this.auction.last_bid.import.toString()) : 0) + this.auction.bid_price_interval;
          this.minimum = new_minimum < this.auction.price ? this.auction.price : new_minimum;
        }
      }
    });
  }


  addToMyAuctions(id: number) {

    if (this.submitting) { return; }
    this.submitting = true;

    this.auctionService.setAuctionToUser(id)
      .subscribe(it => {
        this.router.navigateByUrl('/my-auctions');
        this.submitting = false;
      }, error => {
        this.submitting = false;
      });
  }

  noStarted() {
    var dateNow = new Date();
    var dateStart = new Date(this.auction.start_date);
    return dateNow < dateStart;
  }

  increasePrice() {
    this.price += this.auction.bid_price_interval;
  }

  decreasePrice() {
    if (this.price > (this.auction.last_bid ? this.auction.last_bid.import : this.auction.price) + this.auction.bid_price_interval)
      this.price -= this.auction.bid_price_interval;
  }

  makeBid() {

    if (this.price < this.minimum) {
      //this.error = "Importe de puja es menor que el mínimo.";
      this.utils.showToast("Importe de puja es menor que el mínimo.", "error")
      return;
    } else if (this.price > this.maximum) {
      //this.error = "Importe de puja demasiado alto.";
      this.utils.showToast("Importe de puja demasiado alto.", "error")
      return;
    }

    if (this.submittingBid) { return; }
    this.submittingBid = true;

    this.loading = true;
    this.assetsService.makeBid(this.price, this.auction.id)
      .subscribe((it: BaseResponse<any>) => {

        if (it.status == 201) {
          //this.valid = "La puja ha sido un exito. En breve recibirá un email con el resumen de su puja."
          this.utils.showToast("La puja ha sido un exito. En breve recibirá un email con el resumen de su puja.", "success")
        }
        if (it.status == 418) {
          //this.error = "La subasta ya ha terminado."
          this.utils.showToast("La subasta ya ha terminado.", "error")
        }
        if (it.status == 419) {
          //this.error = "Precio invalido, alguien pujó mas alto."
          this.utils.showToast("Precio invalido, alguien pujó mas alto.", "error")
        }

        this.submittingBid = false;

      }, error => {
        this.submittingBid = false;
      }, () => {
        this.auctionService.getAuction(this.auction.id)
          .subscribe(val => {
            this.loading = false;
            this.auction = val['response']["auction"];
            this.error = null;
            //if (this.valid) {
            this.onCloseModal();
            //}
            this.reload()
          });
      });
  }

  openAuctionConditionModal(a: Auction) {
    this.AuctionCondition = a.conditions
    this.modalRef = this.modalService.show(this.auctionConditionsModal, Object.assign({}, { class: 'gray modal-lg' }));
  }

  getImageUrl(asset: Asset) {
    return environment.url + asset.archives.filter(it => it.archive_type_id == 2)[0]?.path
  }

  getUrl(path: string) {
    return environment.url + path;
  }

  openTemplate() {
    this.modalRef = this.modalService.show(this.template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  onCloseModal() {
    this.modalRef.hide();
  }

  openbidListModal() {
    this.bids = []
    this.auctionService.getBidsFromAuction(this.auction.id).subscribe(
      val => {
        this.bids = val['response']["bid_list"];
      }
    )
    this.modalRef = this.modalService.show(this.bidListModal, Object.assign({}, { class: 'gray modal-lg' }));
  }

  ngOnDestroy() {
    clearInterval(this.reloadAuction);
  }

  routeTo(a: Auction) {
    console.log(a);
    this.stateService.selectedAuction = a;
    this.router.navigate(['auction-access'], { queryParams: { reference: a.reference_code } });
  }
}
