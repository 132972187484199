import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit {
  mapsurl:SafeResourceUrl
  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.getTrustedUrl('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.9245948754956!2d-3.730089976066503!3d42.37613669994688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd45fd9c38b1b64f%3A0xdbb4a884da28ab51!2sTrademat%20Europe%20SL!5e0!3m2!1ses!2ses!4v1623150385057!5m2!1ses!2ses');
  }

  getTrustedUrl(url:any){
    this.mapsurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}
