<div class="widget">
	<h2 class="subtitle">{{ 'auction-text1' | translate }}</h2>

	<hr class="sectionTitleBar">

	<div class="d-none d-lg-block">
		<div class="row" style="border-bottom: solid 1px var(--grey-2); padding-bottom: 16px;">
			<div class="col-6">
				<img src="assets/images/winner.png"
					srcset="assets/images/winner@2x.png 2x,
					assets/images/winner@3x.png 3x"
					class="icon">
				<label class="auctionSubtitle">{{ 'auction-text24' | translate }}</label>
				<br>
				<label class="prices">
					{{auction.price|currency:'EUR':true}}
				</label>
			</div>
			<div class="col-6">
				<img src="assets/images/winner.png"
					srcset="assets/images/winner@2x.png 2x,
					assets/images/winner@3x.png 3x"
					class="icon">
				<label class="auctionSubtitle">{{ 'auction-text2' | translate }}</label>
				<br>
				<!--<label class="prices" [ngClass]="(auction.last_bid != null && auction.last_bid.user_id.toString() == user.id.toString()) ? 'bg-success' : 'bg-danger'">-->
				<label class="prices">
					{{(auction.last_bid != null ? auction.last_bid.import : 0)|currency:'EUR':true}}<br/>
					<span class="taxes">+ impuestos</span>
				</label>
			</div>
			<div class="col-6 mt-3">
				<img src="assets/images/puja-minima.png"
					srcset="assets/images/puja-minima@2x.png 2x,
					assets/images/puja-minima@3x.png 3x"
					class="icon">
				<label class="auctionSubtitle">{{ 'auction-text3' | translate }}</label>
				<br>
				<label class="prices">
					{{(auction.bid_price_interval)|currency:'EUR':true}}
				</label>
			</div>
			<div class="col-6 mt-3">
				<img src="assets/images/puja-minima.png"
					srcset="assets/images/puja-minima@2x.png 2x,
					assets/images/puja-minima@3x.png 3x"
					class="icon">
				<label class="auctionSubtitle">Comisión</label>
				<br>
				<label class="prices">
					{{auction.fee}} %
				</label>
			</div>
		</div>

		<div *ngIf="auction.status == 'Initiated' else EndAuction">
			<div class="row" style="margin-top: 18px">
				<div class="col-12">
					<img src="assets/images/time-1.png"
						srcset="assets/images/time-1@2x.png 2x,
						assets/images/time-1@3x.png 3x"
						class="icon">
					<label class="auctionSubtitle">
						{{ 'auction-text4' | translate }}
					</label>
				</div>
				<div class="col-6">
					<label class="iniandend">
						{{ 'auction-text5' | translate }}
					</label>
					<br>
					<label class="dates">
						{{auction.start_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
				<div class="col-6">
					<label class="iniandend">
						{{ 'auction-text6' | translate }}
					</label>
					<br>
					<label class="dates">
						{{auction.end_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<ngb-progressbar type="primary-light" [value]="percentage" height="3px"></ngb-progressbar>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="!noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text7' | translate }}
								<span class="restTimeText">
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text9' | translate }}
								<span class="restTimeText">
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12" style="margin-top: 24px; height: 100px;">
					<label class="txtFt">
						{{ 'auction-text10' | translate }} <strong>{{auction.deposit}} €</strong>
					</label>
					<br>
					<a class="txtFt" href="{{getUrl(auction.conditions)}}" target="_blank">
						<i class="fa-solid fa-file"></i>
						{{ 'auction-text11' | translate }}
					</a>
				</div>
			</div>

			<div *ngIf="nologin then unloginBlock else loginBlock"></div>

			<ng-template #unloginBlock>
				<div class="row">
					<div class="col-lg-12 col-sm-12">
						<button [routerLink]="['/login']" class="btn primaryBtn btn100 txtBtn">
							<img src="assets/images/auction-solid.png"
								srcset="assets/images/auction-solid@2x.png 2x,
								assets/images/auction-solid@3x.png 3x"
								class="auction-solid">
							{{ 'auction-text12' | translate }}
						</button>
					</div>
				</div>
			</ng-template>

			<ng-template #loginBlock>
				<div *ngIf="this.userAssetAuction != null && !noStarted()">
					<div class="row" *ngIf="userAssetAuction && auctionUser.user_auction_status=='Incomplete'"
						style="background: #f4f4f5;padding: 2%; margin-bottom: 16px;">
						<div class="col-sm-1 col-lg-1">
							<img src="assets/images/announcement.png"
								srcset="assets/images/announcement@2x.png 2x,
								assets/images/announcement@3x.png 3x"
								class="Announcement">
						</div>
						<div class="col-sm-11 col-lg-11">
							<p class="advise">{{ 'auction-text13' | translate }}</p>
							<a class="pink" (click)="routeTo(auctionUser)">{{ 'auction-text14' | translate }}</a>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-12 col-sm 12"
							*ngIf="userAssetAuction && auctionUser.user_auction_status == 'Valid' && auction.status == 'Initiated'">
							<label for="" class="minitext">
								{{ 'auction-text15' | translate }} ({{ 'auction-text26' | translate }} {{ auction.bid_price_interval }}€)
							</label>

							<!-- <div class="priceField row no-gutters my-2">
								<label class="col" [(ngModel)]="price" name="" id="">{{price|currency:'EUR':true}}</label>
								<button class="changeval col-auto" (click)="increasePrice()">+</button>
								<button class="changeval col-auto" (click)="decreasePrice()">-</button>
							</div> -->

							<div class="price-input-container">
								<!-- != null ? price : (auction.last_bid.import + auction.bid_price_interval)  -->
								<input type="number"
									[(ngModel)]="price"
									step="{{ this.auction.bid_price_interval }}">
							</div>
						</div>

						<div class="col-lg-12 col-sm-12"
							*ngIf="userAssetAuction && auctionUser.user_auction_status == 'Valid' && auction.status == 'Initiated'">
							<button type="button" class="btn primaryBtn btn100 txtBtn" (click)="openTemplate()">
								{{'auction-text23' | translate }}
							</button>
						</div>

						<div class="col-lg-12 col-sm-12" style="color: red">
							{{error}}
						</div>

						<div class="col-lg-12 col-sm-12" style="color: green">
							{{valid}}
						</div>

						<div class="col-lg-12 col-sm-12" *ngIf="!userAssetAuction && auction.status == 'Initiated'">
							<button class="btn transparentButton btn100" [disabled]="submitting"
								(click)="addToMyAuctions(auction.id)">
								{{ 'auction-text17' | translate }}
							</button>
						</div>

						<div class="col-lg-12 col-sm-12" *ngIf="auction.status == 'Initiated'">
							<div class="center">
								<a class="txtFt" style="color: var(--secondary); text-decoration: underline; cursor: pointer"
									(click)="openbidListModal()">
									{{ 'auction-text27' | translate }}
								</a>
							</div>
						</div>

						<div class="col-lg-12 col-sm-12" style="margin-top: 24px;">
							<p class="center txtFt">
								<img src="assets/images/mail.png"
									srcset="assets/images/mail@2x.png 2x,
									assets/images/mail@3x.png 3x"
									class="toolbarIcons">
								{{ 'auction-text18' | translate }}
								<a href="mailto:hola@trademat.io" style="color: var(--secondary)">
									{{ 'auction-text19' | translate }}
								</a>
							</p>
						</div>
					</div>
				</div>
			</ng-template>
		</div>

		<ng-template #EndAuction>
			<!-- <label class="auctionSubtitle">{{ 'auction-text20' | translate }}</label> -->

			<div class="row" style="margin-top: 18px">
				<div class="col-6">
					<label class="iniandend">
						{{ 'auction-text5' | translate }}
					</label>
					<br>
					<label class="dates">
						{{auction.start_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
				<div class="col-6">
					<label class="iniandend">
						{{ 'auction-text6' | translate }}
					</label>
					<br>
					<label class="dates">
						{{auction.end_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<ngb-progressbar type="primary-light" [value]="percentage" height="3px"></ngb-progressbar>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="!noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text7' | translate }}
								<span class="restTimeText">
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text9' | translate }}
								<span class="restTimeText">
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12" style="margin-top: 24px; margin-bottom: 24px;">
					<a class="txtFt" href="{{getUrl(auction.conditions)}}" target="_blank">
						<i class="fa-solid fa-file"></i>
						{{ 'auction-text11' | translate }}
					</a>
				</div>
			</div>

			<div *ngIf="nologin then unloginBlock else loginBlock"></div>

			<ng-template #loginBlock>
				<div *ngIf="this.userAssetAuction != null && !noStarted()">
					<div class="row">
						<div class="col-lg-12 col-sm-12" *ngIf="auction.status == 'Initiated'">
							<div class="center">
								<a class="txtFt" style="color: var(--secondary); text-decoration: underline; cursor: pointer"
									(click)="openbidListModal()">
									{{ 'auction-text27' | translate }}
								</a>
							</div>
						</div>

						<div class="col-lg-12 col-sm-12" style="margin-top: 24px;">
							<p class="center txtFt">
								<img src="assets/images/mail.png"
									srcset="assets/images/mail@2x.png 2x,
									assets/images/mail@3x.png 3x"
									class="toolbarIcons">
								{{ 'auction-text18' | translate }}
								<a href="mailto:hola@trademat.io" style="color: var(--secondary)">
									{{ 'auction-text19' | translate }}
								</a>
							</p>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-template>
	</div>

	<div class="d-lg-none">
		<div class="">
			<div class="row pt-2">
				<img src="assets/images/winner.png"
					srcset="assets/images/winner@2x.png 2x,
					assets/images/winner@3x.png 3x"
					class="icon col-2">
				<label class="prices col-6">
					{{'auction-text24'|translate}}
				</label>
				<label class="prices">
					{{auction.price|currency:'EUR':true}}
				</label>
			</div>
			<div class="row pt-2">
				<img src="assets/images/winner.png"
					srcset="assets/images/winner@2x.png 2x,
					assets/images/winner@3x.png 3x" class="icon col-2">
				<label class="prices col-6">
					{{'auction-text2'|translate}}
				</label>
				<label class="prices">
					{{(auction.last_bid != null ? auction.last_bid.import : 0)|currency:'EUR':true}}<br/>
					<span class="taxes">+ impuestos</span>
				</label>
			</div>
			<div class="row pt-2">
				<img src="assets/images/puja-minima.png"
					srcset="assets/images/puja-minima@2x.png 2x,
					assets/images/puja-minima@3x.png 3x"
					class="icon col-2">
				<label class="prices col-6">
					{{'auction-text3'|translate}}
				</label>
				<label class="prices">
					{{(auction.bid_price_interval)|currency:'EUR':true}}
				</label>
			</div>
			<div class="row pt-2">
				<img src="assets/images/puja-minima.png"
					srcset="assets/images/puja-minima@2x.png 2x,
					assets/images/puja-minima@3x.png 3x"
					class="icon col-2">
				<label class="prices col-6">
					{{'auction-text28'|translate}}
				</label>
				<label class="prices">
					{{auction.fee}} %
				</label>
			</div>
		</div>

		<div *ngIf="auction.status == 'Initiated' else EndAuction" style="padding: 10px">
			<div class="row" style="margin-top: 18px; padding:10px">
				<div class="col-12">
					<img src="assets/images/time-1.png"
						srcset="assets/images/time-1@2x.png 2x,
						assets/images/time-1@3x.png 3x"
						class="icon">
					<label class="auctionSubtitle">
						{{'auction-text4'|translate}}
					</label>
				</div>
				<div class="col-6">
					<label class="iniandend">
						{{'auction-text5'|translate}}
					</label>
					<br>
					<label class="dates">
						{{auction.start_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
				<div class="col-6">
					<label class="iniandend">
						{{'auction-text6'|translate}}
					</label>
					<br>
					<label class="dates">
						{{auction.end_date | date:'dd MMM, y H:mm:ss'}}
					</label>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<ngb-progressbar type="primary-light" [value]="percentage" height="3px"></ngb-progressbar>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="!noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text7' | translate }}
								<span class="restTimeText" >
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row" style="margin-top: 20px;" *ngIf="noStarted()">
				<div class="col-12">
					<div class="restTime">
						<label class="generic">
							<span *ngIf="completed == ''">
								{{ 'auction-text9' | translate }}
								<span class="restTimeText" >
									{{days}} {{'auction-text8'| translate}} {{hours}}:{{minutes}}:{{seconds}}
								</span>
							</span>
							<span class="" *ngIf="completed != ''">
								{{ completed }}
							</span>
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12" style="margin-top: 24px; height: 100px;">
					<label class="txtFt">
						{{'auction-text10'|translate}} <strong>{{auction.deposit}} €</strong>
					</label>
					<br>
					<a class="txtFt" href="{{getUrl(auction.conditions)}}" target="_blank">
						<i class="fa-solid fa-file"></i>
						{{ 'auction-text11' | translate }}
					</a>
					<!--<label class="txtFt" (click)="openAuctionConditionModal(auction)">QQQ
						{{'auction-text11'|translate}}
					</label>-->
				</div>
			</div>

			<div *ngIf="nologin then unloginBlock else loginBlock"></div>

			<ng-template #unloginBlock>
				<div class="row">
					<div class="col-lg-12 col-sm-12">
						<button [routerLink]="['/login']" class="btn primaryBtn btn100 txtBtn">
							<img src="assets/images/auction-solid.png"
								srcset="assets/images/auction-solid@2x.png 2x,
								assets/images/auction-solid@3x.png 3x"
								class="auction-solid">
							{{'auction-text12'|translate}}
						</button>
					</div>
				</div>
			</ng-template>

			<ng-template #loginBlock>
				<div *ngIf="this.userAssetAuction!=null && !noStarted()">
					<div class="row" *ngIf="this.userAssetAuction && auctionUser.user_auction_status == 'Incomplete'"
						style="background: #f4f4f5;padding: 0; margin-bottom: 16px; margin:auto">
						<div class="col-sm-1 col-lg-1">
							<img src="assets/images/announcement.png"
								srcset="assets/images/announcement@2x.png 2x,
								assets/images/announcement@3x.png 3x"
								class="Announcement">
						</div>
						<div class="col-sm-11 col-lg-11">
							<p class="advise">{{'auction-text13'|translate}}</p>
							<a class="pink" (click)="routeTo(auctionUser)">{{'auction-text14'|translate}}</a>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-12 col-sm 12" *ngIf="this.userAssetAuction && auctionUser.user_auction_status == 'Valid'">
							<label for="" class="minitext">
								{{ 'auction-text15' | translate }} ({{ 'auction-text26' | translate }} {{ auction.bid_price_interval }}€)
							</label>

							<!-- <div class="priceField row no-gutters my-2">
								<label class="col" [(ngModel)]="price" name="" id="">{{price|currency:'EUR':true}}</label>
								<button class="changeval col-auto" (click)="increasePrice()">+</button>
								<button class="changeval col-auto" (click)="decreasePrice()">-</button>
							</div> -->

							<div class="price-input-container">
								<input type="number"
									[(ngModel)]="price"
									step="{{ auction.bid_price_interval }}">
							</div>
						</div>

						<div class="col-lg-12 col-sm-12"
							*ngIf="userAssetAuction && auctionUser.user_auction_status == 'Valid' && auction.status == 'Initiated'">
							<button type="button" class="btn primaryBtn btn100 txtBtn" (click)="openTemplate()">{{'auction-text23' | translate }}</button>
						</div>

						<!-- <div class="col-lg-12 col-sm-12" *ngIf="userAssetAuction && auctionUser.user_auction_status == 'Valid'">
							<button class="btn primaryBtn btn100 txtBtn" (click)="makeBid()" [disabled]="loading || submittingBid">
								<img src="assets/images/auction-solid.png"
									srcset="assets/images/auction-solid@2x.png 2x,
									assets/images/auction-solid@3x.png 3x"
									class="auction-solid">
								{{'auction-text16'|translate}}
							</button>
						</div> -->

						<div class="col-lg-12 col-sm-12" *ngIf="!userAssetAuction && auction.status == 'Initiated'">
							<button class="btn transparentButton btn100" (click)="addToMyAuctions(auction.id)">
								{{'auction-text17'|translate}}
							</button>
						</div>

						<div class="col-lg-12 col-sm-12" style="margin-top: 24px;">
							<p class="center txtFt">
								<img src="assets/images/mail.png"
									srcset="assets/images/mail@2x.png 2x,
									assets/images/mail@3x.png 3x"
									class="toolbarIcons">
								{{'auction-text18'|translate}}
								<a href="mailto:hola@trademat.io" style="color: var(--secondary)">
									{{'auction-text19'|translate}}
								</a>
							</p>
						</div>
					</div>
				</div>
			</ng-template>
		</div>

		<ng-template #EndAuction>
			<label class="auctionSubtitle">{{'auction-text20'|translate}}</label>
		</ng-template>
	</div>
</div>

<ng-template #auctionConditionsModal>
	<div class="modal-header">
		<div class="row">
			<div class="col-lg-9 col-sm-9">
				<h5 class="modal-title modalTitle">{{ 'auction-text22' | translate }}</h5>
			</div>
			<div class="col-lg-3 col-sm-3">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>

	<div class="modal-body">
		{{AuctionCondition}}
	</div>

	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #template>
	<div class="modal-header">
		<h4 class="modal-title pull-left"></h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<div class="modal-body">
		<h4 class="text-center">
			{{ 'auction-text25' | translate }} {{(price != null ? price : ((this.auction.last_bid ? this.auction.last_bid.import : 0) + this.auction.bid_price_interval) ) | currency:'EUR':true}}?
		</h4>
	</div>

	<div class="modal-footer">
		<div>
			<button class="btn primaryBtn btn100 txtBtn" (click)="makeBid()" [disabled]="loading || submittingBid">
				<img src="assets/images/auction-solid.png"
					srcset="assets/images/auction-solid@2x.png 2x,
					assets/images/auction-solid@3x.png 3x"
					class="auction-solid">
				{{ 'auction-text16' | translate }}
			</button>
		</div>
		<div>
			<button type="button" class="btn btn100 txtBtn btn-outline-secondary" (click)="modalRef.hide()">Cancelar</button>
		</div>
	</div>
</ng-template>

<ng-template #bidListModal>
	<div class="modal-header">
		<div class="row">
			<div class="col-lg-10 col-sm-5">
				<h5>{{'user-auction-text33'|translate}}</h5>
			</div>
			<!-- <div class="col-lg-5 col-sm-5">
				<label class="pink" (click)="downloadPDF()">
					<img src="assets/images/pdf.png"
						srcset="assets/images/pdf@2x.png 2x,
						assets/images/pdf@3x.png 3x"
						class="icon">
					{{'user-auction-text34'|translate}}
				</label>
				|
				<label class="pink" (click)="printDiv('printarea')">
					<img src="assets/images/print.png"
						srcset="assets/images/print@2x.png 2x,
						assets/images/print@3x.png 3x"
						class="icon">
					{{'user-auction-text35'|translate}}
				</label>
			</div> -->

			<div class="col-lg-2 col-sm-2">
				<button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>

	<div class="modal-body">
		<div class="container" id="printarea" #printarea>
			<div class="row" style="height: 56px; border-radius:4px;  background-color: var(--primary-light); font-family: Lato;
				font-size: 16px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 1.31; letter-spacing: -0.01px; color: var(--primary);">
				<div class="col-3 col-lg-4 col-sm-2 rowData">{{'user-auction-text46'|translate}}</div>
				<div class="col-3 col-lg-2 col-sm-2 rowData">{{'user-auction-text37'|translate}}</div>
				<div class="col-3 col-lg-4 col-sm-2 rowData">{{'user-auction-text38'|translate}}</div>
				<div class="col-3 col-lg-2 col-sm-2 rowData">{{'user-auction-text39'|translate}}</div>
			</div>
			<div class="row  tableData" *ngFor="let bid of bids let i = index">
				<div class="col-3 col-lg-4 col-sm-2 rowData" style="white-space:pre-wrap;overflow-wrap:break-word">{{bid.name}}</div>
				<div class="col-3 col-lg-2 col-sm-2 rowData">{{bid.import | currency:'EUR'}}</div>
				<div class="col-3 col-lg-4 col-sm-2 rowData">{{bid.created_at | date:'dd MMM, YYYY H:mm:ss'}}</div>
				<div class="col-3 col-lg-2 col-sm-2 rowData">
					<span *ngIf="bid.is_outbid" class="badge bg-light">
						<img src="assets/images/chart-4.png"
							srcset="assets/images/chart-4@2x.png 2x,
							assets/images/chart-4@3x.png 3x"
							class="chartIcon">
						{{'user-auction-text40'|translate}}
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
	</div>
</ng-template>
