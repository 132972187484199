<div class="cont">
	<div>
		<div class="formstep">
			<div class="section">
				<div class="row">
					<div class="col-lg-6 col-sm-6">
						<h1 class="title">
							<img src="assets/images/user-1.png"
								srcset="assets/images/user-1@2x.png 2x,
								assets/images/user-1@3x.png 3x"
								class="icon">
								{{'my-auctions-text1'|translate}}
						</h1>
					</div>
				</div>
				<div class="row no-gutters t-14">
					{{'my-auctions-text2'|translate}}
					<a class="pink">
						{{'my-auctions-text3'|translate}}
					</a>
				</div>
			</div>

			<div class="section">
				<div class="row no-gutters">
					<div class="col-lg-12 col-sm-12">
						<div class="sectionTitlesContainer row ">
							<label class="section-l col-2 col-lg-auto t-14 "
							[ngClass]="{'section-l-selected ': this.filter_type == 0}"
							(click)="setFilter(0)">
								{{'my-auctions-text4'|translate}}
							</label>
							<label class="section-l col-2 col-lg-auto t-14 "
							[ngClass]="{'section-l-selected ': this.filter_type == 1}"
							(click)="setFilter(1)">
								{{'my-auctions-text5'|translate}}
							</label>
							<label class="section-l col-2 col-lg-auto t-14 "
							[ngClass]="{'section-l-selected ': this.filter_type == 2}"
							(click)="setFilter(2)">
								{{'my-auctions-text6'|translate}}
							</label>
						</div>
					</div>
				</div>

				<!-- Tabla: desktop -->

				<div class="d-none d-xl-block">
					<table class="my-auctions__table">
						<thead>
							<tr>
								<th></th>
								<th>{{'my-auctions-text7'|translate}}</th>
								<th>{{'my-auctions-text8'|translate}}</th>
								<th>{{'my-auctions-text9'|translate}}</th>
								<th>{{'my-auctions-text10'|translate}}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of auctions">
								<td>
									<img *ngIf="a.archives[0]?.path != null"
										class="image"
										src="{{this.url}}{{a.archives[0]?.path}}">
									<img *ngIf="a.archives[0]?.path == null"
										class="image"
										src="assets/images/logo-linea@3x.png">
								</td>
								<td>
									<div>ID:{{a.id}} - {{a.name}}</div>
									<div class="badge badgeToolbar greenBG white d-inline-block" *ngIf="a.auction_type_id==1">
										<img src="assets/images/auction.png"
											srcset="assets/images/auction@2x.png 2x,
											assets/images/auction@3x.png 3x"
											class="auction">
										{{'my-auctions-text11'|translate}}
									</div>

									<div class="badge badgeToolbar purpleBG white d-inline-block" *ngIf="a.auction_type_id==2">
										<img src="assets/images/bag-of-money.png"
											srcset="assets/images/bag-of-money@2x.png 2x,
											assets/images/bag-of-money@3x.png 3x"
											class="auction">
										{{'my-auctions-text12'|translate}}
									</div>
								</td>
								<td>
									{{getLastBid(a) | currency:'EUR'}}
								</td>
								<td>
									{{a.deposit | currency:'EUR'}}
								</td>
								<td>
									<div class="t-14 orange_border" *ngIf="a.user_auction_status=='Pending'">{{'my-auctions-text13'|translate}}<br>{{'my-auctions-text14'|translate}}</div>
									<div class="t-14 green_border" *ngIf="a.user_auction_status=='Valid'">{{'my-auctions-text15'|translate}}</div>
									<div class="t-14 green_border" *ngIf="a.user_auction_status=='Win'">{{'my-auctions-text16'|translate}}</div>
									<div class="t-14 red_border" *ngIf="a.user_auction_status=='Loose'">{{'my-auctions-text17'|translate}}</div>
									<div class="t-14 grey_border" *ngIf="a.user_auction_status=='Incomplete' && a.status=='Initiated'">{{'my-auctions-text18'|translate}}{{a.countDownAuction}}</div>
									<div class="t-14 red_border" *ngIf="a.user_auction_status=='Incomplete' && a.status!='Initiated'">{{'my-auctions-text20'|translate}}</div>
								</td>
								<td>
									<div style="align-items:center">
										<!--<a (click)="routeTo(a)" class="join">-->
										<a [href] = a.linkProduct class="join">
											{{a.user_auction_status=='Incomplete'?'Acceder':a.user_auction_status=='Valid'?'Ir a subasta':'Ver solicitud'}}
										</a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<!-- Tabla: mobile -->

				<div class="d-xl-none">
					<div class="row pt-3" *ngFor="let a of auctions">
						<div class=" col-auto" *ngIf="a.archives[0]?.path != null">
							<img class="image" src="{{this.url}}{{a.archives[0]?.path}}">
						</div>
						<div class="col-auto" *ngIf="a.archives[0]?.path == null">
							<img class="image" src="assets/images/logo-linea@3x.png">
						</div>
						<div class="col col-xl-4">
							<div>ID:{{a.id}} - {{a.name}}</div>
							<div class="badge badgeToolbar greenBG white d-none d-xl-table" *ngIf="a.auction_type_id==1">
								<img src="assets/images/auction.png"
										 srcset="assets/images/auction@2x.png 2x,
																											assets/images/auction@3x.png 3x"
										 class="auction">
								{{'my-auctions-text11'|translate}}
							</div>

							<div class="badge badgeToolbar purpleBG white d-none d-xl-table" *ngIf="a.auction_type_id==2">
								<img src="assets/images/bag-of-money.png"
										 srcset="assets/images/bag-of-money@2x.png 2x,
																													assets/images/bag-of-money@3x.png 3x"
										 class="auction">
								{{'my-auctions-text12'|translate}}
							</div>
						</div>
						<div class="col-2 d-none d-xl-flex"> {{getLastBid(a) | currency:'EUR'}}</div>
						<div class="col-1 d-none d-xl-flex"> {{a.deposit | currency:'EUR'}}</div>
						<div class="col-12 col-xl t-14 orange_border mt-2" *ngIf="a.user_auction_status=='Pending'">{{'my-auctions-text13'|translate}}<br>
							{{'my-auctions-text14'|translate}} </div>
						<div class="col-12 col-xl t-14 green_border mt-2" *ngIf="a.user_auction_status=='Valid'">{{'my-auctions-text15'|translate}}</div>
						<div class="col-12 col-xl t-14 green_border mt-2" *ngIf="a.user_auction_status=='Win'">{{'my-auctions-text16'|translate}}</div>
						<div class="col-12 col-xl t-14 red_border mt-2" *ngIf="a.user_auction_status=='Loose'">{{'my-auctions-text17'|translate}}</div>
						<div class="col-12 col-xl t-14 grey_border mt-2" *ngIf="a.user_auction_status=='Incomplete' && a.status=='Initiated'">{{'my-auctions-text18'|translate}}{{a.status}}</div>
						<div class="col-12 col-xl t-14 red_border mt-2" *ngIf="a.user_auction_status=='Incomplete' && a.status!='Initiated'">{{'my-auctions-text20'|translate}}</div>
						<div class=" col-1 d-none d-xl-flex" style="align-items: center;">
							<a (click)="routeTo(a)" class="join">{{a.user_auction_status=='Incomplete'?'Acceder':a.user_auction_status=='Valid'?'Ir a subasta':'Ver solicitud'}}</a>
						</div>
						<div class="col-12 d-xl-none row">
							<div class="col-auto ">{{'my-auctions-text19'|translate}} {{a.last_bid ? a.last_bid.import : a.price}} €</div>
							<a class="col join" (click)="routeTo(a)">{{a.user_auction_status=='Incomplete'?'Acceder':a.user_auction_status=='Valid'?'Ir a subasta':'Ver solicitud'}}</a>
						</div>
						<div class="col-12" [ngClass]="{'section':auctions.indexOf(a)!=auctions.length-1}"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
