import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {Province} from '../models/provinces';
import {User} from '../models/users';
import {OthersService} from '../services/others.service';
import {UserService} from '../services/user.service';
import {Country} from "../models/country";
import {BaseResponse} from "../models/base-response.model";

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  user: User = {} as any
  step: number = 1
  registerForm: FormGroup
  submitted: boolean = false;
  submitting: boolean = false;
  provinces: Province[] = []
  countries: Country[] = []
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;
  error: any;
  error_format: any;
  error_exist: any;

  inputType1 = false;
  inputType2 = false;
  canRegister = false;

  constructor(public utilService: OthersService, public fb: FormBuilder, public userService: UserService, public router: Router) {
  }

  ngOnInit(): void {
    this.utilService.getProvinces(null).subscribe(
      val => {
        this.provinces = val['response']["provinces_list"];
        this.provinces.unshift({ id: null, name: null, Provinces_Image: null });
      }
    )
    this.utilService.getCountries().subscribe(
      val => {
        this.countries = val['response']["country_list"];
      }
    )
    this.setRegisterFormFields()
  }

  setRegisterFormFields() {
    //PatternPhone = "^([+]?[\s0-9]+)?(\d{3})?([\s]?[0-9]{9})$";
    this.registerForm1 = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      password_confirmation: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    }, {validators: passwordMatchingValidator});
    this.registerForm2 = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.pattern("^[A-za-zÀ-ú ]+$")]),
      surname: new FormControl(null, [Validators.required]),
      prefix: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{9}")]),
    });
    this.registerForm3 = new FormGroup({
      address: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.pattern("[A-zÀ-ú0-9 \-/',#.()º]+")]),
      city: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.pattern("^[A-za-zÀ-ú ]+$")]),
      cp: new FormControl(null, [Validators.pattern("[0-9]+")]),
      province_id: new FormControl(null, []),
      country_id: new FormControl(null, []),
      newsletter: new FormControl(false, []),
      terms: new FormControl(null, [Validators.required]),
    });
  }

  onFormSubmit(): void {
    this.submitted = true
    this.error=null
    this.error_exist=false;
    this.error_format=false;
    if (!this.registerForm3.valid) {
      return
    }

    if (this.submitting) { return; }
    this.submitting = true;

    this.utilService.formToObject(this.registerForm1, this.user)
    this.utilService.formToObject(this.registerForm2, this.user)
    this.utilService.formToObject(this.registerForm3, this.user)
    this.userService.registerUser(this.user).subscribe(
      (val: BaseResponse<any>) => {
        if (val.status == 201) {
          this.router.navigate(['/revisar-correo'], {queryParams: {mail: this.registerForm1.get('email').value}})
        }else{
          if(val.response[0] && val.response[0].password!=undefined ){
            console.log("val.response[0].password[0] es " , val.response[0].password[0]);
            this.error_format =  true;
            this.error=true;
          }
          if( val.response[0] && val.response[0].email!=undefined ){
            console.log("val.response[0].email[0] es " , val.response[0].email[0]);
            this.error_exist =  true;
            this.error=true;
          }
        }
        this.submitting = false;
      }, error => {
        this.submitting = false;
      }
    )
  }

  termsChange(event){
    this.canRegister = event.target.checked;
  }

  plusStep() {
    this.submitted = true;
    if (this.step == 1 && this.registerForm1.valid) {
      this.step++
      this.submitted = false;
      return;
    }
    if (this.step == 2 && this.registerForm2.valid) {
      this.step++
      this.submitted = false;
      return;
    }
  }

  minusStep() {
    this.step--;
    this.error=false;
  }

  get rf1() {
    return this.registerForm1.controls
  }

  get rf2() {
    return this.registerForm2.controls
  }

  get rf3() {
    return this.registerForm3.controls
  }

  changeInputType(n: any){
    if(n==1){ this.inputType1 = !this.inputType1;}
    if(n==2){ this.inputType2 = !this.inputType2;}
  }

  selectedCountry(id: any){
    this.utilService.getProvinces(id).subscribe(
      val => {
        this.provinces = val['response']["provinces_list"];
        this.provinces.unshift({ id: null, name: null, Provinces_Image: null });
      }
    )
  }
}

export const passwordMatchingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('password_confirmation');
  if (password?.value !== confirmPassword?.value)
    confirmPassword.setErrors({'notmatched': true})
  return null;
};