import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NotePressService {
  private url = environment.url

  constructor(public http:HttpClient) { }
  getNotePress(limit,page){
    let param: any = {'limit': limit,'page':page};
    return this.http.get(this.url+"/api/news", {params: param})
  }
}
