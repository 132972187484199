import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {OthersService} from '../services/others.service';
import {UserService} from '../services/user.service';
import {BaseResponse} from "../models/base-response.model";

@Component({
  selector: 'app-register-form',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  user: any = {} as any
  submitted: boolean = false;
  submitting: boolean = false;
  recoverform: FormGroup;
  error: any;
  token: string;

  inputType1 = false;
  inputType2 = false;

  constructor(public utilService: OthersService, public fb: FormBuilder, public userService: UserService, public router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(it => {
      if (it.t) {
        this.token = "Bearer " + it.t

        this.userService.checkToken(this.token)
        .subscribe((val: BaseResponse<any>) => {
          if (val.status != 200) {
            this.router.navigate(['/invalid-token'])
          }
        }, error => {
            this.router.navigate(['/invalid-token'])
        });
      }
      else {
        this.router.navigate(['/invalid-token'])
      }
      // else location.href="/";
    });
    this.setRegisterFormFields()
  }

  setRegisterFormFields() {
    this.recoverform = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      password_confirmation: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    }, {validators: passwordMatchingValidator});

  }

  onFormSubmit(): void {
    this.submitted = true
    this.error = null

    if (this.submitting) { return; }
    this.submitting = true;

    this.utilService.formToObject(this.recoverform, this.user)
    this.userService.recoverPassword(this.user, this.token)
    .subscribe((val: BaseResponse<any>) => {
      if (val.status == 200) {
        this.router.navigate(['/login'])
      } else {
        debugger
        this.error = val.response
      }
      this.submitting = false;
    }, error => {
      debugger
      this.submitting = false;
    });
  }


  get rf1() {
    return this.recoverform.controls
  }

  changeInputType(n: any){
    if(n==1){ this.inputType1 = !this.inputType1;}
    if(n==2){ this.inputType2 = !this.inputType2;}
  }

}

export const passwordMatchingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('password_confirmation');
  if (password?.value !== confirmPassword?.value)
    confirmPassword.setErrors({'notmatched': true})
  return null;
};
