import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-review-mail',
  templateUrl: './review-mail.component.html',
  styleUrls: ['./review-mail.component.scss']
})
export class ReviewMailComponent implements OnInit {
  mail:string
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe( v => {
      this.mail = v['mail'];
    });
  }

  ngOnInit(): void {
  }

}
