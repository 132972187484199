import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../services/user.service';
import {BaseResponse} from "../models/base-response.model";
import {LoginResponse} from "../models/login";
import { UtilsService } from "../services/utils.service";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public modalRef: BsModalRef;
  public user = {mail: "", password: ""}
  public mail_recovery = "";
  submitting: boolean = false;
  @ViewChild('rememberModal') rememberModal: TemplateRef<any>
  error: string;

  inputType = false;

  constructor(private modalService: BsModalService, public userService: UserService, public router: Router, public utilsService: UtilsService) {

  }

  ngOnInit(): void {
    
  }

  openModal() {
    this.modalRef = this.modalService.show(this.rememberModal, Object.assign({}, {class: 'gray modal-lg'}));
  }

  loginUser() {
    this.userService.loginUser(this.user.mail, this.user.password).then(
      (val: BaseResponse<LoginResponse>) => {
        if (val.status == 200) {
          if (val.response.user.confirmed) {
            var now = new Date().toString()
            localStorage.setItem("USER", JSON.stringify(val.response.user));
            localStorage.setItem("TOKEN", val.response.token_type + ' ' + val.response.access_token);
            localStorage.setItem("LAST_LOGIN", now)

            // Cambio de idioma en la web en el storage
            let lang = localStorage.getItem("LANG")
            if (!lang) localStorage.setItem("LANG", val.response.user.language.toLowerCase())

            this.userService.notify(true);
            //this.router.navigateByUrl('/mis-datos');
            location.href="/mis-datos";
          } else {
            localStorage.setItem("EMAIL", val.response.user.email);
            this.router.navigateByUrl('/usuario-no-confirmado');
          }
        } else {
          this.error = "El nombre de usuario o contraseña es incorrecto"
        }
      }
    )
  }

  recoverPassword() {
    this.modalRef.hide();
    if (this.mail_recovery) {
      if (this.submitting) { return; }
      this.submitting = true;
      this.userService.recoverPasswordPetition(this.mail_recovery).subscribe(it => {
        this.submitting = false;
        this.utilsService.showToast(it['message'], 'success')
      }, error => {
        this.submitting = false;
        this.utilsService.showToast(error['error']['data']['email'], 'error')
      });
    }
  }

  changeInputType(){
    this.inputType = !this.inputType;
  }

}
