import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  private headers: HttpHeaders;
  private url = environment.url;

  constructor(public http: HttpClient) {

  }

  private setHeaders() {
    this.headers = new HttpHeaders().set("Accept", "application/json");

    if (localStorage.getItem('TOKEN')) {
      this.headers = this.headers.append("Authorization", localStorage.getItem('TOKEN'))
    }
  }

  getAssets() {
    return this.http.get(this.url + "/api/assets/get/all")
  }

  getAssetsSimilar(id) {
    return this.http.get(this.url + "/api/assets/" + id + "/similar")
  }

  getAuction(id) {
    return this.http.get(this.url + "/api/auctions/public/" + id, { headers: this.headers })
  }

  getReloadAuction(id) {
    return this.http.get(this.url + "/api/auctions/public/reloadList/" + id)
  }

  getFavorites() {
    this.setHeaders();

    return this.http.get(this.url + "/api/auctions/user/favorites", { headers: this.headers })
  }

  getAssetTypes() {
    return this.http.get(this.url + "/api/assets/type/list")
  }

  getNumberOfAssetsOnCities() {
    return this.http.get(this.url + "/api/assets/list/provinces")
  }

  getAllAuctions(filterType: number, order: string, status: string = null) {

    this.setHeaders();

    let params: any = {
      'filter_type': filterType,
      'filter_order': order,
    };

    if (status) { params.status = status; }

    return this.http.get(this.url + "/api/auctions/public/list", { params: params, headers: this.headers })
  }

  getLastAuctions() {

    this.setHeaders();

    let params: any = {
      'status': 'Initiated',
    };

    return this.http.get(this.url + "/api/auctions/public/list/nextEnd", { params: params, headers: this.headers });
  }

  getNextAuctions() {

    this.setHeaders();

    let params: any = {
      'status': 'Initiated',
    };

    return this.http.get(this.url + "/api/auctions/public/list/nextStart", { params: params, headers: this.headers });
  }

  getMyAuctions() {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/user/", { headers: this.headers })
  }

  getAllMyAuctions(filterType: number, order: string, status: string = null) {

    this.setHeaders();

    let params: any = {
      'filter_type': filterType,
      'filter_order': order,
    };

    if (status) { params.status = status; }

    return this.http.get(this.url + "/api/auctions/user/allMyAuction", { params: params, headers: this.headers })
  }

  getReloadMyAuctions() {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/user/reloadList", { headers: this.headers })
  }

  getAuctionByReference(reference: any) {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/reference/", { params: { reference: reference }, headers: this.headers })
  }

  /*getAuctionById(id: any) {
    this.setHeaders();
    return this.http.get(this.url + "/api/auctions/id/", { params: { id: id }, headers: this.headers })
  }*/

  /* sendNick(nick: string, representation_id, user_auction_id: any) {
    this.setHeaders();
    return this.http.put(this.url + "/api/auctions/user", {
      params: { nick: nick, representation_id: representation_id, user_auction_id: user_auction_id }
    }, { headers: this.headers })
  } */

  sendNick(formData: FormData) {
    this.setHeaders();
    formData.append("_method", "PUT");
    return this.http.post(this.url + "/api/auctions/user", formData, { headers: this.headers })
  }

  sendDni(formData: FormData, user_auction_id: any) {
    formData.set("user_auction_id", user_auction_id)
    this.setHeaders();
    return this.http.post(this.url + "/api/auctions/user", formData, { headers: this.headers })
  }

  sendDoc(formData: FormData, user_auctions_id: any) {
    formData.set("user_auction_id", user_auctions_id)
    this.setHeaders();
    return this.http.post(this.url + "/api/auctions/user", formData, { headers: this.headers })
  }

  makeBid(price: any, id: number) {
    this.setHeaders();
    return this.http.post(this.url + "/api/auctions/" + id + "/user/bid", { price: price, }, { headers: this.headers })
  }

  setFavorite(id: number) {
    this.setHeaders();
    return this.http.put(this.url + "/api/auctions/" + id + "/user/favorite", {}, { headers: this.headers })
  }

  removeFav(id: number) {
    this.setHeaders();
    return this.http.delete(this.url + "/api/auctions/" + id + "/user/favorite", { headers: this.headers })
  }
}
