<div class="row">
  <div class="col-sm-12 col-lg-12">
    <div id="containerUbicacion" class="sectionContainer">
      <h2 class="titleContainer">{{'similar-text1'|translate}}</h2>

    </div>
  </div>
</div>
<ngb-carousel class="carousel-active  d-lg-block" [showNavigationIndicators]="false" [showNavigationArrows]="false">
  <ng-template ngbSlide  *ngFor="let slide of slidesAuction">
    <div class="row no-gutters">
      <div class="col-md-4 col-lg-4 p-2"  style="margin-top: 10px" *ngFor="let asset of slide">
        <app-product-item-row [auction]="asset"></app-product-item-row>
      </div>
    </div>
  </ng-template>

</ngb-carousel>
