<div>
  <div class="banner row">
    <div class="section-gallery">
      <div class="row align-items-start">
        <div class="col-md-auto">
          <div class="row">
            <div class="breadcrumb-gallery">
              <span class="link-bread" [routerLink]="['/']">{{ 'dossier-page-title' | translate }}</span>   /  <span class="link-bread" [routerLink]="['/comunication']">{{ 'dossier-page-title3' | translate }}</span>   /  <span class="select-bread">{{ 'gallery-page-title' | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ 'gallery-page-title' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="section-gallery-separator">
            </div>
          </div>
          <div class="row w-100 item-gallery d-none d-lg-block" *ngFor="let item of GalleryType" (click)="selectImages(item.id)">
            <div class="row  w-100 justify-content-between">
              <div class="col-md-auto" [ngClass]="{'active':gallery_select==item.id}">
                <h2 class="team ">{{item.name}}</h2>
              </div>
              <div class="col-4" align="end">
                <img src="assets/images/arrow-drop-right.png"
                     srcset="assets/images/arrow-drop-right@2x.png 2x,
             assets/images/arrow-drop-right@3x.png 3x"
                     class="arrow">
              </div>
              <div class="separator-items"></div>

            </div>
          </div>
          <div class="d-lg-none no-gutters">
            <div  class="row w-100 d-lg-none no-gutters mt-2">
              <div class="col-auto">
                <h2 class="team">{{ 'gallery-page-text1' | translate }}</h2>
              </div>
              <div class="col text-right">
                <img src="assets/images/add-black.png"
                     srcset="assets/images/add-black@2x.png 2x,
             assets/images/add-black@3x.png 3x" (click)="collapse.toggle();;selectImages(0)"
                     aria-controls="collapseExample"
                     class="arrow">
              </div>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class=" d-lg-none col  mt-2">
              <div class="title-image-modal my-2">{{ 'gallery-page-text2' | translate }}</div>
              <div class="download-image-modal"></div>
              <mat-grid-list cols="2" rowHeight="104pt" gutterSize="11px">
                <mat-grid-tile class="imageRec" *ngFor="let image of images">
                  <div></div>
                </mat-grid-tile>

              </mat-grid-list>
            </div>
            <div class=" separator-items mt-2"></div>
          </div>
        </div>

        <div class="col galleryImg">
          <div class="title-image-modal" *ngIf="show">
            <div class="col-auto" >
              <h1 class="team">{{ 'gallery-page-text1' | translate }}</h1>
              <div class="download-gallery"><span class="text-style-1" (click)="downloadAll(gallery_select)"> {{ 'gallery-page-text2' | translate }}</span>
                {{ 'gallery-page-text3' | translate }}</div>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-md-2">
            <div class="col-md-6 mb-4" *ngFor="let image of images">
              <div class="card">
                <a class="light-link" href="{{url+image.image}}" target="_blank">
                  <img class="img-fluid" src="{{url+image.image}}" alt="">
                </a>
              </div>
            </div>
          </div>
          <!-- <mat-grid-list cols="2" rowHeight="1:1" gutterSize="24px">
            <mat-grid-tile class="imageRec" *ngFor="let image of images">
              <div>
                <a class="light-link" href="{{url+image.image}}" target="_blank">
                  <img class="img-fluid" src="{{url+image.image}}" alt="" >
                </a>
              </div>
            </mat-grid-tile>
          </mat-grid-list> -->
        </div>

      </div>
    </div>
  </div>
  <div [ngClass.xs]="(isCollapsed&&isCollapsed1&&isCollapsed2&&isCollapsed3)?'mt-20':'mt-8'" [ngClass.lg]="'mt-0'"><app-comunication-banner></app-comunication-banner></div>

</div>
