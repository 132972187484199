import { Component, ElementRef, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { formatDate } from "@angular/common";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { jsPDF } from 'jspdf';
import moment from "moment";
import domtoimage from 'dom-to-image';

import { UtilsService } from "../services/utils.service";
import { OthersService } from "../services/others.service";
import { UserService } from "../services/user.service";
import { AssetsService } from "../services/assets.service";
import { AuctionsService } from "../services/auctions.service";

import { environment } from "../../environments/environment";
import { BaseResponse } from "../models/base-response.model";
import { Auction, AuctionListResponse } from '../models/auction';
import { Bid } from "../models/bid";
import { User } from '../models/users';

import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-user-auctions',
  templateUrl: './user-auctions.component.html',
  styleUrls: ['./user-auctions.component.scss']
})
export class UserAuctionsComponent implements OnInit, OnDestroy {
  auctions: Auction[] = []
  auctionsMaster: Auction[] = []
  processType: any[] = []
  timeArray: any[] = []

  statusAuction: any[] = []
  restTime: any[] = []
  modalRef: BsModalRef;
  bids: Bid[]
  user: User
  customSelected: string;
  AuctionCondition: string = ""
  public dropdownprocessTypeSettings: IDropdownSettings = {}
  @ViewChild('bidListModal') bidListModal: TemplateRef<any>
  @ViewChild('userBidListModal') userBidListModal: TemplateRef<any>
  @ViewChild('auctionConditionsModal') auctionConditionsModal: TemplateRef<any>
  @ViewChild('successModal') successModal: TemplateRef<any>
  @ViewChild('incidenceModal') incidenceModal: TemplateRef<any>
  @ViewChild('template') template: TemplateRef<any>
  @ViewChild('printarea') printarea: ElementRef;

  processArray: any[] = [];
  dataHTML = null
  collapsed: any = false;
  url = environment.url
  statusArray: any[] = [];
  incidenceForm: FormGroup;
  minimun = 0;
  linkProduct: any = "";
  private incidence = {};
  private incidenceAuction: Auction;
  submitting: boolean = false;
  private search: any = "";
  reloadAuctions: any = "";

  auctionSelected: Auction = null;

  constructor(
    public assetsService: AssetsService,
    public utilsService: UtilsService,
    private otherService: OthersService,
    private userService: UserService,
    private auctionsService: AuctionsService,
    private modalService: BsModalService,
    public router: Router
  ) {
  }

  ngOnInit(): void {

    this.dropdownprocessTypeSettings = {
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "id",
      "textField": "name",
      "enableCheckAll": false
    }
    this.processType = [
      { id: 1, name: 'Subasta' },
      { id: 2, name: 'Venta Directa' },
    ]
    this.statusAuction = [
      { id: 0, name: 'Finalizado' },
      { id: 1, name: 'En Curso' },
    ]
    this.restTime = [
      { id: 0, name: 'Acaban en 24h.' },
      { id: 1, name: 'Acaban en una semana.' },
    ]
    if (localStorage.USER) {
      this.user = JSON.parse(localStorage.USER)
    }
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.reloadAuctions);
  }

  private reload() {

    this.assetsService.getMyAuctions()
    .subscribe((response: BaseResponse<AuctionListResponse>) => {

      this.auctions = response.response.auctions_list;
      console.log('get-auctions', this.auctions);
      this.auctionsMaster = response.response.auctions_list;

      this.auctions.forEach(it => {
        it.price_bid = (it.last_bid ? (Number.parseFloat(it.last_bid.import.toString()) + it.bid_price_interval) : it.price);
        it.minimun = it.price_bid;
        it.linkProduct = "/producto/" + it.id;
        setInterval(() => {
          it.countdown = this.countDownAuction(it.end_date);
        }, 1000);
      });
    });

    clearInterval(this.reloadAuctions);

    this.reloadAuctions = setInterval(() => {
      this.reloadDataAuctions();
    }, 3000);
  }

  reloadDataAuctions() {

    this.assetsService.getReloadMyAuctions()
    .subscribe((response: BaseResponse<any>) => {
      let auctions = response.response.auctions_list;

      this.auctions.forEach(it => {

        let new_data = auctions.find(atn => atn.auction.id == it.id);

        if (new_data) {
          let new_atn = new_data.auction;
          let new_bid = new_data.bid;

          it.end_date = new_atn.end_date;
          let conditions = (!it.last_bid && new_bid)
            || (it.last_bid && new_bid &&
                Number.parseFloat(it.last_bid.import.toString()) != Number.parseFloat(new_bid.import.toString())
            )
            || (it.last_bid && new_bid &&
                it.last_bid.user_id != new_bid.user_id
            )
            || (it.minimun === 1 && it.last_bid && Number.parseFloat(it.last_bid.import.toString()) < (1 + it.bid_price_interval))
            || (it.minimun !== 1 && it.last_bid && Number.parseFloat(it.last_bid.import.toString()) < it.minimun);

          if (conditions) {
            it.last_bid = new_bid;
            it.best_bid = new_data.best_bid;
            if (it.best_bid) {
              it.my_last_bid = new_bid;
            }
            const lastBid = document.getElementById('lastBid' + it.id.toString()) as HTMLInputElement;
            if (lastBid.value !== '') {
              it.price_bid = lastBid.value;
            } else {
              it.price_bid = Number.parseFloat(it.last_bid.import.toString()) + it.bid_price_interval;
            }
            it.minimun = (Number.parseFloat(it.last_bid.import.toString()) + it.bid_price_interval);
          }
        }
      });
    });
  }

  soonDate(date) {
    let soonDate = new Date(date).getTime()
    let now = new Date().getTime();
    if (soonDate > now) {
      return true
    } else {
      return false
    }
  }

  openbidListModal(id) {
    this.bids = []
    this.auctionsService.getBidsFromAuction(id).subscribe(
      val => {
        this.bids = val['response']["bid_list"]
      }
    )
    this.modalRef = this.modalService.show(this.bidListModal, Object.assign({}, { class: 'gray modal-lg' }));
  }

  openUserBidListModal(id) {
    this.bids = []
    this.auctionsService.getBidsFromUser(id).subscribe(
      val => {
        this.bids = val['response']["bid_list"]
      }
    )
    this.modalRef = this.modalService.show(this.bidListModal, Object.assign({}, { class: 'gray modal-lg' }));
  }

  openAuctionConditionModal(a: Auction) {
    this.AuctionCondition = a.conditions
    this.modalRef = this.modalService.show(this.auctionConditionsModal, Object.assign({}, { class: 'gray modal-lg' }));
  }

  filterbyText(event) {
    this.search = event.target.value;
    this.filteredArray()
  }



  onProcessTypeSelect(event) {
    this.processArray.push(event.id)
    this.filteredArray()
  }

  onProcessTypeDeselect(event) {
    this.processArray = this.processArray.filter(i => i !== event.id)
    this.filteredArray()
  }

  onStatusSelect(event) {
    this.statusArray.push(event.id)
    this.filteredArray()
  }

  onStatusDeselect(event) {
    this.statusArray = this.statusArray.filter(i => i !== event.id)
    this.filteredArray()
  }

  onRestTimeSelect(event) {
    this.timeArray.push(event.id)
    this.filteredArray()
  }

  onRestTimeDeselect(event) {
    this.timeArray = this.timeArray.filter(i => i !== event.id)
    this.filteredArray()

  }

  filteredArray() {
    this.auctions = Object.assign([], this.auctionsMaster);
    if (this.processArray.length != 0) {
      this.auctions = this.auctionsMaster.filter(a => this.processArray.includes(a.auction_type_id))
    } else {
      this.auctions = this.auctionsMaster;
    }

    if (this.statusArray.length != 0) {
      this.auctions = this.auctions.filter(a => {
        let show = false;

        // Con moment hace cosas raras como mostrar subastas en curso que acaban el mismo dia pero hemos seleccionado solo Finalizadas
        // if (this.statusArray.includes(0) && moment(a.end_date, "YYYY-MM-DD") < moment()) {
        //   show = true
        // }
        // if (this.statusArray.includes(1) && moment(a.end_date, "YYYY-MM-DD") > moment()) {
        //   show = true
        // }

        // Lo basaremos en el status, cualquier status distinto de Initiated sera finalizado (Win o End)
        if (this.statusArray.includes(0) && (a.status != 'Initiated')) {
          show = true
        }
        if (this.statusArray.includes(1) && (a.status == 'Initiated')) {
          show = true
        }
        return show
      }
      )
    }
    if (this.timeArray.length != 0)
      this.auctions = this.auctions.filter(a => {
        var show = false
        this.timeArray.forEach(s => {
          switch (s) {
            case 0:
              if ((moment(a.end_date, "YYYY-MM-DD") < moment().add(1, "days")) && (moment(a.end_date, "YYYY-MM-DD") > moment()))
                show = true
              break
            case 1:
              if ((moment(a.end_date, "YYYY-MM-DD") < moment().add(7, "days")) && (moment(a.end_date, "YYYY-MM-DD") > moment()))
                show = true
              break

          }
        })
        return show
      }

      )
    if (this.search != "")
      this.auctions = this.auctions.filter(a => a.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1)
    /*if(this.provincesArray.length != 0){
      this.assets = this.assets.filter(a=> this.provincesArray.includes(a.ProductProvince))*/
  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  downloadPDF() {
    const div = document.getElementById('printarea');
    const options = { background: 'white', height: 845, width: 860 };
    domtoimage.toPng(div, options).then((dataUrl) => {
      //Initialize JSPDF
      const doc = new jsPDF('p', 'mm', 'a4');
      //Add image Url to PDF
      doc.addImage(dataUrl, 'PNG', 10, 10, 210, 340);
      doc.save('pdfDocument.pdf');
    })
  }

  getLastBid(a: Auction) {
    return a.last_bid ? a.last_bid.import : 0;
  }
  getMyLastBid(a: Auction) {
    return a.my_last_bid ? a.my_last_bid.import : 0;
  }

  countDownAuction(dateString) {
    // Convertir la cadena de fecha a un objeto Date
    const date = new Date(dateString);

    // Verificar si la conversión fue exitosa
    if (isNaN(date.getTime())) {
        return 'Fecha inválida';
    }

    // Obtener la fecha y hora actual en la zona horaria de Madrid
    const currentDateTimeZone = utcToZonedTime(new Date(), 'Europe/Madrid');

    // Calcular la diferencia de tiempo en milisegundos
    const difference = date.getTime() - currentDateTimeZone.getTime();

    // Calcular días, horas, minutos y segundos restantes
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);

    if (difference > 0) {
        // Subasta aún en curso, mostrar tiempo restante
        return `${days} días y ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
        // Subasta finalizada
        return 'Subasta finalizada';
    }
  }

  increasePrice(a: Auction) {
    a.price_bid += a.bid_price_interval;
  }

  decreasePrice(a: Auction) {
    if (a.price_bid > (a.last_bid ? a.last_bid.import : a.price) + a.bid_price_interval)
      a.price_bid -= a.bid_price_interval;
  }

  openTemplate(a: Auction) {
    this.auctionSelected = a;
    this.modalRef = this.modalService.show(this.template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  makeBid(a: Auction) {
    this.modalRef.hide();
    if ((a.price_bid < a.minimun) || (a.price_bid < a.bid_price_interval)){
      this.utilsService.showToast("Importe de puja es menor que el mínimo.", "error");
      return;
    } else if (a.price_bid > 999999999.99) {
      this.utilsService.showToast("Importe de puja demasiado alto.", "error");
      return;
    }

    //this.modalRef = this.modalService.show(this.successModal, Object.assign({}, {class: 'gray modal-lg modal-success'}));

    if (a.user_auction_status == 'Valid') {
      this.assetsService.makeBid(a.price_bid, a.id).subscribe((it: BaseResponse<any>) => {
        if (it.status == 201) {
          this.modalRef = this.modalService.show(this.successModal, Object.assign({}, {class: 'gray modal-lg modal-success'}));
        }
        if (it.status == 418) {
          this.utilsService.showToast("La subasta ya ha terminado.", "error");
        }
        if (it.status == 419) {
          this.utilsService.showToast("Precio invalido, alguien pujó mas alto.", "error");
        }
      }, null, () => {
        this.reload();
      })
    }
  }

  getTextBid(a: Auction) {
    return "Pujaste el " + formatDate(a.last_bid.created_at, 'd MMM,', 'es-ES') + " a las " + formatDate(a.last_bid.created_at, ' h:mm:ss', 'es-ES')
  }
  getTextLose(a: Auction) {
    return "Puja supera el " + formatDate(a.last_bid.created_at, 'd MMM,', 'es-ES') + " a las " + formatDate(a.last_bid.created_at, ' h:mm:ss', 'es-ES')
  }
  getTextColor(a: Auction) {
    // return a.best_bid ? "var(--secondary);" : "var(--dark);"
    return a.best_bid ? "var(--green);" : "var(--secondary);"
  }

  sendIncidenceModal(a: Auction) {
    this.incidenceAuction = a;
    this.incidenceForm = new FormGroup({
      text: new FormControl(null, [Validators.required]),
    });
    this.modalRef = this.modalService.show(this.incidenceModal, Object.assign({}, { class: 'gray modal-lg modal-success' }));

  }

  sendIncidence() {

    if (this.submitting) { return; }
    this.submitting = true;

    this.otherService.formToObject(this.incidenceForm, this.incidence)
    this.auctionsService.sendIncidence(this.incidence, this.incidenceAuction.id)
      .subscribe(it => {
        this.modalRef.hide();
        this.submitting = false;
      }, error => {
        this.submitting = false;
      })
  }

  getUrl(path: string) {
    return environment.url + path;
  }

  goAuction(e, id: any) {
    e.stopPropagation();
    this.router.navigate(['producto', id]);
    return false;
  }

}
