import { Component, Input, OnInit } from '@angular/core';
import { OthersService } from "../services/others.service";
import { User } from "../models/users";
import { environment } from "../../environments/environment";
import { Auction } from "../models/auction";
import { AssetsService } from "../services/assets.service";
import { BaseResponse } from "../models/base-response.model";
import { Router } from "@angular/router";
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-product-item-row',
  templateUrl: './product-item-row.component.html',
  styleUrls: ['./product-item-row.component.scss']
})
export class ProductItemRowComponent implements OnInit {
  @Input() auction: Auction;
  public user: User
  public favorites: any[] = []
  public favs: any[] = []
  public countdown: string = '';
  public percentage: number;
  public days: any = ""
  public hours: any = ""
  public minutes: any = ""
  public seconds: any = ""
  public test: any = "";
  public completed: any = "";

  public enlaceProducto:any = "";

  constructor(
    public otherServices: OthersService,
    private assetsService: AssetsService,
    private router: Router
  ) {

    if (localStorage.USER) {
      this.user = JSON.parse(localStorage.USER)
    }
  }

  soonDate(date) {
    let soonDate = new Date(date).getTime()
    let now = new Date().getTime();
    if (soonDate > now) {
      return true
    } else {
      return false
    }
  }

  ngOnInit(): void {

    /* setInterval(() => {
      this.countdown = this.otherServices.timeDiffString(this.auction.end_date);
    }, 1000); */

    var dateNow = new Date();
    var dateStart = new Date(this.auction.start_date);
    var dateEnd = new Date(this.auction.end_date);
    var countdown_to_start = dateStart.getTime();
    var countdown_to_end = dateEnd.getTime();

    setInterval(() => {
      // console.log("product-item-row > ngOnInit > Recargando")
      // var now = new Date().getTime();

      let dateNow = new Date();
      let timezoneOffsetLaravel = environment.timezone // 120 minutos es el offset respecto a EuropaMadrid que es lo configurado en Laravel en config/app.php
      let timezoneOffsetLocal = dateNow.getTimezoneOffset() // En la peninsula excepto canarias sera -120 minutos (UTC +2:00), en Canarias -60 (UTC +1:00)
      let diffHorariaEnMinutos = timezoneOffsetLocal + timezoneOffsetLaravel
      dateNow.setMinutes(dateNow.getMinutes() + diffHorariaEnMinutos)
      const madridTimezone = 'Europe/Madrid';
      const nowInMadrid = utcToZonedTime(new Date(), madridTimezone);
      const now = nowInMadrid.getTime();
      //var now = dateNow.getTime();
      
      var distance = countdown_to_start - now;

      if (distance <= 0) {
        distance = countdown_to_end - now;
      }

      if (distance < 0) {
        this.completed = 'Subasta finalizada';
        return;
      }

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.hours = this.hours < 10 ? "0" + this.hours : this.hours
      this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes
      this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds
    }, 1000)

    var start: any = new Date(this.auction.start_date),
      end: any = new Date(this.auction.end_date),
      today: any = new Date();

    //use Math.abs to avoid sign
    var q = Math.abs(today - start);
    var d = Math.abs(end - start);
    this.percentage = Math.round((q / d) * 100);

    if (this.auction.auctions)
      this.enlaceProducto = '/pack/'+ this.auction.id;
    else
      this.enlaceProducto = '/producto/'+ this.auction.id;
    
  }


  toogleFavorite(auction1: Auction) {

    this.assetsService.setFavorite(auction1.id)
      .subscribe((it: BaseResponse<any>) => {
        if (it.status == 200) {
          if (auction1.fav) { auction1.fav = null; }
          else { auction1.fav = it.response[0]; }
        }
      });
  }

  getImage() {
    if (this.auction.archives && this.auction.archives.length > 0) {
      if (this.auction.archives[0].path.toLowerCase().endsWith('.pdf')) {
        for (const archive of this.auction.archives) {
          const lowerCasePath = archive.path.toLowerCase();
          if (lowerCasePath.match(/\.(jpeg|jpg|gif|png)$/i)) {
            return environment.url + archive.path;
          }
        }
      }else {
        return environment.url + this.auction.archives[0].path;
      }
    }
    return '';
  }

  goToProduct() {
    //debugger
    if (this.auction.auctions)
      this.router.navigate(['pack', this.auction.id]);
    else
      this.router.navigate(['producto', this.auction.id]);

  }
}
