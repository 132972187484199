import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { CompleteDataComponent } from './complete-data/complete-data.component';
import { ConfirmUserComponent } from './confirm-user/confirm-user.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { HomeComponent } from './home/home.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ProductListPageComponent } from './modules/product-list-page/product-list-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ReviewMailComponent } from './review-mail/review-mail.component';
import { UserAlertsComponent } from './user-alerts/user-alerts.component';
import { UserAuctionsComponent } from './user-auctions/user-auctions.component';
import { UserFavoritesComponent } from './user-favorites/user-favorites.component';
import { UserPageComponent } from './user-page/user-page.component';
import {AboutUsPageComponent} from "./about-us-page/about-us-page.component";
import {TeamPageComponent} from "./team-page/team-page.component";
import {ComunicationPageComponent} from "./comunication-page/comunication-page.component";
import {GalleryPageComponent} from "./gallery-page/gallery-page.component";
import {NotePressPageComponent} from "./note-press-page/note-press-page.component";
import {DossierPageComponent} from "./dossier-page/dossier-page.component";
import {MyAuctionsComponent} from "./my-auctions/my-auctions.component";
import {AuctionAccessComponent} from "./auction-access/auction-access.component";
import {RecoverPasswordComponent} from "./recover-password/recover-password.component";
import {InvalidTokenComponent} from "./invalid-token/invalid-token.component";
import {PackPageComponent} from "./pack-page/pack-page.component";
import {LegalComponent} from "./legal/legal.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: 'Trademat - Activo' }
  },

  {
    path: 'producto/:id',
    component: ProductPageComponent,
    data: { title: 'Trademat - Activo' },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'legal',
    component: LegalComponent,
    data: { title: 'Trademat - Aviso Legal' }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Trademat - Política de privacidad' }
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: { title: 'Trademat - Política de Cookies' }
  },
  {
    path: 'pack/:id',
    component: PackPageComponent,
    data: { title: 'Trademat - Pack' }
  },

  {
    path: 'registrar',
    component: RegisterPageComponent,
    data: { title: 'Trademat - Registrar Usuario' }
  },

  {
    path: 'registrar-sin-certificado',
    component: RegisterFormComponent,
    data: { title: 'Trademat - Registrar Usuario' }
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    data: { title: 'Trademat - Recuperar contraseña' }
  },
  {
    path: 'invalid-token',
    component: InvalidTokenComponent,
    data: { title: 'Trademat - Recuperar contraseña - Token inválido' }
  },
  {
    path: 'listado-activos',
    component: ProductListPageComponent,
    data: { title: 'Trademat - Listado de Activos' }
  },

  {
    path: 'auction-access',
    component: AuctionAccessComponent,
    data: { title: 'Trademat - Solicitud de acceso' }
  },
  {
    path:'login',
    component:LoginPageComponent,
    data: { title: 'Trademat - Login' }
  },
  {
    path:'mis-datos',
    component:UserPageComponent,
    data: { title: 'Trademat - Mis datos' }
  },
  {
    path:'my-auctions',
    component:MyAuctionsComponent,
    data: { title: 'Trademat - Mis subastas' }
  },
  {
    path:'completar-datos',
    component:CompleteDataComponent,
    data: { title: 'Trademat - Completar mis datos' }
  },
  {
    path:'historial-subastas',
    component:UserAuctionsComponent,
    data: { title: 'Trademat - Mis datos' }
  },
  {
    path:'mis-favoritos',
    component:UserFavoritesComponent,
    data: { title: 'Trademat - Mis datos' }
  },
  {
    path:'mis-alertas',
    component:UserAlertsComponent,
    data: { title: 'Trademat - Mis datos' }
  },
  {
    path:'perfil-cliente',
    component:ClientProfileComponent,
    data: { title: 'Trademat - Perfiles de clientes' }
  },
  {
    path:'faq',
    component:FaqPageComponent,
    data: { title: 'Trademat - Preguntas Frecuentes' }
  },  {
    path:'about-us',
    component:AboutUsPageComponent,
    data: { title: 'Trademat - Sobre Nosotros' }
  },  {
    path:'team',
    component:TeamPageComponent,
    data: { title: 'Trademat - ¿Quienes somos?' }
  }, {
    path:'comunication',
    component:ComunicationPageComponent,
    data: { title: 'Trademat - Comunicación' }
  }, {
    path:'gallery',
    component:GalleryPageComponent,
    data: { title: 'Trademat - Galería' }
  }, {
    path:'note-press',
    component:NotePressPageComponent,
    data: { title: 'Trademat - Notas de prensa' }
  }, {
    path:'dossier',
    component:DossierPageComponent,
    data: { title: 'Trademat - Dossier' }
  },
  {
    path:'contact',
    component:ContactPageComponent,
    data: { title: 'Trademat - Preguntas Frecuentes' }
  },
  {
    path:'usuario-no-confirmado',
    component:ConfirmUserComponent,
    data: { title: 'Trademat - Perfil de usuario', confirmed:false}
  },
  {
    path:'usuario-confirmado',
    component:ConfirmUserComponent,
    data: { title: 'Trademat - Perfil de usuario', confirmed:true }
  },
  {
    path:'revisar-correo',
    component:ReviewMailComponent,
    data: { title: 'Trademat - revisa tu email' }
  },
  {
    path:'verificar-cuenta/:email/:token',
    component:VerifyPageComponent,
    data: { title: 'Trademat - Cuenta verificada' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'enabled',
        scrollOffset: [0, 0],
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
