import {Component, OnInit} from '@angular/core';
import {AssetsService} from "../services/assets.service";

@Component({
  selector: 'app-team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss']
})
export class TeamPageComponent implements OnInit {
  slider: any[];
  isCollapsed: any = true;
  isCollapsed2: any = true;

  constructor(public assetServide: AssetsService) {
    this.slider = this.slideGenerator(["Equipo-Trademat-01", "Trademat-varias-16", "Trademat-varias-20"], window.innerWidth <= 768)
  }

  slideGenerator(assets, responsive) {
    var slidesIndex = 0
    var slidesElement = 0
    var slides = []
    slides[0] = []
    var elements=responsive?0:2;
    for (var i = 0; i < assets.length; i++) {
      if (slidesElement <= elements) {
        slides[slidesIndex][slidesElement] = assets[i];
        slidesElement++
      } else {
        slidesIndex++
        slidesElement = 0
        slides[slidesIndex] = []
        slides[slidesIndex][slidesElement] = assets[i];

      }

    }
    return slides
  }

  ngOnInit(): void {
  }

}
