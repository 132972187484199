import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public current_lang: any = 'es';

  public hour:any
  public minutes:any
  public seconds:any

  constructor(
    private utilsService: UtilsService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (localStorage.LANG) {
       this.utilsService.lang.next(localStorage.LANG);
    } else {
      localStorage.LANG = this.utilsService.lang.getValue();
    }

    this.current_lang = this.utilsService.lang.getValue();
    this.translateService.use(this.current_lang);

    setInterval(() => {
      var clock = new Date();
      this.hour = clock.getHours();
      this.minutes = clock.getMinutes();
      this.seconds = clock.getSeconds();

      this.minutes = this.minutes < 10 ? "0"+this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? "0"+this.seconds : this.seconds;
    },1000)
  }

  changeLang(lang) {
    this.current_lang = localStorage.LANG = lang;
    this.utilsService.lang.next(this.current_lang);
    this.translateService.use(this.current_lang);
  }
}
