import {Injectable} from '@angular/core';
import {Auction} from "../models/auction";
import {AssetsService} from "./assets.service";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  set selectedAuction(value: Auction) {
    this._selectedAuction = value;
  }
  private _selectedAuction: Auction = null;

  constructor(assetsService: AssetsService) {
  }

  get selectedAuction(): Auction {
    if (!this._selectedAuction){

    }
    return this._selectedAuction;
  }

}
