<div class="banner row">
  <div class="section-gallery">
    <div class="row align-items-start  no-gutters">
      <div class="col-md-auto">
        <div class="row  no-gutters">
          <div class="breadcrumb-gallery">
            <span class="link-bread" [routerLink]="['/']">{{ 'dossier-page-title' | translate }}</span>   /  <span class="link-bread" [routerLink]="['/comunication']">{{ 'dossier-page-title3' | translate }}</span>   /  <span class="select-bread">{{ 'note-press-text1' | translate }}</span>
          </div>
        </div>
        <div class="row  no-gutters">
          <div class="title">
            {{'note-press-text1'|translate}}
          </div>
        </div>
        <div class="row  no-gutters">
          <div class="section-gallery-separator">
          </div>
        </div>
      </div>
    </div>
      <mat-grid-list [cols]="getCols()" [rowHeight]="getHeight()">
        <mat-grid-tile *ngFor="let note of notePres_list">
          <app-note-press-item style="width: 95%" [note]="note"></app-note-press-item>
        </mat-grid-tile>

      </mat-grid-list>
    <div class="separator"></div>

    <div class="row justify-content-center pagination-section">

      <app-pagination [totalRecords]="total" recordsPerPage="10" (onPageChange)="displayActivePage($event)"></app-pagination>

    </div>
  </div>

</div>
<app-comunication-banner></app-comunication-banner>

