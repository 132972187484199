<div *ngIf="pack">
  <h5 class="packTitle">
    {{'auction-text21'|translate}}
  </h5>
  <div *ngFor="let auction of pack.auctions" (click)="goToAuction(auction)">
    <div class="row">
      <div class="col-lg-4 col-sm-4">
        <img class="photoPack"
             src="{{getImageUrl(auction.archives[0]?.path)}}"
             alt="">
      </div>
      <div class="col-lg-8 col-sm-8">
        {{auction.name}}
        <br>
        <img src="assets/images/location_pink.png"
             srcset="assets/images/location_pink@2x.png 2x,
                            assets/images/location_pink@3x.png 3x"
             class="miniIcon">
        <label class="minicity">{{auction.city}}, {{auction.province}}</label><br>
        <label class="minicity dark"> {{auction.type}} · {{auction.area}}m<sup>2</sup> · {{auction.bedrooms}}
          habitaciones</label>
      </div>
    </div>
    <hr>
  </div>
</div>
