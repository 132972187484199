import { Component, OnInit } from '@angular/core';
import { AssetsService } from 'src/app/services/assets.service';
import {AssetType} from "../../models/assets";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-near-offers',
  templateUrl: './near-offers.component.html',
  styleUrls: ['./near-offers.component.scss']
})
export class NearOffersComponent implements OnInit {

  public assetsCities=null
  public topSixCities=null
  public citiesColum1=null
  public citiesColum2=null
  public citiesColum3=null

  constructor(public assetsService:AssetsService) {
    this.assetsService.getNumberOfAssetsOnCities().subscribe(
      val=>{
        this.assetsCities = val['response']["asset_list"];
        console.log("res",val['response']["asset_list"]);
        this.topSixCities=this.assetsCities.slice(0,6);
        this.citiesColum1=this.assetsCities.slice(6,9);
        this.citiesColum2=this.assetsCities.slice(10,13);
        this.citiesColum3=this.assetsCities.slice(14,17);

      }
    )
  }

  ngOnInit(): void {
  }

  getUrl(city: any) {
      return environment.url + city.path

  }

  goProvince(province_id:any){
    location.href="/listado-activos?province="+province_id;
  }

}
