import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { BaseResponse } from '../models/base-response.model';

import { Asset } from '../models/assets';
import { Archive } from '../models/Archive';
import { Auction, AuctionResponse } from '../models/auction';

import { UtilsService } from '../services/utils.service';
import { AssetsService } from '../services/assets.service';
import { NotificationService } from '../services/notification.service';

@Component({
	selector: 'app-product-page',
	templateUrl: './product-page.component.html',
	styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit, OnDestroy {
	public assetID: any;
	public auction: Auction;
	public gallery: Archive[];
	public images_gallery: any[] = [];
	public images_gallery_mobile: any[] = [];
	public assetDocuments: Archive[] = [];
	public assetCharges: Archive[] = [];
	public AssetPosition: any = '';
	public MapIcon: google.maps.Icon = { url: 'assets/images/ficha.png' };
	public MarkerOptions: google.maps.MarkerOptions = { icon: this.MapIcon };
	public isPack = false;
	public userExist = false;
	paused = false;
	unpauseOnArrow = false;
	pauseOnIndicator = false;
	pauseOnHover = true;
	pauseOnFocus = true;
	@ViewChild('auctionConditionsModal') auctionConditionsModal: TemplateRef<any>;
	@ViewChild('shareModal') shareModal: TemplateRef<any>;
	modalRef: BsModalRef;
	AuctionCondition: any;
	@ViewChild('carousel', { static: true }) carousel: NgbCarousel;
	selected = 0;
	@ViewChild('imageModal') imageModal: TemplateRef<any>;
	public image_url = '';

	// Used for page reloading
	navigationSubscription;

	constructor(
		private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router,
		public assetService: AssetsService,
		private modalService: BsModalService,
		private notificationService: NotificationService,
		public utils: UtilsService,
		private translateService: TranslateService,
	) {
		this.navigationSubscription = this.router.events
			.subscribe((e: any) => {
				if (e instanceof NavigationEnd) {
					this.assetID = this.route.snapshot.paramMap.get('id');
					this.checkRoute();
					this.loadAuction();
				}
			});

		this.assetID = this.route.snapshot.paramMap.get('id');
	}

	toogleFavorite() {
		this.assetService.setFavorite(this.auction.id)
			.subscribe((it: BaseResponse<any>) => {
				if (this.auction.fav) {
					this.auction.fav = null;
					this.utils.showToast(this.translateService.instant('unfaved'));
				} else {
					this.auction.fav = it.response[0];
					this.utils.showToast(this.translateService.instant('faved'));
				}
			});
	}

	ngOnInit(): void {
		this.checkRoute();

		if (localStorage.USER != null || localStorage.USER != undefined) {
			this.userExist = true;
		}

		this.loadAuction();
	}

	loadAuction() {

		this.assetService.getAuction(this.assetID).subscribe(
			(val: BaseResponse<AuctionResponse>) => {
				this.auction = val.response.auction;
				if (!this.isPack) {
					this.assetDocuments = this.auction.archives.filter(it => it.archive_type_id == 3);
					this.assetCharges = this.auction.archives.filter(it => it.archive_type_id == 4);
					this.AssetPosition = { lat: this.auction.latitude, lng: this.auction.longitude };
					this.gallery = this.auction.archives.filter(it2 => it2.archive_type_id == 2);
				} else {
					const documents = [];
					const charges = [];
					const images = [];
					/*   this.assets.forEach(it => {
							 documents = documents.concat(it.archives.filter(it2 => it2.archive_type_id == 3));
							 charges = charges.concat(it.archives.filter(it2 => it2.archive_type_id == 4));
							 images = images.concat(it.archives.filter(it2 => it2.archive_type_id == 2));
						 })*/
					this.assetDocuments = documents;
					this.assetCharges = charges;
					this.gallery = images;
				}
				this.images_gallery = this.slideGenerator(this.gallery.map(n => environment.url + n.path));
				this.images_gallery_mobile = this.gallery.map(n => [environment.url + n.path]);
			}
		);
	}

	slideGenerator(auctions: any[]) {
		let slidesIndex = 0;
		let slidesElement = 0;
		const slides = [];
		slides[0] = [];
		for (let i = 0; i < auctions.length; i++) {

			if (slidesElement <= 2) {
				slides[slidesIndex][slidesElement] = auctions[i];
				slidesElement++;

			} else {

				slidesIndex++;
				slidesElement = 0;
				slides[slidesIndex] = [];
				slides[slidesIndex][slidesElement] = auctions[i];

			}
			/*  if(auctions[i].assets[0]){
					assets[i].images = assets[i].images[0].AssetsImages_Path+assets[i].images[0].AssetsImages_Name
				}*/
		}
		return slides;
	}
	togglePaused() {
		if (this.paused) {
			this.carousel.cycle();
		} else {
			this.carousel.pause();
		}
		this.paused = !this.paused;
	}

	onSlide(slideEvent: NgbSlideEvent) {
		if (this.unpauseOnArrow && slideEvent.paused &&
			(slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
			this.togglePaused();
		}
		if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
			this.togglePaused();
		}
	}

	openAuctionConditionModal(a: Auction) {
		this.AuctionCondition = a.conditions;
		this.modalRef = this.modalService.show(this.auctionConditionsModal, Object.assign({}, { class: 'gray modal-lg' }));
	}

	checkRoute() {
		this.route.pathFromRoot[1].url.subscribe(
			val => {
				if (val[0].path == 'pack') {
					this.isPack = true;
					return this.isPack;
				}
			}
		);
	}

	getUrl(path: string) {
		return environment.url + path;
	}

	openShareModal() {
		this.modalRef = this.modalService.show(this.shareModal, Object.assign({}, { class: 'gray modal-lg' }));
	}

	getUrlShare() {
		return this.router.url;
	}

	openImage(path: string) {

		this.image_url = path;
		this.modalRef = this.modalService.show(this.imageModal, Object.assign({}, { class: 'gray modal-xl' }));
	}

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
}
