<div style="padding: 48px 11%;">
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
    <strong><u><span style="font-size:19px;line-height:107%;">{{'pp-1' | translate}}</span></u></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-2' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-3' | translate}}</span></strong>
  </p>
  <ul style="list-style-type: undefined;">
    <li><strong><span style="line-height:107%;font-size:16px;">{{'pp-4' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">:</span> <span
      style="line-height:107%;font-size:16px;">{{'pp-5' | translate}}</span></li>
    <li><strong><span style="line-height:107%;font-size:16px;">{{'pp-6' | translate}}</span></strong><span
        style="line-height:107%;font-size:16px;">:</span> <span
        style="line-height:107%;font-size:16px;">B09484346</span></li>
      <li><strong><span style="line-height:107%;font-size:16px;">{{'pp-7' | translate}}</span></strong><span
          style="line-height:107%;font-size:16px;">:</span> <span
          style="line-height:107%;font-size:16px;">C/ Merindad de Montija 18, Nave 11C, Polígono Industrial
          Villalonquéjar, 09001, Burgos.</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-10' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: (+34) 947 04 02 04</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-11' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: hola@trademat.io</span></li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-12' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">
      TRADEMAT AUCTION S.L.U., en adelante TRADEMAT</span><span
    style="font-size:16px;line-height:107%;">{{'pp-14' | translate}}</span><span
    style="font-size:16px;line-height:107%;">{{'pp-15' | translate}}</span>
  </p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ul style="margin-bottom:0cm;list-style-type: disc;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-16' | translate}}</span></strong><span
        style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-17' | translate}}</span><a
        href="http://www.subastastrademat.com"><span
        style="line-height:106%;font-size:16px;">www.subastastrademat.com</span></a><span
        style="line-height:106%;">{{'pp-18' | translate}}</span>
      </li>
    </ul>
  </div>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:8px;line-height:107%;">&nbsp;</span></p>
  <div
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <ul style="margin-bottom:0cm;list-style-type: disc;">
      <li
        style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-19' | translate}}
          </span></strong><span style="line-height:107%;font-size:16px;">{{'pp-20' | translate}}</span>
      </li>
    </ul>
  </div>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <span style="font-size:8px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <span style="font-size:8px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-21' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-22' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-23' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-24' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-25' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-26' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-27' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">TRADEMAT</span><span
    style="font-size:16px;line-height:107%;">&nbsp;{{'pp-28' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <strong><span style="font-size:16px;line-height:107%;">{{'pp-29' | translate}}</span></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-30' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-31' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-32' | translate}}</span><span
    style="font-size:16px;line-height:107%;">hola@trademat.io.</span><span
    style="font-size:16px;line-height:107%;">{{'pp-33' | translate}}&nbsp;</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-34' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">{{'pp-35' | translate}}(</span><a
    href="http://www.aepd.es"><span style="font-size:16px;line-height:107%;">www.aepd.es</span></a><span
    style="font-size:16px;line-height:107%;">).</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
    <strong><u><span style="font-size:19px;line-height:107%;">{{'pp-36' | translate}}</span></u></strong>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
    <strong><u><span style="font-size:16px;line-height:107%;"><span
      style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-37' | translate}}&nbsp;</span><span
    style="font-size:16px;">TRADEMAT AUCTION S.L.U.</span><span style="font-size:16px;">&nbsp;{{'pp-38' | translate}}<strong>SUBASTAS TRADEMAT</strong>{{'pp-39' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <ul style="list-style-type: undefined;">
    <li><strong><span style="line-height:107%;font-size:16px;">{{'pp-40' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">:</span> <span
      style="line-height:107%;font-size:16px;">TRADEMAT AUCTION S.L.U.</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-41' | translate}}
      </span></strong><span style="line-height:107%;font-size:16px;">: B09484346</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-42' | translate}}
      </span></strong><span style="line-height:107%;font-size:16px;">: C/ Merindad de Montija 18, Nave 11C, Pol&iacute;gono Industrial Villalonqu&eacute;jar, 09001, Burgos.&nbsp;</span>
    </li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-43' | translate}}</span></strong><span
      style="line-height:107%;font-size:16px;">: (+34) 947 04 02 04</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">{{'pp-44' | translate}}</span></strong><span
      style="line-height:;font-size:12.0pt;line-height:;;">: hola@trademat.io</span></li>
    <li><strong><span style="line-height:;font-size:12.0pt;line-height:;;">
      Web:&nbsp;</span></strong><span style="line-height:107%;font-size:16px;">
      www.subastastrademat.com</span></li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-45' | translate}}<strong>TRADEMAT</strong>{{'pp-46' | translate}}
     &nbsp;</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-47' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-48' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">TRADEMAT&nbsp;</span><span style="font-size:16px;">{{'pp-49' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-50' | translate}}</span><span
    style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">{{'pp-51' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <ul style="list-style-type: disc;">
    <li><span style="font-size:16px;">{{'pp-52' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-53' | translate}}</span><span
      style="font-size:;;">TRADEMAT</span><span style="font-size:16px;">{{'pp-54' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-55' | translate}}</span><span
      style="font-size:16px;">TRADEMAT</span><span style="font-size:;;">{{'pp-56' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-57' | translate}}&nbsp;</span><span
      style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">{{'pp-58' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-59' | translate}}</span><span
      style="font-size:;;">TRADEMAT</span><span style="font-size:16px;">{{'pp-60' | translate}}</span>
    </li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">{{'pp-61' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <ul style="list-style-type: disc;">
    <li><span style="font-size:16px;">{{'pp-62' | translate}}
      </span></li>
    <li><span style="font-size:16px;">{{'pp-63' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-64' | translate}}</span>
    </li>
    <li><span style="font-size:16px;">{{'pp-65' | translate}}</span>
    </li>
  </ul>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">{{'pp-66' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">&nbsp;</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-67' | translate}}
       <strong>TRADEMAT</strong>{{'pp-68' | translate}}&nbsp;</span><span
    style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">&nbsp;{{'pp-69' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">&nbsp;{{'pp-70' | translate}} <strong>TRADEMAT</strong>.</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-71' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-72' | translate}}</span><span
    style="font-size:16px;">TRADEMAT</span><span style="font-size:16px;">{{'pp-73' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">{{'pp-74' | translate}}</span></strong></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">TRADEMAT&nbsp;</span><span
    style="font-size:16px;">{{'pp-75' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-76' | translate}}<strong>TRADEMAT&nbsp;</strong>{{'pp-77' | translate}}
      </span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">{{'pp-78' | translate}}</span>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">Facebook</span></strong><span style="font-size:16px;">:&nbsp;</span><a
    href="https://www.facebook.com/privacy/explanation"><span style="font-size:16px;">https://www.facebook.com/privacy/explanation</span></a>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">Instagram</span></strong><span style="font-size:16px;">:&nbsp;</span><a
    href="https://www.facebook.com/help/instagram/519522125107875"><span style="font-size:16px;">https://www.facebook.com/help/instagram/519522125107875</span></a>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">Twitter</span></strong><span style="font-size:16px;">:&nbsp;</span><a
    href="https://twitter.com/es/privacy"><span style="font-size:16px;">https://twitter.com/es/privacy</span></a></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <strong><span style="font-size:16px;">LinkedIn</span></strong><span style="font-size:16px;">:&nbsp;</span><a
    href="https://es.linkedin.com/legal/privacy-policy"><span style="font-size:16px;">https://es.linkedin.com/legal/privacy-policy</span></a>
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;vertical-align:baseline;'>
    <span style="font-size:16px;">&nbsp;</span></p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    <span style="font-size:16px;line-height:107%;">&nbsp;</span></p>
</div>
