<div style="background-color:var(--grey-1);">
    <div class="row">
        <div class="col-12 col-lg-6 register" >
            <img src="assets/images/sin-texto.png"
                srcset="assets/images/sin-texto@2x.png 2x,
                        assets/images/sin-texto@3x.png 3x"
                class="SinTexto">
            <h1 class="title">{{'register-page-text1'|translate}}</h1>
          <p>{{'register-page-text2'|translate}} <a style=" color: var(--secondary)" [routerLink]="['/login']">{{'register-page-text3'|translate}}</a></p>
<!--            <div class="card">
                <div class="row">
                    <div class="col-3">
                        <img src="assets/images/icon-circulo-certificado-digital.png"
                        srcset="assets/images/icon-circulo-certificado-digital@2x.png 2x,
                                assets/images/icon-circulo-certificado-digital@3x.png 3x"
                        class="icon">
                    </div>
                    <div class="col-9">
                        <h3 class="cardTitle">{{'register-page-text4'|translate}}</h3>
                        <p>{{'register-page-text5'|translate}}</p>
                    </div>
                </div>
            </div>-->
            <a [routerLink]="['/registrar-sin-certificado']">
            <div class="card">
                <div class="row">
                    <div class="col-3">
                        <img src="assets/images/icon-circulo-sin-certificado.png"
                        srcset="assets/images/icon-circulo-sin-certificado@2x.png 2x,
                                assets/images/icon-circulo-sin-certificado@3x.png 3x"
                        class="icon">
                    </div>
                    <div class="col-9">
                        <h3 class="cardTitle">{{'register-page-text6'|translate}}</h3>
                        <p>{{'register-page-text7'|translate}}</p>
                    </div>
                </div>
            </div>
            </a>
        </div>
        <div class="col-12 col-lg-6">
            <div class="bgForm">
                <h3 class="cardTitle">{{'register-page-text8'|translate}}</h3>
                <p class="blue2">
                    <img src="assets/images/check-azul.png"
                        srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
                        class="check-azul">
                  {{'register-page-text9'|translate}}
                </p>
                <p class="blue2">
                    <img src="assets/images/check-azul.png"
                        srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
                        class="check-azul">
                  {{'register-page-text10'|translate}}
                </p>
                <p class="blue2">
                    <img src="assets/images/check-azul.png"
                        srcset="assets/images/check-azul@2x.png 2x,
                                assets/images/check-azul@3x.png 3x"
                        class="check-azul">
                  {{'register-page-text11'|translate}}
                </p>
                <p class="blue2">
                  {{'register-page-text12'|translate}}  <br><br>

                  {{'register-page-text13'|translate}}
                </p>
            </div>
        </div>
    </div>
</div>
