<div class="chooseTrademat">
  <div class="" style="    padding: 20px 78px;">
    <h3 class="sectionSubtitle center d-none d-lg-block">{{'choose-trademat-text1'|translate}}</h3>
    <h2 class="sectionTitle center d-none d-lg-block ">{{'choose-trademat-text2'|translate}}</h2>
    <hr class="sectionTitleBar d-none d-lg-block">

    <div class="row no-gutters info">
      <img src="assets/images/grafico-logo-vertical-fill-texto.png"
           srcset="assets/images/grafico-logo-vertical-fill-texto@2x.png 2x,
                        assets/images/grafico-logo-vertical-fill-texto@3x.png 3x"
           class="logoSection">
      <div class="col d-none d-lg-block">
        <p class="chooseP">
          {{'choose-trademat-text3'|translate}}<br><span class="secondary">{{'choose-trademat-text4'|translate}}</span>
        </p>
      </div>
      <h2 class="sectionTitle center d-lg-none ">{{'choose-trademat-text5'|translate}}</h2>
      <hr class="sectionTitleBar  d-lg-none">

    </div>
    <div class="d-lg-none">
      <div class="row info-section mt-4 no-gutters">
        <img src="assets/images/icon-circulo-valores-experiencia.png"
             srcset="assets/images/icon-circulo-valores-experiencia@2x.png 2x,
                                assets/images/icon-circulo-valores-experiencia@3x.png 3x"
             class="icon center col-2">
        <h2 class=" col-auto my-auto m-auto">{{'choose-trademat-text6'|translate}}</h2>
      </div>
      <div class="row info-section  mt-4 no-gutters">
        <h2 class=" col-auto my-auto m-auto">{{'choose-trademat-text7'|translate}}</h2>
        <img src="assets/images/icon-circulo-valores-equipo.png"
             srcset="assets/images/icon-circulo-valores-equipo@2x.png 2x,
                                assets/images/icon-circulo-valores-equipo@3x.png 3x"
             class="icon center col-2">
      </div>
      <div class="row info-section mt-4 no-gutters">
        <img src="assets/images/icon-circulo-valores-tech.png"
             srcset="assets/images/icon-circulo-valores-tech@2x.png 2x,
                                assets/images/icon-circulo-valores-tech@3x.png 3x"
             class="icon center col-2">
        <h2 class=" col-auto my-auto m-auto">{{'choose-trademat-text8'|translate}}</h2>
      </div>
      <div class="row info-section mt-4 no-gutters">
        <h2 class=" col-auto my-auto m-auto">{{'choose-trademat-text9'|translate}}</h2>
        <img src="assets/images/icon-circulo-valores-transparencia.png"
             srcset="assets/images/icon-circulo-valores-transparencia@2x.png 2x,
                                assets/images/icon-circulo-valores-transparencia@3x.png 3x"
             class="icon center col-2">
      </div>
    </div>
    <div class="mt-5"></div>

    <div class="row text-center  d-none d-lg-flex">
      <div class="col-md-3 card-container">

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/icon-circulo-valores-experiencia.png"
                   srcset="assets/images/icon-circulo-valores-experiencia@2x.png 2x,
                                assets/images/icon-circulo-valores-experiencia@3x.png 3x"
                   class="icon center">
              <h2>1. Experiencia</h2>
            </div>
            <div class="flip-card-back">
              {{'choose-trademat-text10'|translate}}
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-3 card-container">

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/icon-circulo-valores-equipo.png"
                   srcset="assets/images/icon-circulo-valores-equipo@2x.png 2x,
                                assets/images/icon-circulo-valores-equipo@3x.png 3x"
                   class="icon center">
              <h2>{{'choose-trademat-text11'|translate}}</h2>
            </div>
            <div class="flip-card-back">
              {{'choose-trademat-text12'|translate}}
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-3 card-container">

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/icon-circulo-valores-tech.png"
                   srcset="assets/images/icon-circulo-valores-tech@2x.png 2x,
                                assets/images/icon-circulo-valores-tech@3x.png 3x"
                   class="icon center">
              <h2>{{'choose-trademat-text13'|translate}}</h2>
            </div>
            <div class="flip-card-back">
              {{'choose-trademat-text14'|translate}}
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-3 card-container">

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/icon-circulo-valores-transparencia.png"
                   srcset="assets/images/icon-circulo-valores-transparencia@2x.png 2x,
                                assets/images/icon-circulo-valores-transparencia@3x.png 3x"
                   class="icon center">
              <h2>{{'choose-trademat-text15'|translate}}</h2>
            </div>
            <div class="flip-card-back">
              {{'choose-trademat-text16'|translate}}
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="d-flex justify-content-center " style="margin-bottom: 77px">
      <button class="btn secondaryBG seeAllButton" [routerLink]="['/registrar-sin-certificado']">{{'choose-trademat-text17'|translate}}</button>
    </div>
  </div>
</div>
