import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  
  selected: number = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

  select(number: number) {
    this.selected = number;
  }
  
}
