import { Component, OnInit } from '@angular/core';
import { DossierService } from "../services/dossier.service";
import {UtilsService} from 'src/app/services/utils.service';

@Component({
  selector: 'app-dossier-page',
  templateUrl: './dossier-page.component.html',
  styleUrls: ['./dossier-page.component.scss']
})
export class DossierPageComponent implements OnInit {
  dossier: any;
  msg: '';
  
  constructor(private dossierService: DossierService, public utils: UtilsService,) { }

  ngOnInit(): void {
    this.getFile();
  }


  /* downloadFile(){
    this.dossierService.downloadFile().subscribe(val => {
      console.log(val);
      this.utils.downloadFile(val, 'pdf', 'Dossier');
    })
  } */

  getFile(){
    this.dossierService.getFile().subscribe(
      result => {
        this.dossier = result['response'].data;
        this.msg = result['message'];
      }
  )}
}
